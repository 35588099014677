import React, { useEffect, useState } from "react";
import { useChat } from "../layout/ChatContext";
import { Button, Space } from "antd";
import shutCameraLogo from "../../assets/images/shut-camera-logo.png";
import video_camera_on from "../../assets/images/video_camera_on.jpg";
import muteOn from "../../assets/images/muteOn.png";
import muteOff from "../../assets/images/muteOff.png";
import video_camera_off from "../../assets/images/camera-video-off.png";
import waitCallLogo from "../../assets/images/wait-call-logo.png";
import redPhone from "../../assets/images/red-phone-icon-hang-button.png";
import Draggable from "react-draggable";
const VideoPlayer = () => {
  const {
    callAccepted,
    myVideo,
    userVideo,
    callEnded,
    stream,
    leaveCall,
    socket,
    adressId,
    idToCall,
  } = useChat();
  let id = adressId || idToCall;
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [voiceEnabled, setVoiceEnabled] = useState(true);
  const [toggleCamera, setToggleCamera] = useState(true);
  const [toggledVoice, setToggledVoice] = useState(true);
  

  const toggleVideo = () => {
    // Toggle the state of videoEnabled
    setVideoEnabled((prevEnabled) => !prevEnabled);

    // Access the user's video track
    const videoTrack = myVideo.current.srcObject
      .getTracks()
      .find((track) => track.kind === "video");

    // Toggle the enabled state of the user's video track
    videoTrack.enabled = !videoEnabled;

    if (socket) {
      // Emit a signal to the server indicating the state of your camera
      socket.emit("toggleCamera", { toggleCamera: !videoEnabled, to: id });
    }
  };
  const toggleVoice = () => {
    // Toggle the state of voiceEnabled
    setVoiceEnabled((prevEnabled) => !prevEnabled);

    // Access the user's Voice track
    const audioTrack = myVideo.current.srcObject
      .getTracks()
      .find((track) => track.kind === "audio");

    // Toggle the enabled state of the user's voice track
    if (audioTrack) {
      audioTrack.enabled = !voiceEnabled;
    }
    if (socket) {
      // Emit a signal to the server indicating the state of your camera
      socket.emit("toggleVoice", { toggleVoice: !voiceEnabled, to: id });
    }
  };

  // useEffect(() => {
  //   socket.on("toggledCamera", (response) => {
  //     setToggleCamera(response.toggleCamera);
  //   });

  //   socket.on("toggledVoice", (response) => {
  //     setToggledVoice(response.toggleVoice);
  //   });
  // }, [socket]);

  // useEffect(() => {
  //   // Define a function to handle the video stream ending
  //   const handleStreamEnded = () => {
  //     leaveCall()
  //   };
   

  //   // Add event listeners to the video element for the "ended" and "error" events
  //   const userVideoElement = userVideo.current;
  //   if (userVideoElement) {
  //     // userVideoElement.addEventListener("pause", handleVideoPause);
  //     userVideoElement.addEventListener("ended", handleStreamEnded);
  //   }

  //   // Clean up by removing the event listeners when the component unmounts
  //   return () => {
  //     if (userVideoElement) {
  //       userVideoElement.removeEventListener("ended", handleStreamEnded);
  //       // userVideoElement.removeEventListener("pause", handleVideoPause);
  //     }
  //   };
  // }, [userVideo]); // Ensure to include userVideo in the dependency array to avoid stale closures

  return (
    <>
      <div>
        {callAccepted && !callEnded ? (
          <Draggable>
            <div
              style={{
                position: "absolute",
                top: 50,
                left: 50,
                width: "49%",
                height: "63%",
                zIndex: "1000",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
              }}
            >
              <video
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: toggleCamera ? "100%" : 0, // Conditionally set the width
                  height: "100%",
                }}
                playsInline
                ref={userVideo}
                autoPlay
                muted={!toggledVoice}
              />

              {stream ? (
                <div
                  span={4}
                  style={{
                    position: "absolute",
                    top: "1%",
                    right: "1%",
                    zIndex: "1001",
                    overflow: "hidden",
                    width: "14%",
                    height: "21%",
                  }}
                >
                  <video
                    playsInline
                    muted
                    ref={myVideo}
                    autoPlay
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    position: "absolute",
                    top: "10%",
                    right: "3%",
                    zIndex: "1000",
                    width: "17%",
                    height: "25%",
                    backgroundColor: "#333",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={shutCameraLogo} alt="Shut Camera Logo" />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "absolute",
                  bottom: "3%",
                  justifyContent: "center",
                }}
              >
                <img
                  src={voiceEnabled ? muteOff : muteOn}
                  style={{
                    width: "6%",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                  alt="video_camera_off"
                  onClick={toggleVoice}
                />
                <img
                  src={redPhone}
                  alt="Red Phone Button (Call End)"
                  style={{
                    width: "7%",
                    marginRight: "2%",
                    marginLeft: "2%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    leaveCall();
                  }}
                />

                <img
                  src={videoEnabled ? video_camera_on : video_camera_off}
                  style={{
                    width: "6%",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                  alt="video_camera_off"
                  onClick={toggleVideo}
                />
              </div>
            </div>
          </Draggable>
        ) : (
          <Draggable>
            <div
              style={{
                position: "absolute",
                top: 50,
                left: 50,
                width: "60%",
                height: "60%",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                zIndex: "1000",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={waitCallLogo} alt="wait Call Logo" />
              {stream ? (
                <div
                  style={{
                    position: "absolute",
                    top: "1%",
                    right: "1%",
                    zIndex: "1000",
                    overflow: "hidden",
                    width: "14%",
                    height: "21%",
                  }}
                >
                  <video
                    playsInline
                    muted
                    ref={myVideo}
                    autoPlay
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    position: "absolute",
                    top: "10%",
                    right: "3%",
                    zIndex: "1000",
                    width: "17%",
                    height: "25%",
                    backgroundColor: "#333",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={shutCameraLogo} alt="Shut Camera Logo" />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "absolute",
                  bottom: "3%",
                  justifyContent: "center",
                }}
              >
                <img
                  src={voiceEnabled ? muteOff : muteOn}
                  style={{
                    width: voiceEnabled ? "5%" : "6%", // Adjust width based on voiceEnabled condition

                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                  alt="video_camera_off"
                  onClick={toggleVoice}
                />
                <img
                  src={redPhone}
                  alt="Red Phone Button (Call End)"
                  style={{
                    width: "6%",
                    marginRight: "2%",
                    marginLeft: "2%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    leaveCall();
                  }}
                />

                <img
                  src={videoEnabled ? video_camera_on : video_camera_off}
                  style={{
                    width: videoEnabled ? "5%" : "6%", // Adjust width based on voiceEnabled condition
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                  alt="video_camera_off"
                  onClick={toggleVideo}
                />
              </div>
            </div>
          </Draggable>
        )}
      </div>
    </>
  );
};

export default VideoPlayer;
