import { Card, Col, Row, Typography } from "antd";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useChat } from "../layout/ChatContext";

const Satistics = (props) => {
  const history = useHistory();
  const count = props.count;
  const { Title } = Typography;
  const { setFilter } = useChat();

  return (
    <div className="layout-content">
      <div className="rowgap-vbox">
        <Card
          bordered={false}
          className="cricleboxFooter"
          onClick={() =>
            {
              if (count.action === "pendingInvoice"){
                setFilter({paid : "pending"});
              }
              history.push({
                pathname: `${count.path}`,
              });
            }
          }
        >
          <div className="number">
            <Row align="middle" gutter={[24, 0]}>
              <Col xs={18}>
                <span>{count.today}</span>
                <Title style={{ marginTop: "3%" }} level={3}>
                  {count.title}{" "}
                  <div>
                    {count.titleDetails !== undefined && (
                      <small style={{ marginLeft: "5%" }}>
                        {count.titleDetails}
                      </small>
                    )}
                  </div>
                  <div>
                    <small className={count.bnb}>{count.persent}</small>{" "}
                    <small>{count.persentDetails}</small>{" "}
                  </div>
                </Title>
              </Col>
              <Col xs={6}>
                <div className="icon-box">{count.icon}</div>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Satistics;
