import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Input,
  message,
  Select,
  Modal,
  Checkbox,
  Radio,
  Space,
} from "antd";
import { formService } from "../../utils/URL";

import React, { useRef, useState } from "react";
import {
  CheckCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import axios from "axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useChat } from "../layout/ChatContext";

const { Option } = Select;

const AddForm = () => {
  const { form } = useChat();
  const [mode, setMode] = useState("add");
  const history = useHistory();
  const formRef = useRef();
  const { TextArea } = Input;
  const token = Cookies.get("token");
  const [formData, setFormData] = useState({
    name: form ? form.name : "",
    description: form ? form.description : "",
  });
  const [word, setWord] = useState("");
  const [wordState, setWordState] = useState(false);
  const [questionData, setQuestionData] = useState({
    id: null,
    question: "",
    questionType: "",
    questionOptions: [],
    value: "",
    questionRequired: false,
  });
  const [questions, setQuestions] = useState(form?.questions ?? []);

  const updateQuestionData = (field, value) => {
    setQuestionData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const handleChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const handleOpen = () => {
    setMode("add");
    setModalVisible(true);
  };
  const handleCancelQuestion = () => {
    setModalVisible(false);
    setQuestionData({
      id: null,
      question: "",
      questionType: "",
      questionOptions: [],
      value: "",
      questionRequired: false,
    });
    setWord("");
  };
  const handleEditQuestionSubmit = () => {
    if (questionData.question !== "" && questionData.questionType !== "") {
      if (questionData.questionType !== "Input") {
        if (questionData.questionOptions.length !== 0) {
          setQuestions((prevQuestions) =>
            prevQuestions.map((q) =>
              q.id === questionData.id ? questionData : q
            )
          );

          setQuestionData({
            id: null,
            question: "",
            questionType: "",
            questionOptions: [],
            value: "",
            questionRequired: false,
          });
          setWord("");
          setModalVisible(false);
          setWordState(false);
          formRef.current.resetFields();
        } else {
          message.error("Tout les chapms sont obligatoire");
        }
      } else {
        setQuestions((prevQuestions) =>
          prevQuestions.map((q) =>
            q.id === questionData.id ? questionData : q
          )
        );

        setQuestionData({
          id: null,

          question: "",
          questionType: "",
          questionOptions: [],
          value: "",
          questionRequired: false,
        });
        setWord("");
        setModalVisible(false);
        setWordState(false);
      }
    } else {
      message.error("Tout les chapms sont obligatoire");
    }
  };
  const handledeleteQuestion = (question) => {
    const updatedQuestions = questions.filter((q) => q.id !== question.id);
    // Set the state with the updated questions array
    setQuestions(updatedQuestions);
  };
  const handleAddQuestion = () => {
    if (questionData.question !== "" && questionData.questionType !== "") {
      if (questionData.questionType !== "Input") {
        if (questionData.questionOptions.length !== 0) {
          setQuestions([
            ...questions,
            { ...questionData, id: Date.now().toString() },
          ]);

          setQuestionData({
            id: null,

            question: "",
            questionType: "",
            questionOptions: [],
            value: "",
            questionRequired: false,
          });
          setWord("");
          setModalVisible(false);
          setWordState(false);
        } else {
          message.error("Tout les chapms sont obligatoire");
        }
      } else {
        setQuestions([
          ...questions,
          { ...questionData, id: Date.now().toString() },
        ]);

        setQuestionData({
          id: null,
          question: "",
          questionType: "",
          questionOptions: [],
          value: "",
          questionRequired: false,
        });
        setWord("");
        setModalVisible(false);
        setWordState(false);
      }
      formRef.current.resetFields();
    } else {
      message.error("Tout les chapms sont obligatoire");
    }
  };
  const addOption = (option) => {
    if (questionData.questionOptions.includes(option)) {
      message.error("Cette option existe déjà.");
      return;
    }
    setQuestionData((prevData) => ({
      ...prevData,
      questionOptions: [...prevData.questionOptions, option],
    }));
    message.success("Option ajouté avec succés.");
    setWord("");
    setWordState(false);
  };
  const handleSubmit = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${formService}/createForm`,
        {
          name: formData.name,
          description: formData.description,
          questions: questions,
        },
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      message.success("Formulaire créé avec succès!");
      history.goBack();
    } catch (error) {
      message.error(error.response.data.message || "Erreur interne du serveur");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleModif = async () => {
    setLoading(true);
    try {
      await axios.put(`${formService}/updateForm/${form.id_form}`, {
        name: formData.name,
        description: formData.description,
        questions: questions,
      });
      message.success("Formulaire modifié avec succès!");
      history.goBack();
    } catch (error) {
      message.error(error.response.data.message || "Erreur interne du serveur");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleDelete = async () => {
    setLoading1(true);
    try {
      await axios.delete(`${formService}/deleteForm/${form.id_form}`);
      message.success("Formulaire supprimé avec succès!");
      history.goBack();
    } catch (error) {
      message.error(error.response.data.message || "Erreur interne du serveur");
      console.error(error);
    } finally {
      setLoading1(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Modal
        title={mode === "add" ? "Ajouter une question" : "Modifier la question"}
        visible={modalVisible}
        onCancel={() => handleCancelQuestion()}
        width={700}
        height={700}
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button key="cancel" onClick={() => handleCancelQuestion()}>
              Annuler
            </Button>

            <Button
              key="add"
              type="primary"
              onClick={() => {
                if (wordState === false) {
                  if (mode === "add") {
                    handleAddQuestion();
                  } else {
                    handleEditQuestionSubmit();
                  }
                } else {
                  message.error(
                    "S'il vous plais confirmez ou supprimez l'option recente avant ajouter une autre"
                  );
                }
              }}
            >
              {mode === "add" ? "Ajouter" : "Modifier"}
            </Button>
          </div>,
        ]}
      >
        <Form layout="vertical" ref={formRef}>
          <Form.Item label="Exigence de la question">
            <Radio.Group
              value={questionData.questionRequired}
              onChange={(e) => {
                updateQuestionData("questionRequired", e.target.value);
              }}
            >
              <Radio value={true}>Oui</Radio>
              <Radio value={false}>Non</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Question">
            <Input
              value={questionData.question}
              style={{ height: "40px" }}
              onChange={(e) => {
                updateQuestionData("question", e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Type de la question">
            <Select
              value={questionData.questionType}
              style={{ width: "100%" }}
              onChange={(e) => {
                updateQuestionData("questionType", e);
              }}
            >
              <Option value="Input">Texte</Option>
              <Option value="Checkbox">Checkbox</Option>
              <Option value="Radio">Radio</Option>
            </Select>
          </Form.Item>
          {modalVisible &&
            (questionData.questionType === "Checkbox" ||
              questionData.questionType === "Radio") && (
              <Form.Item label="Options">
                <Form.List name="options">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: 8,
                            width: "100%",
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "option"]}
                            rules={[
                              {
                                required: true,
                                message:
                                  "Ajouter une option ou supprimer la case ",
                              },
                            ]}
                          >
                            <Space
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Input
                                style={{
                                  width: "100%",
                                }}
                                placeholder="Option"
                                onChange={(e) => {
                                  setWord(e.target.value);
                                  setWordState(true);
                                }}
                              />
                              <CheckCircleOutlined
                                onClick={() => {
                                  if (word !== "") {
                                    addOption(word);
                                  }
                                }}
                              />
                            </Space>
                          </Form.Item>
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(name);
                              setWordState(false);
                              setQuestionData((prevData) => ({
                                ...prevData,
                                questionOptions:
                                  prevData.questionOptions.filter(
                                    (_, index) =>
                                      index !==
                                      fields.findIndex(
                                        (field) => field.key === key
                                      )
                                  ),
                              }));
                              message.success("Option supprimé avec succés.");
                            }}
                          />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            if (wordState === false) {
                              add();
                            } else {
                              message.error(
                                "S'il vous plais confirmez ou supprimez l'option recente avant ajouter une autre"
                              );
                            }
                            setWordState(true);
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Ajouter un option
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            )}
        </Form>
      </Modal>
      <div className="icon-container">
        <Button
          type="text"
          shape="circle"
          icon={<RollbackOutlined />}
          className="circle-icon"
          onClick={() => {
            history.goBack();
          }}
        />
      </div>
      <br />
      <div style={{ width: "60%", textAlign: "center" }}>
        <Card
          style={{
            width: "80%",
            margin: "0 auto",
            marginBottom: "2rem",
            marginTop: "5%",
          }}
        >
          <Form layout="vertical" style={{ marginTop: "2%" }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  label="Nom du formulaire"
                  rules={[
                    {
                      message: "Veuillez entrer un Nom pur ce formulaire",
                    },
                  ]}
                >
                  <Input
                    defaultValue={form?.name}
                    style={{ height: "40px" }}
                    onChange={(e) => {
                      handleChange("name", e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Description"
                  rules={[
                    {
                      // required: formData.buffer_option,
                      message:
                        "Veuillez entrer une description pour ce formulaire",
                    },
                  ]}
                >
                  <TextArea
                    defaultValue={form?.description}
                    style={{ height: "70px" }}
                    onChange={(e) => {
                      handleChange("description", e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <br />
        <Card
          style={{ width: "80%", margin: "0 auto", marginBottom: "2rem" }}
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>Questionnaire</div>
              <Button
                type="text"
                style={{ backgroundColor: "#CDE8E5" }}
                onClick={() => {
                  handleOpen();
                }}
              >
                Ajouter une question
              </Button>
            </div>
          }
        >
          <Form layout="vertical">
            {questions.map((question, index) => (
              <div key={index} style={{ textAlign: "start" }}>
                <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                  {question.questionRequired && "*"} {question.question}
                </p>

                {question.questionType === "Input" && (
                  <Input
                    style={{ marginBottom: "15px", width: "60%" }}
                    placeholder="répondre à cette question"
                  />
                )}
                {question.questionType === "Checkbox" && (
                  <Checkbox.Group
                    options={question.questionOptions}
                    style={{ marginBottom: "15px" }}
                  />
                )}
                {question.questionType === "Radio" && (
                  <Radio.Group style={{ marginBottom: "15px" }}>
                    {question.questionOptions.map((option, idx) => (
                      <Radio key={idx} value={option}>
                        {option}
                      </Radio>
                    ))}
                  </Radio.Group>
                )}

                {/* <EditOutlined
                  onClick={() => handleEditQuestion(question)}
                  style={{
                    cursor: "pointer",
                    fontSize: "24px",
                    position: "absolute",
                    right: "1rem",
                  }}
                /> */}
                <MinusCircleOutlined
                  onClick={() => handledeleteQuestion(question)}
                  style={{
                    cursor: "pointer",
                    fontSize: "24px",
                    position: "absolute",
                    right: "1rem",
                  }}
                />
              </div>
            ))}
          </Form>
        </Card>
        <Button
          loading={loading}
          style={{
            backgroundColor: "#00800087",
            width: "100%",
            maxWidth: "170px", // Adjust the maxWidth as needed
            marginRight: "3%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          type="text"
          onClick={() => {
            if (form !== null) {
              handleModif();
            } else {
              handleSubmit();
            }
          }}
        >
          {form !== null ? "Modifier le formulaire" : "Créer le formulaire "}
        </Button>
        {form !== null && (
          <Button
            loading={loading1}
            style={{
              backgroundColor: "#EE4E4E",
              width: "100%",
              maxWidth: "170px", // Adjust the maxWidth as needed
              marginRight: "3%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            type="text"
            onClick={() => {
              handleDelete();
            }}
          >
            Supprimer le formulaire
          </Button>
        )}

        <Button
          style={{
            backgroundColor: "#ff000054",
            width: "100%",
            maxWidth: "170px", // Adjust the maxWidth as needed
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          type="text"
          onClick={() => {
            history.goBack();
          }}
        >
          Annuler
        </Button>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default AddForm;
