import React, { useEffect, useState } from "react";
import axios from "axios";
import logo from "../assets/images/whiteLogo.png"; // Import the logo image
import { Layout, Button, message, Card } from "antd";
import { appointmentService, userServices } from "../utils/URL";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Footer, Header } from "antd/lib/layout/layout";
const { Content } = Layout;

const Confirm = () => {
  const [appointment, setAppointment] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [doctor, setDoctor] = useState();
  const enterLoading = () => {
    setLoadings(true);
  };

  const { confirmation_token } = useParams();
  const getAppointment = async () => {
    try {
      const response = await axios.get(
        `${appointmentService}/getAppointment/${confirmation_token}`
      );
      setAppointment(response.data);
      const response2 = await axios.get(
        `${userServices}/getDoctor/${response.data.id_doctor}`
      );
      setDoctor(response2.data);
    } catch (error) {
      console.error("Confirmation failed:", error);
    }
  };
 
  const confirmer = async () => {
    try {
      enterLoading(1);
      const response = await axios.get(
        `${appointmentService}/confirmAppointment/${confirmation_token}`
      );
      setLoadings(false);

      setTimeout(() => {}, 6000);
      window.location.href = "/dashboard";
    } catch (error) {
      setLoadings(false);
      console.error("Confirmation failed:", error);
      message.error(error.response.data.message || "Erreur interne du serveur");
    }
  };
  useEffect(() => {
    getAppointment();
  }, []);

  return (
    <>
      <div className="layout-default ant-layout layout-sign-up">
        <Header>
          <div className="header-col header-brand">
            <img
              src={logo}
              alt="Logo"
              style={{ width: "60%", marginTop: "3%" }}
            />
          </div>
        </Header>

        <Content className="p-0">
          <div className="verification-header">
            <div className="content">
              <p class="VerificationTitle" style={{ width: "550px" }}>
                Confirmation du rendez-vous
              </p>
              <p className="verificationT">
                Nous somme ravis de vous avoir parmi nous. Explorez nos
                services, découvrez de nouvelles opportunités
              </p>
            </div>
          </div>

          <Card
            className="card-signup header-solid h-full ant-card pt-0"
            style={{ with: "500px", height: "345px", borderRadius: "12px" }}
            bordered="false"
          >
            <p className="verificationText">
              vous avez un Rendez-vous le {appointment.date} de {appointment.start_time} à {appointment.end_time} avec le médecin {doctor?.user?.first_name} {doctor?.user?.last_name} , cliquer sur ce bouton
              pour confirmer votre render-vous s'il vous plait !
            </p>
            <Button
              loading={loadings}
              // type="text"
              type="primary"
              htmlType="submit"
              style={{ width: "100%", height: "40px", marginBottom: "10%",  }}
              onClick={() => {
                confirmer();
              }}
              class="verification-button"
            >
              Confirmer
            </Button>
          </Card>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2024 Practicia by <a href="#pablo">Block-T</a>.{" "}
          </p>
        </Footer>
      </div>
    </>
  );
};

export default Confirm;
