import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

import { Layout, Button, message, Form, Input, Card } from "antd";
import { userServices } from "../utils/URL";
const { Content } = Layout;

const ForgetPasswordRecovery = () => {
  const [loadings, setLoadings] = useState(false);

  const enterLoading = () => {
    setLoadings(true);
  };
  const token = Cookies.get("token");

  const onFinish = async (values) => {
    try {
      enterLoading(1);
      await axios.put(
        `${userServices}/changePasswordV2`,
        {
          oldPAssword: values.oldPAssword,
          newPassword: values.password,
        },
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      Cookies.remove("token");
      setLoadings(false);
      message.success("Votre mot de passe est changé avec succès");
      // setTimeout(() => {}, 6000);
      // window.location.href = "/";
    } catch (error) {
      setLoadings(false);
      console.error("reset password failed:", error);
      message.error(error.response.data.message || "Erreur interne du serveur");
    }
  };
  const onFinishFailed = (errorInfo) => {
    setLoadings(false);
  };

  return (
    <>
      <div className="">
        {/* <Header>
          <div className="header-col header-brand"></div>
        </Header> */}

        <Content className="p-0">
          {/* <div className="verification-header">
            <div className="content">
              <p class="RecoveryTitle">Réinitialiser le mot de passe</p>
              <p className="verificationT">
                Nous sommes heureux de vous accueillir à nouveau parmi nous .
                Réinitialisez votre mot de passe pour continuer à profiter
                pleinement de notre plateforme.
              </p>
            </div>
          </div> */}

          <Card
            className=" "
            title={
              <p
                style={{ color: "black", fontWeight: "500", fontSize: "21px" }}
              >
                Réinitialiser le mot de passe
              </p>
            }
            bordered="false"
          >
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="row-col"
              layout="vertical"
            >
              <Form.Item
                className="username"
                style={{ with: "90%" , marginLeft:"2%", marginRight:"2%" }}
                label="Ancien mot de passe"
                name="oldPAssword"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir votre ancien mot de passe !",
                  },
                  {
                    min: 8,
                    message: "Au moins 8 caractères requis !",
                  },
                  {
                    pattern: /(?=.*[a-z])/,
                    message: "Au moins une lettre minuscule requise !",
                  },
                  {
                    pattern: /(?=.*[A-Z])/,
                    message: "Au moins une lettre majuscule requise !",
                  },
                  {
                    pattern: /(?=.*\d)/,
                    message: "Au moins un chiffre requis !",
                  },
                  {
                    pattern: /(?=.*[!@#$%^&*])/,
                    message: "Au moins un symbole requis !",
                  },
                ]}
              >
                <Input.Password
                  style={{ width: "100%" }}
                  placeholder="Veuillez saisir votre mot de passe"
                  type="password"
                />
              </Form.Item>
              <Form.Item
                className="username"
                style={{ with: "90%" , marginLeft:"2%", marginRight:"2%" }}
                label="Nouveau mot de passe"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir votre mot de passe !",
                  },
                  {
                    min: 8,
                    message: "Au moins 8 caractères requis !",
                  },
                  {
                    pattern: /(?=.*[a-z])/,
                    message: "Au moins une lettre minuscule requise !",
                  },
                  {
                    pattern: /(?=.*[A-Z])/,
                    message: "Au moins une lettre majuscule requise !",
                  },
                  {
                    pattern: /(?=.*\d)/,
                    message: "Au moins un chiffre requis !",
                  },
                  {
                    pattern: /(?=.*[!@#$%^&*])/,
                    message: "Au moins un symbole requis !",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || value !== getFieldValue("oldPAssword")) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "Le nouveau mot de passe ne peut pas être identique à l'ancien mot de passe."
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  style={{ width: "100%" }}
                  placeholder="Veuillez saisir votre mot de passe"
                  type="password"
                />
              </Form.Item>

              <Form.Item
                className="username"
                style={{ with: "90%" , marginLeft:"2%", marginRight:"2%" }}
                label="Confirmer le mot de passe"
                name="confirm_password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir votre mot de passe !",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "Les champs mot de passe et confirmation du mot de passe ne sont pas conformes."
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  style={{ width: "100%" }}
                  placeholder="Veuillez confirmer votre mot de passe"
                  type="password"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loadings}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "50%" , margin : "0 auto" , backgroundColor: "#01013d", display: "block", }}
                >
                  Soumettre
                </Button>
              </Form.Item>
            </Form>
            {/* <p className="font-semibold text-muted text-center">
              ou{" "}
              <Link to="/" className="font-bold text-dark">
                Se connecter
              </Link>
            </p> */}
          </Card>
        </Content>
        {/* <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2024 Practicia by <a href="#pablo">Block-T</a>.{" "}
          </p>
        </Footer> */}
      </div>
    </>
  );
};

export default ForgetPasswordRecovery;
