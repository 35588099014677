import React, { useRef, useState } from "react";
import { userServices } from "../utils/URL";
import logo from "../assets/images/whiteLogo.png"; // Import the logo image

import {
  Layout,
  Button,
  Typography,
  Card,
  Form,
  Input,
  Checkbox,
  message,
} from "antd";

import { Link } from "react-router-dom";
import axios from "axios";
const { Title } = Typography;
const { Header, Footer, Content } = Layout;

const SignUp = () => {
  const formRef = useRef(null);
  const [role, setRole] = useState("");
  const [activeRole, setActiveRole] = useState(null);

  const [loadings, setLoadings] = useState(false);
  const enterLoading = () => {
    setLoadings(true);
  };
  const setrole = (e) => {
    setRole(e);
    setActiveRole(e);
  };
  const onFinish = async (values) => {
    try {
      enterLoading(1);
      // Exclude "remember" field from merged values
      const { remember, ...mergedValues } = values;
      const postData = { ...mergedValues, role };
      const response = await axios.post(`${userServices}/signup`, postData);

      // message.success(
      //   "created succesfully, You will receive an email to verify your account "
      // );
      message.success(response.data.message);
      formRef.current.resetFields();
      setrole(null);
      setLoadings(false);
      // window.location.href = "/dashboard";
    } catch (error) {
      setLoadings(false);
      console.error("Login failed:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    setLoadings(false);
  };

  return (
    <>
      <div className="layout-default ant-layout layout-sign-up">
        <Header>
          <div className="header-col header-brand">
            <img
              src={logo}
              alt="Logo"
              style={{ width: "60%", marginTop: "3%" }}
            />
          </div>
        </Header>{" "}
        <Content className="p-0">
          <div className="verification-header">
            <div className="content">
              <Title>S'inscrire</Title>
              <p className="text-lg">
                Utilisez ces superbes formulaires pour vous connecter ou créer
                un nouveau compte dans votre projet gratuitement.
              </p>
            </div>
          </div>

          <Card
            className="card-signup header-solid h-full ant-card pt-0"
            title={<h5>Inscrivez-vous en tant que</h5>}
            bordered="false"
          >
            <div className="sign-up-gateways">
              <Button
                type={activeRole === "doctor" ? "primary" : "false"}
                onClick={() => setrole("doctor")}
              >
                Médecin
              </Button>
              <Button
                type={activeRole === "patient" ? "primary" : "false"}
                onClick={() => setrole("patient")}
              >
                Patient
              </Button>
            </div>
            <br />
            <br />
            <br />
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              ref={formRef}
              className="row-col"
              layout="vertical"
            >
              <Form.Item
                className="username"
                style={{ with: "100%" }}
                label="email"
                name="email"
                rules={[
                  { required: true, message: "Veuillez saisir votre email !" },
                  {
                    type: "email",
                    message:
                      "S'il vous plaît, mettez une adresse email valide!",
                  },
                ]}
              >
                <Input placeholder="email" style={{height:"40px"}}/>
              </Form.Item>
              <Form.Item
                className="username"
                style={{ with: "100%" }}
                label="Mot de passe"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir votre mot de passe !",
                  },
                  {
                    min: 8,
                    message: "Au moins 8 caractères requis !",
                  },
                  {
                    pattern: /(?=.*[a-z])/,
                    message: "Au moins une lettre minuscule requise !",
                  },
                  {
                    pattern: /(?=.*[A-Z])/,
                    message: "Au moins une lettre majuscule requise !",
                  },
                  {
                    pattern: /(?=.*\d)/,
                    message: "Au moins un chiffre requis !",
                  },
                  {
                    pattern: /(?=.*[!@#$%^&*])/,
                    message: "Au moins un symbole requis !",
                  },
                ]}
              >
                <Input.Password placeholder="mot de passe" type="password" />
              </Form.Item>
              <Form.Item
                className="username"
                style={{ with: "100%" }}
                label="Confirmer le mot de passe"
                name="confirm_password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir votre mot de passe !",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "Les champs mot de passe et confirmation du mot de passe ne sont pas conformes."
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="mot de passe" type="password" />
              </Form.Item>

              {/* <Form.Item
                className="username"
                label="Nom"
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir votre nom d'utilisateur !",
                  },
                  {
                    pattern: /\S/,
                    message: "Le nom ne peut pas être vide ou contenir seulement des espaces !",
                  },
                ]}
              >
                <Input placeholder="Nom" />
              </Form.Item>
              <Form.Item
                className="username"
                label="Prénom"
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir votre nom d'utilisateur !",
                  },
                  {
                    pattern: /\S/,
                    message: "Le nom ne peut pas être vide ou contenir seulement des espaces !",
                  },
                ]}
              >
                <Input placeholder="Prénom" />
              </Form.Item> */}
              <Form.Item
                className="username"
                name="remember"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            "Veuillez accepter les termes et conditions"
                          ),
                  },
                ]}
              >
                <Checkbox>
                  Je suis d'accord les{" "}
                  <a href="#pablo" className="font-bold text-dark">
                    Termes et conditions
                  </a>
                </Checkbox>
              </Form.Item>

              <Form.Item className="username">
                <Button
                  type="primary"
                  loading={loadings}
                  style={{ width: "100%" }}
                  htmlType="submit"
                >
                  S'INSCRIRE
                </Button>
              </Form.Item>
            </Form>
            <p className="font-semibold text-muted text-center">
              Vous avez déjà un compte?{" "}
              <Link to="/" className="font-bold text-dark">
                Se connecter
              </Link>
            </p>
          </Card>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2024 PB par <a href="#pablo">créatif</a>.{" "}
          </p>
        </Footer>
      </div>
    </>
  );
};
export default SignUp;
