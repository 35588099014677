import { Row, Col, Card, Avatar, message, Upload, Modal } from "antd";
import BgProfile from "../assets/images/bg-profile.jpg";
// import profilavatar from "../assets/images/face-1.jpg";
import { DoctorInformations } from "../components/layout/DoctorInformations";
import { PatientInformations } from "../components/layout/PatientInformations";
import { useEffect, useState } from "react";
import { userServices } from "../utils/URL";
import "react-phone-number-input/style.css";
import axios from "axios";
import Cookies from "js-cookie";
import ImgCrop from "antd-img-crop";
import profileIcon from "../assets/images/profileIcon.png"; // Import the image file
import { useChat } from "../components/layout/ChatContext";

const Profile = () => {
  const { profile } = useChat();
  const token = Cookies.get("token");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [editable, setEditable] = useState(false); // State variable for edit mode
  const [updatedData, setUpdatedData] = useState({}); // State variable for edit mode
  useEffect(() => {
    setUpdatedData({});
  }, [editable]);

  const handleEditClick = () => {
    setEditable(true);
  };

  const handleUpdate = (fieldName, value) => {
    setUpdatedData({
      ...updatedData,
      [fieldName]: value,
    });
  };
  const handleEditCansel = () => {
    setEditable(false);
    setFileList([]);
  };

  const getUser = async () => {
    try {
      if (profile === undefined) {
        const response = await axios.get(`${userServices}/getUser`, {
          headers: {
            "auth-token": token,
          },
        });
        const responseData = response.data.User;
        setData(responseData);
      } else {
        const response = await axios.get(
          `${userServices}/getUserById/${profile}`
        );
        const responseData = response.data.User;
        setData(responseData);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    }
  };
  const handleEdit = async (updatedUserData) => {
    try {
      setLoading(true);

      const response = await axios.put(
        `${userServices}/updateUser`,
        updatedUserData,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setData(response.data.user);
      setEditable(false);
      message.success("données mises à jour avec succès");
      setLoading(false);
    } catch (error) {
      console.error("Error updating user data:", error);
      setLoading(false);

      message.error(
        error.response.data.message ||
          "Échec de la mise à jour des données utilisateur"
      );
    }
  };

  const handleEditSave = async () => {
    try {
      let updatedUserData = { ...updatedData };

      // Check if a new image is selected
      if (fileList.length > 0) {
        const file = fileList[0].originFileObj;
        if (file instanceof Blob) {
          const imageData = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = reject;
          });
          updatedUserData = {
            ...updatedData,
            image: imageData,
          };
        } else {
          updatedUserData = {
            ...updatedData,
            image: null,
          };
        }
      }

      await handleEdit(updatedUserData);
    } catch (error) {
      console.error("Error saving user data:", error);
      message.error("Failed to save user data");
    }
  };

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onloadend = () => resolve(reader.result);
      });
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleChange = ({ file, fileList: newFileList }) => {
    file.status = "done";

    setFileList(newFileList);
  };

  const handleRemove = (file) => {
    setFileList(fileList.filter((item) => item.uid !== file.uid));
  };

  useEffect(() => {
    getUser();

    if (data.image) {
      const fileObject = {
        uid: "-1",
        name: "image.png",
        status: "done",
        url: data.image, // Image URL
      };
      setFileList([fileObject]);
    } else {
      setFileList([]);
    }
  }, [editable, profile]);

  return (
    <>
      <div
        className="profile-nav-bg"
        style={{ backgroundImage: "url(" + BgProfile + ")" }}
      ></div>

      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                {editable ? (
                  <>
                    <ImgCrop rotationSlider>
                      <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        onRemove={handleRemove}
                        showUploadList={{
                          showPreviewIcon: true,
                          showRemoveIcon: true,
                          showDownloadIcon: false,
                        }}
                      >
                        {fileList.length < 1 && "+ Télécharger"}
                      </Upload>
                    </ImgCrop>
                    <Modal
                      visible={previewVisible}
                      onCancel={handleCancel}
                      footer={null}
                      // onOk={handleOk}
                    >
                      <img
                        alt="Preview"
                        style={{ width: "100%" }}
                        src={previewImage}
                      />
                    </Modal>
                  </>
                ) : (
                  // <img src={profileIcon} alt="profileIcon"/>
                  <Avatar
                    size={74}
                    shape="square"
                    src={data.image || profileIcon}
                  />
                )}
                <div className="avatar-info">
                  <h4 className="font-semibold m-0">{data.first_name}</h4>
                  <p>{data.last_name}</p>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <p className={"Exelent"}>
                {data.role === "doctor" ? "Médecin" : "Patient"}
              </p>
            </Col>
          </Row>
        }
      ></Card>
      {data.role === "doctor" ? (
        <DoctorInformations
          loading={loading}
          doctorData={data}
          editable={editable}
          onEditClick={handleEditClick}
          onEditCancel={handleEditCansel}
          updatedData={updatedData}
          onUpdate={handleUpdate}
          onEditSave={handleEditSave}
        />
      ) : (
        <PatientInformations
          loading={loading}
          patientData={data}
          editable={editable}
          onEditClick={handleEditClick}
          onEditCancel={handleEditCansel}
          updatedData={updatedData}
          onUpdate={handleUpdate}
          onEditSave={handleEditSave}
        />
      )}
    </>
  );
};

export default Profile;
