import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import logo from "../assets/images/whiteLogo.png"; // Import the logo image
import { Layout, Button, message, Form, Input, Card } from "antd";
import { userServices } from "../utils/URL";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Footer, Header } from "antd/lib/layout/layout";
const { Content } = Layout;

const ForgetPasswordRecovery = () => {
  const [loadings, setLoadings] = useState(false);
  const { reset_password_code } = useParams();

  const enterLoading = () => {
    setLoadings(true);
  };

  const onFinish = async (values) => {
    try {
      enterLoading(1);
      const response = await axios.put(
        `${userServices}/changePassword/${reset_password_code}`,
        {
          newPassword: values.password,
        }
      );
      const token = response.data.token;
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 1); // 24 hours from now
      Cookies.set("token", token, { expires: expirationDate });
      setLoadings(false);

      setTimeout(() => {}, 6000);
      window.location.href = "/dashboard";
    } catch (error) {
      setLoadings(false);
      console.error("reset password failed:", error);
      message.error(error.response.data.message || "Erreur interne du serveur");
    }
  };
  const onFinishFailed = (errorInfo) => {
    setLoadings(false);
  };

  return (
    <>
      <div className="layout-default ant-layout layout-sign-up">
        <Header>
          <div className="header-col header-brand">
          <img src={logo} alt="Logo" style={{width: "60%", marginTop: "3%"}} />
          </div>
        </Header>

        <Content className="p-0">
          <div className="verification-header">
            <div className="">
              <p class="RecoveryTitle">Réinitialiser le mot de passe</p>
              <p className="verificationTRR" >
                Nous sommes heureux de vous accueillir à nouveau parmi nous .
                Réinitialisez votre mot de passe pour continuer à profiter
                pleinement de notre plateforme.
              </p>
            </div>
          </div>

          <Card
            className="card-signup header-solid h-full ant-card pt-0"
            title={<h5>Réinitialiser le mot de passe</h5>}
            bordered="false"
          >
            <br />

            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="row-col"
              layout="vertical"
            >
              <Form.Item
                className="username"
                style={{ with: "100%" }}
                label="Nouveau mot de passe"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir votre mot de passe !",
                  },
                  {
                    min: 8,
                    message: "Au moins 8 caractères requis !",
                  },
                  {
                    pattern: /(?=.*[a-z])/,
                    message: "Au moins une lettre minuscule requise !",
                  },
                  {
                    pattern: /(?=.*[A-Z])/,
                    message: "Au moins une lettre majuscule requise !",
                  },
                  {
                    pattern: /(?=.*\d)/,
                    message: "Au moins un chiffre requis !",
                  },
                  {
                    pattern: /(?=.*[!@#$%^&*])/,
                    message: "Au moins un symbole requis !",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Veuillez saisir votre mot de passe"
                  type="password"
                />
              </Form.Item>
              <Form.Item
                className="username"
                style={{ with: "100%" }}
                label="Confirmer le mot de passe"
                name="confirm_password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir votre mot de passe !",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "Les champs mot de passe et confirmation du mot de passe ne sont pas conformes."
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Veuillez confirmer votre mot de passe"
                  type="password"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loadings}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%", marginTop: "7%" }}
                >
                  Soumettre
                </Button>
              </Form.Item>
            </Form>
            <p className="font-semibold text-muted text-center">
              ou{" "}
              <Link to="/" className="font-bold text-dark">
                Se connecter
              </Link>
            </p>
          </Card>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2024 Practicia by <a href="#pablo">Block-T</a>.{" "}
          </p>
        </Footer>
      </div>
    </>
  );
};

export default ForgetPasswordRecovery;
