import {
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Input,
  message,
  Space,
  DatePicker,
  List,
  Select,
  TimePicker,
  Checkbox,
  ConfigProvider,
  Tooltip,
} from "antd";
import {
  appointmentService,
  LocationService,
  userServices,
  noteService,
  availibilityService,
  packageService,
  serviceService,
} from "../utils/URL";
import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { CalendarOutlined, ReloadOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import moment from "moment";
import CalendarToolbar from "../components/layout/CustomToolbar";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import frFR from "antd/lib/locale/fr_FR";
import { useHistory } from "react-router-dom";
import frenchTimezones from "../components/layout/frenchTimezones";
import { FaLock } from "react-icons/fa";
import { GiEarthAmerica } from "react-icons/gi";
import jwt from "jsonwebtoken";
import note from "../assets/images/note.png"; // Import the logo image
import rearange from "../assets/images/rearange.png"; // Import the logo image
import rejected from "../assets/images/rejected.png"; // Import the logo image
import { debounce } from "lodash";

const localizer = momentLocalizer(moment);

const getInitials = (name) => {
  const [firstName, lastName] = name.split(" ");
  const initials = firstName.charAt(0) + lastName.charAt(0);
  return initials.toUpperCase();
};
const { Option } = Select;

const MyBookings = () => {
  const [reloadStatus, setReloadStatus] = useState(false);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [patientData, setPatientData] = useState({});
  const [bookings, setBookings] = useState([]);
  const [searchData, setSearchData] = useState({
    legal_first_name: "",
    last_name: "",
    date: "",
    name: "",
  });
  const [patientOriginalData, setPatientOriginalData] = useState({});
  const token = Cookies.get("token");
  const decoded = jwt.decode(token);
  const [id_user, setIdUser] = useState(decoded?.id_user);
  const [patients, setPatients] = useState([]);
  const [modal2Open, setModal2Open] = useState(false);
  const [modalPackOpen, setModalPackOpen] = useState(false);
  const [modal3Open, setModal3Open] = useState(false);
  const [modal4Open, setModal4Open] = useState(false);
  const [available, setAvailable] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [modalMode, setModalMode] = useState("create"); // Add modal mode state
  const [availabilityData, setAvailabilityData] = useState([]);
  const [bookData, setBookData] = useState({});
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isModalCreationOpen, setIsModalCreationOpen] = useState(false);
  const [noteFormData, setNoteFormData] = useState({});
  const [packs, setPacks] = useState([]);
  const [localData, setLocalData] = useState();
  const [AfterSession, setAfterSession] = useState(0);
  const [services, setServices] = useState([]);
  const [servicesOptions, setServicesOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [loadingState, setLoadingState] = useState({
    submitNote :false,
    delete: false,
    submit: false,
  });
  const history = useHistory();

  const openModaCreation = () => {
    setIsModalCreationOpen(true);
    setModal3Open(false);
  };
  const getLabelFromId = (id) => {
    const local = localData?.find((local) => local.id === id);
    return local ? `${local.title} (${local.bigtitle})` : "";
  };
  const { TextArea } = Input;
  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours > 0 ? `${hours} heure${hours > 1 ? "s" : ""} ` : ""}${
      remainingMinutes > 0
        ? `${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}`
        : ""
    }`;
  };
  const showNoteModal = () => {
    setIsModalOpen(true);
  };
  const showConfirmModal = () => {
    setConfirmModalOpen(true);
  };
  const handleOkNote = async () => {
    const updatedNoteFormData = {
      ...noteFormData,
      id_appointment: bookData.id_appointment,
      id_doctor: bookData.id_doctor,
    };

    try {
      setLoadingState((prevState) => ({ ...prevState, submitNote: true }));

      await axios.post(`${noteService}/createNote`, updatedNoteFormData);
      message.success("Votre note est ajoutée avec succès");
      setNoteFormData({});
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error creating note:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    }
    setLoadingState((prevState) => ({ ...prevState, submitNote: false }));

  };
  const handleCancelNote = () => {
    setNoteFormData({});
    setIsModalOpen(false);
  };
  const handleChangeDataSearch = (fieldName, value) => {
    setSearchData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };
  const handleChange = (fieldName, value) => {
    if (fieldName === "buffer_option" && value === "false") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        buffer_option: value,
        before_session: "0",
        after_session: "0",
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: value,
      }));
    }
  };
  const fetchPatients = async () => {
    try {
      const response = await axios.get(`${userServices}/getPatients`, {
        headers: {
          "auth-token": token,
        },
      });
      const sortedPatients = response.data.patients.sort((a, b) =>
        `${a.user.first_name} ${a.user.last_name}`.localeCompare(
          `${b.user.first_name} ${b.user.last_name}`
        )
      );
      setPatients(sortedPatients);
      setFilteredPatients(sortedPatients);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };
  const fetchServices = async () => {
    try {
      const response = await axios.get(
        `${serviceService}/getServiceByIdDoctor/${id_user}`
      );

      // Sort the appointments array by the time of creation
      const sortedServices = response.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      setServices(sortedServices);

      const Options = [
        { label: "Tout", value: "" },
        ...sortedServices.map((service) => ({
          label: service.name,
          value: service.name,
        })),
      ];

      setServicesOptions(Options);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };
  const fetchBookings = async () => {
    try {
      const [availabilityResponse, appointmentResponse] = await Promise.all([
        axios.get(`${availibilityService}/getAllAvailability`, {
          headers: { "auth-token": token },
        }),
        axios.get(
          `${appointmentService}/searchAppointments?legal_first_name=${searchData.legal_first_name.trim()}&last_name=${searchData.last_name.trim()}&date=${
            searchData.date
          }&name=${searchData.name.trim()}`,
          {
            headers: { "auth-token": token },
          }
        ),
      ]);

      // setAvailabilityData(availabilityResponse.data);

      // Sort the appointments array by the time of creation
      const sortedAppointments = appointmentResponse.data.appointments.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      if (
        !availabilityResponse.data ||
        availabilityResponse.data.length === 0
      ) {
        console.error("availabilityData is not loaded or is empty.");
        return;
      }

      const booking = sortedAppointments.map((appointment) => {
        const id_locale = appointment?.Availability?.id_locale;
        const video_conferencing =
          appointment?.Availability?.video_conferencing;

        return {
          key: appointment.id_appointment,
          name: `${appointment.patient_name} `,
          image: getInitials(`${appointment.patient_name} `),
          time: moment(`${appointment.date} ${appointment.start_time}`).format(
            "ddd MMM D @ hh:mm A"
          ),
          confirmed: appointment.confirmed,
          name_service: appointment?.Service?.name,
          time_service: moment(appointment?.Service?.created_at).format(
            "ddd MMM D @ hh:mm A"
          ),
          duration_service: appointment?.Service?.duration,
          type_service: appointment?.Service?.type,
          id_locale: appointment?.Availability?.id_locale,
          video_conferencing: appointment?.Availability?.video_conferencing,
          correspondance: appointment?.correspondance,
          location: appointment.buffer_option
            ? ` ${appointment.after_session} minutes after session and ${appointment.before_session} minutes before session `
            : `Buffer options is null`,
          actions: [
            {
              action: "Confirmer",
              color: "#3a86ff",
              icon: rejected,
              test:
                appointment.confirmed !== "confirmed" &&
                appointment?.correspondance === "patient",

              onclick: (key) => {
                const data = sortedAppointments.find(
                  (appointment) => appointment.id_appointment === key
                );
                const updatedData = { ...data, id_locale, video_conferencing };
                setBookData(updatedData);
                showConfirmModal();
              },
            },
            {
              action: "Ajouter note",
              color: "#0D9276",
              icon: note,
              test: true,

              onclick: (key) => {
                const data = sortedAppointments.find(
                  (appointment) => appointment.id_appointment === key
                );
                const updatedData = { ...data, id_locale, video_conferencing };
                setBookData(updatedData);
                showNoteModal();
              },
            },
            {
              action: "Réarranger",
              color: "#F08B09",
              icon: rearange,
              test: true,

              onclick: (key) => {
                handlePatientClick("update");
                const data = sortedAppointments.find(
                  (appointment) => appointment.id_appointment === key
                );
                const updatedData = { ...data, id_locale, video_conferencing };
                setBookData(updatedData);
                setServices(updatedData?.Availability?.Services);
                setFormData(updatedData);
              },
            },
          ],
        };
      });

      setBookings(booking);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const createAppointment = async () => {
    try {
      const dataforCreation = {
        ...formData,
        id_patient: selectedPatient.id_patient,
      };
      await axios.post(
        `${appointmentService}/createAppointment`,
        dataforCreation,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setBookData({});
      await fetchBookings();
      message.success("Rendez-vous créé avec succès");
      setVisible(false);
      setModal3Open(false);
      setIsModalCreationOpen(false);
    } catch (error) {
      console.error("Error creating patient:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
        if (
          error.response.data.message ===
          "Aucune disponibilité correspondante trouvée."
        ) {
          openModaCreation(true);
        }
      } else {
        message.error("Erreur interne du serveur");
      }
    } finally {
      setConfirmLoading(false);
    }
  };
  const updateAppointment = async () => {
    try {
      const id = bookData.id_appointment;
      await axios.put(
        `${appointmentService}/updateAppointmentById/${id}`,
        formData
      );
      setBookData({});
      await fetchBookings();
      message.success("Rendez-vous mise à jour avec succès");
      setVisible(false);
      setModal3Open(false);
    } catch (error) {
      console.error("Error updating :", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    } finally {
      setConfirmLoading(false);
    }
  };
  const ConfirmAppointment = async () => {
    try {
      await axios.put(
        `${appointmentService}/confirmAppointmentForDoctor/${bookData.id_appointment}`,
        {},
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setBookData({});
      await fetchBookings();
      message.success("Rendez-vous confirmmé avec succès");
      setConfirmModalOpen(false);
    } catch (error) {
      console.error("Error updating :", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    } finally {
      setConfirmLoading(false);
    }
  };
  const DeleteAppointment = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, delete: true }));

      const id = bookData.id_appointment;
      await axios.delete(`${appointmentService}/deleteAppointmentById/${id}`);
      message.success("Rendez-vous supprimé avec succès");
      setBookData({});
      await fetchBookings();
      setModal3Open(false);
      setVisibleDelete(false);
    } catch (error) {
      message.error(
        error.response.data.message ||
          "Erreur lors de la suppression du rendez-vous"
      );
      console.error("Error deleting appointment:", error);
    }
    setLoadingState((prevState) => ({ ...prevState, delete: false }));

  };

  const createAvailability = async (data) => {
    try {
      const dataforCreation = {
        ...data,
        description:
          "cette disponibilité a été forcement crée afin de crée une réservation ",
        color: "#ffb703",
        service_ids: [data.id_service],
      };

      await axios.post(
        `${availibilityService}/createAvailability`,
        dataforCreation,
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      message.success("Disponibilités ajoutées avec succès");
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Erreur interne du serveur"
      );
      console.error("Error creating availabilities:", error);
    }
  };
  const handleOk = async () => {
    setLoadingState((prevState) => ({ ...prevState, submit: true }));

    if (modalMode === "create") {
      await createAppointment(formData);
    } else if (modalMode === "update") {
      await updateAppointment();
    }
    setLoadingState((prevState) => ({ ...prevState, submit: false }));

  };
  const handleCancel = () => {
    setModal3Open(false);
  };
  const handleForcedOk = async () => {
    await createAvailability(formData);
    await createAppointment(formData);
  };
  const handleForcedCancel = () => {
    setBookData({});
    setFormData({});
    setModal4Open(false);
  };
  const fetchPackages = async () => {
    try {
      const response = await axios.get(
        `${packageService}/getPackageByIdDoctor`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      // Sort the appointments array by the time of creation
      const sortedPacks = response.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      const packs = sortedPacks.map((pack) => ({
        key: pack.id_package,
        name: `${pack.name} `,
        image: getInitials(`${pack.name} `),
        description: pack.description,
        condition: pack.condition,
        SKU: pack.SKU,
        time: moment(pack.created_at).format("ddd MMM D @ hh:mm A"),
        price_Service: pack.price_Service,
      }));

      setPacks(packs);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };
  // useEffect(() => {
  //   fetchAvailibilities();
  // }, [formData.id_service]);
  // useEffect(() => {
  //   const fetchAllData = async () => {
  //     await fetchAvailibilities();
  //     await fetchBookings();
  //   };
  //   fetchServices();
  //   fetchAllData();
  //   fetchPatients();
  //   fetchPackages();
  //   fetchData();
  // }, [confirmLoading]);
  // useEffect(() => {
  //   handleData(patientData.id_patient);
  // }, [patients]);
  // useEffect(() => {
  //   fetchAvailibilityDataBydate(
  //     formData.date,
  //     formData.start_time,
  //     formData.end_time
  //   );
  // }, [formData.date, formData.start_time, formData.end_time]);

  // useEffect(() => {
  //   setSearchData({
  //     ...searchData,

  //     legal_first_name: "",
  //     last_name: "",
  //     date: "",
  //     name: "",
  //   });

  //   fetchBookings();
  // }, [reloadStatus]);

  useEffect(() => {
    const fetchAllData = async () => {
      await Promise.all([fetchAvailibilities(), fetchBookings()]);
    };
    fetchServices();
    fetchAllData();
    fetchPatients();
    fetchPackages();
    fetchData();
  }, [confirmLoading]);
  const fetchAvailabilityDebounced = useCallback(
    debounce((formData) => {
      fetchAvailibilityDataBydate(
        formData.date,
        formData.start_time,
        formData.end_time
      );
    }, 300),
    []
  );

  useEffect(() => {
    fetchAvailabilityDebounced(formData);
  }, [formData.date, formData.start_time, formData.end_time]);

  useEffect(() => {
    fetchAvailibilities();
  }, [formData.id_service, formData.id_locale]);

  useEffect(() => {
    handleData(patientData.id_patient);
  }, [patients]);

  const handleData = (patientIds) => {
    const filteredPatients = patients.filter(
      (patient) => patient.id_patient === patientIds
    );

    setPatientOriginalData(selectedPatient);
  };

  const fetchAvailibilities = async () => {
    try {
      let response;
      if (formData.id_service === undefined && formData.id_locale === undefined) {
        response = await axios.get(
          `${availibilityService}/getAllAvailability`,
          {
            headers: {
              "auth-token": token,
            },
          }
        );
      } else {
        response = await axios.get(
          `${availibilityService}/getAllAvailabilityByService/${formData.id_service}/${formData.id_locale}`,
          {
            headers: {
              "auth-token": token,
            },
          }
        );
      }

      setAvailabilityData(response.data);
    } catch (error) {
      console.error("Error creating availability:", error);
      message.error(
        error.response.data.message ||
          "Erreur Internet du serveur"
      );
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${LocationService}/getLocationByIdUser`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setLocalData(response.data.location);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleDelete = async () => {
    await DeleteAppointment();
  };
  const handleDatePickerChange = (date, dateString) => {
    setSelectedDate(moment(date));
  };
  const handleNavigate = (view, date) => {
    if (view === Views.MONTH) {
      setSelectedDate(moment(date));
    } else if (view === Views.YEAR) {
      setSelectedDate(moment(date));
    } else {
      setSelectedDate(moment(date));
    }
  };

  const openModal2create = () => {
    setModal2Open(true);
    setModalMode("create");
  };
  const openModal2book = () => {
    setModal2Open(true);
    setModalMode("book");
  };

  const handlePatientClick = (mode, patient) => {
    setModalMode(mode);
    if (mode === "create") {
      setSelectedPatient(patient);
      handleChange("id_patient", patient.id_patient);
      setBookData({});
      setFormData({});
      fetchServices();
      setModal3Open(true);
      setModal2Open(false);
    } else if (mode === "book") {
      setModal2Open(false);
      history.push({
        pathname: `/addBooking/${patient.id_user}`,
      });
    } else {
      // setServices(appointment.Availability.Services);
      setModal3Open(true);
      setSelectedPatient(patient);
    }
  };
  const handlePackageClick = (mode, pack) => {
    setModalMode(mode);
    if (mode === "create") {
      handleChange("id_package", pack.key);
      setModal3Open(true);
      setModalPackOpen(false);
    } else if (mode === "book") {
      setModal2Open(false);
      history.push({
        pathname: `/addBooking/${selectedPatient.id_user}/${pack.key}`,
      });
    } else {
      setModalPackOpen(false);
      handleChange("id_package", pack.key);
      setModal3Open(true);
    }
  };
  const fetchAvailibilityDataBydate = async (date, start_time, end_time) => {
    try {
      const response = await axios.post(
        `${availibilityService}/checkAvailability`,
        { date, start_time, end_time },
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setAvailable(response.data.available);
      handleChange("id_locale", response.data?.availability?.id_locale);
      handleChange(
        "video_conferencing",
        response.data?.availability?.video_conferencing
      );
      setServices(response.data?.availability?.Services);
      if (response.data.available === false) {
        fetchServices();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSelectEvent = (event) => {
    const dateObject = new Date(event.start);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(dateObject.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    handleChange("date", formattedDate);
    handleChange("start_time", event.start_time);
    handleChange("end_time", event.end_time);
    handleChange("id_locale", event.id_locale);
    handleChange("video_conferencing", event.video_conferencing);
    setServices(event.Services);
  };
  const extractTime = (date) => {
    return date.toTimeString().split(" ")[0];
  };
  const handleSlotEvent = (event) => {
    const dateObject = new Date(event.start);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(dateObject.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    handleChange("date", formattedDate);
    handleChange("start_time", extractTime(new Date(event.start)));
    handleChange("end_time", extractTime(new Date(event.end)));
    handleChange("id_locale", event.id_locale);
    handleChange("video_conferencing", event.video_conferencing);
    setServices(event.Services);
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = patients.filter(
      (patient) =>
        patient.user.first_name.toLowerCase().includes(value) ||
        patient.user.last_name.toLowerCase().includes(value) ||
        patient.user.email.toLowerCase().includes(value)
    );
    setFilteredPatients(filtered);
  };

  const events = availabilityData.map((availability) => {
    const startDateString = `${availability.date}T${availability.start_time}`;
    const endDateString = `${availability.date}T${availability.end_time}`;
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    return {
      title: availability.description,
      start: startDate,
      end: endDate,
      description: availability.description,
      color: availability.color,
      id_availibility: availability.id_availibility,
      timezone: availability.timezone,
      start_time: availability.start_time,
      end_time: availability.end_time,
      service_availability: availability.service_availability,
      id_doctor: availability.id_doctor,
      id_locale: availability.id_locale,
      video_conferencing: availability.video_conferencing,
      Services: availability.Services,
    };
  });

  return (
    <>
      <Modal
        title="Confirmer ce rendez-vous "
        open={confirmModalOpen}
        onOk={() => {
          ConfirmAppointment();
        }}
        onCancel={() => {
          setConfirmModalOpen(false);
        }}
      >
        <p> S'il y a d'autres rendez-vous au meme temps ils vont être rejeté</p>
      </Modal>
      <Modal
        title="Création du resérvation"
        visible={isModalCreationOpen}
        onCancel={() => setIsModalCreationOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalCreationOpen(false)}>
            Annuler
          </Button>,
          <Button
            key="delete"
            type="primary"
            danger
            onClick={() => {
              handleForcedOk();
              // setModal4Open(true);
              // fetchServices();
            }}
          >
            Forcer
          </Button>,
        ]}
      >
        <p>Voulez vous forcer la création du resérvation ?</p>
      </Modal>
      <div
        className="setting-drwer"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#CDFADB",
        }}
        onClick={openModal2book}
      >
        <CalendarOutlined />
      </div>

      <div>
        <div className="tabled table-card">
          <Row justify="end" style={{ marginBottom: 20 }}>
            <Col style={{ width: "20%" }}>
              <Button
                type="text"
                style={{ backgroundColor: "#CDFADB", width: "92%" }}
                onClick={openModal2create}
              >
                Réservez une séance
              </Button>
              <Modal
                title="Sélectionnez un patient"
                centered
                visible={modal2Open}
                onOk={() => setModal2Open(false)}
                onCancel={() => setModal2Open(false)}
                footer={[
                  <div
                    key="footer"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      key="cancel"
                      onClick={() => {
                        setModal2Open(false);
                      }}
                    >
                      Annuler
                    </Button>
                  </div>,
                ]}
              >
                <Input
                  type="text"
                  placeholder="Rechercher par nom ou par email "
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{ marginBottom: "16px" }}
                />

                {filteredPatients.map((patient) => (
                  <Space
                    key={patient.id_patient}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                    }}
                    onClick={() => handlePatientClick(modalMode, patient)}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          backgroundColor: "lightblue",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "8px",
                        }}
                      >
                        {getInitials(
                          `${patient.user.first_name} ${patient.user.last_name}`
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <strong>{`${patient.user.first_name} ${patient.user.last_name}`}</strong>
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#C7C8CC",
                          fontSize: "12px",
                          marginLeft: "7%",
                          width: "100%",
                        }}
                      >
                        {patient.Doctors.length > 0 ? "Invité" : "Non invité"}
                      </span>
                    </div>
                  </Space>
                ))}
              </Modal>
              {/* <Modal
                    title="Sélectionnez un paquet"
                    centered
                    width={600}
                    visible={modalPackOpen}
                    onOk={() => setModalPackOpen(false)}
                    onCancel={() => setModalPackOpen(false)}
                    footer={[
                      <div
                        key="footer"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          key="cancel"
                          onClick={() => {
                            setModalPackOpen(false);
                          }}
                        >
                          Annuler
                        </Button>
                      </div>,
                    ]}
                  >
                    <div className="table-responsive">
                      <List
                        style={{
                          height: "400px",
                        }}
                        dataSource={packs}
                        renderItem={(item, index) => (
                          <List.Item
                            key={`${item.key}_${index}`}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              borderBottom: "1px solid #f0f0f0",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "start",
                                width: "500px",
                                height: "100px",
                                marginLeft: "3%",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handlePackageClick(modalMode, item)
                              }
                            >
                              <div>
                                <h3>{item.name}</h3>
                                <p>{item.time}</p>
                                <p
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    color: "grey",
                                  }}
                                >
                                  1 hour @ C€
                                  {item?.price_Service?.toFixed(2)}
                                </p>
                              </div>
                            </div>
                          </List.Item>
                        )}
                      />
                    </div>
                  </Modal> */}
              <Modal
                title={
                  modalMode === "create"
                    ? "Ajouter une réservation"
                    : "Modifier la réservation"
                }
                centered
                width={1000}
                visible={modal3Open}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose
                okText={modalMode === "create" ? "Enregistrer" : "Modifier "}
                cancelText="Annuler"
                style={{ marginBottom: "8%" }}
                afterClose={handleCancel}
                onValuesChange={handleChange}
                footer={[
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    key="footer"
                  >
                    {modalMode === "update" ? (
                      <>
                        <Button
                          key="delete"
                          type="danger"
                          // onClick={handleDelete}
                          onClick={() => {
                            setVisibleDelete(true);
                          }}
                        >
                          Supprimer
                        </Button>
                        <Modal
                          title="Confirm Deletion"
                          visible={visibleDelete}
                          onCancel={() => setVisibleDelete(false)}
                          footer={[
                            <Button
                              key="cancel"
                              onClick={() => setVisibleDelete(false)}
                            >
                              Annuler
                            </Button>,
                            <Button
                              loading={loadingState.delete}
                              key="delete"
                              type="primary"
                              danger
                              onClick={handleDelete}
                            >
                              Supprimer
                            </Button>,
                          ]}
                        >
                          <p>
                            Êtes-vous sûr de vouloir supprimer ce rendez vous ?
                          </p>
                        </Modal>
                      </>
                    ) : (
                      <>
                        <p></p>
                      </>
                    )}
                    <div key="right-buttons" style={{ display: "flex" }}>
                      <Button key="cancel" onClick={handleCancel}>
                        Annuler
                      </Button>
                      <Button key="submit" type="primary" onClick={handleOk}   loading={loadingState.submit}>
                        {modalMode === "create" ? "Sauvegarder" : "Modifier"}
                      </Button>
                    </div>
                  </div>,
                ]}
              >
                <Row>
                  <Col span={14}>
                    <Form layout="vertical">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Form.Item
                            label="Fuseau horaire"
                            style={{ marginTop: "7%" }}
                            rules={[
                              {
                                required: true,
                                message:
                                  "Veuillez sélectionner le fuseau horaire!",
                              },
                            ]}
                          >
                            <ConfigProvider locale={frFR}>
                              <Select
                                defaultValue={formData.timezone || ""}
                                onChange={(timezone) => {
                                  handleChange("timezone", timezone);
                                }}
                                placeholder="Sélectionner un fuseau horaire"
                                className="custom-timezone-select"
                                style={{
                                  fontWeight: "lighter",
                                  width: "100%",
                                }}
                              >
                                {frenchTimezones.map((timezone) => (
                                  <Option
                                    key={timezone.value}
                                    value={timezone.value}
                                  >
                                    {timezone.label}
                                  </Option>
                                ))}
                              </Select>
                            </ConfigProvider>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item label="Services">
                            <Select
                              defaultValue={
                                `${
                                  services?.find(
                                    (service) =>
                                      service?.id_service ===
                                      formData?.id_service
                                  )?.name || "Pas de service"
                                } - ${
                                  services?.find(
                                    (service) =>
                                      service?.id_service ===
                                      formData?.id_service
                                  )?.type || ""
                                }` || ""
                              }
                              onChange={(value) => {
                                const selectedService = services?.find(
                                  (service) => service?.id_service === value
                                );
                                handleChange("id_service", value);
                                handleChange(
                                  "after_session",
                                  selectedService?.buffer_time
                                );
                                setAfterSession(selectedService?.buffer_time);
                              }}
                              className="custom-timezone-select"
                              style={{ width: "100%" }}
                            >
                              {services?.map((service) => (
                                <Option
                                  key={service.id_service}
                                  value={service.id_service}
                                >
                                  {`${service.name} (${
                                    service.type === "public"
                                      ? "publique"
                                      : "privée"
                                  })`}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={14}>
                          <Form.Item label="Emplacement">
                            {/* {available ? (
                              <Input
                                style={{
                                  width: "100%",
                                  height: "40px",
                                  borderRadius: "5px",
                                }}
                                value={
                                  `${
                                    localData?.find(
                                      (local) =>
                                        local?.id === formData?.id_locale
                                    )?.title || "Pas d'emplacement "
                                  }   -  ${
                                    localData?.find(
                                      (local) =>
                                        local?.id === formData?.id_locale
                                    )?.bigtitle || ""
                                  }` || ""
                                }
                                readOnly
                              />
                            ) : ( */}
                              <ConfigProvider locale={frFR}>
                                <Select
                                  value={{
                                    key: formData.id_locale,
                                    label: getLabelFromId(formData.id_locale),
                                  }}
                                  onChange={(value) => {
                                    handleChange("id_locale", value.key);
                                  }}
                                  placeholder="Sélectionner un Emplacement"
                                  className="custom-timezone-select"
                                  style={{ width: "100%" }}
                                  optionLabelProp="label"
                                  labelInValue
                                >
                                  {localData?.map((local) => (
                                  <Option
                                    key={local.id}
                                    value={local.id}
                                    label={`${local.title} (${local.bigtitle})`}
                                  >
                                    {local.title} ({local.bigtitle})
                                  </Option>
                                ))}
                                </Select>
                              </ConfigProvider>
                            {/* )} */}
                          </Form.Item>
                        </Col>
                        
                        <Col span={10}>
                          <Form.Item label="Type">
                            <Input
                              style={{
                                width: "100%",
                                height: "40px",
                                borderRadius: "5px",
                              }}
                              
                              value={`${
                                formData.video_conferencing === "online"
                                  ? "Video Conférence"
                                  : formData.video_conferencing === "locale"
                                  ? "présentiel"
                                  : "Video Conférence ou présentiel"
                              }`}
                              readOnly
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label="Date"
                            rules={[
                              {
                                required: true,
                                message: "Veuillez sélectionner la date!",
                              },
                            ]}
                          >
                            <ConfigProvider locale={frFR}>
                              <DatePicker
                                value={
                                  formData.date ? moment(formData.date) : null
                                }
                                onChange={(value) => {
                                  handleDatePickerChange(value);
                                  handleChange(
                                    "date",
                                    value ? value.format("YYYY-MM-DD") : ""
                                  );
                                }}
                                style={{
                                  width: "100%",
                                  height: "40px",
                                  borderRadius: "5px",
                                }}
                                allowClear={false}
                              />
                            </ConfigProvider>
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Heure de début"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Veuillez sélectionner l'heure de début!",
                              },
                            ]}
                          >
                            <ConfigProvider locale={frFR}>
                              <TimePicker
                                value={
                                  formData.start_time
                                    ? moment(formData.start_time, "HH:mm:ss")
                                    : null
                                }
                                onSelect={(value) => {
                                  handleChange(
                                    "start_time",
                                    value ? value.format("HH:mm:ss") : ""
                                  );

                                  // handleChange(
                                  //   "date",
                                  //   selectedDate
                                  //     ? selectedDate.format("YYYY-MM-DD")
                                  //     : ""
                                  // );
                                }}
                                style={{
                                  width: "100%",
                                  height: "40px",
                                  borderRadius: "5px",
                                }}
                              />
                            </ConfigProvider>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Heure de fin"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Veuillez sélectionner l'heure de fin!",
                              },
                            ]}
                          >
                            <ConfigProvider locale={frFR}>
                              <TimePicker
                                value={
                                  formData.end_time
                                    ? moment(formData.end_time, "HH:mm:ss")
                                    : null
                                }
                                onSelect={(value) =>
                                  handleChange(
                                    "end_time",
                                    value ? value.format("HH:mm:ss") : ""
                                  )
                                }
                                style={{
                                  width: "100%",
                                  height: "40px",
                                  borderRadius: "5px",
                                }}
                              />
                            </ConfigProvider>
                          </Form.Item>
                        </Col>
                      
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item>
                            <Checkbox
                              defaultChecked={bookData.buffer_option}
                              onChange={(e) =>
                                handleChange("buffer_option", e.target.checked)
                              }
                            >
                              Ajouter un temps tampon
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label="Après la séance"
                            rules={[
                              {
                                required: formData.buffer_option,
                                message: "Veuillez sélectionner une heure",
                              },
                            ]}
                          >
                            <Select
                              value={formData.after_session || AfterSession}
                              style={{ height: "40px" }}
                              onChange={(value) =>
                                handleChange("after_session", value)
                              }
                              disabled={!formData.buffer_option}
                            >
                              <Select.Option value="5">5 min</Select.Option>
                              <Select.Option value="10">10 min</Select.Option>
                              <Select.Option value="15">15 min</Select.Option>
                              <Select.Option value="20">20 min</Select.Option>
                              <Select.Option value="25">25 min</Select.Option>
                              <Select.Option value="30">30 min</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      {/* <Row gutter={[16, 16]}>
                            <Col span={12}>
                              <Form.Item
                                label="Avant la séance"
                                rules={[
                                  {
                                    required: formData.buffer_option,
                                    message: "Veuillez sélectionner une heure",
                                  },
                                ]}
                              >
                                <Select
                                  defaultValue={bookData.before_session}
                                  style={{ height: "40px" }}
                                  onChange={(value) =>
                                    handleChange("before_session", value)
                                  }
                                  disabled={!formData.buffer_option}
                                >
                                  <Select.Option value="5">5 min</Select.Option>
                                  <Select.Option value="10">
                                    10 min
                                  </Select.Option>
                                  <Select.Option value="15">
                                    15 min
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label="Après la séance"
                                rules={[
                                  {
                                    required: formData.buffer_option,
                                    message: "Veuillez sélectionner une heure",
                                  },
                                ]}
                              >
                                <Select
                                  defaultValue={bookData.after_session}
                                  style={{ height: "40px" }}
                                  onChange={(value) =>
                                    handleChange("after_session", value)
                                  }
                                  disabled={!formData.buffer_option}
                                >
                                  <Select.Option value="5">5 min</Select.Option>
                                  <Select.Option value="10">
                                    10 min
                                  </Select.Option>
                                  <Select.Option value="15">
                                    15 min
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row> */}
                    </Form>
                  </Col>
                  <Col span={10}>
                    <div>
                      <Calendar
                        date={selectedDate}
                        onNavigate={(date, view) => handleNavigate(view, date)}
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ marginLeft: "4%", height: "700px" }}
                        onSelectEvent={handleSelectEvent}
                        selectable
                        onSelectSlot={handleSlotEvent}
                        eventPropGetter={(event) => ({
                          style: {
                            backgroundColor: event.color,
                            border: "1px solid black",
                          },
                        })}
                        components={{
                          toolbar: CalendarToolbar,
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Modal>
              <Modal
                title={"Forcer la création du réservation"}
                centered
                width={1000}
                visible={modal4Open}
                onOk={handleForcedOk}
                onCancel={handleForcedCancel}
                destroyOnClose
                okText="create"
                cancelText="Annuler"
                style={{ marginBottom: "8%" }}
                afterClose={handleForcedCancel}
                onValuesChange={handleChange}
                footer={[
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    key="footer"
                  >
                    <>
                      <p></p>
                    </>
                    <div key="right-buttons" style={{ display: "flex" }}>
                      <Button key="cancel" onClick={handleForcedCancel}>
                        Annuler
                      </Button>
                      <Button
                        key="submit"
                        type="primary"
                        onClick={handleForcedOk}
                      >
                        Sauvegarder
                      </Button>
                    </div>
                  </div>,
                ]}
              >
                <Row>
                  <Col span={14}>
                    <Form layout="vertical">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Form.Item
                            label="Fuseau horaire"
                            style={{ marginTop: "7%" }}
                            rules={[
                              {
                                required: true,
                                message:
                                  "Veuillez sélectionner le fuseau horaire!",
                              },
                            ]}
                          >
                            <ConfigProvider locale={frFR}>
                              <Select
                                defaultValue={formData.timezone || ""}
                                onChange={(timezone) => {
                                  handleChange("timezone", timezone);
                                }}
                                placeholder="Sélectionner un fuseau horaire"
                                className="custom-timezone-select"
                                style={{
                                  fontWeight: "lighter",
                                  width: "100%",
                                }}
                              >
                                {frenchTimezones.map((timezone) => (
                                  <Option
                                    key={timezone.value}
                                    value={timezone.value}
                                  >
                                    {timezone.label}
                                  </Option>
                                ))}
                              </Select>
                            </ConfigProvider>
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            label="Date"
                            rules={[
                              {
                                required: true,
                                message: "Veuillez sélectionner la date!",
                              },
                            ]}
                          >
                            <ConfigProvider locale={frFR}>
                              <DatePicker
                                value={
                                  formData.date ? moment(formData.date) : null
                                }
                                onChange={(value) => {
                                  handleDatePickerChange(value);
                                  handleChange(
                                    "date",
                                    value ? value.format("YYYY-MM-DD") : ""
                                  );
                                }}
                                style={{
                                  width: "100%",
                                  height: "40px",
                                  borderRadius: "5px",
                                }}
                                allowClear={false}
                              />
                            </ConfigProvider>
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            label="Emplacement"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Veuillez sélectionner l'emplacement !",
                              },
                            ]}
                          >
                            <ConfigProvider locale={frFR}>
                              <Select
                                value={{
                                  key: formData.id_locale,
                                  label: getLabelFromId(formData.id_locale),
                                }}
                                onChange={(value) => {
                                  handleChange("id_locale", value.key);
                                }}
                                placeholder="Sélectionner un Emplacement"
                                className="custom-timezone-select"
                                style={{ width: "100%" }}
                                optionLabelProp="label"
                                labelInValue
                              >
                                {localData?.map((local) => (
                                  <Option
                                    key={local.id}
                                    value={local.id}
                                    label={`${local.title} (${local.bigtitle})`}
                                  >
                                    {local.title} ({local.bigtitle})
                                  </Option>
                                ))}
                              </Select>
                            </ConfigProvider>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item label="Services">
                            <Select
                              onChange={(value) =>
                                handleChange("id_service", value)
                              }
                              className="custom-timezone-select"
                              style={{ width: "100%" }}
                            >
                              {services?.map((service) => (
                                <Option
                                  key={service.id_service}
                                  value={service.id_service}
                                >
                                  {`${service.name} (${
                                    service.type === "public"
                                      ? "publique"
                                      : "privée"
                                  })`}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Form.Item
                            label="Heure de début"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Veuillez sélectionner l'heure de début!",
                              },
                            ]}
                          >
                            <ConfigProvider locale={frFR}>
                              <TimePicker
                                value={
                                  formData.start_time
                                    ? moment(formData.start_time, "HH:mm:ss")
                                    : null
                                }
                                onSelect={(value) => {
                                  handleChange(
                                    "start_time",
                                    value ? value.format("HH:mm:ss") : ""
                                  );

                                  // handleChange(
                                  //   "date",
                                  //   selectedDate
                                  //     ? selectedDate.format("YYYY-MM-DD")
                                  //     : ""
                                  // );
                                }}
                                style={{
                                  width: "100%",
                                  height: "40px",
                                  borderRadius: "5px",
                                }}
                              />
                            </ConfigProvider>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Heure de fin"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Veuillez sélectionner l'heure de fin!",
                              },
                            ]}
                          >
                            <ConfigProvider locale={frFR}>
                              <TimePicker
                                value={
                                  formData.end_time
                                    ? moment(formData.end_time, "HH:mm:ss")
                                    : null
                                }
                                onSelect={(value) =>
                                  handleChange(
                                    "end_time",
                                    value ? value.format("HH:mm:ss") : ""
                                  )
                                }
                                style={{
                                  width: "100%",
                                  height: "40px",
                                  borderRadius: "5px",
                                }}
                              />
                            </ConfigProvider>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Col span={24}>
                        <Form.Item>
                          <Checkbox
                            defaultChecked={bookData.buffer_option}
                            onChange={(e) =>
                              handleChange("buffer_option", e.target.checked)
                            }
                          >
                            Ajouter un temps tampon avant ou après la session
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Form.Item
                            label="Avant la séance"
                            rules={[
                              {
                                required: formData.buffer_option,
                                message: "Veuillez sélectionner une heure",
                              },
                            ]}
                          >
                            <Select
                              defaultValue={bookData.before_session}
                              style={{ height: "40px" }}
                              onChange={(value) =>
                                handleChange("before_session", value)
                              }
                              disabled={!formData.buffer_option}
                            >
                              <Select.Option value="5">5 min</Select.Option>
                              <Select.Option value="10">10 min</Select.Option>
                              <Select.Option value="15">15 min</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Après la séance"
                            rules={[
                              {
                                required: formData.buffer_option,
                                message: "Veuillez sélectionner une heure",
                              },
                            ]}
                          >
                            <Select
                              defaultValue={bookData.after_session}
                              style={{ height: "40px" }}
                              onChange={(value) =>
                                handleChange("after_session", value)
                              }
                              disabled={!formData.buffer_option}
                            >
                              <Select.Option value="5">5 min</Select.Option>
                              <Select.Option value="10">10 min</Select.Option>
                              <Select.Option value="15">15 min</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col span={10}>
                    <div>
                      <Calendar
                        date={selectedDate}
                        onNavigate={(date, view) => handleNavigate(view, date)}
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ marginLeft: "4%", height: "700px" }}
                        onSelectEvent={handleSelectEvent}
                        eventPropGetter={(event) => ({
                          style: {
                            backgroundColor: event.color,
                            border: "1px solid black",
                          },
                        })}
                        components={{
                          toolbar: CalendarToolbar,
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Modal>
              <Modal
                title="Ajouter une Note"
                open={isModalOpen}
                onOk={handleOkNote}
                onCancel={handleCancelNote}
                okButtonProps={{ loading: loadingState.submitNote }} 
              >
                <Form layout="vertical">
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label="Titre"
                        style={{ marginTop: "7%" }}
                        rules={[
                          {
                            required: true,
                            message: "Veuillez saisir un titre!",
                          },
                        ]}
                      >
                        <Input
                          value={noteFormData?.title}
                          onChange={(e) => {
                            setNoteFormData((prevFormData) => ({
                              ...prevFormData,
                              ["title"]: e.target.value,
                            }));
                          }}
                          placeholder="Insérer un titre"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Description"
                        rules={[
                          {
                            required: true,
                            message: "Veuillez entrer une description!",
                          },
                        ]}
                      >
                        <TextArea
                          value={noteFormData?.content}
                          rows={4}
                          onChange={(e) => {
                            setNoteFormData((prevFormData) => ({
                              ...prevFormData,
                              ["content"]: e.target.value,
                            }));
                          }}
                          placeholder="Insérer une description"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </Col>
          </Row>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24 "
            extra={
              <Row gutter={[16, 0]} align="middle">
                <Col flex="auto" style={{ width: "20%" }}>
                  <Input
                    value={searchData?.legal_first_name}
                    onChange={(e) => {
                      handleChangeDataSearch(
                        "legal_first_name",
                        e.target.value
                      );
                    }}
                    style={{ height: "40px" }}
                    placeholder="Nom Légale"
                  />
                </Col>
                <Col flex="auto" style={{ width: "20%" }}>
                  <Input
                    value={searchData?.last_name}
                    onChange={(e) => {
                      handleChangeDataSearch("last_name", e.target.value);
                    }}
                    style={{ height: "40px" }}
                    placeholder="Prénom"
                  />
                </Col>
                <Col flex="auto" style={{ width: "20%" }}>
                  <ConfigProvider locale={frFR}>
                    <DatePicker
                      value={searchData.date ? moment(searchData.date) : null}
                      onChange={(value) => {
                        handleChangeDataSearch(
                          "date",
                          value ? value.format("YYYY-MM-DD") : ""
                        );
                      }}
                      style={{
                        height: "40px",
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="Date"
                    />
                  </ConfigProvider>
                </Col>
                <Col flex="auto" style={{ width: "20%" }}>
                  <Select
                    onChange={(e) => {
                      handleChangeDataSearch("name", e);
                    }}
                    value={searchData?.name}
                    options={servicesOptions}
                    placeholder="Service"
                    style={{ height: "40px", width: "100%" }}
                  />
                </Col>

                <Col flex="none" style={{ width: "20%" }}>
                  <Row gutter={[5, 0]}>
                    <Col span={20}>
                      {" "}
                      <Button
                        type="text"
                        style={{
                          backgroundColor: "#CDFADB",
                          height: "40px",
                          width: "100%",
                        }}
                        onClick={async () => {
                          await fetchBookings();
                        }}
                      >
                        Chercher
                      </Button>
                    </Col>
                    <Col span={4}>
                      <Button
                        type="text"
                        style={{
                          backgroundColor: "#CDFADB",
                          height: "40px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center", // Center horizontally
                          alignItems: "center", // Center vertically
                        }}
                        onClick={async () => {
                          setSearchData({
                            ...searchData,

                            legal_first_name: "",
                            last_name: "",
                            date: "",
                            name: "",
                          });
                          reloadStatus
                            ? setReloadStatus(false)
                            : setReloadStatus(true);
                        }}
                      >
                        <ReloadOutlined style={{ fontSize: "20px" }} />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
          >
            <div className="table-responsive">
              <List
                dataSource={bookings}
                renderItem={(item, index) => (
                  <List.Item
                    key={`${item.key}_${index}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid #f0f0f0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        width: "500px",
                      }}
                    >
                      {" "}
                      <Tooltip
                        title={
                          item.confirmed === "confirmed"
                            ? "confirmée"
                            : item.confirmed === "pending"
                            ? "en attente"
                            : "Non confirmée"
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "5px",
                          }}
                        >
                          {item.confirmed === "confirmed" ? (
                            <div
                              style={{
                                marginTop: "3%",
                                marginLeft: "4%",
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                backgroundColor: "#AAD9BB",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "12px",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              {item.image}
                            </div>
                          ) : item.confirmed === "pending" ? (
                            <div
                              style={{
                                marginTop: "3%",
                                marginLeft: "4%",
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                backgroundColor: "#E59BE9",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "12px",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              {item.image}
                            </div>
                          ) : (
                            <div
                              style={{
                                marginTop: "3%",
                                marginLeft: "4%",
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                backgroundColor: "#FFBB64",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "12px",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              {item.image}
                            </div>
                          )}
                          {}
                        </div>
                      </Tooltip>
                      <div>
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            lineHeight: "33px",
                          }}
                        >
                          {item.name}
                        </p>
                        <p
                          style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "22px",
                          }}
                        >
                          {item.time}
                        </p>
                        <p
                          style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "22px",
                          }}
                        >
                          {item.location}
                        </p>
                      </div>
                    </div>
                    <Space direction="vertical">
                      {item.name_service ? (
                        <div
                          style={{
                            fontWeight: "bold",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            position: "absolute",
                            right: "3%",
                          }}
                        >
                          {item.type_service === "public" ? (
                            <GiEarthAmerica style={{ marginRight: "8px" }} />
                          ) : (
                            <FaLock style={{ marginRight: "8px" }} />
                          )}
                          {item.name_service || "paquet non trouvé"} (
                          {item.type_service})
                        </div>
                      ) : (
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            position: "absolute",
                            right: "3%",
                          }}
                        >
                          {"paquet non trouvé"}
                        </div>
                      )}

                      <div style={{ display: "flex", marginLeft: "10px" }}>
                        {item.actions.map(
                          (action, index) =>
                            action.test === true && (
                              <div
                                key={index}
                                style={{
                                  fontWeight: "bold",
                                  background: "none",
                                  border: "none",
                                  cursor: "pointer",
                                  marginLeft: index === 0 ? "0" : "15px",
                                  marginRight: index === 0 ? "0" : "50px",
                                  marginTop: "20%",
                                  color: action.color, // Use action.color directly
                                  textDecoration: "underline", // Ensure it's a string
                                  display: "flex",
                                  alignItems: "center", // Center the icon and text vertically
                                }}
                                onClick={() => action.onclick(item.key)}
                              >
                                <img
                                  src={action.icon}
                                  alt={`${action.action} icon`}
                                  style={{ marginRight: "8px", height: "16px" }}
                                />
                                {action.action}
                              </div>
                            )
                        )}
                      </div>
                    </Space>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default MyBookings;
