import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Input,
  Row,
  Space,
} from "antd";
import React from "react";
import PhoneInput from "react-phone-number-input";
import moment from "moment";
import ResetPassword from "../../pages/resetPassword";

export const PatientInformations = ({
  patientData,
  editable,
  onEditClick,
  onEditCancel,
  onEditSave,
  updatedData,
  onUpdate,
}) => {
  
  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ];

  if (!patientData) {
    return null;
  }

  return (
    <Row justify="start" align="middle" gutter={[24, 0]}>
      <Col span={24} md={18} className="mb-24">
        <Card
          bordered={false}
          title={
            <h6 className="font-semibold m-0">Informations sur le profil</h6>
          }
          style={{ width: "134%" }}
          className="header-solid h-full card-profile-information"
          extra={
            <>
              {editable ? (
                <Space>
                  {/* <Button
                    style={{
                      backgroundColor: "green",
                      marginRight: "20px",
                      border: "1px solid gray",
                    }}
                    type="primary"
                    onClick={() => {
                      window.location.href = "/resetPassword";
                    }}
                  >
                    Changer mot de passe
                  </Button> */}
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#01013d" }}
                    onClick={() => {
                      onEditSave();
                    }}
                  >
                    Sauvegarder
                  </Button>
                  <Button
                    type="text"
                    style={{ backgroundColor: "orange" }}
                    onClick={() => {
                      onEditCancel(); // Set editable to false when clicked
                    }}
                  >
                    Annuler
                  </Button>
                </Space>
              ) : (
                <Button
                  type="link"
                  onClick={() => {
                    onEditClick(); // Set editable to true when clicked
                  }}
                >
                  {pencil}
                </Button>
              )}
            </>
          }
          bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
        >
          <hr className="my-25" />
          <div style={{ paddingLeft: "2%" }}>
            <Descriptions layout="vertical" column={3}>
              <Descriptions.Item label="Prénom légal" span={1}>
                {editable ? (
                  <Input
                    style={{ width: "90%" }}
                    defaultValue={patientData.Patient.legal_first_name}
                    onChange={(e) => {
                      onUpdate("legal_first_name", e.target.value);
                    }}
                  />
                ) : (
                  patientData.Patient.legal_first_name
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Prénom" span={1}>
                {editable ? (
                  <Input
                    style={{ width: "90%" }}
                    defaultValue={patientData.first_name}
                    onChange={(e) => {
                      onUpdate("first_name", e.target.value);
                    }}
                  />
                ) : (
                  patientData.first_name
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Nom" span={1}>
                {editable ? (
                  <Input
                    style={{ width: "90%" }}
                    defaultValue={patientData.last_name}
                    onChange={(e) => {
                      onUpdate("last_name", e.target.value);
                    }}
                  />
                ) : (
                  patientData.last_name
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Email Adresse" span={1}>
                {editable ? (
                  <Input
                    style={{ width: "90%" }}
                    defaultValue={patientData.email}
                    readOnly={true}
                  />
                ) : (
                  patientData.email
                )}
              </Descriptions.Item>
              <Descriptions.Item label="téléphone fixe" span={1}>
                {editable ? (
                  <PhoneInput
                    // placeholder="Enter phone number"
                    value={patientData.Patient.home_phone}
                    onChange={(e) => {
                      onUpdate("home_phone", e);
                    }}
                  />
                ) : (
                  patientData.Patient.home_phone
                )}
              </Descriptions.Item>
              <Descriptions.Item label="téléphone mobile" span={1}>
                {editable ? (
                  <PhoneInput
                    // placeholder="Enter phone number"
                    value={patientData.Patient.mobile_phone}
                    onChange={(e) => {
                      onUpdate("mobile_phone", e);
                    }}
                  />
                ) : (
                  patientData.Patient.mobile_phone
                )}
              </Descriptions.Item>
              <Descriptions.Item label="date de naissance" span={1}>
                {editable ? (
                  <DatePicker
                    style={{ width: "90%" }}
                    defaultValue={
                      patientData.Patient.date_of_birth
                        ? moment(
                            patientData.Patient.date_of_birth,
                            "YYYY-MM-DD"
                          )
                        : null
                    }
                    format="YYYY-MM-DD" // Set the format to display
                    onChange={(date, dateString) => {
                      onUpdate("date_of_birth", dateString);
                    }}
                  />
                ) : (
                  moment(patientData.Patient.date_of_birth).format("YYYY-MM-DD")
                )}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Card>
      </Col>
      {editable && (
        <div style={{ width: "150%" }}>
          <ResetPassword />
        </div>
      )}
    </Row>
  );
};
