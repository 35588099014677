// PrivateRoute.js
import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import Main from "./components/layout/Main";
import { isAuthenticated } from "./utils/auth";
import { useChat } from "./components/layout/ChatContext";
import { Button, Modal } from "antd";
import VideoChat from "./pages/VideoChat";

const PrivateRoute = ({ component: Component, onlineUsers, ...rest }) => {
  const [Calling, setCalling] = useState(false);

  const {
    receaivedMessage,
    answerCall,
    call,
    callAccepted,
    socket,
    leaveCall,
    myVideo,
    setStream,
    setCall,
    setMe,
  } = useChat();

  // useEffect(() => {
  //   navigator.mediaDevices
  //     .getUserMedia({ video: true, audio: true })
  //     .then((currentStream) => {
  //       setStream(currentStream);
  //       myVideo.current.srcObject = currentStream;
  //     })
  //     .catch((error) => {
  //       console.error("Error accessing media devices:", error);
  //     });

  //   if (socket) {
  //     socket.on("me", (id) => setMe(id));

  //     socket.on("callUserV", ({ userToCall, from, name, signal }) => {
  //       setCall({ isReceivingCall: true, from, name, signal });
  //     });
  //   }
  // }, [socket]);

  // useEffect(() => {
  //   if (call.isReceivingCall) {
  //     setIsModalOpen(call.isReceivingCall);
  //   }
  //   if (call.isReceivingCall && !callAccepted) {
  //     console.log("play music");
  //     const audio = new Audio(require("./assets/Sounds/calsound.mp3").default);

  //     // Play the audio
  //     audio.play();

  //     audio.addEventListener("ended", () => {
  //       // Handle when audio ends
  //       console.log("Audio ended");
  //     });

  //     return () => {
  //       audio.pause();
  //     };
  //   }
  // }, [call.isReceivingCall, callAccepted]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // useEffect(() => {
  //   let timer;

  //   if ((isModalOpen && !callAccepted) || Calling) {
  //     timer = setTimeout(() => {
  //        leaveCall();
  //       window.location.reload();
  //     }, 10000);
  //   }

  //   return () => clearTimeout(timer);
  // }, [isModalOpen, callAccepted, leaveCall, Calling]);



  // useEffect(() => {
  //   if (callAccepted) {
  //     setCalling(false);
  //   }
  //   console.log("callAccepted");
  // }, [callAccepted]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          rest.path === "/resetPassword" ? (
            <Component {...props} />
          ) : (
            <Main>
              {call.isReceivingCall && !callAccepted && (
                <Modal
                  title={`${call.name} is calling:`}
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={null}
                  closable={false}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        answerCall();
                        // history.push("/VideoChat");
                      }}
                    >
                      Répondre
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        leaveCall();
                      }}
                    >
                      raccrocher
                    </Button>
                  </div>
                </Modal>
              )}
              {callAccepted && (
                <VideoChat
                  style={{
                    width: "75%",
                    height: "75%",
                  }}
                />
              )}
              {Calling && !callAccepted && (
                <VideoChat
                  style={{
                    width: "75%",
                    height: "75%",
                  }}
                />
              )}
              <Component {...props} onlineUsers={onlineUsers} />
            </Main>
          )
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};
export default PrivateRoute;
