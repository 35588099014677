import {
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Input,
  message,
  Space,
  List,
  Select,
  Checkbox,
} from "antd";
import { FaLock } from "react-icons/fa";
import { GiEarthAmerica } from "react-icons/gi";

import { packageService, serviceService } from "../utils/URL";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import rearange from "../assets/images/rearange.png"; // Import the logo image
import "react-big-calendar/lib/css/react-big-calendar.css";

const getInitials = (name) => {
  const [firstName, lastName] = name.split(" ");
  const initials = firstName.charAt(0) + lastName.charAt(0);
  return initials.toUpperCase();
};

const MyPackages = () => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [patientData, setPatientData] = useState({});
  const [packs, setPacks] = useState([]);
  const [patientOriginalData, setPatientOriginalData] = useState({});
  const token = Cookies.get("token");
  const [patients, setPatients] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [modalMode, setModalMode] = useState("create"); // Add modal mode state
  const [packData, setPackData] = useState({});
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [serviceData, setServiceData] = useState({});
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [loadingState, setLoadingState] = useState({
    delete: false,
    submit: false,
  });
  const handleServiceSelect = (key) => {
    setSelectedServices((prevSelected) =>
      prevSelected.includes(key)
        ? prevSelected.filter((id_service) => id_service !== key)
        : [...prevSelected, key]
    );
  };
  const handleChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };
  const fetchServices = async () => {
    try {
      const response = await axios.get(`${serviceService}/getServiceByIdUser`, {
        headers: {
          "auth-token": token,
        },
      });

      // Sort the appointments array by the time of creation
      const sortedServices = response.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      const services = sortedServices.map((service) => ({
        key: service.id_service,
        name: `${service.name} `,
        image: getInitials(`${service.name} `),
        time: moment(service.created_at).format("ddd MMM D @ hh:mm A"),
        price_Service: service.price_Service,
        type: service.type,
        duration: service.duration,
      }));

      setServices(services);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };
  const fetchPackages = async () => {
    try {
      const response = await axios.get(
        `${packageService}/getPackageByIdDoctor`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      // Sort the appointments array by the time of creation
      const sortedPacks = response.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      const packs = sortedPacks.map((pack) => ({
        key: pack.id_package,
        name: `${pack.name} `,
        image: getInitials(`${pack.name} `),
        description: pack.description,
        condition: pack.condition,
        SKU: pack.SKU,
        time: moment(pack.created_at).format("ddd MMM D @ hh:mm A"),
        price_Service: pack.price_Service,
        serviceList: pack.Services,
        actions: [
          {
            action: "Réarranger",
            icon: rearange,

            onclick: (key) => {
              setModalOpen(true);
              const data = sortedPacks.find((pack) => pack.id_package === key);
              setModalMode("update");
              setPackData(data);
              setFormData(data);
              setSelectedServices(
                data.Services.map((service) => service.id_service)
              );
            },
          },
        ],
      }));

      setPacks(packs);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };
  const createPack = async () => {
    try {
      const responce = await axios.post(
        `${packageService}/createPackage`,
        formData,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setPackData({});
      await AddServiceToPack(responce.data.newPackage.id_package);
      await fetchPackages();
    } catch (error) {
      console.error("Error creating Paquet:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    } finally {
      setConfirmLoading(false);
    }
  };
  const updatePack = async () => {
    try {
      const id = packData.id_package;
      await axios.put(`${packageService}/updatePackageById/${id}`, formData);
      setPackData({});
      await fetchPackages();
      await AddServiceToPack(id);
    } catch (error) {
      console.error("Error updating Paquet:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    } finally {
      setConfirmLoading(false);
    }
  };
  const AddServiceToPack = async (id) => {
    try {
      await axios.put(`${packageService}/addServicesToPackage/${id}`, {
        service_ids: selectedServices,
      });
      setPackData({});
      fetchPackages();
      if (modalMode === "create") {
        message.success("Paquet créé avec succès");
        setVisible(false);
        setModalOpen(false);
      } else {
        message.success("Paquet mise à jour avec succès");
        setVisible(false);
        setModalOpen(false);
      }
    } catch (error) {
      console.error("Error updating Paquet:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    } finally {
      setConfirmLoading(false);
    }
  };
  const DeletePack = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, delete: true }));

      const id = packData.id_package;
      await axios.delete(`${packageService}/deletePackage/${id}`);
      message.success("Paquet supprimé avec succès");
      setPackData({});
      await fetchPackages();
      setModalOpen(false);
      setVisibleDelete(false);
    } catch (error) {
      message.error(
        error.response.data.message || "Erreur lors de la suppression du Paquet"
      );
      console.error("Error deleting Paquet:", error);
    }
    setLoadingState((prevState) => ({ ...prevState, delete: false }));
  };

  const handleOk = async () => {
    setLoadingState((prevState) => ({ ...prevState, submit: true }));

    if (modalMode === "create") {
      await createPack(formData);
    } else if (modalMode === "update") {
      await updatePack();
    }
    setLoadingState((prevState) => ({ ...prevState, submit: false }));
  };
  const handleCancel = () => {
    setPackData({});
    setFormData({});
    setModalOpen(false);
  };
  useEffect(() => {
    fetchPackages();
    fetchServices();
  }, [confirmLoading]);
  useEffect(() => {
    handleData(patientData.id_patient);
  }, [patients]);
  const handleData = (patientIds) => {
    const filteredPatients = patients.filter(
      (patient) => patient.id_patient === patientIds
    );

    setPatientOriginalData(selectedPatient);
  };

  const handleDelete = async () => {
    await DeletePack();
  };

  const openModalcreate = () => {
    setModalOpen(true);
    setModalMode("create");
  };

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours > 0 ? `${hours} heure${hours > 1 ? "s" : ""} ` : ""}${
      remainingMinutes > 0
        ? `${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}`
        : ""
    }`;
  };

  return (
    <>
      <div>
        <div className="tabled">
          <Card
            bordered={false}
            className="criclebox tablespace mb-24 "
            extra={
              <Row justify="end" style={{ marginBottom: 20 }}>
                <Col style={{ width: "100%" }}>
                  <Button
                    type="text"
                    style={{ backgroundColor: "#CDFADB", width: "100%" }}
                    onClick={openModalcreate}
                  >
                    Ajouter un packet
                  </Button>

                  <Modal
                    title={
                      modalMode === "create"
                        ? "Ajouter un paquet"
                        : "Modifier le paquet"
                    }
                    centered
                    width={800}
                    visible={modalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    destroyOnClose
                    okText={
                      modalMode === "create" ? "Enregistrer" : "Modifier "
                    }
                    cancelText="Annuler"
                    style={{ marginBottom: "8%" }}
                    afterClose={handleCancel}
                    onValuesChange={handleChange}
                    footer={[
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        key="footer"
                      >
                        {modalMode === "update" ? (
                          <>
                            <Button
                              key="delete"
                              type="danger"
                              // onClick={handleDelete}
                              onClick={() => {
                                setVisibleDelete(true);
                              }}
                            >
                              Supprimer
                            </Button>
                            <Modal
                              title="Confirm Deletion"
                              visible={visibleDelete}
                              onCancel={() => setVisibleDelete(false)}
                              footer={[
                                <Button
                                  key="cancel"
                                  onClick={() => setVisibleDelete(false)}
                                >
                                  Annuler
                                </Button>,
                                <Button
                                  //loader
                                  loading={loadingState.delete}
                                  key="delete"
                                  type="primary"
                                  danger
                                  onClick={handleDelete}
                                >
                                  Supprimer
                                </Button>,
                              ]}
                            >
                              <p>
                                Êtes-vous sûr de vouloir supprimer ce Paquet ?
                              </p>
                            </Modal>
                          </>
                        ) : (
                          <>
                            <p></p>
                          </>
                        )}
                        <div key="right-buttons" style={{ display: "flex" }}>
                          <Button key="cancel" onClick={handleCancel}>
                            Annuler
                          </Button>
                          <Button
                            //loader
                            loading={loadingState.submit}
                            key="submit"
                            type="primary"
                            onClick={handleOk}
                          >
                            {modalMode === "create"
                              ? "Sauvegarder"
                              : "Modifier"}
                          </Button>
                        </div>
                      </div>,
                    ]}
                  >
                    <Row>
                      <Col span={24}>
                        <Form layout="vertical">
                          <Row gutter={[16, 16]}>
                            <Col span={24}>
                              <Form.Item
                                label="Nom du Paquet"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Veuillez saisir le nom du Paquet!",
                                  },
                                ]}
                              >
                                <Input
                                  defaultValue={formData.name || ""}
                                  onChange={(e) => {
                                    handleChange("name", e.target.value);
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "40px",
                                    borderRadius: "5px",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                label="Description"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Veuillez saisir une description de cd paquet!",
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  defaultValue={formData.description || ""}
                                  onChange={(e) => {
                                    handleChange("description", e.target.value);
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "100px",
                                    borderRadius: "5px",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label="Terms and conditions"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Veuillez sélectionner le condition de ce Paquet!",
                                  },
                                ]}
                              >
                                <Select
                                  defaultValue={formData.condition || ""}
                                  onChange={(e) => {
                                    handleChange("condition", e);
                                  }}
                                  placeholder="Sélectionner un type"
                                  style={{ fontWeight: "lighter" }}
                                  options={[
                                    {
                                      value: "Condition 1",
                                      label: "Condition 1",
                                    },
                                    {
                                      value: "Condition 2",
                                      label: "Condition 2",
                                    },
                                  ]}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label="SKU"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Veuillez sélectionner le SKU de la pacquet!",
                                  },
                                ]}
                              >
                                <Input
                                  defaultValue={formData.SKU || ""}
                                  onChange={(e) => {
                                    handleChange("SKU", e.target.value);
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "40px",
                                    borderRadius: "5px",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <div
                            style={{
                              maxHeight: "200px", // Limit the height of the list
                              overflowY: "auto",
                            }}
                          >
                            <List
                              dataSource={services}
                              renderItem={(item, index) => (
                                <List.Item
                                  key={`${item.key}_${index}`}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    borderBottom: "1px solid #f0f0f0",
                                    padding: "10px 0", // Adjust the padding to make the list item shorter
                                  }}
                                >
                                  <Checkbox
                                    defaultChecked={
                                      formData.Services?.some(
                                        (service) =>
                                          service.id_service === item.key
                                      ) || false
                                    }
                                    onChange={() => {
                                      handleServiceSelect(item.key);
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "start",
                                        width: "400px",
                                        marginLeft: "3%",
                                      }}
                                    >
                                      <div>
                                        <h5>{item.name}</h5>
                                        <p
                                          style={{
                                            fontSize: "13px",
                                            color: "black",
                                          }}
                                        >
                                          {item.time}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "bold",
                                            color: "grey",
                                          }}
                                        >
                                          {formatDuration(item.duration)} @ C€
                                          {item.price_Service.toFixed(2)}
                                        </p>
                                      </div>
                                    </div>
                                  </Checkbox>
                                  <Space direction="vertical">
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                        position: "absolute",
                                        right: "3%",
                                      }}
                                    >
                                      {item.type === "public" ? (
                                        <GiEarthAmerica
                                          style={{ marginRight: "8px" }}
                                        />
                                      ) : (
                                        <FaLock
                                          style={{ marginRight: "8px" }}
                                        />
                                      )}
                                      {item.type}
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginLeft: "10px",
                                      }}
                                    ></div>
                                  </Space>
                                </List.Item>
                              )}
                            />
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </Modal>
                </Col>
              </Row>
            }
          >
            <div className="table-responsive">
              <List
                dataSource={packs}
                renderItem={(item, index) => (
                  <List.Item
                    key={`${item.key}_${index}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid #f0f0f0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        width: "500px",
                        marginLeft: "3%",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            lineHeight: "33px",
                          }}
                        >
                          {item.name}
                        </p>{" "}
                        <p>{item.time}</p>
                        <p
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "grey",
                          }}
                        >
                          1 hour @ C€
                          {item?.price_Service?.toFixed(2)}
                        </p>
                      </div>
                    </div>
                    <Space direction="vertical">
                      <div style={{ display: "flex", marginLeft: "10px" }}>
                        {item.actions.map((action, index) => (
                          <div
                            key={index}
                            style={{
                              fontWeight: "bold",
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                              marginLeft: "15px",
                              marginRight: "30px",
                              marginTop: "40%",
                              position: "relative",
                              right: "3%",
                              top: "5%",
                              color: "#F08B09",
                            }}
                            onClick={() => action.onclick(item.key)}
                          >
                            <img
                              src={action.icon}
                              alt={`${action.action} icon`}
                              style={{ marginRight: "8px", height: "16px" }}
                            />
                            {action.action}
                          </div>
                        ))}
                      </div>
                    </Space>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default MyPackages;
