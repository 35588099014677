import {
  Button,
  Card,
  Col,
  Descriptions,
  Input,
  List,
  Modal,
  Row,
  Space,
  message,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { LocationService } from "../utils/URL";
import Cookies from "js-cookie";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const pencil = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
      className="fill-gray-7"
    ></path>
    <path
      d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
      className="fill-gray-7"
    ></path>
  </svg>,
];
const MyLocations = () => {
  const [editable, setEditable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newLocationTitle, setNewLocationTitle] = useState("");
  const [newLocationBigTitle, setNewLocationBigTitle] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [locationUpdatebale, setLocationUpdatebale] = useState(false);
  const showModal = () => {
    setNewLocationTitle("");
    setNewLocationBigTitle("");
    setIsModalOpen(true);
  };
  const [loadingState, setLoadingState] = useState({
    submit: false,
  });
  const [localData, setLocalData] = useState({
    id_location: "",
    company_name: "",
    email_address: "",
    business_number: "",
    website: "",
    tag_line: "",
    location: [],
  });
  const [local, setLocal] = useState(
    Array.isArray(localData.location) ? localData.location : []
  );
  const [location, setLocations] = useState(
    Array.isArray(localData.location) ? localData.location : []
  );

  const updateData = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, submit: true }));

      if(localData.id_location){
        await axios.put(
          `${LocationService}/updateLocationById/${localData.id_location}`,
          localData,
          {
            headers: {
              "auth-token": token,
            },
          }
        );
      }else{
        await axios.post(
          `${LocationService}/createLocation`,
          localData,
          {
            headers: {
              "auth-token": token,
            },
          }
        );
      }
     
      setEditable(false);
      message.success("Mis à jour avec succés ");
    } catch (error) {
      console.error("Error updating data:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    }
    setLoadingState((prevState) => ({ ...prevState, submit: false }));
  };

  const onEditSave = () => {
    updateData();
  };

  const onEditCancel = () => {
    setEditable(false);
  };

  const onEditClick = () => {
    setEditable(true);
  };

  const onUpdate = (fieldName, value) => {
    setLocalData({
      ...localData,
      [fieldName]: value,
    });
  };

  const handleAddLocation = () => {
    const updatedLocations = Array.isArray(localData.location)
      ? localData.location
      : [];
    const newLocation = {
      id: `${localData?.id_location}_${Date.now()}` ,
      title: newLocationTitle,
      bigtitle: newLocationBigTitle,
    };
    setLocations([...updatedLocations, newLocation]);

    const addedLocations = [...updatedLocations, newLocation];
    onUpdate("location", addedLocations);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setLocationUpdatebale(false);
    setIsModalOpen(false);
    setLocations(localData.location);
  };

  const handleLocationUpdate = (index, fieldName, newData) => {
    const updatedLocations = [...location];
    if (index >= 0 && index < updatedLocations.length) {
      updatedLocations[index][fieldName] = newData;

      /////

      onUpdate("location", updatedLocations);
    } else {
      console.error("Index fourni non valide.");
    }
  };

  const handleDeleteLocation = (index) => {
    const updatedLocations = [...location];
    if (index >= 0 && index < updatedLocations.length) {
      updatedLocations.splice(index, 1);
      setLocations(updatedLocations);
      onUpdate("location", updatedLocations);
    } else {
      console.error("Invalid index provided.");
    }
  };

  const cancelLine = () => {
    setLocationUpdatebale(false);
    setLocations(local);
  };
  const token = Cookies.get("token");

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${LocationService}/getLocationByIdUser`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setLocalData(response.data);
      setLocal(response.data.location);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    setLocations(localData.location);
  }, [editable]);

  return (
    <Row justify="center" align="middle" gutter={[24, 0]}>
      <Col span={24} md={18} className="mb-24">
        <Card
          bordered={false}
          title={<h6 className="font-semibold m-0">Entreprise</h6>}
          className="header-solid h-full card-profile-information"
          extra={
            <>
              {editable ? (
                <Space>
                  <Button
                    style={{ backgroundColor: "#01013d" }}
                    type="primary"
                    onClick={() => {
                      onEditSave();
                    }}
                  >
                    Sauvegarder
                  </Button>
                  <Button
                    type="text"
                    style={{ backgroundColor: "orange" }}
                    onClick={() => {
                      onEditCancel();
                    }}
                  >
                    Annuler
                  </Button>
                </Space>
              ) : (
                <Button
                  type="link"
                  onClick={() => {
                    onEditClick();
                  }}
                >
                  {pencil}
                </Button>
              )}
            </>
          }
          bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
        >
          <div style={{ paddingLeft: "2%" }}>
            <Descriptions layout="vertical" column={2}>
              <Descriptions.Item label="Nom de l'entreprise" span={1}>
                {editable ? (
                  <Input
                    style={{ width: "90%", height:"40px" }}
                    defaultValue={localData.company_name}
                    onChange={(e) => {
                      onUpdate("company_name", e.target.value);
                    }}
                  />
                ) : (
                  localData.company_name
                )}
              </Descriptions.Item>

              <Descriptions.Item label="Adresse email" span={1}>
                {editable ? (
                  <Input
                    style={{ width: "90%", height:"40px" }}
                    defaultValue={localData.email_address}
                    onChange={(e) => {
                      onUpdate("email_address", e.target.value);
                    }}
                  />
                ) : (
                  localData.email_address
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Numéro d'entreprise" span={1}>
                {editable ? (
                  <PhoneInput
                    style={{ width: "90%" }}
                    value={localData.business_number}
                    onChange={(e) => {
                      onUpdate("business_number", e);
                    }}
                  />
                ) : (
                  localData.business_number
                )}
              </Descriptions.Item>
              <Descriptions.Item label="site web" span={1}>
                {editable ? (
                  <Input
                    style={{ width: "90%" , height:"40px"}}
                    defaultValue={localData.website}
                    onChange={(e) => {
                      onUpdate("website", e.target.value);
                    }}
                  />
                ) : (
                  localData.website
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Slogan" span={1}>
                {editable ? (
                  <Input
                    style={{ width: "90%" , height:"40px"}}
                    defaultValue={localData.tag_line}
                    onChange={(e) => {
                      onUpdate("tag_line", e.target.value);
                    }}
                  />
                ) : (
                  localData.tag_line
                )}
              </Descriptions.Item>
            </Descriptions>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 className="Locations" style={{ marginTop: "1%" }}>
                Emplacements
              </h2>
              {editable && (
                <Button
                  type="primary"
                  style={{
                    marginBottom: "2px",
                    marginRight: "2px",
                    backgroundColor: "#01013d",
                  }}
                  className="AccreditationIcon"
                  onClick={showModal}
                >
                  Ajouter un emplacement
                </Button>
              )}
              <Modal
                title="Ajouter un emplacement"
                visible={isModalOpen}
                onOk={handleAddLocation}
                onCancel={handleCancel}
              >
                <Input
                  value={newLocationTitle}
                  style={{ width: "61%", marginBottom: "3%" , height:"40px"}}
                  placeholder="Entrez un titre"
                  onChange={(e) => {
                    setNewLocationTitle(e.target.value);
                  }}
                />
                <Input
                  value={newLocationBigTitle}
                  style={{ width: "61%", height:"40px" }}
                  placeholder="Entrez une adresse"
                  onChange={(e) => {
                    setNewLocationBigTitle(e.target.value);
                  }}
                />
              </Modal>
            </div>

            <div
              style={{
                height: 300,
                overflow: "auto",
                padding: "0 16px",
                border: "1px solid rgba(140, 140, 140, 0.35)",
              }}
            >
              <InfiniteScroll
                dataLength={
                  editable
                    ? location?.length || 0 
                    : localData?.location?.length || 0 
                }
                hasMore={false}
                loader={<h4>Chargement...</h4>}
                scrollableTarget="scrollableDiv"
              >
                <List
                  dataSource={
                    editable ? location || [] : localData?.location || []
                  }
                  renderItem={(item, index) => (
                    <List.Item key={item.id}>
                      <List.Item.Meta
                        title={
                          locationUpdatebale && editingIndex === index ? (
                            <Input
                              style={{ width: "35%", height:"40px" }}
                              defaultValue={item.title}
                              onChange={(e) =>
                                handleLocationUpdate(
                                  index,
                                  "title",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            item.title
                          )
                        }
                        description={
                          locationUpdatebale && editingIndex === index ? (
                            <Input
                              style={{ width: "35%", height:"40px" }}
                              defaultValue={item.bigtitle}
                              onChange={(e) =>
                                handleLocationUpdate(
                                  index,
                                  "bigtitle",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            item.bigtitle
                          )
                        }
                      />
                      {editable && (
                        <Space>
                          {editable &&
                          locationUpdatebale &&
                          editingIndex === index ? (
                            <Button
                              type="primary"
                              danger
                              onClick={() => cancelLine()}
                            >
                              Annuler
                            </Button>
                          ) : (
                            <Button
                              type="text"
                              style={{ backgroundColor: "orange" }}
                              onClick={() => {
                                setLocationUpdatebale(true);
                                setEditingIndex(index);
                              }}
                            >
                              Mise à jour
                            </Button>
                          )}
                          {editable &&
                          locationUpdatebale &&
                          editingIndex === index ? (
                            <Button
                              loading={loadingState.submit}

                              type="primary"
                              style={{ backgroundColor: "#01013d" }}
                              onClick={() => {
                                setLocationUpdatebale(false);
                                setEditingIndex(null);
                              }}
                            >
                              Sauvegarder
                            </Button>
                          ) : (
                            <Button
                              type="primary"
                              danger
                              onClick={() => handleDeleteLocation(index)}
                            >
                              Supprimer
                            </Button>
                          )}
                        </Space>
                      )}
                    </List.Item>
                  )}
                />
              </InfiniteScroll>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};
export default MyLocations;
