import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import {
  Col,
  Row,
  DatePicker,
  Modal,
  Form,
  message,
  ConfigProvider,
  Button,
  TimePicker,
  Select,
  Input,
  Radio,
} from "antd";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  appointmentService,
  LocationService,
  availibilityService,
} from "../utils/URL";
import frFR from "antd/lib/locale/fr_FR";
import "moment/locale/fr";
import { PlusOutlined } from "@ant-design/icons";
import frenchTimezones from "../components/layout/frenchTimezones";
import { ChromePicker } from "react-color";
import { useChat } from "../components/layout/ChatContext";
import jwt from "jsonwebtoken";

moment.locale("fr");
moment.updateLocale("fr", {
  week: {
    dow: 1,
    doy: 4,
  },
});
const localizer = momentLocalizer(moment);
const { Option } = Select;

const MyEvents = () => {
  const { service, profile, locale, role } = useChat();
  const messages = {
    allDay: "toute la journée",
    previous: "précédent",
    next: "suivant",
    today: "aujourd'hui",
    month: "mois",
    week: "semaine",
    day: "jour",
    agenda: "Agenda",
    date: "date",
    time: "temps",
    event: "événement",
    showMore: (total) => `+ (${total}) Événements`,
  };
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const weekDays = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
  const [formData, setFormData] = useState({});
  const [open, setOpen] = useState(false);
  const [openn, setOpenn] = useState(false);
  const [openEvent, setOpenEvent] = useState(false);
  const token = Cookies.get("token");
  const [modalMode, setModalMode] = useState("create"); // Add modal mode state
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [availabilityData, setAvailabilityData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [localData, setLocalData] = useState();
  const [showPicker, setShowPicker] = useState(false);
  const [time, setTime] = useState("");
  const [video_conferencing, setVideo_conferencing] = useState("");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [overlappingAvailability, setOverlappingAvailability] = useState([]);
  const [loadingState, setLoadingState] = useState({
    delete: false,
    confirmBooking: false,
    submit: false,
  });
  const handlePickerToggle = () => {
    setShowPicker(!showPicker);
  };
  const handleClosePicker = () => {
    setShowPicker(false);
  };

  const createAppointmentPatient = async (data) => {
    try {
      await axios.post(`${appointmentService}/createAppointmentPatient`, data, {
        headers: {
          "auth-token": token,
        },
      });
      message.success("Votre resérvation est crée avec succée");
      fetchEvents();
      setOpenn(false);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const handleReservation = async () => {
    setLoading(true);
    const [start, end] = time.split(" - ");
    const data = {
      id_doctor: profile,
      id_service: service,
      date: formData.date,
      start_time: start,
      end_time: end,
      timezone: formData.timezone,
      buffer_option: true,
      before_session: serviceData?.bufferTime,
      after_session: serviceData?.bufferTime,
      video_conferencing:
        formData.video_conferencing === "both"
          ? video_conferencing
          : formData.video_conferencing,
    };

    await createAppointmentPatient(data);
    setTime("");
    setLoading(false);
    setVideo_conferencing(undefined);
  };

  const fetchData = async (id_doctor) => {
    try {
      const response = await axios.get(
        `${LocationService}/getLocationByIdDoctor/${id_doctor}`
      );
      setLocalData(response?.data?.location);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const showModal = (mode, eventData = {}) => {
    setModalMode(mode);
    setFormData({
      description: eventData.description || "",
      color: eventData.color || "",
      timezone: eventData.timezone || "",
      date: moment(eventData.start).format("YYYY-MM-DD"),
      start_time: moment(eventData.start).format("HH:mm:ss"),
      end_time: moment(eventData.end).format("HH:mm:ss"),
      service_availability: eventData.service_availability || "",
      id_availibility: eventData.id_availibility || "",
      id_appointment: eventData.id_appointment || "",
      before_session: eventData.before_session || "",
      after_session: eventData.after_session || "",
      buffer_option: eventData.buffer_option || "",
      patient_name: eventData.patient_name || "",
      doctor_name: eventData.doctor_name || "",
      role: eventData.role || "",
      confirmed: eventData.confirmed || "",
      id_locale: eventData.id_locale || "",
      video_conferencing: eventData.video_conferencing || "",
      Services: eventData.Services || "",
      slots: eventData.slots || [],
      correspondance: eventData.correspondance || "",
    });
    if (service === undefined) {
      setOpen(true);
    } else {
      setOpenn(true);
    }
  };
  const showEventModal = (mode, eventData = {}) => {
    setModalMode(mode);
    if (mode === "create") {
      setFormData({
        name: "",
        description: "",
        color: "",
        timezone: "",
        date: "",
        start_time: "",
        end_time: "",
        service_availability: null,
        id_availibility: "",
        repeated: "",
        type: "",
        id_locale: "",
        video_conferencing: "",
      });
    } else {
      setFormData({
        name: eventData.name || "",
        description: eventData.description || "",
        color: eventData.color || "",
        timezone: eventData.timezone || "",
        date: moment(eventData.start).format("YYYY-MM-DD"),
        start_time: moment(eventData.start).format("HH:mm:ss"),
        end_time: moment(eventData.end).format("HH:mm:ss"),
        service_availability: eventData.service_availability || "",
        id_availibility: eventData.id_availibility || "",
        repeated: eventData.repeated || "",
        type: eventData.type || "",
        id_locale: eventData.id_locale || "",
        video_conferencing: eventData.video_conferencing || "",
        Services: eventData.Services || "",
        slots: eventData.slots || [],
      });
    }
    setOpenEvent(true);
  };
  const handleSelectEvent = (event) => {
    setOpen(false);
    fetchData(event.id_doctor);
    if (event.id_availibility !== undefined) {
      if (event.type === "event") {
        setModalMode("update", event);
        showEventModal("update", event);
      } else {
        showModal("availability", event);
      }
    } else {
      showModal("appointment", event);
    }
  };
  const generateDateList = () => {
    const { start_date, end_date, repeated, selectedDays } = formData;
    const dateList = [];
    let currentDate = moment(start_date);

    if (repeated === "day") {
      while (currentDate <= moment(end_date)) {
        dateList.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }
    } else if (repeated === "week") {
      const selectedDayIndices = selectedDays.map((day) =>
        weekDays.indexOf(day)
      );
      while (currentDate <= moment(end_date)) {
        if (selectedDayIndices.includes(currentDate.weekday())) {
          dateList.push(moment(currentDate).format("YYYY-MM-DD"));
        }
        currentDate = moment(currentDate).add(1, "days");
      }
    } else {
      dateList.push(moment(formData.date).format("YYYY-MM-DD"));
    }
    return dateList;
  };
  const handleOk = async () => {
    setOpen(false);
  };
  const createAvailability = async (availabilityData, dateList) => {
    try {
      const validationPromises = dateList.map(async (date) => {
        const data = { ...availabilityData, date, type: "event" }; // Merge formData with current date

        try {
          const response = await axios.post(
            `${availibilityService}/validateAvailability`,
            data,
            {
              headers: {
                "auth-token": token,
              },
            }
          );
          return { date, valid: response.data.valid };
        } catch (error) {
          setOverlappingAvailability(
            error.response.data.overlappingAvailability
          );
          setConfirmModalOpen(true);
          return { date, valid: false, error: error.response.data.message };
        }
      });

      const validations = await Promise.all(validationPromises);

      // Check if all availabilities are valid
      const allValid = validations.every((result) => result.valid);

      if (allValid) {
        // If all availabilities are valid, proceed with creation
        const creationPromises = dateList.map(async (date) => {
          const data = { ...availabilityData, date, type: "event" }; // Merge formData with current date

          const response = await axios.post(
            `${availibilityService}/createAvailability`,
            data,
            {
              headers: {
                "auth-token": token,
              },
            }
          );
          return response.data;
        });

        const newAvailabilities = await Promise.all(creationPromises);
        setAvailabilityData((prevState) => [
          ...prevState,
          ...newAvailabilities,
        ]);
        message.success("Disponibilités ajoutées avec succès");
        setOpenEvent(false);
      } else {
        const uniqueErrorMessages = new Set(
          validations
            .filter((result) => !result.valid)
            .map((result) => result.error)
        );

        const errorMessage = [...uniqueErrorMessages].join("\n");
        message.error(`${errorMessage}`);
      }
    } catch (error) {
      message.error(error.response.data.message || "Erreur interne du serveur");

      console.error("Error creating availabilities:", error);
    }
  };

  const ConfirmEvenement = async (req, res) => {
    try {
      for (const availability of overlappingAvailability) {
        const existingStart = availability.start_time;
        const existingEnd = availability.end_time;
        const newStart = formData.start_time;
        const newEnd = formData.end_time;

        // Delete all existing appointments for this availability
        for (const appointment of availability.Appointments) {
          await axios.delete(
            `${appointmentService}/deleteAppointmentById/${appointment.id_appointment}`
          );
        }

        // Case 1: New event overlaps at the beginning of existing availability
        if (
          newStart > existingStart &&
          newStart < existingEnd &&
          newEnd >= existingEnd
        ) {
          await axios.put(
            `${availibilityService}/updateAvailability/${availability.id_availibility}`,
            {
              ...availability,
              end_time: newStart,
            }
          );

          // Recreate appointments that fall within the updated availability period
          for (const appointment of availability.Appointments) {
            const existingAppStart = appointment.start_time;
            const existingAppEnd = appointment.end_time;

            if (
              existingAppStart >= existingStart &&
              existingAppEnd <= newStart
            ) {
              await axios.post(
                `${appointmentService}/createAppointment`,
                {
                  ...appointment,
                  id_availibility: availability.id_availibility,
                },
                {
                  headers: {
                    "auth-token": token,
                  },
                }
              );
            }
          }
        }

        // Case 2: New event overlaps at the end of existing availability
        if (
          newEnd > existingStart &&
          newEnd < existingEnd &&
          newStart <= existingStart
        ) {
          await axios.put(
            `${availibilityService}/updateAvailability/${availability.id_availibility}`,
            {
              ...availability,
              start_time: newEnd,
            }
          );

          // Recreate appointments that fall within the updated availability period
          for (const appointment of availability.Appointments) {
            const existingAppStart = appointment.start_time;
            const existingAppEnd = appointment.end_time;

            if (existingAppStart >= newEnd && existingAppEnd <= existingEnd) {
              await axios.post(
                `${appointmentService}/createAppointment`,
                {
                  ...appointment,
                  id_availibility: availability.id_availibility,
                },
                {
                  headers: {
                    "auth-token": token,
                  },
                }
              );
            }
          }
        }

        // Case 3: New event is in the middle of existing availability
        if (newStart > existingStart && newEnd < existingEnd) {
          // Delete the old availability since it is now split into two
          await axios.delete(
            `${availibilityService}/deleteAvailability/${availability.id_availibility}`
          );
          const newAvailability1 = await axios.post(
            `${availibilityService}/createAvailability`,
            {
              ...availability,
              end_time: newStart,
            },
            {
              headers: {
                "auth-token": token,
              },
            }
          );

          const newAvailability2 = await axios.post(
            `${availibilityService}/createAvailability`,
            {
              ...availability,
              start_time: newEnd,
            },
            {
              headers: {
                "auth-token": token,
              },
            }
          );

          // Recreate appointments that fall within the first part of the availability
          for (const appointment of availability.Appointments) {
            const existingAppStart = appointment.start_time;
            const existingAppEnd = appointment.end_time;

            if (
              existingAppStart >= existingStart &&
              existingAppEnd <= newStart
            ) {
              await axios.post(
                `${appointmentService}/createAppointment`,
                {
                  ...appointment,
                  id_availibility: newAvailability1.data.id_availibility,
                },
                {
                  headers: {
                    "auth-token": token,
                  },
                }
              );
            }

            // Recreate appointments that fall within the second part of the availability
            if (existingAppStart >= newEnd && existingAppEnd <= existingEnd) {
              await axios.post(
                `${appointmentService}/createAppointment`,
                {
                  ...appointment,
                  id_availibility: newAvailability2.data.id_availibility,
                },
                {
                  headers: {
                    "auth-token": token,
                  },
                }
              );
            }
          }
        }

        // Additional Case: Old availability fully overlapped by new event
        if (
          (newStart <= existingStart && newEnd >= existingEnd) || // New event completely covers the old availability
          (newStart === existingStart && newEnd === existingEnd) // New event exactly matches the old availability
        ) {
          await axios.delete(
            `${availibilityService}/deleteAvailability/${availability.id_availibility}`
          );
          // No appointments are recreated in this case since the availability is fully covered
        }
      }

      const dateList = generateDateList();

      // Create the new availability/event
      await createAvailability(formData, dateList);

      setConfirmModalOpen(false);
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
          "Erreur lors de la suppression du rendez-vous"
      );
      console.error("Error deleting appointment:", error);
    }
  };

  // const ConfirmEvenement = async (req, res) => {
  //   try {
  //     for (const availability of overlappingAvailability) {
  //       await axios.delete(
  //         `${availibilityService}/deleteAvailability/${availability.id_availibility}`
  //       );
  //       await createExistingAvailability(availability);
  //     }

  //     const dateList = generateDateList();

  //     await createAvailability(formData, dateList);
  //     setConfirmModalOpen(false);
  //   } catch (error) {
  //     message.error(
  //       error.response.data.message ||
  //         "Erreur lors de la suppression du rendez-vous"
  //     );
  //     console.error("Error deleting appointment:", error);
  //   }
  // };
  const updateAvailability = async (formData) => {
    try {
      handleChange("type", "event");
      const response = await axios.put(
        `${availibilityService}/updateAvailability/${formData.id_availibility}`,
        formData,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setAvailabilityData((prevState) => [...prevState, response.data]);
      fetchAvailabilities();
      message.success("Disponibilité mise à jour avec succès ");
      setOpenEvent(false);
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Erreur interne du serveur"
      );
      console.error("Error updating availability:", error);
    }
  };
  const handleEventOk = async () => {
    setLoadingState((prevState) => ({ ...prevState, submit: true }));

    if (modalMode === "create") {
      const dateList = generateDateList();
      await createAvailability(formData, dateList);
    } else if (modalMode === "update") {
      await updateAvailability(formData);
    }
    setLoadingState((prevState) => ({ ...prevState, submit: false }));
  };
  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };
  const DeleteAvailability = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, delete: true }));

      await axios.delete(
        `${availibilityService}/deleteAvailability/${formData.id_availibility}`
      );
      message.success("Disponibilité supprimée avec succès");
      setOpen(false);
      setVisibleDelete(false);
      setOpenEvent(false);
    } catch (error) {
      message.error(
        error.response.data.message ||
          "Erreur lors de la suppression du rendez-vous"
      );
      console.error("Error deleting appointment:", error);
    }
    setLoadingState((prevState) => ({ ...prevState, delete: false }));
  };
  const handleDelete = async () => {
    await DeleteAvailability();
  };
  const [selectedDate, setSelectedDate] = useState(moment());

  const handleDatePickerChange = (date, dateString) => {
    setSelectedDate(moment(date));
  };
  const handleNavigate = (view, date) => {
    if (view === Views.MONTH) {
      setSelectedDate(moment(date));
    } else if (view === Views.YEAR) {
      setSelectedDate(moment(date));
    } else {
      setSelectedDate(moment(date));
    }
  };
  const fetchEvents = async () => {
    try {
      const [availabilitiesResponse, appointmentsResponse] = await Promise.all([
        await fetchAvailabilities(),
        !service && (await fetchAppointments()),
      ]);
      // Check if availabilitiesResponse is null or empty array
      const availabilities = availabilitiesResponse || [];

      // Check if appointmentsResponse is null or empty array
      const appointments = appointmentsResponse || [];

      const combinedEvents = [...availabilities, ...appointments];

      if (availabilities.length > 0) {
        setEvents(combinedEvents);
      } else {
        setEvents(appointments);
      }
    } catch (error) {
      message.error(
        error.response?.data?.message || "Erreur interne du serveur"
      );
      console.error("Error fetching events:", error);
    }
  };
  const fetchAvailabilities = async () => {
    try {
      let response;
      if (service === undefined) {
        response = await axios.get(
          `${availibilityService}/getAllAvailability`,
          {
            headers: {
              "auth-token": token,
            },
          }
        );
      } else {
        response = await axios.get(
          `${availibilityService}/getAllAvailabilityByServiceAndLocale/${service}/${locale.id}`,
          {
            headers: {
              "auth-token": token,
            },
          }
        );
      }
      setAvailabilityData(response?.data);
      setServiceData(response?.data[0]);
      return response?.data?.map(formatAvailabilityEvent);
    } catch (error) {}
  };
  const fetchAppointments = async () => {
    try {
      const response = await axios.get(
        `${appointmentService}/getAllAppointments`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      return response?.data?.appointments?.map(formatAppointmentEvent);
    } catch (error) {
      console.log("error", error);
    }
  };
  const formatAvailabilityEvent = (availability) => {
    return {
      title: service ? "" : availability.description,
      start: moment(`${availability.date}T${availability.start_time}`).toDate(),
      end: moment(`${availability.date}T${availability.end_time}`).toDate(),
      description: availability.description,
      color: availability.color,
      id_availibility: availability.id_availibility,
      timezone: availability.timezone,
      service_availability: availability.service_availability,
      id_doctor: availability.id_doctor,
      type: availability.type,
      name: availability.name,
      id_locale: availability.id_locale,
      video_conferencing: availability.video_conferencing,
      Services: availability.Services || "",
      slots: availability.slots || [],
    };
  };
  const formatAppointmentEvent = (appointment) => {
    return {
      title: "Rendez-vous",
      start: moment(`${appointment.date}T${appointment.start_time}`).toDate(),
      end: moment(`${appointment.date}T${appointment.end_time}`).toDate(),
      buffer_option: appointment.buffer_option,
      before_session: appointment.before_session,
      after_session: appointment.after_session,
      id_appointment: appointment.id_appointment,
      id_doctor: appointment.id_doctor,
      timezone: appointment.timezone,
      patient_name: appointment.patient_name,
      doctor_name: appointment.doctor_name,
      role: appointment.role,
      correspondance: appointment.correspondance,
      confirmed: appointment.confirmed,
      id_locale: appointment.Availability.id_locale,
      video_conferencing: appointment.video_conferencing,
      Services: [appointment.Service],
    };
  };
  const confirmBooking = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, confirmBooking: true }));

      await axios.get(
        `${appointmentService}/confirmAppointmentByIdAppointment/${formData.id_appointment}`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      message.success("Rendez-vous confirmé avec succès");
      fetchAppointments();
      setOpen(false);
    } catch (error) {
      console.log("error", error);
    }
    setLoadingState((prevState) => ({ ...prevState, confirmBooking: false }));
  };
  const handleEventCancel = () => {
    setFormData({});
    setOpenEvent(false);
    handleClosePicker();
  };
  useEffect(() => {
    fetchEvents();
  }, [open, openEvent, service]);

  return (
    <>
      <Row>
        <Col span={18}>
          {service !== undefined && (
            <div className="title-container">
              <h2 className="page-title">{serviceData?.serviceName}</h2>
            </div>
          )}

          <div>
            <Calendar
              messages={messages}
              date={selectedDate}
              onNavigate={(date, view) => handleNavigate(view, date)}
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{
                height: "700px",
              }}
              onSelectEvent={handleSelectEvent}
              onSelectSlot={(slotInfo) => {}}
              eventPropGetter={(event) => {
                return {
                  style: {
                    backgroundColor: event.color,
                    border: "1px solid black",
                    minHeight: "35px",
                  },
                };
              }}
              views={
                service !== undefined
                  ? ["month", "agenda"]
                  : ["month", "week", "day", "agenda"]
              } // Conditional views
            />
          </div>
        </Col>
        <Col span={6} style={service !== undefined ? { marginTop: "4%" } : {}}>
          <div style={{ paddingLeft: "10%" }}>
            <ConfigProvider locale={frFR}>
              <DatePicker
                defaultValue={moment()}
                value={selectedDate}
                onChange={handleDatePickerChange}
                style={{
                  width: "87%",
                  height: "41px",
                  borderRadius: "5px",
                }}
                placeholder="Select Date"
                allowClear={false}
                calendarClassName="custom-calendar-dropdown"
              />
            </ConfigProvider>

            <div style={{ width: "87%", marginTop: "10%" }}>
              <label
                htmlFor="timezone"
                style={{
                  marginBottom: "5%",
                  display: "block",
                  fontWeight: "bold",
                  color: "#607274",
                }}
              >
                Fuseau horaire:
              </label>

              <ConfigProvider locale={frFR}>
                <Select
                  value={formData.timezone}
                  onChange={(value) => handleChange("timezone", value)}
                  placeholder="Sélectionner un fuseau horaire"
                  className="custom-timezone-select"
                  style={{ fontWeight: "lighter", width: "100%" }}
                >
                  {frenchTimezones.map((timezone) => (
                    <Option key={timezone.value} value={timezone.value}>
                      {timezone.label}
                    </Option>
                  ))}
                </Select>
              </ConfigProvider>
            </div>
            {locale !== undefined && (
              <>
                <label
                  htmlFor="Location :"
                  style={{
                    marginBottom: "5%",
                    marginTop: "5%",
                    display: "block",
                    fontWeight: "bold",
                    color: "#607274",
                  }}
                >
                  Location:
                </label>
                <p
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  {locale.title} -{locale.bigtitle}
                </p>{" "}
              </>
            )}
            {service === undefined && (
              <div style={{ width: "78%" }}>
                <label
                  htmlFor="timezone"
                  style={{
                    marginTop: "8%",
                    marginBottom: "2%",
                    display: "block",
                    fontWeight: "bold",
                    color: "#607274",
                  }}
                >
                  Créneaux de événement :
                </label>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "12px",
                    color: "black",
                    marginLeft: "2%",
                  }}
                >
                  Cette section vous permet de gérer vos créneaux de événement.
                  Chaque créneau représente une période de temps pendant
                  laquelle vous êtes disponible pour des rendez-vous ou des
                  réunions.
                </p>
                {role === "doctor" && (
                  <button
                    onClick={() => {
                      showEventModal("create");
                      setModalMode("create");
                    }}
                    style={{
                      color: "green",
                      float: "left",
                      width: "100%",
                      fontWeight: "bold",
                      border: "none",
                    }}
                  >
                    <PlusOutlined />
                    Ajouter un événement
                  </button>
                )}

                <Modal
                  width={600}
                  title={
                    modalMode === "create"
                      ? "Ajouter une événement"
                      : "Modifier la événement"
                  }
                  centered
                  visible={openEvent}
                  onOk={handleEventOk}
                  onCancel={handleEventCancel}
                  destroyOnClose
                  okText={modalMode === "create" ? "Enregistrer" : "Modifier "}
                  cancelText="Annuler"
                  afterClose={handleEventCancel}
                  onValuesChange={handleChange}
                  footer={[
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      key="footer"
                    >
                      {modalMode === "update" ? (
                        <>
                          <Button
                            key="delete"
                            type="danger"
                            // onClick={handleDelete}
                            onClick={() => {
                              setVisibleDelete(true);
                            }}
                          >
                            Supprimer
                          </Button>
                          <Modal
                            title="Confirm Deletion"
                            visible={visibleDelete}
                            onCancel={() => setVisibleDelete(false)}
                            footer={[
                              <Button
                                key="cancel"
                                onClick={() => setVisibleDelete(false)}
                              >
                                Annuler
                              </Button>,
                              <Button
                                loading={loadingState.delete}
                                key="delete"
                                type="primary"
                                danger
                                onClick={handleDelete}
                              >
                                Supprimer
                              </Button>,
                            ]}
                          >
                            <p>Êtes-vous sûr de vouloir supprimer ce temps ?</p>
                          </Modal>
                        </>
                      ) : (
                        <>
                          <p></p>
                        </>
                      )}
                      <div key="right-buttons" style={{ display: "flex" }}>
                        <Button key="cancel" onClick={handleEventCancel}>
                          Annuler
                        </Button>
                        <Button
                          key="submit"
                          type="primary"
                          onClick={handleEventOk}
                          loading={loadingState.submit}
                        >
                          {modalMode === "create" ? "Sauvegarder" : "Modifier"}
                        </Button>
                      </div>
                    </div>,
                  ]}
                  style={{
                    marginBottom: "8%",
                  }}
                >
                  <Form layout="vertical">
                    <Row gutter={[16, 16]}>
                      <Col span={18}>
                        <Form.Item
                          label="Nom"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez saisir un nom !",
                            },
                          ]}
                        >
                          <Input
                            style={{ height: "40px" }}
                            placeholder="Saisir un nom"
                            className="custom-placeholder"
                            defaultValue={formData.name}
                            onChange={(e) => {
                              handleChange("name", e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Couleur"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez sélectionner la couleur!",
                            },
                          ]}
                        >
                          <Button
                            onClick={handlePickerToggle}
                            style={{ position: "relative" }}
                          >
                            {showPicker
                              ? "Fermer le sélecteur"
                              : "Ouvrir le sélecteur"}
                          </Button>
                          {showPicker && (
                            <div
                              style={{
                                position: "absolute",
                                right: 10,
                                zIndex: "1",
                                marginTop: "6%",
                              }}
                            >
                              {" "}
                              <ChromePicker
                                color={formData.color}
                                onChange={(newColor) => {
                                  handleChange("color", newColor.hex);
                                }}
                              />{" "}
                            </div>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Form.Item
                          label="Description"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez saisir la description!",
                            },
                          ]}
                        >
                          <Input
                            style={{ height: "40px" }}
                            placeholder="Saisir une description"
                            className="custom-placeholder"
                            value={formData.description}
                            onChange={(e) =>
                              handleChange("description", e.target.value)
                            }
                          />
                        </Form.Item>
                      </Col>

                      {/* {modalMode === "create" ? (
                      <>
                        <Col span={24}>
                          <Form.Item label="Date">
                            <ConfigProvider locale={frFR}>
                              <DatePicker
                                value={
                                  formData.date ? moment(formData.date) : null
                                }
                                onChange={(value) =>
                                  handleChange(
                                    "date",
                                    value
                                      ? moment(value).format("YYYY-MM-DD")
                                      : ""
                                  )
                                }
                                style={{
                                  width: "100%",
                                  height: "40px",
                                  borderRadius: "5px",
                                }}
                              />
                            </ConfigProvider>
                          </Form.Item>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={24}>
                          <Form.Item label="Date">
                            <ConfigProvider locale={frFR}>
                              <DatePicker
                                value={
                                  formData.date ? moment(formData.date) : null
                                }
                                onChange={(value) =>
                                  handleChange(
                                    "date",
                                    value
                                      ? moment(value).format("YYYY-MM-DD")
                                      : ""
                                  )
                                }
                                style={{
                                  width: "100%",
                                  height: "40px",
                                  borderRadius: "5px",
                                }}
                              />
                            </ConfigProvider>
                          </Form.Item>
                        </Col>
                      </>
                    )} */}
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Form.Item
                          label="Fuseau horaire"
                          rules={[
                            {
                              required: true,
                              message:
                                "Veuillez sélectionner le fuseau horaire!",
                            },
                          ]}
                        >
                          <ConfigProvider locale={frFR}>
                            <Select
                              value={formData.timezone}
                              onChange={(value) =>
                                handleChange("timezone", value)
                              }
                              placeholder="Sélectionner un fuseau horaire"
                              className="custom-timezone-select"
                              style={{ width: "100%" }}
                            >
                              {frenchTimezones.map((timezone) => (
                                <Option
                                  key={timezone.value}
                                  value={timezone.value}
                                >
                                  {timezone.label}
                                </Option>
                              ))}
                            </Select>
                          </ConfigProvider>
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item label="Date">
                          <ConfigProvider locale={frFR}>
                            <DatePicker
                              value={
                                formData.date ? moment(formData.date) : null
                              }
                              onChange={(value) =>
                                handleChange(
                                  "date",
                                  value
                                    ? moment(value).format("YYYY-MM-DD")
                                    : ""
                                )
                              }
                              style={{
                                width: "100%",
                                height: "40px",
                                borderRadius: "5px",
                              }}
                            />
                          </ConfigProvider>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Form.Item
                          label="Heure de début"
                          rules={[
                            {
                              required: true,
                              message:
                                "Veuillez sélectionner l'heure de début!",
                            },
                          ]}
                        >
                          <ConfigProvider locale={frFR}>
                            <TimePicker
                              value={
                                formData.start_time
                                  ? moment(formData.start_time, "HH:mm:ss")
                                  : null
                              }
                              onSelect={(value) =>
                                handleChange(
                                  "start_time",
                                  value ? value.format("HH:mm:ss") : ""
                                )
                              }
                              style={{
                                width: "100%",
                                height: "40px",
                                borderRadius: "5px",
                              }}
                            />
                          </ConfigProvider>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Heure de fin"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez sélectionner l'heure de fin !",
                            },
                          ]}
                        >
                          <ConfigProvider locale={frFR}>
                            <TimePicker
                              value={
                                formData.end_time
                                  ? moment(formData.end_time, "HH:mm:ss")
                                  : null
                              }
                              onSelect={(value) =>
                                handleChange(
                                  "end_time",
                                  value ? value.format("HH:mm:ss") : ""
                                )
                              }
                              style={{
                                width: "100%",
                                height: "40px",
                                borderRadius: "5px",
                              }}
                            />
                          </ConfigProvider>
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Form.Item
                        label="Disponibilité du service"
                        rules={[
                          {
                            required: true,
                            message:
                              "Veuillez sélectionner la disponibilité du service!",
                          },
                        ]}
                      >
                        <Select
                          value={
                            formData.service_availability !== null
                              ? formData.service_availability
                                ? "Disponible"
                                : "Indisponible"
                              : null
                          }
                          placeholder="Sélectionner un service"
                          onChange={(value) =>
                            handleChange("service_availability", value)
                          }
                        >
                          <Select.Option value="true">Disponible</Select.Option>
                          <Select.Option value="false">
                            Indisponible
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row> */}
                  </Form>
                </Modal>
                <Modal
                  title="Confirmer cet événement "
                  open={confirmModalOpen}
                  onOk={() => {
                    ConfirmEvenement();
                  }}
                  onCancel={() => {
                    setConfirmModalOpen(false);
                  }}
                >
                  <p>
                    {" "}
                    S'il y a d'autres disponibilités ou rendez-vous au meme
                    temps ils vont être rejeté
                  </p>
                </Modal>
                <Modal
                  title={"informations :"}
                  centered
                  visible={open}
                  onOk={handleOk}
                  onCancel={handleOk}
                  destroyOnClose
                  okText="Ok"
                  style={{
                    marginBottom: "8%",
                  }}
                  footer={null}
                >
                  {modalMode === "appointment" && (
                    <Form layout="vertical">
                      <Row>
                        <Col span={12}>
                          {formData.role === "doctor"
                            ? formData.patient_name !== "null null" && (
                                <Form.Item label="Nom du patient">
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      marginLeft: "",
                                    }}
                                  >
                                    {formData.patient_name}
                                  </p>
                                </Form.Item>
                              )
                            : formData.doctor_name !== "null null" && (
                                <Form.Item label="Nom du médecin">
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      marginLeft: "",
                                    }}
                                  >
                                    {formData.doctor_name}
                                  </p>
                                </Form.Item>
                              )}
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Status">
                            <p style={{ fontWeight: "bold", marginLeft: "" }}>
                              {formData.confirmed === "notConfirmed"
                                ? "Non confirmé"
                                : formData.confirmed === "pending"
                                ? "En attente"
                                : formData.confirmed === "confirmed"
                                ? "Confirmé"
                                : "Statut inconnu"}
                            </p>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item label="Fuseau horaire">
                            <p style={{ fontWeight: "Bold", marginLeft: "" }}>
                              {formData.timezone}
                            </p>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Date">
                            <p style={{ fontWeight: "Bold" }}>
                              {formData.date}
                            </p>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Form.Item label="Heure de début">
                            <p style={{ fontWeight: "Bold" }}>
                              {formData.start_time}
                            </p>
                          </Form.Item>
                        </Col>
                        {role === "doctor" && (
                          <Col span={12}>
                            <Form.Item label="Heure de fin">
                              <p style={{ fontWeight: "Bold" }}>
                                {formData.end_time}
                              </p>
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item label="Emplacement">
                            <p style={{ fontWeight: "Bold" }}>
                              {localData?.find(
                                (local) => local?.id === formData?.id_locale
                              )?.title || "Pas d'emplacement "}{" "}
                              -
                              {localData?.find(
                                (local) => local?.id === formData?.id_locale
                              )?.bigtitle || ""}
                            </p>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Type">
                            <p style={{ fontWeight: "Bold" }}>
                              {`${
                                formData.video_conferencing === "online"
                                  ? "Video Conférence"
                                  : formData.video_conferencing === "locale"
                                  ? "présentiel"
                                  : "Video Conférence ou présentiel"
                              }`}
                            </p>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={12}>
                          <Form.Item label="Services">
                            {formData &&
                            formData?.Services &&
                            formData?.Services?.length > 0 ? (
                              formData?.Services?.map((service) => (
                                <p
                                  style={{ fontWeight: "Bold" }}
                                  key={service?.key}
                                  value={service?.key}
                                >
                                  {`${service?.name} (${
                                    service?.type === "public"
                                      ? "publique"
                                      : "privée"
                                  })`}
                                </p>
                              ))
                            ) : (
                              <p style={{ fontWeight: "Bold" }}>
                                pas de service
                              </p>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      {formData.buffer_option === true && (
                        <Row gutter={[16, 16]}>
                          <Col span={12}>
                            <Form.Item label="Avant la seance">
                              <p style={{ fontWeight: "Bold" }}>
                                {formData.before_session}
                              </p>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="Aprés la seance">
                              <p style={{ fontWeight: "Bold" }}>
                                {formData.after_session}
                              </p>
                            </Form.Item>
                          </Col>
                        </Row>
                      )}

                      {formData.role === "patient" &&
                        formData.confirmed !== "confirmed" &&
                        formData.correspondance !== "patient" && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              loading={loadingState.confirmBooking}
                              onClick={() => {
                                confirmBooking();
                              }}
                            >
                              Confirm
                            </Button>
                          </div>
                        )}
                    </Form>
                  )}
                  {modalMode === "availability" && (
                    <Form layout="vertical">
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Form.Item
                            label="Description"
                            rules={[
                              {
                                required: true,
                                message: "Veuillez saisir la description!",
                              },
                            ]}
                          >
                            <p style={{ fontWeight: "Bold" }}>
                              {formData.description}
                            </p>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Couleur">
                            <div
                              style={{
                                width: "35%",
                                height: "20px",
                                backgroundColor: formData.color,
                                border: "1px solid #000",
                                display: "inline-block",
                                marginRight: "5px",
                              }}
                            ></div>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item label="Fuseau horaire">
                            <p style={{ fontWeight: "Bold", marginLeft: "" }}>
                              {formData.timezone}
                            </p>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Date">
                            <p style={{ fontWeight: "Bold" }}>
                              {formData.date}
                            </p>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Form.Item label="Heure de début">
                            <p style={{ fontWeight: "Bold" }}>
                              {formData.start_time}
                            </p>
                          </Form.Item>
                        </Col>
                        {role === "doctor" && (
                          <Col span={12}>
                            <Form.Item
                              label="Heure de fin"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Veuillez sélectionner l'heure de fin !",
                                },
                              ]}
                            >
                              <p style={{ fontWeight: "Bold" }}>
                                {formData.end_time}
                              </p>
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Form.Item label="Emplacement">
                            <p style={{ fontWeight: "Bold" }}>
                              {localData?.find(
                                (local) => local?.id === formData?.id_locale
                              )?.title || "Pas d'emplacement "}{" "}
                              -
                              {localData?.find(
                                (local) => local?.id === formData?.id_locale
                              )?.bigtitle || ""}
                            </p>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Type">
                            <p style={{ fontWeight: "Bold" }}>
                              {`${
                                formData.video_conferencing === "online"
                                  ? "Video Conférence"
                                  : formData.video_conferencing === "locale"
                                  ? "présentiel"
                                  : "Video Conférence ou présentiel"
                              }`}
                            </p>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item label="Services">
                            {formData &&
                            formData.Services &&
                            formData.Services.length > 0 ? (
                              formData.Services.map((service) => (
                                <p
                                  style={{ fontWeight: "Bold" }}
                                  key={service.key}
                                  value={service.key}
                                >
                                  {`${service.name} (${
                                    service.type === "public"
                                      ? "publique"
                                      : "privée"
                                  })`}
                                </p>
                              ))
                            ) : (
                              <p style={{ fontWeight: "Bold" }}>
                                pas de service
                              </p>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Modal>
              </div>
            )}
          </div>
        </Col>
      </Row>

      <Modal
        title={"informations :"}
        visible={openn}
        centered
        onOk={() => {
          setOpenn(false);
        }}
        onCancel={() => {
          setOpenn(false);
        }}
        destroyOnClose
        okText="Ok"
        style={{
          marginBottom: "8%",
        }}
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              loading={loading}
              type="text"
              style={{ backgroundColor: "#CDFADB", width: "50%" }}
              onClick={() => {
                handleReservation();
              }}
            >
              Réservez la disponibilité
            </Button>
          </div>,
        ]}
      >
        {modalMode === "appointment" && (
          <Form layout="vertical">
            <Row>
              <Col span={12}>
                {formData.role === "doctor"
                  ? formData.patient_name !== "null null" && (
                      <Form.Item label="Nom du patient">
                        <p
                          style={{
                            fontWeight: "bold",
                            marginLeft: "",
                          }}
                        >
                          {formData.patient_name}
                        </p>
                      </Form.Item>
                    )
                  : formData.doctor_name !== "null null" && (
                      <Form.Item label="Nom du médecin">
                        <p
                          style={{
                            fontWeight: "bold",
                            marginLeft: "",
                          }}
                        >
                          {formData.doctor_name}
                        </p>
                      </Form.Item>
                    )}
              </Col>
              <Col span={12}>
                <Form.Item label="Status">
                  <p style={{ fontWeight: "bold", marginLeft: "" }}>
                    {formData.confirmed === "notConfirmed"
                      ? "Non confirmé"
                      : formData.confirmed === "pending"
                      ? "En attente"
                      : formData.confirmed === "confirmed"
                      ? "Confirmé"
                      : "Statut inconnu"}
                  </p>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="Fuseau horaire">
                  <p style={{ fontWeight: "Bold", marginLeft: "" }}>
                    {formData.timezone}
                  </p>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Date">
                  <p style={{ fontWeight: "Bold" }}>{formData.date}</p>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item label="Heure de début">
                  <p style={{ fontWeight: "Bold" }}>{formData.start_time}</p>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Heure de fin">
                  <p style={{ fontWeight: "Bold" }}>{formData.end_time}</p>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="Emplacement">
                  <p style={{ fontWeight: "Bold" }}>
                    {localData?.find(
                      (local) => local?.id === formData?.id_locale
                    )?.title || "Pas d'emplacement "}{" "}
                    -
                    {localData?.find(
                      (local) => local?.id === formData?.id_locale
                    )?.bigtitle || ""}
                  </p>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Type">
                  <p style={{ fontWeight: "Bold" }}>
                    {`${
                      formData.video_conferencing === "online"
                        ? "Video Conférence"
                        : formData.video_conferencing === "locale"
                        ? "présentiel"
                        : "Video Conférence ou présentiel"
                    }`}
                  </p>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Form.Item label="Services">
                  {formData &&
                  formData?.Services &&
                  formData?.Services?.length > 0 ? (
                    formData?.Services?.map((service) => (
                      <p
                        style={{ fontWeight: "Bold" }}
                        key={service?.key}
                        value={service?.key}
                      >
                        {`${service?.name} (${
                          service?.type === "public" ? "publique" : "privée"
                        })`}
                      </p>
                    ))
                  ) : (
                    <p style={{ fontWeight: "Bold" }}>pas de service</p>
                  )}
                </Form.Item>
              </Col>
            </Row>
            {formData.buffer_option === true && (
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item label="Avant la seance">
                    <p style={{ fontWeight: "Bold" }}>
                      {formData.before_session}
                    </p>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Aprés la seance">
                    <p style={{ fontWeight: "Bold" }}>
                      {formData.after_session}
                    </p>
                  </Form.Item>
                </Col>
              </Row>
            )}

            {formData.role === "patient" &&
              formData.confirmed !== "confirmed" &&
              formData.correspondance !== "patient" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={() => {
                      confirmBooking();
                    }}
                  >
                    Confirm
                  </Button>
                </div>
              )}
          </Form>
        )}
        {modalMode === "availability" && (
          <Form layout="vertical">
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "Veuillez saisir la description!",
                    },
                  ]}
                >
                  <p style={{ fontWeight: "Bold" }}>{formData.description}</p>
                </Form.Item>
              </Col>
              {service === undefined ? (
                <Col span={12}>
                  <Form.Item label="Couleur">
                    <p style={{ fontWeight: "Bold" }}>{formData.color}</p>
                  </Form.Item>
                </Col>
              ) : (
                <Col span={12}>
                  <Form.Item
                    label="Temps disponible"
                    rules={[
                      {
                        message: "Veuillez sélectionner une slot",
                      },
                    ]}
                  >
                    <Select
                      style={{ height: "35px", width: "80%" }}
                      value={time}
                      onChange={(value) => {
                        setTime(value);
                      }}
                    >
                      {formData.slots && Array.isArray(formData.slots) ? (
                        formData.slots.map((slot, index) => (
                          <Select.Option
                            key={index}
                            value={`${slot.start} - ${slot.end}`}
                          >
                            {/* {`${slot.start} - ${slot.end}`} */}
                            {`${slot.start} `}
                          </Select.Option>
                        ))
                      ) : (
                        <Select.Option disabled key="no-slots">
                          No available slots
                        </Select.Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="Fuseau horaire">
                  <p style={{ fontWeight: "Bold", marginLeft: "" }}>
                    {formData.timezone}
                  </p>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Date">
                  <p style={{ fontWeight: "Bold" }}>{formData.date}</p>
                </Form.Item>
              </Col>
            </Row>
            {service === undefined && (
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item label="Heure de début">
                    <p style={{ fontWeight: "Bold" }}>{formData.start_time}</p>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Heure de fin"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez sélectionner l'heure de fin !",
                      },
                    ]}
                  >
                    <p style={{ fontWeight: "Bold" }}>{formData.end_time}</p>
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item label="Emplacement">
                  <p style={{ fontWeight: "Bold" }}>
                    {localData?.find(
                      (local) => local?.id === formData?.id_locale
                    )?.title || "Pas d'emplacement "}{" "}
                    -
                    {localData?.find(
                      (local) => local?.id === formData?.id_locale
                    )?.bigtitle || ""}
                  </p>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Type">
                  {formData.video_conferencing !== "both" ? (
                    <p style={{ fontWeight: "Bold" }}>
                      {`${
                        formData.video_conferencing === "online"
                          ? "Video Conférence"
                          : formData.video_conferencing === "locale"
                          ? "présentiel"
                          : "Video Conférence ou présentiel"
                      }`}
                    </p>
                  ) : (
                    <Radio.Group
                      onChange={(e) => {
                        setVideo_conferencing(e.target.value);
                      }}
                    >
                      <Radio value={"online"}>Video Conférence</Radio>
                      <Radio value={"locale"}>Présentiel</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                {service === undefined && (
                  <Form.Item label="Services">
                    {formData &&
                    formData.Services &&
                    formData.Services.length > 0 ? (
                      formData.Services.map((service) => (
                        <p
                          style={{ fontWeight: "Bold" }}
                          key={service.key}
                          value={service.key}
                        >
                          {`${service.name} (${
                            service.type === "public" ? "publique" : "privée"
                          })`}
                        </p>
                      ))
                    ) : (
                      <p style={{ fontWeight: "Bold" }}>pas de service</p>
                    )}
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default MyEvents;
