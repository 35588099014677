import React, { useEffect, useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  FileTextOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  Layout,
  Menu,
  Row,
  message,
  theme,
} from "antd";
import { Footer } from "antd/lib/layout/layout";
import profileIcon from "../assets/images/profileIcon.png"; // Import the image file
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import { protocoleService, recommendationService } from "../utils/URL";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Recommendation from "../components/layout/Recommendation";
import TableComponent from "../components/layout/TableComponent";
import FicheComponent from "../components/layout/FicheComponent";
const { Header, Sider, Content } = Layout;
const CheckboxGroup = Checkbox.Group;
const ProtocoleDetails = () => {
  const token = Cookies.get("token");
  const history = useHistory();
  const [recommendations, setRecommendations] = useState([]);
  const [recommendationsIds, setRecommendationsIds] = useState([]);
  const [recommendationList, setRecommendationList] = useState([]);
  const [formData, setFormData] = useState({
    image: "",
    name: "",
    objective: "",
    supplement: "",
    date: "",
    start_time: "",
    end_time: "",
    id_locale: "",
    updated_at: "",
    supplementTable: "",
    supplementFile: "",
  });
  const { id_protocol } = useParams();

  const fetchProtocolData = async () => {
    try {
      const responce = await axios.get(
        `${protocoleService}/getProtocolById/${id_protocol}`
      );

      setFormData({
        image: responce.data.image,
        name: responce.data.name,
        objective: responce.data.objective,
        supplement: responce.data.supplement,
        updated_at: responce.data.updated_at,
        supplementTable: responce.data.supplementTable,
        supplementFile: responce.data.supplementFile,
      });
      const recommendationNames = responce.data.Recommendations.map(
        (rec) => rec.name
      );
      const recommendationIds = responce.data.Recommendations.map(
        (rec) => rec.id_recommendation
      );
      setRecommendations(recommendationNames);
      setRecommendationsIds(recommendationIds);
      setRecommendationList(responce.data.Recommendations);
    } catch (error) {
      console.error("Error creating patient:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    }
  };

  const options = [
    { label: "H Y D R A T A T I O N", value: "hyratation" },
    { label: "N U T R I T I O N", value: "nutrition" },
    { label: "A C T I V I T É   P H Y S I Q U E", value: "activité_physique" },
    { label: "S O M M E I L", value: "sommail" },
    { label: "G E S T I O N   D U   S T R E S S", value: "gestion_du_stress" },
    { label: "E N V I R O N N E M E N T", value: "environnement" },
  ];
  const options2 = [
    { label: "T A B L E A U", value: "tableau" },
    { label: "F I C H E", value: "fiche" },
    { label: "A I D E   M É M O I R E", value: "aide_memoire" },
  ];
  let colorBgContainer;
  let borderRadiusLG;

  useEffect(() => {
    fetchProtocolData();
  }, [id_protocol]);

  return (
    <>
      <Card>
        <Layout style={{ marginLeft: "0px" }}>
          <Sider
            style={{
              backgroundColor: "#001d3d",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            width={300}
            trigger={null}
          >
            <div
              style={{
                marginTop: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px 0",
                backgroundColor: "#fff",
                borderRadius: "50%",
                width: "170px",
                height: "170px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  width: "150px",
                  height: "150px",
                  border: "2px solid #000",
                }}
              >
                <img
                  src={formData.image || profileIcon} // Replace with your image path
                  alt="Logo"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                />
              </div>
            </div>
          </Sider>

          <Layout style={{ marginLeft: "0px" }}>
            <Header
              style={{
                padding: "10px 0px",
                background: colorBgContainer,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2 style={{ margin: 0 }}>
                {moment(formData.updated_at).format("ddd MMM D - YYYY")}
              </h2>
            </Header>

            <Content
              style={{
                padding: 24,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <div
                style={{
                  marginTop: "2%",
                  marginRight: "2%",
                  padding: 0,
                  background: colorBgContainer,
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end", // Change "end" to "center"
                }}
              >
                <div>
                  <h2 style={{ margin: 0 }}>{formData?.name}</h2>
                  <div
                    style={{
                      marginTop: "2%",
                      marginRight: "2%",
                      padding: 0,
                      background: colorBgContainer,
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end", // Change "end" to "center"
                    }}
                  >
                    <div
                      style={{
                        borderBottom: "5px solid #023047",
                        width: "60%",
                        marginTop: "5px",
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginTop: "3%",
                  marginRight: "2%",
                  padding: 0,
                  background: colorBgContainer,
                  display: "flex",
                  justifyContent: "Start",
                  alignItems: "center", // Change "end" to "center"
                }}
              >
                <div>
                  <h2 style={{ margin: 0 }}>OBJECTIFS</h2>
                  <div
                    style={{
                      borderBottom: "5px solid #023047",
                      width: "50%",
                      marginTop: "5px",
                    }}
                  ></div>
                </div>
              </div>

              <div style={{ marginTop: "2%" }}>
                <TextArea
                  value={formData?.objective}
                  disabled
                  rows={4}
                  style={{ width: "600px", border: "2px solid #023047" }}
                  placeholder="Editeur de texte"
                />
              </div>
              <div
                style={{
                  marginTop: "3%",
                  marginRight: "2%",
                  padding: 0,
                  background: colorBgContainer,
                  display: "flex",
                  justifyContent: "Start",
                  alignItems: "center", // Change "end" to "center"
                }}
              >
                <div>
                  <h2 style={{ margin: 0 }}>RECOMMANDATIONS</h2>
                  <div
                    style={{
                      borderBottom: "5px solid #023047",
                      width: "50%",
                      marginTop: "5px",
                    }}
                  ></div>
                </div>
              </div>

              <div style={{ marginTop: "2%" }}>
                <CheckboxGroup
                  value={recommendations}
                  options={options}
                  //   defaultValue={["hyratation"]}
                  style={{
                    width: "600px",
                    border: "2px solid #023047",
                    borderRadius: "5px",
                  }}
                />
              </div>

              {recommendationsIds.map((id, index) => (
                <Recommendation
                  id={id}
                  recommendation={recommendationList.find(
                    (recommendationListElement) =>
                      recommendationListElement.id_recommendation === id
                  )}
                  handleState={(data) => {
                    console.log(data);
                  }}
                />
              ))}

              <div
                style={{
                  marginTop: "3%",
                  marginRight: "2%",
                  padding: 0,
                  background: colorBgContainer,
                  display: "flex",
                  justifyContent: "Start",
                  alignItems: "center", // Change "end" to "center"
                }}
              >
                <div>
                  <h2 style={{ margin: 0 }}>SUPPLÉMENTS</h2>
                  <div
                    style={{
                      borderBottom: "5px solid #023047",
                      width: "50%",
                      marginTop: "5px",
                    }}
                  ></div>
                </div>
              </div>

              <div style={{ marginTop: "2%" }}>
                <CheckboxGroup
                  value={formData.supplement}
                  options={options2}
                  //   defaultValue={["fiche"]}
                  style={{
                    width: "600px",
                    border: "2px solid #023047",
                    borderRadius: "5px",
                  }}
                />
              </div>

              <div style={{ marginTop: "2%" }}>
                {id_protocol !== undefined && (
                  <>
                    {formData.supplement.includes("tableau") && (
                      <TableComponent
                        show={true}
                        id_protocol={id_protocol}
                        tableData={formData?.supplementTable}
                        handleTable={(data) => {
                          console.log(data);
                        }}
                      />
                    )}
                    {formData.supplement.includes("fiche") && (
                      <FicheComponent
                        show={true}
                        id_protocol={id_protocol}
                        fileData={formData?.supplementFile}
                        handleFile={(data) => {
                          console.log(data);
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </Content>
          </Layout>
        </Layout>
      </Card>

      <div
        className="setting-drwer"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FCDC94",
        }}
        onClick={() => {
          history.goBack();
        }}
      >
        <RollbackOutlined />
      </div>
    </>
  );
};

export default ProtocoleDetails;
