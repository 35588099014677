import React, { useEffect, useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Input,
  Layout,
  Menu,
  Row,
  Space,
  message,
  theme,
} from "antd";
import profileIcon from "../assets/images/profileIcon.png"; // Import the image file
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import { protocoleService, recommendationService } from "../utils/URL";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Recommendation from "../components/layout/Recommendation";
import TableComponent from "../components/layout/TableComponent";
import FicheComponent from "../components/layout/FicheComponent";
const { Header, Sider, Content } = Layout;
const CheckboxGroup = Checkbox.Group;
const AddProtocole = () => {
  const token = Cookies.get("token");
  const history = useHistory();

  const [recommendations, setRecommendations] = useState([]);
  const [showRecommendations, setShowRecommendations] = useState(false);
  const [showSupplements, setShowSupplements] = useState(false);
  const [showSupplementsComponents, setShowSupplementsComponents] =
    useState(false);
  const [states, setStates] = useState([]);
  const [recomendationList, setRecomendationList] = useState([]);
  const [loadingState, setLoadingState] = useState({
    delete: false,
    submit: false,
  });
  const [formData, setFormData] = useState({
    image: "",
    name: "",
    objective: "",
    supplement: "",
    date: "",
    start_time: "",
    end_time: "",
    id_locale: "",
  });
  const { id_protocol } = useParams();

  const fetchProtocolData = async () => {
    try {
      const responce = await axios.get(
        `${protocoleService}/getProtocolById/${id_protocol}`
      );
      setFormData({
        image: responce.data.image,
        name: responce.data.name,
        objective: responce.data.objective,
        supplement: responce.data.supplement,
        supplementTable: responce.data.supplementTable,
        supplementFile: responce.data.supplementFile,
      });
      const recommendationNames = responce.data.Recommendations.map(
        (rec) => rec.name
      );
      setRecomendationList(responce.data.Recommendations);
      setRecommendations(recommendationNames);
    } catch (error) {
      console.error("Error creating patient:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    }
  };

  const handleChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };
  const currentDate = new Date().toISOString().split("T")[0];

  const formattedDate = moment(currentDate).format("ddd MMM D - YYYY");

  const options = [
    { label: "H Y D R A T A T I O N", value: "hyratation" },
    { label: "N U T R I T I O N", value: "nutrition" },
    { label: "A C T I V I T É   P H Y S I Q U E", value: "activité_physique" },
    { label: "S O M M E I L", value: "sommail" },
    { label: "G E S T I O N   D U   S T R E S S", value: "gestion_du_stress" },
    { label: "E N V I R O N N E M E N T", value: "environnement" },
  ];
  const options2 = [
    { label: "T A B L E A U", value: "tableau" },
    { label: "F I C H E", value: "fiche" },
    { label: "A I D E   M É M O I R E", value: "aide_memoire" },
  ];
  let colorBgContainer;
  let borderRadiusLG;

  useEffect(() => {
    if (id_protocol !== undefined) {
      fetchProtocolData();
      setShowRecommendations(true);
      setShowSupplements(true);
    }
  }, [id_protocol]);

  useEffect(() => {
    if (recommendations.length === 0) {
      setShowSupplements(false);
    }
    const recommendationTable = {};

    recommendations.forEach((recommendation) => {
      const state = id_protocol ? true : false;

      recommendationTable[recommendation] = state;
    });
    setStates(recommendationTable);

    setRecomendationList((prevList) => {
      // Retain existing recommendations that are still in the new list
      const updatedList = prevList.filter((rec) =>
        recommendations.includes(rec.name)
      );

      // Add new recommendations that are in the new list but not in the existing list
      recommendations.forEach((name) => {
        if (!updatedList.some((rec) => rec.name === name)) {
          updatedList.push({ name });
        }
      });

      return updatedList;
    });
  }, [recommendations, id_protocol]);

  const createProtocole = async () => {
    try {
      const response = await axios.post(
        `${protocoleService}/createProtocol`,
        formData,
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      // Create recommendations
      await Promise.all(
        recomendationList.map(async (recommendation) => {
          await axios.post(
            `${recommendationService}/createRecommendation`,
            {
              name: recommendation.name,
              instructions_title: recommendation.instructions_title,
              instructions: recommendation.instructions,
              to_do_title: recommendation.to_do_title,
              to_do: recommendation.to_do,
              id_protocol: response.data.id_protocol,
            },
            {
              headers: {
                "auth-token": token,
              },
            }
          );
        })
      );

      message.success("Protocole est créé avec succès");
      history.goBack();
    } catch (error) {
      console.error("Error creating protocole:", error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        // Check if error is from recommendation creation
        if (error.config.url.includes("/createRecommendation")) {
          message.error("Erreur lors de la création des recommandations");
        } else {
          message.error("Erreur interne du serveur");
        }
      }
    }
  };

  const updateProtocole = async (id) => {
    try {
      const response = await axios.put(
        `${protocoleService}/updateProtocol/${id}`,
        formData
      );

      const recommendationsToRemove = response?.data?.Recommendations.filter(
        (rec) => !recomendationList.find((newRec) => newRec.name === rec.name)
      );
      const recommendationsToUpdate = recomendationList.map((rec) => {
        const matchingRec = response?.data?.Recommendations.find(
          (newRec) => newRec.name === rec.name
        );
        return matchingRec
          ? { ...rec, id_recommendation: matchingRec.id_recommendation }
          : rec;
      });

      const existingRecommendations = response?.data?.Recommendations.map(
        (rec) => rec
      );
      const recommendationsToAdd = recomendationList.filter(
        (newRec) =>
          !existingRecommendations.find((rec) => rec.name === newRec.name)
      );


      // Delete recommendations
      await Promise.all(
        recommendationsToRemove.map(async (rec) => {
          await axios.delete(
            `${recommendationService}/deleteRecommendationById/${rec.id_recommendation}`,
            {
              headers: {
                "auth-token": token,
              },
            }
          );
        })
      );

      // Add new recommendations
      await Promise.all(
        recommendationsToAdd.map(async (recommendation) => {
          await axios.post(
            `${recommendationService}/createRecommendation`,
            {
              name: recommendation.name,
              instructions_title: recommendation.instructions_title,
              instructions: recommendation.instructions,
              to_do_title: recommendation.to_do_title,
              to_do: recommendation.to_do,
              id_protocol: response.data.id_protocol,
            },
            {
              headers: {
                "auth-token": token,
              },
            }
          );
        })
      );

      // Update recommendations
      await Promise.all(
        recommendationsToUpdate.map(async (recommendation) => {
          await axios.put(
            `${recommendationService}/updateRecommendation/${recommendation.id_recommendation}`,
            {
              name: recommendation.name,
              instructions_title: recommendation.instructions_title,
              instructions: recommendation.instructions,
              to_do_title: recommendation.to_do_title,
              to_do: recommendation.to_do,
              id_protocol: response.data.id_protocol,
            }
            // {
            //   headers: {
            //     "auth-token": token,
            //   },
            // }
          );
        })
      );

      message.success("Protocole est modifié avec succès");
      history.goBack();
    } catch (error) {
      console.error("Error creating patient:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    }
  };

  const handleDelete = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, delete: true }));

      await axios.delete(
        `${protocoleService}/deleteProtocolById/${id_protocol}`
      );

      message.success("Protocole est Supprimé avec succès");
      history.goBack();
    } catch (error) {
      console.error("Error deleting protocole:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    }
    setLoadingState((prevState) => ({ ...prevState, delete: false }));

  };

  const handleCreate = async () => {
    const allStatesTrue = Object.values(states).every((state) => state);

    if (!allStatesTrue) {
      const recommendationWithError = Object.keys(states).find(
        (name) => !states[name]
      );
      message.error(
        `Le donnée de "${recommendationWithError}" n'est pas enregistrée.`
      );
    } else {
      setLoadingState((prevState) => ({ ...prevState, submit: true }));

      if (id_protocol !== undefined) {
        await updateProtocole(id_protocol);
      } else {
        await createProtocole();
      }
      setLoadingState((prevState) => ({ ...prevState, submit: false }));
    }
  };
  const handleAddRecommendation = (newRecommendation) => {
    // Check if the recommendation with the same name already exists
    const existingIndex = recomendationList.findIndex(
      (item) => item.name === newRecommendation.name
    );

    if (existingIndex !== -1) {
      // Update the existing recommendation
      const updatedList = [...recomendationList];
      updatedList[existingIndex] = newRecommendation;
      setRecomendationList(updatedList);
    } else {
      setRecomendationList((prevList) => [...prevList, newRecommendation]);
    }
  };

  return (
    <Layout style={{ marginLeft: "0px" }}>
      <Sider
        style={{
          backgroundColor: "#001d3d",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        width={300}
        trigger={null}
      >
        <div
          style={{
            marginTop: "20%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px 0",
            backgroundColor: "#fff",
            borderRadius: "50%",
            width: "170px",
            height: "170px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              width: "150px",
              height: "150px",
              border: "2px solid #000",
            }}
          >
            <img
              src={formData.image || profileIcon}
              alt="Logo"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
              }}
            />
          </div>
        </div>
      </Sider>

      <Layout style={{ marginLeft: "0px" }}>
        <Header
          style={{
            padding: "10px 0px",
            background: colorBgContainer,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 style={{ margin: 0 }}>{formattedDate}</h2>
        </Header>

        <Content
          style={{
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div
            style={{
              marginTop: "2%",
              marginRight: "2%",
              padding: 0,
              background: colorBgContainer,
              display: "flex",
              justifyContent: "end",
              alignItems: "end", // Change "end" to "center"
            }}
          >
            <div>
              <h2 style={{ margin: 0 }}>TITRE</h2>
              <div
                style={{
                  marginTop: "2%",
                  marginRight: "2%",
                  padding: 0,
                  background: colorBgContainer,
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end", // Change "end" to "center"
                }}
              >
                <div
                  style={{
                    borderBottom: "5px solid #023047",
                    width: "60%",
                    marginTop: "5px",
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "1%",
              marginRight: "2%",
              padding: 0,
              background: colorBgContainer,
              display: "flex",
              justifyContent: "end",
              alignItems: "end", // Change "end" to "center"
            }}
          >
            <Input
              value={formData?.name}
              onChange={(e) => {
                handleChange("name", e.target.value);
              }}
              style={{ width: "120px", border: "2px solid #023047" }}
              placeholder="Nom"
            />{" "}
          </div>

          <div
            style={{
              marginTop: "3%",
              marginRight: "2%",
              padding: 0,
              background: colorBgContainer,
              display: "flex",
              justifyContent: "Start",
              alignItems: "center", // Change "end" to "center"
            }}
          >
            <div>
              <h2 style={{ margin: 0 }}>OBJECTIFS</h2>
              <div
                style={{
                  borderBottom: "5px solid #023047",
                  width: "50%",
                  marginTop: "5px",
                }}
              ></div>
            </div>
          </div>
          <div style={{ marginTop: "2%" }}>
            <Button
              type="text"
              style={{
                backgroundColor: "#55a630",
                borderRadius: "15px",
              }}
              onClick={() => {
                if (formData?.objective !== "") {
                  setShowRecommendations(true);
                } else {
                  message.error(
                    "Vous devriez remplir la description s'il vous plaît"
                  );
                }
              }}
            >
              {" "}
              Ajouter votre objectif
            </Button>
          </div>
          <div style={{ marginTop: "2%" }}>
            <TextArea
              value={formData?.objective}
              onChange={(e) => {
                handleChange("objective", e.target.value);
              }}
              rows={4}
              style={{ width: "600px", border: "2px solid #023047" }}
              placeholder="Editeur de texte"
            />
          </div>

          {showRecommendations && (
            <>
              <div
                style={{
                  marginTop: "3%",
                  marginRight: "2%",
                  padding: 0,
                  background: colorBgContainer,
                  display: "flex",
                  justifyContent: "Start",
                  alignItems: "center", // Change "end" to "center"
                }}
              >
                <div>
                  <h2 style={{ margin: 0 }}>RECOMMANDATIONS</h2>
                  <div
                    style={{
                      borderBottom: "5px solid #023047",
                      width: "50%",
                      marginTop: "5px",
                    }}
                  ></div>
                </div>
              </div>
              <div style={{ marginTop: "2%" }}>
                <Button
                  type="text"
                  style={{
                    backgroundColor: "#55a630",
                    borderRadius: "15px",
                  }}
                  onClick={() => {
                    if (recommendations.length > 0) {
                      setShowSupplements(true);
                    } else {
                      message.error(
                        "Vous devriez sélectionner une ou plusieur s'il vous plaît"
                      );
                    }
                  }}
                >
                  {id_protocol
                    ? `Afficher les recommendations`
                    : `Ajouter des recommendations`}
                </Button>
              </div>
              <div style={{ marginTop: "2%" }}>
                <CheckboxGroup
                  value={recommendations}
                  onChange={(value) => {
                    setRecommendations(value);
                  }}
                  options={options}
                  //   defaultValue={["hyratation"]}
                  style={{
                    width: "600px",
                    border: "2px solid #023047",
                    borderRadius: "5px",
                  }}
                />
              </div>
            </>
          )}

          {showSupplements && (
            <>
              {recommendations.map((recommendation, index) => (
                <Recommendation
                  recommendation={recomendationList.find(
                    (recommendationListElement) =>
                      recommendationListElement.name === recommendation
                  )}
                  id_protocol={id_protocol}
                  key={index}
                  name={recommendation}
                  customKey={index + 1}
                  handleSend={handleAddRecommendation}
                  handleState={(data) => {
                    setStates((prevStates) => ({
                      ...prevStates,
                      [recommendation]: data,
                    }));
                  }}
                />
              ))}
            </>
          )}

          {(showSupplements || id_protocol !== undefined) && (
            <>
              <div
                style={{
                  marginTop: "3%",
                  marginRight: "2%",
                  padding: 0,
                  background: colorBgContainer,
                  display: "flex",
                  justifyContent: "Start",
                  alignItems: "center", // Change "end" to "center"
                }}
              >
                <div>
                  <h2 style={{ margin: 0 }}>SUPPLÉMENTS</h2>
                  <div
                    style={{
                      borderBottom: "5px solid #023047",
                      width: "50%",
                      marginTop: "5px",
                    }}
                  ></div>
                </div>
              </div>
              <div style={{ marginTop: "2%" }}>
                <Button
                  type="text"
                  style={{
                    backgroundColor: "#55a630",
                    borderRadius: "15px",
                    // pointerEvents: "none",
                  }}
                  onClick={() => {
                    setShowSupplementsComponents(true);
                  }}
                >
                  {" "}
                  Ajouter des supplements
                </Button>
              </div>
              <div style={{ marginTop: "2%" }}>
                <CheckboxGroup
                  value={formData.supplement}
                  onChange={(e) => {
                    handleChange("supplement", e);
                  }}
                  options={options2}
                  //   defaultValue={["fiche"]}
                  style={{
                    width: "600px",
                    border: "2px solid #023047",
                    borderRadius: "5px",
                  }}
                />
              </div>

              <div style={{ marginTop: "2%" }}>
                {(showSupplementsComponents || id_protocol !== undefined) && (
                  <>
                    {formData.supplement.includes("tableau") && (
                      <TableComponent
                        show={false}
                        handleState={(data) => {
                          setStates((prevStates) => ({
                            ...prevStates,
                            ["Tableau supplement"]: data,
                          }));
                        }}
                        id_protocol={id_protocol}
                        tableData={formData?.supplementTable}
                        handleTable={(data) => {
                          handleChange("supplementTable", data);
                        }}
                      />
                    )}
                    {formData.supplement.includes("fiche") && (
                      <FicheComponent
                        show={false}
                        id_protocol={id_protocol}
                        handleState={(data) => {
                          setStates((prevStates) => ({
                            ...prevStates,
                            ["la Fiche supplement"]: data,
                          }));
                        }}
                        fileData={formData?.supplementFile}
                        handleFile={(data) => {
                          handleChange("supplementFile", data);
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </>
          )}
          <div
            style={{
              marginTop: "2%",
              marginRight: "2%",
              padding: 0,
              background: colorBgContainer,
              display: "flex",
              justifyContent: "end",
              alignItems: "center", // Change "end" to "center"
            }}
          >
            {/* <div
              style={{ position: "relative", width: "100%", height: "100%" }}
            >
              <Button
                type="text"
                style={{
                  backgroundColor: "#55a630",
                  borderRadius: "15px",
                  width: "20%",
                  position: "absolute",
                  bottom: 30,
                  right: 50,
                }}
                onClick={handleCreate}
              >
                {id_protocol ? `Modifier` : `Créer`}
              </Button>
            </div> */}

            <div style={{ position: "absolute", bottom: 0, right: 50 }}>
              <Space>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Button
                    type="text"
                    loading={loadingState.submit}
                    style={{
                      backgroundColor: "#55a630",
                      borderRadius: "15px",
                      width: "150px",
                    }}
                    onClick={handleCreate}
                  >
                    {id_protocol ? `Modifier` : `Créer`}
                  </Button>
                </div>
                {id_protocol && (
                  <div
                    style={{
                      position: "relative",
                      width: "150px",
                      height: "100%",
                    }}
                  >
                    <Button
                      type="text"
                      style={{
                        backgroundColor: "#C80036",
                        borderRadius: "15px",
                        width: "100%",
                      }}
                      onClick={handleDelete}
                    >
                      Supprimer
                    </Button>
                  </div>
                )}
              </Space>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AddProtocole;
