import { Button } from 'antd';

const CustomToolbar = (toolbarProps) => {
  const { label, onNavigate } = toolbarProps;

  return (
    <div className="rbc-toolbar">
      <div className="rbc-toolbar-label">{label}</div>
      <div className="rbc-btn-group">
        <Button style={{height:"10%"}} type="button" onClick={() => onNavigate('PREV')}>{"<"}</Button>
        <Button style={{height:"10%"}} type="button" onClick={() => onNavigate('TODAY')}>Aujourd'hui</Button>
        <Button style={{height:"10%"}} type="button" onClick={() => onNavigate('NEXT')}>{">"}</Button>
      </div>
    </div>
  );
};

export default CustomToolbar;
