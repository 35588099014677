const frenchTimezones = [
  { label: "UTC-03:30 (Canada/Newfoundland)", value: "Canada/Newfoundland" },
  { label: "UTC-04:00 (Canada/Atlantic)", value: "Canada/Atlantic" },
  { label: "UTC-05:00 (Canada/Eastern)", value: "Canada/Eastern" },
  { label: "UTC-06:00 (Canada/Central)", value: "Canada/Central" },
  { label: "UTC-07:00 (Canada/Mountain)", value: "Canada/Mountain" },
  { label: "UTC-08:00 (Canada/Pacific)", value: "Canada/Pacific" },
];

export default frenchTimezones;
