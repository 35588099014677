import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import {
  Col,
  Row,
  DatePicker,
  Button,
  Modal,
  Form,
  Select,
  TimePicker,
  Input,
  message,
  ConfigProvider,
  Space,
  Checkbox,
  Card,
} from "antd";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import frenchTimezones from "../components/layout/frenchTimezones";
import { MinusOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import {
  availibilityService,
  LocationService,
  serviceService,
} from "../utils/URL";
import frFR from "antd/lib/locale/fr_FR";
import "moment/locale/fr";
import { ChromePicker } from "react-color";
import jwt from "jsonwebtoken";
const { RangePicker } = DatePicker;
moment.updateLocale("fr", {
  week: {
    dow: 1,
    doy: 4,
  },
});
const { Option } = Select;
const localizer = momentLocalizer(moment);
const getInitials = (name) => {
  const [firstName, lastName] = name.split(" ");
  const initials = firstName.charAt(0) + lastName.charAt(0);
  return initials.toUpperCase();
};

const Myavailability = () => {
  const [localData, setLocalData] = useState();
  const [services, setServices] = useState([]);
  const [servicesOptions, setServicesOptions] = useState([]);
  const [localOptions, setLocalOptions] = useState([]);
  const [event, setEvent] = useState();
  const messages = {
    allDay: "toute la journée",
    previous: "précédent",
    next: "suivant",
    today: "aujourd'hui",
    month: "mois",
    week: "semaine",
    day: "jour",
    agenda: "Agenda",
    date: "date",
    time: "temps",
    event: "événement",
    showMore: (total) => `+ (${total}) Événements`,
  };

  const [availabilityData, setAvailabilityData] = useState([]);
  const [formData, setFormData] = useState({
    video_conferencing: "locale",
    repeated: "seperate",
    start_date: null,
    end_date: null,
  });
  const [open, setOpen] = useState(false);
  const token = Cookies.get("token");
  const decoded = jwt.decode(token);
  const [id_user, setIdUser] = useState(decoded?.id_user);
  const [reloadStatus, setReloadStatus] = useState(false);
  const [isModalModifOpen, setIsModalModifOpen] = useState(false);
  const [InGroup, setInGroup] = useState(false);

  const [searchData, setSearchData] = useState({
    endInscriptionDate: "",
    startInscriptionDate: "",
    id_locale: "",
    name: "",
  });
  const [modalMode, setModalMode] = useState("create"); // Add modal mode state
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [loadingState, setLoadingState] = useState({
    deletePlage: false,
    delete: false,
    submit: false,
  });
  const handlePickerToggle = () => {
    setShowPicker(!showPicker);
  };
  const handleClosePicker = () => {
    setShowPicker(false);
  };
  const handleModalOk = () => {
    setOpenModal(false);
    // Perform any additional actions when the modal is closed
  };
  const handleDeleteModalOk = async () => {
    const { startDate, endDate, endTime } = formData;

    if (!startDate || !endDate) {
      return;
    }

    let dateList = [];
    let currentDate = moment(startDate);

    while (currentDate <= moment(endDate)) {
      dateList.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = moment(currentDate).add(1, "days");
    }

    try {
      setLoadingState((prevState) => ({ ...prevState, deletePlage: true }));

      const response = await axios.post(
        `${availibilityService}/deleteAvailabilitiesByDates`,
        { dates: dateList },
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      message.success("Plage des disponibilités est supprimé avec succés");
      fetchAvailibilities();
    } catch (error) {
      message.error(error.response.data.message || "Erreur interne du serveur");
      console.error("Error deleting availabilities:", error);
    }
    setOpenDeleteModal(false);
    setFormData({
      startDate: "",
      endDate: "",
    });
    setLoadingState((prevState) => ({ ...prevState, deletePlage: false }));
  };
  const weekDays = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
  const getLabelFromId = (id) => {
    const local = localData?.find((local) => local.id === id);
    return local ? `${local.title} (${local.bigtitle})` : "";
  };
  const handleChangeDataSearch = (fieldName, value) => {
    setSearchData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };
  const showModal = (mode, eventData = {}) => {
    setModalMode(mode);
    if (mode === "create") {
      setFormData({
        description: "",
        color: "",
        timezone: "",
        date: "",
        start_time: "",
        end_time: "",
        service_availability: null,
        id_availibility: "",
        repeated: "",
        id_locale: "",
        Services: "",
        id_group: "",
        video_conferencing: "locale",
      });
    } else {
      setFormData({
        description: eventData.description || "",
        color: eventData.color || "",
        timezone: eventData.timezone || "",
        date: moment(eventData.start).format("YYYY-MM-DD"),
        start_time: moment(eventData.start).format("HH:mm:ss"),
        end_time: moment(eventData.end).format("HH:mm:ss"),
        service_availability: eventData.service_availability || "",
        id_availibility: eventData.id_availibility || "",
        repeated: eventData.repeated || "",
        id_locale: eventData.id_locale || "",
        Services: eventData.Services || "",
        video_conferencing: eventData.video_conferencing || "locale",
        id_group: eventData.id_group || "",
      });
    }
    setOpen(true);
  };
  const fetchServices = async () => {
    try {
      const response = await axios.get(
        `${serviceService}/getServiceByIdDoctor/${id_user}`
      );

      // Sort the appointments array by the time of creation
      const sortedServices = response.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      const services = sortedServices.map((service) => ({
        key: service.id_service,
        name: `${service.name} `,
        image: getInitials(`${service.name} `),
        time: moment(service.created_at).format("ddd MMM D @ hh:mm A"),
        price_Service: service.price_Service,
        type: service.type,
        duration: service.duration,
      }));

      setServices(services);

      const Options = [
        { label: "Tout", value: "" },
        ...sortedServices.map((service) => ({
          label: service.name,
          value: service.name,
        })),
      ];

      setServicesOptions(Options);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };
  const createAvailability = async (availabilityData, dateList) => {
    try {
      // Perform pre-validation for all availabilities
      const validationPromises = dateList.map(async (date) => {
        const data = { ...availabilityData, date }; // Merge formData with current date

        try {
          const response = await axios.post(
            `${availibilityService}/validateAvailability`,
            data,
            {
              headers: {
                "auth-token": token,
              },
            }
          );
          return { date, valid: response.data.valid };
        } catch (error) {
          return { date, valid: false, error: error.response.data.message };
        }
      });

      const validations = await Promise.all(validationPromises);

      // Check if all availabilities are valid
      const allValid = validations.every((result) => result.valid);

      if (allValid) {

        const id_group =
          dateList.length > 1 ? Math.random().toString(36).substr(2, 9) : null;
        const creationPromises = dateList.map(async (date) => {
          const data = { ...availabilityData, date, ...{ id_group } };
          const response = await axios.post(
            `${availibilityService}/createAvailability`,
            data,
            {
              headers: {
                "auth-token": token,
              },
            }
          );
          return response.data;
        });

        const newAvailabilities = await Promise.all(creationPromises);
        setAvailabilityData((prevState) => [
          ...prevState,
          ...newAvailabilities,
        ]);
        message.success("Disponibilités ajoutées avec succès");
        setOpen(false);
      } else {
        const uniqueErrorMessages = new Set(
          validations
            .filter((result) => !result.valid)
            .map((result) => result.error)
        );

        const errorMessage = [...uniqueErrorMessages].join("\n");
        message.error(`${errorMessage}`);
      }
    } catch (error) {
      message.error(error.response.data.message || "Erreur interne du serveur");
      console.error("Error creating availabilities:", error);
    }
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${LocationService}/getLocationByIdUser`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setLocalData(response.data.location);
      const Options = [
        { label: "Tout", value: "" },
        ...response.data.location.map((locale) => ({
          label: locale.title + " - " + "(" + locale.bigtitle + ")",
          value: locale.id,
        })),
      ];

      setLocalOptions(Options);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAvailibilities = async () => {
    try {
      const response = await axios.get(
        `${availibilityService}/searchAvailibility?name=${searchData.name}&id_locale=${searchData.id_locale}&startInscriptionDate=${searchData.startInscriptionDate}&endInscriptionDate=${searchData.endInscriptionDate}`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      const filteredData = response.data.filter(
        (item) => item.type !== "event"
      );
      setAvailabilityData(filteredData);
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Erreur interne du serveur"
      );
      console.error("Error creating availability:", error);
    }
  };
  const updateAvailability = async (formData) => {
    try {
      const response = await axios.put(
        `${availibilityService}/updateAvailability/${formData.id_availibility}`,
        formData,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setAvailabilityData((prevState) => [...prevState, response.data]);
      fetchAvailibilities();
      message.success("Disponibilité mise à jour avec succès ");
      setOpen(false);
    } catch (error) {
      message.error(error.response.data.message || "Erreur interne du serveur");
      console.error("Error updating availability:", error);
    }
  };

  const updateAvailabilitiesByIdGroup = async (id_group, availabilityData) => {
    try {
      // Step 1: Fetch all availabilities by id_group
      const response = await axios.get(
        `${availibilityService}/getAvailabilitiesByIdGroup/${id_group}`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
  
      const availabilities = response.data;
  
      // Step 2: Perform pre-validation for all availabilities
      const validationPromises = availabilities.map(async (availability) => {
        const data = { ...availabilityData, date: availability.date, id_availibility: availability.id }; // Merge availabilityData with the current date from availability

        try {
          const validationResponse = await axios.post(
            `${availibilityService}/validateAvailabilityForModification`,
            data,
            {
              headers: {
                "auth-token": token,
              },
            }
          );
          return { id: availability.id_availibility, valid: validationResponse.data.valid };
        } catch (error) {
          return { id: availability.id_availibility, valid: false, error: error.response.data.message };
        }
      });
  
      const validations = await Promise.all(validationPromises);
  
      // Step 3: Check if all availabilities are valid
      const allValid = validations.every((result) => result.valid);
  
      if (allValid) {
        // Step 4: Update all valid availabilities
        const updatePromises = availabilities.map(async (availability) => {
          const data = { ...availabilityData, date: availability.date }; // Merge availabilityData with the current date from availability
          try {
            const updateResponse = await axios.put(
              `${availibilityService}/updateAvailability/${availability.id_availibility}`,
              data,
              {
                headers: {
                  "auth-token": token,
                },
              }
            );
            return updateResponse.data;
          } catch (error) {
            throw new Error(error.response.data.message || "Update failed");
          }
        });
  
        const updatedAvailabilities = await Promise.all(updatePromises);
        setAvailabilityData((prevState) => [
          ...prevState,
          ...updatedAvailabilities,
        ]);
        message.success("Disponibilités mises à jour avec succès");
        setOpen(false);
      } else {
        // Handle validation errors
        const uniqueErrorMessages = new Set(
          validations
            .filter((result) => !result.valid)
            .map((result) => result.error)
        );
  
        const errorMessage = [...uniqueErrorMessages].join("\n");
        message.error(`${errorMessage}`);
      }
    } catch (error) {
      message.error(error.message || "Erreur interne du serveur");
      console.error("Error updating availabilities:", error);
    }
  };
  

  const DeleteAvailability = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, delete: true }));

      await axios.delete(
        `${availibilityService}/deleteAvailability/${formData.id_availibility}`
      );
      message.success("Disponibilité supprimée avec succès");
      setOpen(false);
      setVisibleDelete(false);
    } catch (error) {
      message.error(
        error.response.data.message ||
          "Erreur lors de la suppression du rendez-vous"
      );
      console.error("Error deleting appointment:", error);
    }
    setLoadingState((prevState) => ({ ...prevState, delete: false }));
  };
  const handleDelete = async () => {
    await DeleteAvailability();
  };

  const handleSelectEvent = (event) => {
    setInGroup(false);
    if (event.id_group === null) {
      showModal("update", event);
    } else {
      setIsModalModifOpen(true);
    }

    setEvent(event);
  };
  useEffect(() => {
    fetchAvailibilities();
    fetchData();
    fetchServices();
  }, [open]);

  useEffect(() => {
    setSearchData({
      ...searchData,

      endInscriptionDate: "",
      startInscriptionDate: "",
      id_locale: "",
      name: "",
    });

    fetchAvailibilities();
  }, [reloadStatus]);
  const handleOk = async () => {
    setLoadingState((prevState) => ({ ...prevState, submit: true }));

    if (modalMode === "create") {
      const dateList = generateDateList();
      await createAvailability(formData, dateList);
    } else if (modalMode === "update") {
      if (InGroup === false) {
        await updateAvailability(formData);
      } else {
        await updateAvailabilitiesByIdGroup(event.id_group, formData);
      }
    }
    setLoadingState((prevState) => ({ ...prevState, submit: false }));
  };

  const handleCancel = () => {
    setFormData({});
    setOpen(false);
    handleClosePicker();
  };
  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };
  const generateDateList = () => {
    const { start_date, end_date, repeated, selectedDays } = formData;
    const dateList = [];
    let currentDate = moment(start_date);

    if (repeated === "day") {
      while (currentDate <= moment(end_date)) {
        dateList.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }
    } else if (repeated === "week") {
      const selectedDayIndices = selectedDays.map((day) =>
        weekDays.indexOf(day)
      );
      while (currentDate <= moment(end_date)) {
        if (selectedDayIndices.includes(currentDate.weekday())) {
          dateList.push(moment(currentDate).format("YYYY-MM-DD"));
        }
        currentDate = moment(currentDate).add(1, "days");
      }
    } else {
      dateList.push(moment(formData.date).format("YYYY-MM-DD"));
    }
    return dateList;
  };
  const [selectedDate, setSelectedDate] = useState(moment());

  const handleDatePickerChange = (date, dateString) => {
    setSelectedDate(moment(date));
  };
  const handleNavigate = (view, date) => {
    if (view === Views.MONTH) {
      setSelectedDate(moment(date));
    } else if (view === Views.YEAR) {
      setSelectedDate(moment(date));
    } else {
      setSelectedDate(moment(date));
    }
  };

  const events = availabilityData.map((availability) => {
    const startDateString = `${availability.date}T${availability.start_time}`;
    const endDateString = `${availability.date}T${availability.end_time}`;
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    return {
      title: availability.description,
      start: startDate,
      end: endDate,
      description: availability.description,
      color: availability.color,
      id_availibility: availability.id_availibility,
      timezone: availability.timezone,
      start_time: availability.start_time,
      end_time: availability.end_time,
      service_availability: availability.service_availability,
      id_doctor: availability.id_doctor,
      repeated: availability.repeated,
      id_locale: availability.id_locale,
      id_group: availability.id_group,
      Services: availability.Services,
      video_conferencing: availability.video_conferencing,
    };
  });
  return (
    <>
      <div className="TableCard">
        <Card
          bordered={false}
          className="criclebox tablespace mb-24 "
          extra={
            <Row gutter={[16, 0]} align="middle">
              <Col flex="auto" style={{ width: "20%", marginBottom: "1%" }}>
                <label
                  htmlFor="location"
                  style={{
                    marginBottom: "5px",
                    marginLeft: "10px",
                    display: "block",
                    fontWeight: "bold",
                    color: "rgb(118 118 118 / 63%)",
                  }}
                >
                  Emplacement:
                </label>
                <Select
                  id="location"
                  placeholder="Sélectionner un emplacement"
                  className="custom-timezone-select"
                  style={{ width: "100%", height: "42px" }}
                  options={localOptions}
                  value={{
                    key: searchData.id_locale,
                    // label: getLabelFromId(formData.id_locale),
                  }}
                  onChange={(e) => {
                    handleChangeDataSearch("id_locale", e);
                  }}
                />
              </Col>
              <Col flex="auto" style={{ width: "20%", marginBottom: "1%" }}>
                <label
                  htmlFor="service"
                  style={{
                    marginBottom: "5px",
                    marginLeft: "10px",
                    display: "block",
                    fontWeight: "bold",
                    color: "rgb(118 118 118 / 63%)",
                  }}
                >
                  Service:
                </label>
                <Select
                  id="service"
                  placeholder="Sélectionner un service"
                  className="custom-timezone-select"
                  style={{ width: "100%", height: "42px" }}
                  options={servicesOptions}
                  value={searchData.name}
                  onChange={(e) => {
                    handleChangeDataSearch("name", e);
                  }}
                />
              </Col>
              <Col flex="auto" style={{ width: "20%", marginBottom: "1%" }}>
                <label
                  htmlFor="date-range"
                  style={{
                    marginBottom: "5px",
                    marginLeft: "10px",
                    display: "block",
                    fontWeight: "bold",
                    color: "rgb(118 118 118 / 63%)",
                  }}
                >
                  Date de plage:
                </label>
                <ConfigProvider locale={frFR}>
                  <RangePicker
                    id="date-range"
                    placeholder={["Date de début", "Date de fin"]}
                    style={{
                      width: "100%",
                      height: "42px",
                      borderRadius: "5px",
                    }}
                    value={[
                      searchData.startInscriptionDate
                        ? moment(searchData.startInscriptionDate)
                        : null,
                      searchData.endInscriptionDate
                        ? moment(searchData.endInscriptionDate)
                        : null,
                    ]}
                    onChange={(dates, dateString) => {
                      setSearchData({
                        ...searchData,
                        startInscriptionDate: dateString[0],
                        endInscriptionDate: dateString[1],
                      });
                    }}
                  />
                </ConfigProvider>
              </Col>

              <Col flex="none" style={{ width: "20%", marginTop: "10px" }}>
                <Row gutter={[5, 0]}>
                  <Col span={20}>
                    {" "}
                    <Button
                      type="text"
                      style={{
                        backgroundColor: "#CDFADB",
                        height: "40px",
                        width: "100%",
                      }}
                      onClick={async () => {
                        await fetchAvailibilities();
                      }}
                    >
                      Chercher
                    </Button>
                  </Col>
                  <Col span={4}>
                    <Button
                      type="text"
                      style={{
                        backgroundColor: "#CDFADB",
                        height: "40px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={async () => {
                        setSearchData({
                          ...searchData,

                          endInscriptionDate: "",
                          startInscriptionDate: "",
                          id_locale: "",
                          name: "",
                        });
                        reloadStatus
                          ? setReloadStatus(false)
                          : setReloadStatus(true);
                      }}
                    >
                      <ReloadOutlined style={{ fontSize: "20px" }} />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        />
      </div>

      <Row>
        <Col span={18}>
          <div>
            <Calendar
              messages={messages}
              date={selectedDate}
              onNavigate={(date, view) => handleNavigate(view, date)}
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{
                height: "700px",
              }}
              onSelectEvent={handleSelectEvent}
              onSelectSlot={(slotInfo) => {}}
              eventPropGetter={(event) => {
                return {
                  style: {
                    backgroundColor: event.color,
                    border: "1px solid black",
                  },
                };
              }}
            />
          </div>
        </Col>
        <Col span={6}>
          <div style={{ paddingLeft: "10%" }}>
            <ConfigProvider locale={frFR}>
              <DatePicker
                defaultValue={moment()}
                value={selectedDate}
                onChange={handleDatePickerChange}
                style={{
                  width: "87%",
                  height: "41px",
                  borderRadius: "5px",
                }}
                placeholder="Select Date"
                allowClear={false}
                calendarClassName="custom-calendar-dropdown"
              />
            </ConfigProvider>

            <div style={{ width: "87%", marginTop: "10%" }}>
              <label
                htmlFor="timezone"
                style={{
                  marginBottom: "5%",
                  display: "block",
                  fontWeight: "bold",
                  color: "#607274",
                }}
              >
                Fuseau horaire :
              </label>
              <ConfigProvider locale={frFR}>
                <Select
                  value={formData.timezone}
                  onChange={(value) => handleChange("timezone", value)}
                  placeholder="Sélectionner un fuseau horaire"
                  className="custom-timezone-select"
                  style={{ fontWeight: "lighter", width: "100%" }}
                >
                  {frenchTimezones.map((timezone) => (
                    <Option key={timezone.value} value={timezone.value}>
                      {timezone.label}
                    </Option>
                  ))}
                </Select>
              </ConfigProvider>
            </div>

            <div style={{ width: "78%" }}>
              <label
                htmlFor="timezone"
                style={{
                  marginTop: "8%",
                  marginBottom: "2%",
                  display: "block",
                  fontWeight: "bold",
                  color: "#607274",
                }}
              >
                Créneaux de disponibilité :
              </label>
              <p
                style={{ fontSize: "14px", fontWeight: "12px", color: "black" }}
              >
                Cette section vous permet de gérer vos créneaux de
                disponibilité. Chaque créneau représente une période de temps
                pendant laquelle vous êtes disponible pour des rendez-vous ou
                des réunions.
              </p>
              <button
                onClick={() => showModal("create")}
                style={{
                  color: "green",
                  float: "left",
                  width: "100%",
                  fontWeight: "bold",
                  border: "none",
                  marginBottom: "5%",
                }}
              >
                <PlusOutlined />
                Ajouter une disponibilité
              </button>
              <button
                onClick={() => setOpenDeleteModal(true)}
                style={{
                  color: "#ff4800c4",
                  float: "left",
                  width: "100%",
                  fontWeight: "bold",
                  border: "none",
                  marginBottom: "5%",
                }}
              >
                <MinusOutlined style={{ marginRight: "2%" }} />
                Supprimer une plage des disponibilités
              </button>
              <Modal
                width={700}
                title={
                  modalMode === "create"
                    ? "Ajouter une disponibilité"
                    : "Modifier la disponibilité"
                }
                centered
                visible={open}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose
                okText={modalMode === "create" ? "Enregistrer" : "Modifier "}
                cancelText="Annuler"
                afterClose={handleCancel}
                onValuesChange={handleChange}
                footer={[
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    key="footer"
                  >
                    {modalMode === "update" ? (
                      <>
                        <Button
                          type="danger"
                          // onClick={handleDelete}
                          onClick={() => {
                            setVisibleDelete(true);
                          }}
                        >
                          Supprimer
                        </Button>
                        <Modal
                          title="Confirm Deletion"
                          visible={visibleDelete}
                          onCancel={() => setVisibleDelete(false)}
                          footer={[
                            <Button onClick={() => setVisibleDelete(false)}>
                              Annuler
                            </Button>,
                            <Button
                              loading={loadingState.delete}
                              type="primary"
                              danger
                              onClick={handleDelete}
                            >
                              Supprimer
                            </Button>,
                          ]}
                        >
                          <p>Êtes-vous sûr de vouloir supprimer ce temps ?</p>
                        </Modal>
                      </>
                    ) : (
                      <>
                        <p></p>
                      </>
                    )}
                    <div key="right-buttons" style={{ display: "flex" }}>
                      <Button key="cancel" onClick={handleCancel}>
                        Annuler
                      </Button>
                      <Button
                        key="submit"
                        type="primary"
                        onClick={handleOk}
                        loading={loadingState.submit}
                      >
                        {modalMode === "create" ? "Sauvegarder" : "Modifier"}
                      </Button>
                    </div>
                  </div>,
                ]}
                style={{
                  marginBottom: "8%",
                }}
              >
                <Form layout="vertical">
                  <Row gutter={[16, 16]}>
                    <Col span={18}>
                      <Form.Item
                        label="Description"
                        rules={[
                          {
                            required: true,
                            message: "Veuillez saisir la description!",
                          },
                        ]}
                      >
                        <Input
                          style={{ height: "40px" }}
                          placeholder="Saisir une description"
                          className="custom-placeholder"
                          value={formData.description}
                          onChange={(e) =>
                            handleChange("description", e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Couleur"
                        rules={[
                          {
                            required: true,
                            message: "Veuillez sélectionner la couleur!",
                          },
                        ]}
                      >
                        <Button
                          onClick={handlePickerToggle}
                          style={{ position: "relative" }}
                        >
                          {showPicker
                            ? "Fermer le sélecteur"
                            : "Ouvrir le sélecteur"}
                        </Button>
                        {showPicker && (
                          <div
                            style={{
                              position: "absolute",
                              right: 10,
                              marginTop: "6%",
                              zIndex: "1",
                            }}
                          >
                            {" "}
                            <ChromePicker
                              color={formData.color}
                              onChange={(newColor) => {
                                handleChange("color", newColor.hex);
                              }}
                            />{" "}
                          </div>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label="Fuseau horaire"
                        rules={[
                          {
                            required: true,
                            message: "Veuillez sélectionner le fuseau horaire!",
                          },
                        ]}
                      >
                        <ConfigProvider locale={frFR}>
                          <Select
                            value={formData?.timezone}
                            onChange={(value) =>
                              handleChange("timezone", value)
                            }
                            placeholder="Sélectionner un fuseau horaire"
                            className="custom-timezone-select"
                            style={{ width: "100%" }}
                          >
                            {frenchTimezones.map((timezone) => (
                              <Option
                                key={timezone.value}
                                value={timezone.value}
                              >
                                {timezone.label}
                              </Option>
                            ))}
                          </Select>
                        </ConfigProvider>
                      </Form.Item>
                    </Col>

                    <Col span={18}>
                      <Form.Item
                        label="Emplacement"
                        rules={[
                          {
                            required: true,
                            message: "Veuillez sélectionner l'emplacement !",
                          },
                        ]}
                      >
                        <ConfigProvider locale={frFR}>
                          <Select
                            value={{
                              key: formData.id_locale,
                              label: getLabelFromId(formData.id_locale),
                            }}
                            onChange={(value) => {
                              handleChange("id_locale", value.key);
                            }}
                            placeholder="Sélectionner un Emplacement"
                            className="custom-timezone-select"
                            style={{ width: "100%" }}
                            optionLabelProp="label"
                            labelInValue
                          >
                            {localData?.map((local) => (
                              <Option
                                key={local.id}
                                value={local.id}
                                label={`${local.title} (${local.bigtitle})`}
                              >
                                {local.title} ({local.bigtitle})
                              </Option>
                            ))}
                          </Select>
                        </ConfigProvider>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Type"
                        rules={[
                          {
                            required: true,
                            message: "Veuillez sélectionner le fuseau horaire!",
                          },
                        ]}
                      >
                        {/* <Checkbox
                          checked={formData.video_conferencing}
                          onChange={(e) => {
                            handleChange(
                              "video_conferencing",
                              e.target.checked
                            );
                          }}
                          style={{ width: "100%", marginTop: "25%" }}
                        >
                          Visio Conférence
                        </Checkbox> */}
                        <Select
                          value={formData.video_conferencing}
                          onChange={(e) => {
                            handleChange("video_conferencing", e);
                          }}
                          style={{ width: "100%" }}
                          allowClear
                          options={[
                            { value: "both", label: "Tout" },
                            { value: "locale", label: "présentiel" },
                            { value: "online", label: "video conferencing" },
                          ]}
                          placeholder="Sélectioner un type"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Services"
                        rules={[
                          {
                            required: true,
                            message: "Veuillez sélectionner les services !",
                          },
                        ]}
                      >
                        <Select
                          defaultValue={
                            formData.Services
                              ? formData.Services.map(
                                  (service) => service?.id_service
                                )
                              : undefined
                          }
                          onChange={(value) =>
                            handleChange("service_ids", value)
                          }
                          className="custom-timezone-select"
                          style={{ width: "100%" }}
                          mode="multiple"
                        >
                          {services?.map((service) => (
                            <Option key={service.key} value={service.key}>
                              {`${service.name} (${
                                service.type === "public"
                                  ? "publique"
                                  : "privée"
                              })`}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    {modalMode === "create" ? (
                      <>
                        <Col span={24}>
                          <Form.Item label="Répéter">
                            <Select
                              value={formData.repeated}
                              style={{ height: "40px" }}
                              onSelect={(value) => {
                                handleChange("repeated", value);
                                if (value === "week" || value === "day") {
                                  setOpenModal(true);
                                }
                              }}
                            >
                              <Select.Option value="seperate">
                                Ne répète pas
                              </Select.Option>
                              <Select.Option value="week">
                                Répéter chaque semaine{" "}
                              </Select.Option>
                              <Select.Option value="day">
                                Répéter tous les jours
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Modal
                          title="Définir la récurrence"
                          visible={openModal}
                          onOk={handleModalOk}
                          onCancel={() => setOpenModal(false)}
                        >
                          <Space direction="vertical">
                            <div>
                              Commencer :
                              <ConfigProvider locale={frFR}>
                                <DatePicker
                                  value={formData.start_date}
                                  onChange={(value) => {
                                    handleChange("start_date", value);
                                  }}
                                  style={{
                                    marginLeft: "9%",
                                    width: "60%",
                                    height: "35px",
                                    borderRadius: "5px",
                                  }}
                                />
                              </ConfigProvider>
                            </div>
                            <div
                              style={{
                                marginTop: "3%",
                                marginBottom: "2%",
                                marginLeft: "1%",
                                width: "500px",
                              }}
                            >
                              Répétez tous les :
                              <Select
                                value={formData.repeated}
                                style={{ marginLeft: "3%", width: "60%" }}
                                onChange={(value) => {
                                  handleChange("repeated", value);
                                  if (value === "seperate") {
                                    setOpenModal(false);
                                  }
                                }}
                              >
                                <Select.Option value="seperate">
                                  Ne répète pas{" "}
                                </Select.Option>
                                <Select.Option value="week">
                                  Répéter chaque semaine{" "}
                                </Select.Option>
                                <Select.Option value="day">
                                  Répéter tous les jours{" "}
                                </Select.Option>
                              </Select>
                            </div>
                            {formData.repeated === "week" && (
                              <div style={{ marginBottom: "3%" }}>
                                <Checkbox.Group
                                  value={formData.selectedDays}
                                  onChange={(checkedValues) =>
                                    handleChange("selectedDays", checkedValues)
                                  }
                                >
                                  {weekDays.map((day) => (
                                    <Checkbox key={day} value={day}>
                                      {day}
                                    </Checkbox>
                                  ))}
                                </Checkbox.Group>
                              </div>
                            )}
                            <div>
                              Fin :
                              <ConfigProvider locale={frFR}>
                                <DatePicker
                                  value={formData.end_date}
                                  onChange={(value) =>
                                    handleChange("end_date", value)
                                  }
                                  style={{
                                    width: "60%",
                                    height: "35px",
                                    borderRadius: "5px",
                                    marginLeft: "21%",
                                  }}
                                />
                              </ConfigProvider>
                            </div>
                          </Space>
                        </Modal>

                        {formData.repeated === "seperate" && (
                          <Col span={24}>
                            <Form.Item label="Date">
                              <ConfigProvider locale={frFR}>
                                <DatePicker
                                  value={
                                    formData.date ? moment(formData.date) : null
                                  }
                                  onChange={(value) =>
                                    handleChange(
                                      "date",
                                      value
                                        ? moment(value).format("YYYY-MM-DD")
                                        : ""
                                    )
                                  }
                                  style={{
                                    width: "100%",
                                    height: "40px",
                                    borderRadius: "5px",
                                  }}
                                />
                              </ConfigProvider>
                            </Form.Item>
                          </Col>
                        )}
                      </>
                    ) : (
                      <>
                        {(InGroup === false || formData.id_group === "") && (
                          <>
                            <Col span={24}>
                              <Form.Item label="Date">
                                <ConfigProvider locale={frFR}>
                                  <DatePicker
                                    value={
                                      formData.date
                                        ? moment(formData.date)
                                        : null
                                    }
                                    onChange={(value) =>
                                      handleChange(
                                        "date",
                                        value
                                          ? moment(value).format("YYYY-MM-DD")
                                          : ""
                                      )
                                    }
                                    style={{
                                      width: "100%",
                                      height: "40px",
                                      borderRadius: "5px",
                                    }}
                                  />
                                </ConfigProvider>
                              </Form.Item>
                            </Col>
                          </>
                        )}
                      </>
                    )}
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Form.Item
                        label="Heure de début"
                        rules={[
                          {
                            required: true,
                            message: "Veuillez sélectionner l'heure de début!",
                          },
                        ]}
                      >
                        <ConfigProvider locale={frFR}>
                          <TimePicker
                            value={
                              formData.start_time
                                ? moment(formData.start_time, "HH:mm:ss")
                                : null
                            }
                            onSelect={(value) =>
                              handleChange(
                                "start_time",
                                value ? value.format("HH:mm:ss") : ""
                              )
                            }
                            style={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "5px",
                            }}
                          />
                        </ConfigProvider>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Heure de fin"
                        rules={[
                          {
                            required: true,
                            message: "Veuillez sélectionner l'heure de fin !",
                          },
                        ]}
                      >
                        <ConfigProvider locale={frFR}>
                          <TimePicker
                            value={
                              formData.end_time
                                ? moment(formData.end_time, "HH:mm:ss")
                                : null
                            }
                            onSelect={(value) =>
                              handleChange(
                                "end_time",
                                value ? value.format("HH:mm:ss") : ""
                              )
                            }
                            style={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "5px",
                            }}
                          />
                        </ConfigProvider>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Form.Item
                        label="Disponibilité du service"
                        rules={[
                          {
                            required: true,
                            message:
                              "Veuillez sélectionner la disponibilité du service!",
                          },
                        ]}
                      >
                        <Select
                          value={
                            formData.service_availability !== null
                              ? formData.service_availability
                                ? "Disponible"
                                : "Indisponible"
                              : null
                          }
                          placeholder="Sélectionner un service"
                          onChange={(value) =>
                            handleChange("service_availability", value)
                          }
                        >
                          <Select.Option value="true">Disponible</Select.Option>
                          <Select.Option value="false">
                            Indisponible
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row> */}
                </Form>
              </Modal>
              <Modal
                title="Modification de disponibilité"
                open={isModalModifOpen}
                onCancel={() => {
                  setIsModalModifOpen(false);
                }}
                footer={[
                  <div
                    key="footer-buttons"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <Button
                        key="seul"
                        type="primary"
                        style={{
                          backgroundColor: "#4CAF50",
                          borderColor: "#4CAF50",
                        }} // Green color for "Seul"
                        onClick={() => {
                          setInGroup(false);
                          showModal("update", event);
                          setIsModalModifOpen(false);
                        }}
                      >
                        Disponibilité sélectionné
                      </Button>
                      <Button
                        key="group"
                        type="primary"
                        style={{
                          backgroundColor: "#2196F3",
                          borderColor: "#2196F3",
                          marginLeft: "8px",
                        }} // Blue color for "En groupe"
                        onClick={() => {
                          setInGroup(true);
                          showModal("update", event);
                          setIsModalModifOpen(false);
                        }}
                      >
                        Plage des disponibilités
                      </Button>
                    </div>
                    <Button
                      key="cancel"
                      style={{
                        backgroundColor: "#f44336",
                        borderColor: "#f44336",
                        color: "white",
                      }} // Red color for "Annuler"
                      onClick={handleCancel}
                    >
                      Annuler
                    </Button>
                  </div>,
                ]}
              />
              <Modal
                title="Supprimer une plage des disponibilités"
                visible={openDeleteModal}
                onCancel={() => setOpenDeleteModal(false)}
                width={400}
                footer={[
                  <Button
                    onClick={() => {
                      setFormData({
                        startDate: "",
                        endDate: "",
                      });
                      setOpenDeleteModal(false);
                    }}
                  >
                    Annuler
                  </Button>,
                  <Button
                    type="primary"
                    danger
                    onClick={handleDeleteModalOk}
                    loading={loadingState.deletePlage}
                  >
                    Supprimer
                  </Button>,
                ]}
              >
                <Form
                  layout="vertical"
                  onFinish={handleDeleteModalOk} // Use onFinish to handle form submission
                >
                  <Form.Item
                    label="Début"
                    name="startDate"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez sélectionner la date de début!",
                      },
                    ]}
                  >
                    <ConfigProvider locale={frFR}>
                      <DatePicker
                        value={formData.startDate}
                        onChange={(date) => handleChange("startDate", date)}
                        style={{
                          width: "100%",
                          height: "35px",
                          borderRadius: "5px",
                        }}
                      />
                    </ConfigProvider>
                  </Form.Item>

                  <Form.Item
                    label="Fin"
                    name="endDate"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez sélectionner la date de fin!",
                      },
                    ]}
                  >
                    <ConfigProvider locale={frFR}>
                      <DatePicker
                        value={formData.endDate}
                        onChange={(date) => handleChange("endDate", date)}
                        style={{
                          width: "100%",
                          height: "35px",
                          borderRadius: "5px",
                        }}
                      />
                    </ConfigProvider>
                  </Form.Item>

                  {/* <Form.Item
        label="Heure de début"
        name="startTime"
        rules={[{ required: true, message: "Veuillez sélectionner l'heure de début!" }]}
      >
        <ConfigProvider locale={frFR}>
          <TimePicker
            value={formData.start_time ? moment(formData.start_time, "HH:mm:ss") : null}
            onSelect={(value) =>
              handleChange("start_time", value ? value.format("HH:mm:ss") : "")
            }
            style={{
              width: "100%",
              height: "40px",
              borderRadius: "5px",
            }}
          />
        </ConfigProvider>
      </Form.Item>

      <Form.Item
        label="Heure de fin"
        name="endTime"
        rules={[{ required: true, message: "Veuillez sélectionner l'heure de fin!" }]}
      >
        <ConfigProvider locale={frFR}>
          <TimePicker
            value={formData.end_time ? moment(formData.end_time, "HH:mm:ss") : null}
            onSelect={(value) =>
              handleChange("end_time", value ? value.format("HH:mm:ss") : "")
            }
            style={{
              width: "100%",
              height: "40px",
              borderRadius: "5px",
            }}
          />
        </ConfigProvider>
      </Form.Item> */}
                </Form>
              </Modal>
            </div>
            {/* <div style={{ width: "87%" }}>
              <label
                htmlFor="timezone"
                style={{
                  marginBottom: "5%",
                  display: "block",
                  fontWeight: "bold",
                  color: "#607274",
                }}
              >
                Filtre :
              </label>
              <div>
                <div style={{ marginBottom: "10px" }}>
                  <label
                    htmlFor="location"
                    style={{
                      marginBottom: "5px",
                      marginLeft: "10px",
                      display: "block",
                      fontWeight: "bold",
                      color: "rgb(118 118 118 / 63%)",
                    }}
                  >
                    Emplacement:
                  </label>
                  <Select
                    id="location"
                    placeholder="Sélectionner un emplacement"
                    className="custom-timezone-select"
                    style={{ width: "100%", height: "42px" }}
                    options={localOptions}
                    value={{
                      key: searchData.id_locale,
                      // label: getLabelFromId(formData.id_locale),
                    }}
                    onChange={(e) => {
                      handleChangeDataSearch("id_locale", e);
                    }}
                  />
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <label
                    htmlFor="service"
                    style={{
                      marginBottom: "5px",
                      marginLeft: "10px",
                      display: "block",
                      fontWeight: "bold",
                      color: "rgb(118 118 118 / 63%)",
                    }}
                  >
                    Service:
                  </label>
                  <Select
                    id="service"
                    placeholder="Sélectionner un service"
                    className="custom-timezone-select"
                    style={{ width: "100%", height: "42px" }}
                    options={servicesOptions}
                    value={searchData.name}
                    onChange={(e) => {
                      handleChangeDataSearch("name", e);
                    }}
                  />
                </div>
                <div>
                  <label
                    htmlFor="date-range"
                    style={{
                      marginBottom: "5px",
                      marginLeft: "10px",
                      display: "block",
                      fontWeight: "bold",
                      color: "rgb(118 118 118 / 63%)",
                    }}
                  >
                    Date de plage:
                  </label>
                  <ConfigProvider locale={frFR}>
                    <RangePicker
                      id="date-range"
                      placeholder={["Date de début", "Date de fin"]}
                      style={{
                        width: "100%",
                        height: "42px",
                        borderRadius: "5px",
                      }}
                      value={[
                        searchData.startInscriptionDate
                          ? moment(searchData.startInscriptionDate)
                          : null,
                        searchData.endInscriptionDate
                          ? moment(searchData.endInscriptionDate)
                          : null,
                      ]}
                      onChange={(dates, dateString) => {
                        setSearchData({
                          ...searchData,
                          startInscriptionDate: dateString[0],
                          endInscriptionDate: dateString[1],
                        });
                      }}
                    />
                  </ConfigProvider>
                </div>

                <div flex="none" style={{ width: "100%", marginTop: "5%" }}>
                  <Row gutter={[5, 0]}>
                    <Col span={20}>
                      {" "}
                      <Button
                        type="text"
                        style={{
                          backgroundColor: "#CDFADB",
                          height: "40px",
                          width: "100%",
                        }}
                        onClick={async () => {
                          await fetchAvailibilities();
                        }}
                      >
                        Chercher
                      </Button>
                    </Col>
                    <Col span={4}>
                      <Button
                        type="text"
                        style={{
                          backgroundColor: "#CDFADB",
                          height: "40px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={async () => {
                          setSearchData({
                            ...searchData,

                            endInscriptionDate: "",
                            startInscriptionDate: "",
                            id_locale: "",
                            name: "",
                          });
                          reloadStatus
                            ? setReloadStatus(false)
                            : setReloadStatus(true);
                        }}
                      >
                        <ReloadOutlined style={{ fontSize: "20px" }} />
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div> */}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Myavailability;
