import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Avatar,
  message,
  Space,
  Dropdown,
  Menu,
  Button,
  Input,
} from "antd";
import { userServices } from "../utils/URL";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  MoreOutlined,
  ReloadOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useChat } from "../components/layout/ChatContext";

const MyClients = () => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [filteredData, setFilteredData] = useState({
    last_name: "",
    first_name: "",
    professional_title: "",
  });
  const history = useHistory();
  const { setProfile } = useChat();
  const [doctorData, setDoctorData] = useState({});
  const [doctorOriginalData, setDoctorOriginalData] = useState({});
  const [reloadStatus, setReloadStatus] = useState(false);

  const token = Cookies.get("token");

  // const secretKey = "your_session_secret";

  const [doctors, setDoctors] = useState([]);
  const [filter, setFilter] = useState("all");
  const [invitedPatientsLength, setInvitedPatientsLength] = useState(0);
  const gradientStyle = {
    background: "linear-gradient(to bottom, black 0%, black 100%)",
    color: "black",
    width: "11px",
    height: "100%",
    display: "inline-block",
    margin: "0 5px",
    borderRadius: "20px",
  };

  const fetchDoctors = async () => {
    try {
      const response = await axios.get(
        `${userServices}/searchUsers/doctor?last_name=${filteredData.last_name}&first_name=${filteredData.first_name}&professional_title=${filteredData.professional_title}`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      if (filter === "invited") {
        const sortedDcotorss = response.data.assignedDoctors.sort((a, b) =>
          `${a.user.first_name} ${a.user.last_name}`.localeCompare(
            `${b.user.first_name} ${b.user.last_name}`
          )
        );
        setDoctors(sortedDcotorss);
        setInvitedPatientsLength(sortedDcotorss.length);
      } else if (filter === "not-invited") {
        const sortedPatients = response.data.unassignedDoctors.sort((a, b) =>
          `${a.user.first_name} ${a.user.last_name}`.localeCompare(
            `${b.user.first_name} ${b.user.last_name}`
          )
        );
        setDoctors(sortedPatients);
        setInvitedPatientsLength(sortedPatients.length);
      } else {
        const sortedPatients = response.data.doctors.sort((a, b) =>
          `${a.user.first_name} ${a.user.last_name}`.localeCompare(
            `${b.user.first_name} ${b.user.last_name}`
          )
        );
        setDoctors(sortedPatients);
        setInvitedPatientsLength(sortedPatients.length);
      }
    } catch (error) {
      console.error("Error fetching doctors:", error);
    }
  };

  const handleInfo = (doctorIds) => {
    const filteredDoctors = doctors.filter(
      (doctor) => doctor.id_doctor === doctorIds
    );

    if (filteredDoctors.length > 0) {
      const selectedDoctor = filteredDoctors[0];
      setDoctorData(selectedDoctor);
    }
  };
  const assignDoctorToPtient = async () => {
    try {
      const doctorIds = doctorData?.id_doctor;
      await axios.post(
        `${userServices}/assignDoctorToPatient`,
        { doctorIds },
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      await fetchDoctors();
      handleInfo(doctorIds);
      message.success("Patient invité avec suces");
    } catch (error) {
      console.error("Error creating doctor:", error);
      message.error(error.response.data.message || "Erreur interne du serveur");
    }
  };
  const unassignDoctorFromPtient = async () => {
    try {
      const doctorIds = doctorData?.id_doctor;
      await axios.post(
        `${userServices}/unassignDoctorFromPatient`,
        { doctorIds },
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      await fetchDoctors();
      handleInfo(doctorIds);
      message.success("Patient exclue avec suces");
    } catch (error) {
      console.error("Error creating doctor:", error);
      message.error(error.response.data.message || "Erreur interne du serveur");
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };
  const handleData = (doctorIds) => {
    const filteredDoctors = doctors.filter(
      (doctor) => doctor.id_doctor === doctorIds
    );

    if (filteredDoctors.length > 0) {
      const selecteddoctor = filteredDoctors[0];
      setDoctorOriginalData(selecteddoctor);
    }
  };

  useEffect(() => {
    fetchDoctors();
  }, [confirmLoading, filter, reloadStatus]);

  useEffect(() => {
    handleData(doctorData.id_doctor);
  }, [doctors]);

  const assignToMe = async () => {
    await assignDoctorToPtient();
  };
  const unAssignToMe = async () => {
    await unassignDoctorFromPtient();
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => assignToMe()}>
        inviter
      </Menu.Item>
      <Menu.Item key="2" onClick={() => unAssignToMe()}>
        exclure
      </Menu.Item>
    </Menu>
  );
  const columns = [
    {
      dataIndex: "name",
      key: "name",
    },

    {
      dataIndex: "email",
      key: "email",
    },
    {
      dataIndex: "professional_title",
      key: "professional_title",
    },
    {
      key: "action",
      align: "end",
      render: (text, record) => (
        <>
          <Space>
            <p
              type="link"
              style={{
                fontWeight: "bold",
                cursor: "pointer",
                marginRight: "15px",
                background: "none",
                border: "none",
                color: "#0D9276",
                textDecoration: "underline",
                alignItems: "center",
              }}
              onClick={() => {
                setProfile(record.id_user);
                history.push(`/DoctorService`);
              }}
            >
              Services
            </p>
            <Avatar
              className="clickable"
              icon={<UserOutlined />}
              onClick={() => {
                setProfile(record.id_user);
                history.push(`/DoctorProfile`);
              }}
            />

            <Dropdown overlay={menu}>
              <a
                onClick={(e) => {
                  handleInfo(record.key);
                  e.preventDefault();
                }}
                onMouseEnter={() => handleInfo(record.key)}
              >
                <MoreOutlined style={{ marginLeft: "12px" }} />
              </a>
            </Dropdown>
          </Space>
        </>
      ),
    },
  ];

  const filterDoctors = (doctors) => {
    if (filter === "invited") {
      return doctors;
    } else if (filter === "not-invited") {
      return doctors;
    } else {
      return doctors;
    }
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <div className="TableCard">
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                // title={`Liste des médecins (${invitedPatientsLength})`}
                extra={
                  <div style={{ marginTop: "15px", width: "100%" }}>
                    <Row style={{ width: "100%" }}>
                      <Col xs={24} sm={24} md={14} lg={16} xl={18} xxl={20}>
                        <Col
                          className="hidden-on-small-screen"
                          style={{
                            marginRight: "1%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Input
                            value={filteredData.first_name}
                            className="inputStyle"
                            placeholder="Entrez le nom"
                            min={1}
                            max={10}
                            style={{ marginRight: "8px" }}
                            onChange={(e) => {
                              const enteredValue = e.target.value.trim();
                              const sanitizedValue = enteredValue || "";
                              setFilteredData({
                                ...filteredData,
                                first_name: sanitizedValue,
                              });
                              // setSearchData({
                              //   ...searchData,
                              //   Nom_entreprise: sanitizedValue,
                              // });
                            }}
                          />
                          <Input
                            value={filteredData.last_name}
                            className="inputStyle"
                            placeholder="Entrer le prenom"
                            min={1}
                            max={10}
                            style={{ marginRight: "8px" }}
                            onChange={(e) => {
                              const enteredValue = e.target.value.trim();
                              const sanitizedValue = enteredValue || "";
                              setFilteredData({
                                ...filteredData,
                                last_name: sanitizedValue,
                              }); // setSearchData({
                              //   ...searchData,
                              //   Ville: sanitizedValue,
                              // });
                            }}
                          />

                          <Input
                            value={filteredData.professional_title}
                            className="inputStyle"
                            placeholder="Entrez le titre professionel"
                            min={1}
                            max={10}
                            style={{ marginRight: "8px" }}
                            onChange={(e) => {
                              const enteredValue = e.target.value.trim();
                              const sanitizedValue = enteredValue || "";
                              setFilteredData({
                                ...filteredData,
                                professional_title: sanitizedValue,
                              });
                              // setSearchData({
                              //   ...searchData,
                              //   Email: sanitizedValue,
                              // });
                            }}
                          />
                          <div style={gradientStyle} />

                          <Button
                            className="inputStyle"
                            style={{ fontSize: "13px" }}
                            type="primary"
                            icon={<SearchOutlined />}
                            onClick={() => {
                              fetchDoctors();
                            }}
                          >
                            Recherche
                          </Button>
                          <Button
                            type="primary"
                            style={{
                              width: "5%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginLeft: "5px",
                              fontSize: "13px", // Add this line to maintain font size on the reload button
                            }}
                            className="reloadButton"
                            icon={
                              <ReloadOutlined
                                style={{ marginLeft: "3px", fontSize: "20px" }}
                              />
                            }
                            onClick={async () => {
                              setFilteredData({
                                ...filteredData,
                                email: "",
                                first_name: "",
                                last_name: "",
                                validation_status: "",
                                blocked: "",
                                legal_first_name: "",
                                startDate: "",
                                endDate: "",
                                startInscriptionDate: "",
                                endInscriptionDate: "",
                                home_phone: "",
                                mobile_phone: "",
                                title: "",
                                professional_title: "",
                                designation_letters: "",
                                bioagraphy: "",
                                accreditations: "",
                                social_networks: "",
                                Naissance: "",
                                Inscription: "",
                              });
                              reloadStatus
                                ? setReloadStatus(false)
                                : setReloadStatus(true);
                            }}
                          />
                        </Col>
                      </Col>

                      <Col>
                        <Radio.Group
                          defaultValue={filter}
                          onChange={handleFilterChange}
                        >
                          <Radio.Button value="all">Tout</Radio.Button>
                          <Radio.Button value="invited">Invité</Radio.Button>
                          <Radio.Button value="not-invited">
                            Non invité
                          </Radio.Button>
                        </Radio.Group>
                      </Col>
                    </Row>
                    <h3>Liste des médecins ({invitedPatientsLength})</h3>
                  </div>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={filterDoctors(doctors).map((doctor) => ({
                      key: doctor?.id_doctor,
                      name: `${doctor?.user?.first_name} ${doctor?.user?.last_name}`,
                      email: doctor?.user?.email,
                      professional_title: doctor?.professional_title,
                      id_user: doctor?.user?.id_user,
                    }))}
                    pagination={false}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MyClients;
