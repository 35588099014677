import { createContext, useContext, useEffect, useState, useRef } from "react";
import io from "socket.io-client";
import jwt from "jsonwebtoken";
import Cookies from "js-cookie";
import {
  messageService,
  userServices,
  SocketUrl,
  chatUrl,
} from "../../utils/URL";
import axios from "axios";
import Peer from "simple-peer";

// Create a new context
const ChatContext = createContext();

// Custom hook to use the chat context
export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
  const [form, setForm] = useState({});

  const [socket, setSocket] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const token = Cookies.get("token");
  const decoded = jwt.decode(token);
  const [id_user, setIdUser] = useState(decoded?.id_user);
  const [receaivedMessage, setReceaivedMessage] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [messagess, setMessagess] = useState([]);
  const [stream, setStream] = useState();
  const [call, setCall] = useState({});
  const [filter, setFilter] = useState({});
  const [me, setMe] = useState("");
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [name, setName] = useState("");
  const [idToCall, setIdToCall] = useState();
  const [adressId, setAdressId] = useState();
  const [user, setUser] = useState();
  const [selectedPatient, setSelectedPatient] = useState();
  const [BillData, setBillData] = useState({});
  const [lastInvoiceId, setLastInvoiceId] = useState();
  const [profile, setProfile] = useState();
  const [service, setService] = useState();
  const [locale, setLocale] = useState();
  const [role, setRole] = useState();
  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();
  const currentURL = window.location.href;

  const getMessages = async (conversationId) => {
    try {
      const token = Cookies.get("token");
      const result = await axios.get(
        `${messageService}/getMessagesByConversationId/${conversationId}`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setMessagess(result.data);
    } catch (error) {
      console.error("Confirmation failed:", error);
    }
  };

  useEffect(() => {
    const newSocket = io(`${SocketUrl}`, {
      reconnectionDelayMax: 10000,
    });
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [token]);
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${userServices}/getUser`, {
          headers: {
            "auth-token": token,
          },
        });
        const responseData = response.data.User;
        setName(responseData.first_name, responseData.last_name);
        setUser(responseData);
        setRole(response.data.User.role)

      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    if (token) {
      getUser();
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.emit("addNewUser", id_user);
      socket.on("getOnlineUsers", (response) => {
        setOnlineUsers(response);
      });
      return () => {
        socket.off("getOnlineUsers");
      };
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.on("getOnlineUsers", (response) => {
        setOnlineUsers(response);
      });
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.on("getMessage", (response) => {
        if (response.textMessage === "message deleted by other side") {
          getMessages(response.id_conversation);
        } else {
          setReceaivedMessage(response.textMessage);
        }
      });
      socket.on("getId", (response) => {
        if (socket.id !== response.requestId) {
          socket.emit("receiverId", {
            requestedId: socket.id,
            adressId: response.requestId,
          });
          setAdressId(response.requestId);
        }
      });
      socket.on("getrecevedId", (response) => {
        setIdToCall(response.requestedId);
      });

      socket.on("getNotification", (res) => {
        const isChatOpen = res?.members.some(
          (user) => user.id_user === id_user
        );
        if (isChatOpen) {
          setNotifications((prev) => [{ ...res, isRead: true }, ...prev]);
        } else {
          setNotifications((prev) => [{ ...res, ...prev }]);
        }
      });

      return () => {
        socket.off("getMessage");
        socket.off("getNotification");
      };
    }
  }, [socket]);

  const answerCall = () => {
    setCallAccepted(true);

    const peer = new Peer({ initiator: false, trickle: false, stream });
    peer.on("signal", (data) => {
      socket.emit("answercall", { signal: data, to: call.from, from: name });
    });

    peer.on("stream", (currentStream) => {
      userVideo.current.srcObject = currentStream;
    });

    peer.signal(call.signal);

    connectionRef.current = peer;
  };

  const callUser = () => {
    initiateCall(idToCall, stream);
    // if (!stream) {
    //   navigator.mediaDevices
    //     .getUserMedia({ video: true, audio: true })
    //     .then((currentStream) => {
    //       setStream(currentStream);
    //       myVideo.current.srcObject = currentStream;
    //     })
    //     .catch((error) => {
    //       if (
    //         error.name === "NotAllowedError" ||
    //         error.name === "NotFoundError"
    //       ) {
    //         // Prompt the user to grant permission to access media devices
    //         // Handle accordingly based on user's response
    //       } else {
    //         // Handle other errors
    //         console.error("Error accessing media devices:", error);
    //       }
    //     });
    // }
  };

  const initiateCall = (id, stream) => {
    const peer = new Peer({ initiator: true, trickle: false, stream });

    peer.on("signal", (data) => {
      socket.emit("callUser", {
        userToCall: id,
        signalData: data,
        from: me,
        name,
      });
    });

    peer.on("stream", (currentStream) => {
      userVideo.current.srcObject = currentStream;
    });

    socket.on("callaccepted", (data) => {
      setCall({ ...call, nameToCall: data.from });
      setCallAccepted(true);
      peer.signal(data.signal);
    });

    connectionRef.current = peer;
  };

  // useEffect(() => {
  //   if (socket) {
  //     socket.on("callEnded", () => {
  //       setCallEnded(true);
  //       // window.location.href = "/Chat";
  //       window.location.reload();
  //     });
  //   }
  // }, [socket]);

  const leaveCall = () => {
    socket.emit("disconnectCall");
    setCallEnded(true);
    if (connectionRef.current) {
      connectionRef.current.destroy();
    }
    // window.location.href = "/Chat";
    window.location.reload();
  };
  // if (currentURL !== chatUrl) {
  //   if (userVideo && socket) {
  //     leaveCall();
  //   }
  // }
  return (
    <ChatContext.Provider
      value={{
        call,
        callAccepted,
        myVideo,
        userVideo,
        stream,
        setStream,
        name,
        setName,
        callEnded,
        me,
        callUser,
        leaveCall,
        answerCall,
        socket,
        onlineUsers,
        token,
        id_user,
        receaivedMessage,
        messagess,
        setMe,
        setIdToCall,
        setCall,
        adressId,
        idToCall,
        currentURL,
        setSelectedPatient,
        selectedPatient,
        user,
        BillData,
        setBillData,
        lastInvoiceId,
        setLastInvoiceId,
        form,
        setForm,
        profile,
        setProfile,
        service,
        setService,
        filter,setFilter,
        locale, setLocale,
        role,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
