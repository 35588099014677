// export const userServices = "http://localhost:5001/api/userService";
// export const invoiceService = "http://localhost:5001/api/invoiceService";
// export const formService = "http://localhost:5001/api/formService";
// export const reponceService = "http://localhost:5001/api/reponceService";
// export const availibilityService = "http://localhost:5001/api/availibilityService";
// export const LocationService = "http://localhost:5001/api/locationService";
// export const appointmentService = "http://localhost:5001/api/appointmentService";
// export const noteService = "http://localhost:5001/api/noteService";
// export const activityService = "http://localhost:5001/api/activityService";
// export const documentService = "http://localhost:5001/api/documentService";
// export const conversationService = "http://localhost:5001/api/conversationService";
// export const messageService = "http://localhost:5001/api/messageService";
// export const serviceService = "http://localhost:5001/api/serviceService";
// export const packageService = "http://localhost:5001/api/packageService";
// export const protocoleService = "http://localhost:5001/api/protocoleService";
// export const recommendationService = "http://localhost:5001/api/recommendationService";
// export const SocketUrl = "http://localhost:3001/";
// export const chatUrl = "http://localhost:3003/Chat"; 

export const chatUrl = "http://54.36.191.173:3003/Chat";
export const SocketUrl = "http://54.36.191.173:3001/";
export const userServices = "http://54.36.191.173:5001/api/userService";
export const availibilityService = "http://54.36.191.173:5001/api/availibilityService";
export const LocationService = "http://54.36.191.173:5001/api/locationService";
export const appointmentService = "http://54.36.191.173:5001/api/appointmentService";
export const noteService = "http://54.36.191.173:5001/api/noteService";
export const activityService = "http://54.36.191.173:5001/api/activityService";
export const conversationService = "http://54.36.191.173:5001/api/conversationService";
export const messageService = "http://54.36.191.173:5001/api/messageService";
export const documentService = "http://54.36.191.173:5001/api/documentService";
export const invoiceService = "http://54.36.191.173:5001/api/invoiceService";
export const formService = "http://54.36.191.173:5001/api/formService";
export const reponceService = "http://54.36.191.173:5001/api/reponceService";
export const serviceService = "http://54.36.191.173:5001/api/serviceService";
export const packageService = "http://54.36.191.173:5001/api/packageService";
export const protocoleService = "http://54.36.191.173:5001/api/protocoleService";
export const recommendationService = "http://54.36.191.173:5001/api/recommendationService";
