// PrivateRoute.js
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "./utils/auth";

const PrivateRoute = ({ component: Component, onlineUsers, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        rest.path === "/resetPassword" ? (
          <Component {...props} />
        ) : (
            <Component {...props} onlineUsers={onlineUsers} />
        )
      ) : (
        <Redirect to="/" />
      )
    }
  />
);
export default PrivateRoute;
