import React, { useState } from "react";
import { Button, Card, Form, Input, Space, Table } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { protocol } from "socket.io-client";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 20,
    },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};

const TableComponent = ({
  show,
  handleTable,
  tableData,
  id_protocol,
  handleState,
}) => {
  const [initialsation, setInitialsation] = useState(true);
  const [tableSaved, setTableSaved] = useState(false);
  const [modifTable, setModifTable] = useState(false);
  const [columns, setColumns] = useState(tableData ? tableData.columns : []);
  const [data, setData] = useState(tableData ? tableData.data : []);
  const onFinish = (values) => {
    const newColumns = values.names.map((column, index) => ({
      title: column,
      dataIndex: column,
      key: index,
    }));
    setColumns(newColumns);
    setInitialsation(false);
  };
  const onFinishLines = (values) => {
    setTableSaved(true);
    setData(values?.data);
    setModifTable(false);
    handleState(true);
    handleTable({
      columns: columns,
      data: values?.data,
    });
  };

  return (
    <Card style={{ marginTop: "3%", marginBottom: "1%", width: "100%" }}>
      {!modifTable ? (
        <div>
          {tableSaved || (id_protocol !== undefined && columns.length>0 && data.length>0)  ? (
            <div>
              <div
                style={{
                  marginTop: "3%",
                  marginBottom: "2%",
                  marginRight: "2%",
                  padding: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center", // Change "end" to "center"
                }}
              >
                <div>
                  <h2 style={{ margin: 0 }}>tableau</h2>
                  <div
                    style={{
                      borderBottom: "5px solid #023047",
                      width: "100%",
                      marginTop: "5px",
                    }}
                  ></div>
                </div>
              </div>
              <Table
                style={{
                  border: "2px solid black",
                  borderRadius: "5px",
                  width: "100%",
                }}
                bordered
                columns={columns}
                dataSource={data}
                pagination={false}
              />

              <div style={{ textAlign: "center", marginTop: "3%" }}>
                {(id_protocol   && show === false )&&(
                  <Button
                    type="text"
                    style={{ backgroundColor: "#DEF9C4" }}
                    onClick={() => {
                      setInitialsation(false);
                      setTableSaved(false);
                      setModifTable(true);
                    }}
                  >
                    Modifier le tableau
                  </Button>
                )}
              </div>
            </div>
          ) : initialsation ? (
            <div>
              <div
                style={{
                  marginTop: "3%",
                  marginBottom: "2%",
                  marginRight: "2%",
                  padding: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <h2>Initialiser le tableau</h2>
                  <div
                    style={{
                      borderBottom: "5px solid #023047",
                      width: "100%",
                      marginTop: "5px",
                    }}
                  ></div>
                </div>
              </div>
              <Form
                name="dynamic_form_item"
                {...formItemLayoutWithOutLabel}
                onFinish={onFinish}
                style={{
                  maxWidth: 600,
                }}
              >
                <Form.List
                  name="names"
                  rules={[
                    {
                      validator: async (_, names) => {
                        if (!names || names.length < 2) {
                          return Promise.reject(
                            new Error("At least 2 passengers")
                          );
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item required={false} key={field.key}>
                          <Form.Item
                            {...field}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message:
                                  "Please input passenger's name or delete this field.",
                              },
                            ]}
                            noStyle
                          >
                            <Input
                              placeholder="passenger name"
                              style={{
                                width: "90%",
                              }}
                            />
                          </Form.Item>
                          {fields.length > 1 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          ) : null}
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                          }}
                          style={{
                            width: "100%",
                          }}
                          icon={<PlusOutlined />}
                        >
                          Ajouter un colone
                        </Button>

                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="text"
                    style={{
                      backgroundColor: "#DEF9C4",
                      borderRadius: "15px",
                      width: "100%",
                    }}
                  >
                    Enregistrer les colones
                  </Button>
                </Form.Item>
              </Form>
            </div>
          ) : (
            <div>
              <Form
                name="dynamic_form_nest_item"
                onFinish={onFinishLines}
                style={{
                  maxWidth: 600,
                }}
                autoComplete="off"
              >
                <Form.List name="data">
                  {(fields, { add, remove }) => (
                    <div>
                      <div
                        style={{
                          marginTop: "3%",
                          marginRight: "2%",
                          padding: 0,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center", // Change "end" to "center"
                        }}
                      >
                        <div>
                          <h2 style={{ margin: 0 }}>tableau</h2>
                          <div
                            style={{
                              borderBottom: "5px solid #023047",
                              width: "100%",
                              marginTop: "5px",
                            }}
                          ></div>
                        </div>
                      </div>
                      <Card style={{ marginTop: "2%" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginRight: "2%",
                            marginLeft: "1%",
                          }}
                        >
                          {columns.map((column, index) => (
                            <React.Fragment key={index}>
                              <p style={{ fontSize: "18px", margin: "0" }}>
                                {column.title}
                              </p>
                              {index !== columns.length - 1 && (
                                <span style={{ width: "10px" }}></span>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{
                              display: "flex",
                              marginBottom: 8,
                            }}
                            align="baseline"
                          >
                            {columns.map((column) => (
                              <Form.Item
                                {...restField}
                                name={[name, column.dataIndex]}
                                rules={[
                                  {
                                    required: true,
                                    message: `Missing ${column.title.toLowerCase()}`,
                                  },
                                ]}
                                key={column.key}
                              >
                                <Input placeholder={column.title} />
                              </Form.Item>
                            ))}
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Ajouter le ligne
                          </Button>
                        </Form.Item>
                      </Card>
                    </div>
                  )}
                </Form.List>
                <div style={{ textAlign: "center", marginTop: "3%" }}>
                  <Form.Item>
                    <Button
                      type="text"
                      htmlType="submit"
                      style={{ backgroundColor: "#DEF9C4" }}
                    >
                      Enregistrer le tableau
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          )}
        </div>
      ) : (
        <div>
          {id_protocol !== undefined &&
            !initialsation &&
            !tableSaved &&
            modifTable && (
              <div>
                <Form
                  name="dynamic_form_nest_item"
                  onFinish={onFinishLines}
                  style={{ maxWidth: 600 }}
                  autoComplete="off"
                  initialValues={{ data }}
                >
                  <Form.List name="data">
                    {(fields, { add, remove }) => (
                      <div>
                        <div
                          style={{
                            marginTop: "3%",
                            marginRight: "2%",
                            padding: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <h2 style={{ margin: 0 }}>tableau</h2>
                            <div
                              style={{
                                borderBottom: "5px solid #023047",
                                width: "100%",
                                marginTop: "5px",
                              }}
                            ></div>
                          </div>
                        </div>
                        <Card style={{ marginTop: "2%" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginRight: "2%",
                              marginLeft: "1%",
                            }}
                          >
                            {columns.map((column, index) => (
                              <React.Fragment key={index}>
                                <p style={{ fontSize: "18px", margin: "0" }}>
                                  {column.title}
                                </p>
                                {index !== columns.length - 1 && (
                                  <span style={{ width: "10px" }}></span>
                                )}
                              </React.Fragment>
                            ))}
                          </div>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{
                                display: "flex",
                                marginBottom: 8,
                              }}
                              align="baseline"
                            >
                              {columns.map((column) => (
                                <Form.Item
                                  {...restField}
                                  name={[name, column.dataIndex]}
                                  rules={[
                                    {
                                      required: true,
                                      message: `Missing ${column.title.toLowerCase()}`,
                                    },
                                  ]}
                                  key={column.key}
                                >
                                  <Input
                                    placeholder={column.title}
                                    onChange={() => {
                                      handleState(false);
                                    }}
                                  />
                                </Form.Item>
                              ))}
                              <MinusCircleOutlined
                                onClick={() => {
                                  remove(name);
                                  handleState(false);
                                }}
                              />
                            </Space>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => {
                                add();
                                handleState(false);
                              }}
                              block
                              icon={<PlusOutlined />}
                            >
                              Ajouter une ligne
                            </Button>
                          </Form.Item>
                        </Card>
                      </div>
                    )}
                  </Form.List>
                  <div style={{ textAlign: "center", marginTop: "3%" }}>
                    <Form.Item>
                      <Button
                        type="text"
                        htmlType="submit"
                        style={{ backgroundColor: "#DEF9C4" }}
                      >
                        Modifier le tableau
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            )}
        </div>
      )}
    </Card>
  );
};

export default TableComponent;
