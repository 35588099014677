import {
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Input,
  message,
  Space,
  List,
  Select,
} from "antd";
import { FaLock } from "react-icons/fa";
import { GiEarthAmerica } from "react-icons/gi";

import {
  appointmentService,
  LocationService,
  serviceService,
} from "../utils/URL";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import jwt from "jsonwebtoken";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useChat } from "../components/layout/ChatContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import rearange from "../assets/images/rearange.png"; // Import the logo image

const getInitials = (name) => {
  const [firstName, lastName] = name.split(" ");
  const initials = firstName.charAt(0) + lastName.charAt(0);
  return initials.toUpperCase();
};

const Locations = () => {
  const { service, setService, locale, setLocale } = useChat();
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [patientData, setPatientData] = useState({});
  const [locations, setLocations] = useState([]);
  const [patientOriginalData, setPatientOriginalData] = useState({});
  const token = Cookies.get("token");
  const decoded = jwt.decode(token);
  const [id_user, setIdUser] = useState(decoded?.id_user);
  const [patients, setPatients] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [modalMode, setModalMode] = useState("create"); // Add modal mode state

  const history = useHistory();



  const fetchLocations = async () => {
    try {
      const response = await axios.get(
        `${LocationService}/getLocationByIdService/${service}`
      );
      const values = response.data;
      const sortedServices = values.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      const Locations = sortedServices.map((location) => ({
        key: location.id,
        title: `${location.title} `,
        bigtitle: `${location.bigtitle} `,
        image: getInitials(`${location.name} `),

        actions: [
          {
            action: "Disponibilité",
            color: "#F08B09",
            icon: rearange,
            onclick: (key) => {
              const data = sortedServices.find(
                (location) => location.id === key
              );
              setLocale(data);
              history.push(`/Availibilities`);
            },
          },
        ],
      }));

      setLocations(Locations);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, [confirmLoading]);

  return (
    <>
      <div>
        <div className="tabled">
          {service !== undefined && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {patientData !== undefined && (
                <Button
                  type="text"
                  style={{
                    marginLeft: "100%",
                    marginBottom: "1%",
                    backgroundColor: "#CDFADB",
                  }}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Retour à la page des services
                </Button>
              )}
            </div>
          )}
          <Card
            bordered={false}
            className="criclebox tablespace mb-24 "
            extra={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "1000px",
                    padding: "0px",
                    margin: "0px",
                  }}
                >
                  <h4>Emplacements :</h4>
                </div>
              </div>
            }
          >
            <div className="table-responsive">
              <List
                dataSource={locations}
                renderItem={(item, index) => (
                  <List.Item
                    key={`${item.key}_${index}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid #f0f0f0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        width: "500px",
                        marginLeft: "3%",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            lineHeight: "33px",
                          }}
                        >
                          {item?.title}
                        </p>{" "}
                        <p
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "grey",
                          }}
                        >
                          {item?.bigtitle}
                        </p>
                      </div>
                    </div>

                    <Space direction="vertical">
                      <div style={{ display: "flex", marginLeft: "10px" }}>
                        {item.actions.map((action, index) => (
                          <div
                            key={index}
                            style={{
                              fontWeight: "bold",
                              background: "none",
                              border: "none",
                              textDecoration: "underline",
                              cursor: "pointer",
                              marginLeft: "15px",
                              marginRight: "30px",
                              marginTop: "40%",
                              position: "relative",
                              right: "3%",
                              top: "5%",
                              color: action.color || "#F08B09",
                            }}
                            onClick={() => action.onclick(item.key)}
                          >
                            <img
                              src={action.icon}
                              alt={`${action.action} icon`}
                              style={{ marginRight: "8px", height: "16px" }}
                            />
                            {action.action}
                          </div>
                        ))}
                      </div>
                    </Space>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Locations;
