import {
  Button,
  Card,
  Col,
  Empty,
  Input,
  Modal,
  Row,
  Space,
  Table,
  message,
  ConfigProvider,
  DatePicker,
} from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { formService, userServices, reponceService } from "../utils/URL";
import { useChat } from "../components/layout/ChatContext";
import rearange from "../assets/images/rearange.png";
import { ReloadOutlined } from "@ant-design/icons";
import frFR from "antd/lib/locale/fr_FR";
import moment from "moment";

const Forms = () => {
  const { form, setForm } = useChat();
  const history = useHistory();

  const token = Cookies.get("token");

  const [modalOpen, setModalOpen] = useState(false);
  const [modalPatientOpen, setModalPatientOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [patients, setPatients] = useState([]);
  const [forms, setForms] = useState([]);
  const [role, setRole] = useState("");
  const [reloadStatus, setReloadStatus] = useState(false);
  const [searchData, setSearchData] = useState({
    first_name: "",
    legal_first_name: "",
    created_at: "",
    name: "",
  });

  const handleChangeDataSearch = (fieldName, value) => {
    setSearchData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const columns = [
    {
      title: "Formulaire",
      dataIndex: "name",
      key: "name",
      render: (text, form) => (
        <>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              marginTop: "10px",
              color: "black",
            }}
          >
            {text}
          </p>
          <br />
          <p>{new Date(form.created_at).toLocaleString()}</p>
        </>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      width: 250,
      fixed: "right",
      render: (text, form) => (
        <Space>
          {role === "doctor" ? (
            <>
              <p
                style={{
                  color: "green",
                  fontSize: "18px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  openModal();
                  setForm(form);
                  const formPatients = form.Patients.map(
                    (patient) => patient.id_patient
                  ).flat();
                  const filtered = patients.filter(
                    (patient) => !formPatients.includes(patient.id_patient)
                  );
                  setFilteredPatients(filtered);
                }}
              >
                Envoyer au client
              </p>
              <p
                style={{
                  color: "green",
                  fontSize: "18px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  margin: "0 13px",
                }}
                onClick={() => {
                  setForm(form);
                  openPatients();
                }}
              >
                Patient
              </p>
              <p
                style={{
                  color: "#F08B09",
                  fontSize: "18px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setForm(form);
                  history.push("/addForm");
                }}
              >
                <img
                  src={rearange}
                  alt="modif icon"
                  style={{ marginRight: "8px", height: "16px" }}
                />
                Modifier
              </p>
            </>
          ) : (
            <>
              <p
                style={{
                  color: "green",
                  fontSize: "18px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  history.push("/formToFill");
                  setForm(form);
                }}
              >
                Ouvrir le formulaire
              </p>
            </>
          )}
        </Space>
      ),
    },
  ];
  const openModal = () => {
    setModalOpen(true);
    // setMode("book");
  };
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = patients.filter(
      (patient) =>
        patient.user.first_name.toLowerCase().includes(value) ||
        patient.user.last_name.toLowerCase().includes(value) ||
        patient.user.email.toLowerCase().includes(value)
    );
    setFilteredPatients(filtered);
  };
  const getInitials = (name) => {
    const [firstName, lastName] = name.split(" ");
    const initials = firstName.charAt(0) + lastName.charAt(0);
    return initials.toUpperCase();
  };
  const associatePatient = async (formId, patientId) => {
    try {
      await axios.put(
        `${formService}/associateFormToPatient/${formId}/${patientId}`
      );

      fetchUserRole();
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };
  const fetchPatients = async () => {
    try {
      const response = await axios.get(`${userServices}/getPatients`, {
        headers: {
          "auth-token": token,
        },
      });
      const sortedPatients = response.data.patients.sort((a, b) =>
        `${a.user.first_name} ${a.user.last_name}`.localeCompare(
          `${b.user.first_name} ${b.user.last_name}`
        )
      );
      setPatients(sortedPatients);
      setFilteredPatients(sortedPatients);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };
  const fetchFormPatient = async (id) => {
    try {
      const response = await axios.get(
        `${formService}/
        /${id}`
      );
      const sortedForms = response.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setForms(sortedForms);
    } catch (error) {
      console.error("Error fetching forms:", error);
    }
  };
  const fetchFormDoctor = async () => {
    try {
      const response = await axios.get(
        `${formService}/searchForms?legal_first_name=${searchData.legal_first_name.trim()}&created_at=${
          searchData.created_at
        }&name=${searchData.name.trim()}&first_name=${searchData.first_name.trim()}`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      const sortedForms = response.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setForms(sortedForms);
    } catch (error) {
      console.error("Error fetching pformstients:", error);
    }
  };
  const fetchUserRole = async () => {
    try {
      const response = await axios.get(`${userServices}/getUser`, {
        headers: {
          "auth-token": token,
        },
      });
      if (response?.data?.User?.role === "doctor") {
        fetchPatients();
        fetchFormDoctor();
      } else if (response.data.User.role === "patient") {
        fetchFormDoctor();
      }
      setRole(response?.data?.User?.role);
    } catch (error) {
      console.error("Error fetching user role:", error);
    }
  };

  useEffect(() => {
    fetchUserRole();
  }, []);
  useEffect(() => {
    setSearchData({
      ...searchData,
      first_name: "",
      legal_first_name: "",
      last_name: "",
      created_at: "",
      name: "",
    });

    fetchFormDoctor();
  }, [reloadStatus]);
  const associatedPatientName = forms.map((form) => {
    const associatedPatient = patients.find(
      (patient) => patient.id_patient === form.id_patient
    );
    if (associatedPatient) {
      return `${associatedPatient.user.first_name} ${associatedPatient.user.last_name}`;
    } else {
      return null;
    }
  });

  const openPatients = () => {
    setModalPatientOpen(true);
  };

  const fetchResponses = async (patient, form) => {
    try {
      const response = await axios.get(
        `${reponceService}/getReponcesByIdPatientAndIdForm/${patient.id_patient}/${form.id_form}`
      );
      setForm({
        id_form: 0,
        name: response.data.form.name,
        description: response.data.form.description,
        questions: response.data.reponse.questions,
        created_at: response.data.reponse.created_at,
        updated_at: response.data.reponse.updated_at,
        id_doctor: response.data.form.id_doctor,
        signature: response.data.reponse.signature,
      });
      history.push(`/formToFill/${patient.id_patient}/${form.id_form}`);
      setModalPatientOpen(false);
    } catch (error) {
      message.error(
        `le patient ${patient.user.first_name}  ${patient.user.last_name} n'a pas remplie le formulaire`
      );
      console.error("Error fetching patients:", error);
    }
  };

  const CkeckResponce = (form, patient) => {
    fetchResponses(patient, form);
  };
  return (
    <>
      <Modal
        title="Patient associé"
        centered
        visible={modalPatientOpen}
        onOk={() => setModalPatientOpen(false)}
        onCancel={() => setModalPatientOpen(false)}
        footer={[
          <div
            key="footer"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              key="cancel"
              onClick={() => {
                setModalPatientOpen(false);
              }}
            >
              Annuler
            </Button>
          </div>,
        ]}
      >
        {form && form.Patients && form.Patients.length > 0 ? (
          form.Patients.map((patient) => (
            <Space
              key={patient.id_patient}
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                
                CkeckResponce(form, patient);
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    backgroundColor: "lightblue",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "8px",
                  }}
                >
                  {getInitials(
                    `${patient?.user?.first_name} ${patient?.user?.last_name}`
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <strong>{`${patient?.user?.first_name} ${patient?.user?.last_name}`}</strong>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#C7C8CC",
                    fontSize: "12px",
                    marginLeft: "7%",
                    width: "100%",
                  }}
                ></span>
              </div>
            </Space>
          ))
        ) : (
          <Empty />
        )}
      </Modal>

      <Modal
        title="Sélectionnez un patient"
        centered
        visible={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={[
          <div
            key="footer"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              key="cancel"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Annuler
            </Button>
          </div>,
        ]}
      >
        <Input
          type="text"
          placeholder="Rechercher par nom ou par email "
          value={searchTerm}
          onChange={handleSearch}
          style={{ marginBottom: "16px" }}
        />

        {filteredPatients.length > 0 ? (
          filteredPatients.map((patient) => (
            <Space
              key={patient.id_patient}
              style={{
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
              }}
              onClick={() => {
                associatePatient(form.id_form, patient.id_patient);
                setModalOpen(false);
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    backgroundColor: "lightblue",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "8px",
                  }}
                >
                  {getInitials(
                    `${patient.user.first_name} ${patient.user.last_name}`
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <strong>{`${patient.user.first_name} ${patient.user.last_name}`}</strong>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#C7C8CC",
                    fontSize: "12px",
                    marginLeft: "7%",
                    width: "100%",
                  }}
                >
                  {patient.Doctors.length > 0 ? "Invité" : "Non invité"}
                </span>
              </div>
            </Space>
          ))
        ) : (
          <Empty />
        )}
      </Modal>

      {role === "doctor" && (
        <Row justify="end" style={{ marginBottom: 20 }}>
          <Col style={{ width: "20%" }}>
            <Button
              type="text"
              style={{ backgroundColor: "#CDFADB", width: "92%" }}
              onClick={() => {
                setForm(null);
                history.push("/addForm");
              }}
            >
              Créer un formulaire
            </Button>
          </Col>
        </Row>
        // <Card style={{ width: "80%", marginTop: "2%" }}>
        //   <p style={{ color: "grey", fontSize: "20px" }}>
        //     Créez, gérez et envoyez des formulaires à vos clients. Vous pouvez
        //     télécharger vos formulaires existants ou créer des formulaires à
        //     partir de nos modèles d'échantillons.
        //   </p>
        //   <div style={{ display: "flex", justifyContent: "flex-end" }}>
        //     <Button
        //       style={{
        //         color: "white",
        //         backgroundColor: "#35ae35",
        //         fontSize: "15px",
        //         width: "20%",
        //       }}
        //       onClick={() => {
        //         setForm(null);
        //         history.push("/addForm");
        //       }}
        //     >
        //       Créer un formulaire
        //     </Button>
        //   </div>
        // </Card>
      )}

      <div className="formCard">
        <Card
          className="criclebox formTable tablespace mb-24 "
          extra={
            <Row
              gutter={[16, 0]}
              align="middle"
              style={{ width: "100%", backgroundColor: "#ffffff" }}
            >
              <Col flex="auto" style={{ width: "20%" }}>
                <Input
                  value={searchData?.name}
                  onChange={(e) => {
                    handleChangeDataSearch("name", e.target.value);
                  }}
                  style={{ height: "40px" }}
                  placeholder="Nom du formulaire"
                />
              </Col>
              <Col flex="auto" style={{ width: "20%" }}>
                <Input
                  value={
                    role === "doctor"
                      ? searchData?.legal_first_name
                      : searchData?.first_name
                  }
                  onChange={(e) => {
                    const key =
                      role === "doctor" ? "legal_first_name" : "first_name";
                    handleChangeDataSearch(key, e.target.value);
                  }}
                  style={{ height: "40px" }}
                  placeholder={
                    role === "doctor"
                      ? "Nom Légale du patient"
                      : "Nom du médecin"
                  }
                />
              </Col>
              {/* <Col flex="auto" style={{ width: "20%" }}>
                <Input
                  // value={searchData?.last_name}
                  onChange={(e) => {
                    // handleChangeDataSearch("last_name", e.target.value);
                  }}
                  style={{ height: "40px" }}
                  placeholder="Prénom"
                />
              </Col> */}
              <Col flex="auto" style={{ width: "20%" }}>
                <ConfigProvider locale={frFR}>
                  <DatePicker
                    value={
                      searchData.created_at
                        ? moment(searchData.created_at)
                        : null
                    }
                    onChange={(value) => {
                      handleChangeDataSearch(
                        "created_at",
                        value ? value.format("YYYY-MM-DD") : ""
                      );
                    }}
                    style={{
                      height: "40px",
                      width: "100%",
                      borderRadius: "5px",
                    }}
                    placeholder="Date de creation du formulaire"
                  />
                </ConfigProvider>
              </Col>

              <Col flex="none" style={{ width: "20%" }}>
                <Row gutter={[5, 0]}>
                  <Col span={20}>
                    {" "}
                    <Button
                      type="text"
                      style={{
                        backgroundColor: "#CDFADB",
                        height: "40px",
                        width: "100%",
                      }}
                      onClick={async () => {
                        await fetchFormDoctor();
                      }}
                    >
                      Chercher
                    </Button>
                  </Col>
                  <Col span={4}>
                    <Button
                      type="text"
                      style={{
                        backgroundColor: "#CDFADB",
                        height: "40px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center", // Center horizontally
                        alignItems: "center", // Center vertically
                      }}
                      onClick={async () => {
                        setSearchData({
                          ...searchData,
                          first_name: "",
                          legal_first_name: "",
                          last_name: "",
                          created_at: "",
                          name: "",
                        });
                        reloadStatus
                          ? setReloadStatus(false)
                          : setReloadStatus(true);
                      }}
                    >
                      <ReloadOutlined style={{ fontSize: "20px" }} />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        >
          <div className="table-responsive">
            <Table
              style={{ marginTop: "1px" }}
              title={null}
              dataSource={forms.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
              )}
              columns={columns}
              rowKey={(form) => form.id_appointment}
              pagination={false}
            />
          </div>
        </Card>
      </div>
    </>
  );
};

export default Forms;
