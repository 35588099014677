import { ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  DatePicker,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import frFR from "antd/lib/locale/fr_FR";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { documentService } from "../utils/URL";

const Documents = () => {
  const history = useHistory();
  const token = Cookies.get("token");
  const [documents, setDocuments] = useState([]);
  const [searchData, setSearchData] = useState({
    titre: "",
    file_type: "",
    createdAt: "",
    doctor_name: "",
  });
  const [reloadStatus, setReloadStatus] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [doctorOptions, setDoctorOptions] = useState(null);
  const columns = [
    {
      title: "Titre",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Nom du medecin",
      dataIndex: "doctor_name",
      key: "doctor_name",
    },
    {
      title: "Date de creation",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <>{moment(text).format("DD/MM/YYYY")}</>,
    },
    {
      title: "Actions",
      key: "actions",
      width: 250,
      fixed: "right",
      render: (text, document) => (
        <Space>
          <p
            style={{
              color: "green",
              fontSize: "18px",
              fontWeight: "bold",
              cursor: "pointer",
              marginRight: "2rem",
            }}
            onClick={() => showModal(document)}
          >
            détails
          </p>
        </Space>
      ),
    },
  ];

  const handleChangeDataSearch = (fieldName, value) => {
    setSearchData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const fetchDocuments = async () => {
    try {
      const response = await axios.get(
        `${documentService}/searchDocuments?titre=${searchData.titre.trim()}&createdAt=${
          searchData.createdAt
        }&file_type=${searchData.file_type.trim()}&doctor_name=${searchData.doctor_name.trim()}`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      const sortedDocuments = response.data.documents.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setDocuments(sortedDocuments);
      setDoctorOptions(response.data.doctorOptions);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const showModal = (document) => {
    setSelectedDocument(document);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedDocument(null);
  };

  useEffect(() => {
    setSearchData({
      ...searchData,
      titre: "",
      file_type: "",
      createdAt: "",
      doctor_name: "",
    });
    fetchDocuments();
  }, [reloadStatus]);
  const options = [
    { value: "image/", label: "Image" },
    { value: "application/pdf", label: "PDF Document" },
    { value: "text/plain", label: "Plain Text" },
  ];
  return (
    <>
      <div className="formCard">
        <Card
          className="criclebox formTable tablespace mb-24 "
          extra={
            <Row
              gutter={[16, 0]}
              align="middle"
              style={{ width: "100%", backgroundColor: "#ffffff" }}
            >
              <Col flex="auto" style={{ width: "20%" }}>
                <Input
                  value={searchData?.titre}
                  onChange={(e) => {
                    handleChangeDataSearch("titre", e.target.value);
                  }}
                  style={{ height: "40px" }}
                  placeholder="Titre du document"
                />
              </Col>
              <Col flex="auto" style={{ width: "20%" }}>
                <ConfigProvider locale={frFR}>
                  <DatePicker
                    value={
                      searchData.createdAt ? moment(searchData.createdAt) : null
                    }
                    onChange={(value) => {
                      handleChangeDataSearch(
                        "createdAt",
                        value ? value.format("YYYY-MM-DD") : ""
                      );
                    }}
                    style={{
                      height: "40px",
                      width: "100%",
                      borderRadius: "5px",
                    }}
                    placeholder="Date de creation du document"
                  />
                </ConfigProvider>
              </Col>
              <Col flex="auto" style={{ width: "20%" }}>
                <Select
                  options={options}
                  value={searchData.file_type ? searchData.file_type : null}
                  onChange={(value) => {
                    handleChangeDataSearch("file_type", value ? value : "");
                  }}
                  style={{ height: "40px", width: "100%" }}
                  placeholder="Type du document"
                />
              </Col>
              <Col flex="auto" style={{ width: "20%" }}>
                <Select
                  options={doctorOptions}
                  value={searchData.doctor_name ? searchData.doctor_name : null}
                  onChange={(value) => {
                    handleChangeDataSearch("doctor_name", value ? value : "");
                  }}
                  style={{ height: "40px", width: "100%" }}
                  placeholder="Nom du medecin"
                />
              </Col>
              <Col flex="none" style={{ width: "20%" }}>
                <Row gutter={[5, 0]}>
                  <Col span={20}>
                    <Button
                      type="text"
                      style={{
                        backgroundColor: "#CDFADB",
                        height: "40px",
                        width: "100%",
                      }}
                      onClick={async () => {
                        await fetchDocuments();
                      }}
                    >
                      Chercher
                    </Button>
                  </Col>
                  <Col span={4}>
                    <Button
                      type="text"
                      style={{
                        backgroundColor: "#CDFADB",
                        height: "40px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setReloadStatus(!reloadStatus);
                        setSearchData({
                          ...searchData,
                          titre: "",
                          file_type: "",
                          createdAt: "",
                          doctor_name: "",
                        });
                        fetchDocuments();
                      }}
                    >
                      <ReloadOutlined style={{ fontSize: "20px" }} />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        >
          <div className="table-responsive">
            <Table
              style={{ marginTop: "1px" }}
              dataSource={documents}
              columns={columns}
              rowKey={(doc) => doc.id_document}
              pagination={false}
            />
          </div>
        </Card>

        <Modal
          title={selectedDocument?.title}
          visible={isModalVisible}
          onCancel={handleCloseModal}
          footer={null}
          width={800}
        >
          {selectedDocument ? (
            <>
              {selectedDocument.file_type === "image/jpeg" ||
              selectedDocument.file_type === "image/png" ? (
                <img
                  src={selectedDocument.file_url}
                  alt="Document"
                  style={{ width: "100%" }}
                />
              ) : selectedDocument.file_type === "application/pdf" ? (
                <iframe
                  src={selectedDocument.file_url}
                  title="PDF Document"
                  style={{ width: "100%", height: "500px" }}
                />
              ) : selectedDocument.file_type === "text/plain" ? (
                <iframe
                  src={selectedDocument.file_url}
                  title="Text Document"
                  style={{ width: "100%", height: "500px" }}
                />
              ) : (
                <p>Unsupported document type</p>
              )}
            </>
          ) : (
            <p>Loading document content...</p>
          )}
        </Modal>
      </div>
    </>
  );
};

export default Documents;
