import { Button, Input, Layout, Space, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import profileIcon from "../../assets/images/profileIcon.png"; // Import the image file
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { recommendationService } from "../../utils/URL";
import { useHistory } from "react-router-dom";

const { Header, Sider, Content } = Layout;
const Recommendation = ({
  id,
  customKey,
  handleSend,
  handleState,
  name,
  id_protocol,
  recommendation,
}) => {
  const history = useHistory();
  let colorBgContainer;
  let borderRadiusLG;
  // const { id_recommendation } = useParams();
  const [formData, setFormData] = useState({
    name: customKey ? name : "",
    instructions_title: "",
    instructions: "",
    to_do_title: "",
    to_do: "",
    id_protocol: "",
    ...(recommendation?.id_recommendation && {
      image: recommendation.image,
      id_protocol: recommendation.id_protocol,
      name: recommendation.name,
      instructions_title: recommendation.instructions_title,
      instructions: recommendation.instructions,
      to_do_title: recommendation.to_do_title,
      to_do: recommendation.to_do,
    }),
  });

const [state , setState] = useState(recommendation?.id_recommendation !== undefined ? true : false );
  useEffect(() => {
    setFormData({
      image: recommendation?.image,
      id_protocol: recommendation?.id_protocol,
      name: recommendation?.name,
      instructions_title: recommendation?.instructions_title,
      instructions: recommendation?.instructions,
      to_do_title: recommendation?.to_do_title,
      to_do: recommendation?.to_do,
    });
    
  }, [recommendation]);

  const handleChange = (fieldName, value) => {
    handleState(false)
    setState(false)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };
  // const fetchRecommendationData = async () => {
  //   try {
  //     const responce = await axios.get(
  //       `${recommendationService}/getRecommendationById/${recommendation?.id_recommendation}`
  //     );
  //     setFormData({
  //       image: responce.data.image,
  //       id_protocol: responce.data.id_protocol,
  //       name: responce.data.name,
  //       instructions_title: responce.data.instructions_title,
  //       instructions: responce.data.instructions,
  //       to_do_title: responce.data.to_do_title,
  //       to_do: responce.data.to_do,
  //     });
  //   } catch (error) {
  //     console.error("Error fetching Recommendation:", error);
  //     if (
  //       error.response &&
  //       error.response.data &&
  //       error.response.data.message
  //     ) {
  //       message.error(error.response.data.message);
  //     } else {
  //       message.error("Erreur interne du serveur");
  //     }
  //   }
  // };

  // const handleSave = async () => {
  //   try {
  //     await axios.put(
  //       `${recommendationService}/updateRecommendation/${recommendation?.id_recommendation}`,
  //       formData
  //     );

  //     message.success("Recommendation est enregistrée avec succès");
  //     history.goBack();
  //   } catch (error) {
  //     console.error("Error updating Recommendation:", error);
  //     if (
  //       error.response &&
  //       error.response.data &&
  //       error.response.data.message
  //     ) {
  //       message.error(error.response.data.message);
  //     } else {
  //       message.error("Erreur interne du serveur");
  //     }
  //   }
  // };
  // const handleDelete = async () => {
  //   try {
  //     await axios.delete(
  //       `${recommendationService}/deleteRecommendationById/${recommendation?.id_recommendation}`
  //     );

  //     message.success("Recommendation est enregistrée avec succès");
  //     history.goBack();
  //   } catch (error) {
  //     console.error("Error updating Recommendation:", error);
  //     if (
  //       error.response &&
  //       error.response.data &&
  //       error.response.data.message
  //     ) {
  //       message.error(error.response.data.message);
  //     } else {
  //       message.error("Erreur interne du serveur");
  //     }
  //   }
  // };

  return (
    <Layout style={{ marginLeft: "0px" }}>
      {/*       
      <Sider
        style={{
          backgroundColor: "#001d3d",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        width={300}
        trigger={null}
      >
        <div
          style={{
            marginTop: "20%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px 0",
            backgroundColor: "#fff",
            borderRadius: "50%",
            width: "170px",
            height: "170px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              width: "150px",
              height: "150px",
              border: "2px solid #000",
            }}
          >
            <img
              src={formData.image || profileIcon  } // Replace with your image path
              alt="Logo"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
              }}
            />
          </div>
        </div>
      </Sider> */}

      <Layout style={{ marginLeft: "0px" }}>
        <Header
          style={{
            marginTop: "5%",
            padding: "10px 0px",
            background: colorBgContainer,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 style={{ margin: 0 }}>
            {customKey ? `Recommendation N=° ${customKey}` : "RECOMMANDATIONS"}
          </h2>
        </Header>

        <Content
          style={{
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div
            style={{
              marginTop: "2%",
              marginRight: "2%",
              padding: 0,
              background: colorBgContainer,
              display: "flex",
              justifyContent: "end",
              alignItems: "end", // Change "end" to "center"
            }}
          >
            <div>
              <h2 style={{ margin: 0 }}>
                {formData?.name ? formData.name : "TITRE"}
              </h2>
              <div
                style={{
                  marginTop: "2%",
                  marginRight: "2%",
                  padding: 0,
                  background: colorBgContainer,
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end", // Change "end" to "center"
                }}
              >
                <div
                  style={{
                    borderBottom: "5px solid #023047",
                    width: "60%",
                    marginTop: "5px",
                  }}
                ></div>
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: "3%",
              marginRight: "2%",
              padding: 0,
              background: colorBgContainer,
              display: "flex",
              justifyContent: "Start",
              alignItems: "center", // Change "end" to "center"
            }}
          >
            <div>
              <h2 style={{ margin: 0 }}>OBJECTIFS</h2>
              <div
                style={{
                  borderBottom: "5px solid #023047",
                  width: "50%",
                  marginTop: "5px",
                }}
              ></div>
            </div>
          </div>
          <div style={{ marginTop: "2%" }}>
            <Input
              value={formData?.instructions_title}
              disabled={customKey === undefined}
              onChange={(e) => {
                handleChange("instructions_title", e.target.value);
              }}
              style={{ width: "200px", border: "2px solid #023047" }}
              placeholder="Titre"
            />{" "}
          </div>
          <div style={{ marginTop: "2%" }}>
            <TextArea
              disabled={customKey === undefined}
              value={formData?.instructions}
              onChange={(e) => {
                handleChange("instructions", e.target.value);
              }}
              rows={4}
              style={{ width: "600px", border: "2px solid #023047" }}
              placeholder="Editeur de texte"
            />
          </div>

          <div
            style={{
              marginTop: "3%",
              marginRight: "2%",
              padding: 0,
              background: colorBgContainer,
              display: "flex",
              justifyContent: "Start",
              alignItems: "center", // Change "end" to "center"
            }}
          >
            <div>
              <h2 style={{ margin: 0 }}>INSTRUCTIONS</h2>
              <div
                style={{
                  borderBottom: "5px solid #023047",
                  width: "50%",
                  marginTop: "5px",
                }}
              ></div>
            </div>
          </div>
          <div style={{ marginTop: "2%" }}>
            <Input
              disabled={customKey === undefined}
              value={formData?.to_do_title}
              onChange={(e) => {
                handleChange("to_do_title", e.target.value);
              }}
              style={{ width: "200px", border: "2px solid #023047" }}
              placeholder="Titre"
            />{" "}
          </div>
          <div style={{ marginTop: "2%" }}>
            <TextArea
              disabled={customKey === undefined}
              value={formData?.to_do}
              onChange={(e) => {
                handleChange("to_do", e.target.value);
              }}
              rows={4}
              style={{ width: "600px", border: "2px solid #023047" }}
              placeholder="Editeur de texte"
            />
          </div>
          <div
            style={{
              marginTop: "2%",
              marginRight: "2%",
              padding: 0,
              background: colorBgContainer,
              display: "flex",
              justifyContent: "end",
              alignItems: "center", // Change "end" to "center"
            }}
          >
            {(customKey  && !state) && (
              <Button
                type="text"
                style={{
                  backgroundColor: "#55a630",
                  borderRadius: "15px",
                  width: "150px",
                }}
                onClick={() => {
                  const errors = [];

                  if (!formData.name) {
                    errors.push("Le nom est vide.");
                  }
                  if (!formData.instructions_title) {
                    errors.push("Le titre des instructions est vide.");
                  }
                  if (!formData.instructions) {
                    errors.push("Les instructions sont vides.");
                  }
                  if (!formData.to_do_title) {
                    errors.push("Le titre des tâches à faire est vide.");
                  }
                  if (!formData.to_do) {
                    errors.push("Les tâches à faire sont vides.");
                  }

                  if (errors.length > 0) {
                    errors.forEach((error) => {
                      message.error(error);
                    });
                  } else {
                    handleSend(formData);
                    handleState(true);
                    setState(true);
                  }
                }}
              >
                {recommendation?.id_protocol ? `Modifier` : `Enregistrer`}
              </Button>
            )}
            {/* {recommendation?.id_recommendation && (
              <div style={{ position: "absolute", bottom: 30, right: 50 }}>
                <Space>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Button
                      type="text"
                      style={{
                        backgroundColor: "#55a630",
                        borderRadius: "15px",
                        width: "150px",
                      }}
                      onClick={handleSave}
                    >
                      Enregistrer
                    </Button>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      width: "150px",
                      height: "100%",
                    }}
                  >
                    <Button
                      type="text"
                      style={{
                        backgroundColor: "#C80036",
                        borderRadius: "15px",
                        width: "100%",
                      }}
                      onClick={handleDelete}
                    >
                      Supprimer
                    </Button>
                  </div>
                </Space>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    type="text"
                    style={{
                      backgroundColor: "#FCDC94",
                      borderRadius: "15px",
                      width: "90%",
                    }}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Retour
                  </Button>
                </div>
              </div>
            )} */}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Recommendation;
