import { Row, Col, Card, Button, Form, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  availibilityService,
  userServices,
  appointmentService,
  LocationService,
} from "../utils/URL";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
const { Option } = Select;

const AddBooking = () => {
  const history = useHistory();
  const [services, setServices] = useState([]);
  const [loadingState, setLoadingState] = useState({
    submit: false,
  });
  const { TextArea } = Input;
  const token = Cookies.get("token");
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [user, setUser] = useState({});
  const [availabilityData, setAvailabilityData] = useState("");
  const [filteredAvailabilities, setFilteredAvailabilities] = useState([]);
  const { id_user, id_package } = useParams();
  const [formData, setFormData] = useState({
    messageText: "",
    subjectText: "",
    id_patient: "",
    date: "",
    start_time: "",
    end_time: "",
    id_locale: "",
  });
  const [localData, setLocalData] = useState();

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${LocationService}/getLocationByIdUser`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setLocalData(response.data.location);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const fetchAvailibilities = async () => {
    try {
      const response = await axios.get(
        `${availibilityService}/getAllAvailabilityList`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setAvailabilityData(response.data);
      // Get today's date
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set time to 00:00:00

      // Filter availability data for dates in the future and remove duplicates
      const uniqueFutureDates = Array.from(
        new Set(
          response.data
            .map((item) => new Date(item.date))
            .filter((date) => date >= today)
            .map((date) => date.toISOString().slice(0, 10)) // Convert dates to strings (YYYY-MM-DD)
        )
      );

      // Sort the list of dates chronologically
      uniqueFutureDates.sort();
      setDates(uniqueFutureDates);
    } catch (error) {
      message.error(error.response.data.message || "Erreur interne du serveur");
      console.error("Error creating availability:", error);
    }
  };
  const getUser = async () => {
    try {
      const response = await axios.get(
        `${userServices}/getUserById/${id_user}`
      );
      handleChange("id_patient", response.data.User.Patient.id_patient);
      handleChange("id_package", id_package);
      setUser(response.data.User);
    } catch (error) {
      message.error(error.response.data.message || "Erreur interne du serveur");
      console.error("Error creating availability:", error);
    }
  };
  const createAppointment = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, submit: true }));

      await axios.post(
        `${appointmentService}/createAppointmentSuggsetion`,
        formData,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      message.success("patient créé avec succès");
      history.goBack();
    } catch (error) {
      console.error("Error creating patient:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    }
    setLoadingState((prevState) => ({ ...prevState, submit: false }));

  };
  useEffect(() => {
    fetchAvailibilities();
    getUser();
    fetchData();
  }, []);

  return (
    <>
      <Card style={{ width: "60%", margin: "0 auto" }}>
        <Form layout="vertical" style={{ marginTop: "2%" }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="méthode de livraison"
                rules={[
                  {
                    message: "Veuillez sélectionner une une méthode",
                  },
                ]}
              >
                <Select
                  style={{ height: "40px" }}
                  defaultValue={"email"}
                  //   onChange={(value) =>
                  //     handleChange("before_session", value)
                  //   }
                  //   disabled={!formData.buffer_option}
                >
                  <Select.Option value="email">Email</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="suggérer une journée"
                rules={[
                  {
                    message: "Veuillez sélectionner une journée",
                  },
                ]}
              >
                <Select
                  style={{ height: "40px" }}
                  onChange={(value) => {
                    
                    handleChange("date", value);
                    setSelectedDate(value);
                    const filteredAvail = availabilityData.filter(
                      (item) => item.date === value
                    );
                    handleChange("id_locale", filteredAvail[0]?.id_locale);
                    // .filter((item) => new Date(item.end_time) > new Date()); // Filter availabilities with end times in the future
                    setFilteredAvailabilities(filteredAvail);
                    setServices(filteredAvail[0].Services);
                  }}
                >
                  {dates.map((date, index) => (
                    <Select.Option key={index} value={date}>
                      {date}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="date de réservation la plus rapprochée"
                rules={[
                  {
                    message: "Veuillez sélectionner une heure",
                  },
                ]}
              >
                <Select
                  style={{ height: "40px" }}
                  onChange={(value) => {
                    const [startTime, endTime] = value.split(" - ");
                    handleChange("start_time", startTime);
                    handleChange("end_time", endTime);
                  }}
                >
                  {filteredAvailabilities.map((item, index) => (
                    <Select.Option
                      key={index}
                      value={`${item.start_time} - ${item.end_time}`}
                    >
                      {`${item.start_time} - ${item.end_time}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Emplacement">
                <Input
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "5px",
                  }}
                  value={
                    `${
                      localData?.find(
                        (local) => local?.id === formData?.id_locale
                      )?.title || "Pas d'emplacement "
                    }   -  ${
                      localData?.find(
                        (local) => local?.id === formData?.id_locale
                      )?.bigtitle || ""
                    }` || ""
                  }
                  readOnly
                />
              </Form.Item>
              <Col span={24}>
                <Form.Item label="Services">
                  <Select
                    defaultValue={
                      `${
                        services?.find(
                          (service) =>
                            service?.id_service === formData?.id_service
                        )?.name || "Pas de service "
                      }   -  ${
                        services?.find(
                          (service) =>
                            service?.id_service === formData?.id_service
                        )?.type || ""
                      }` || ""
                    }
                    onChange={(value) => handleChange("id_service", value)}
                    className="custom-timezone-select"
                    style={{ width: "100%" }}
                  >
                    {services?.map((service) => (
                      <Option
                        key={service.id_service}
                        value={service.id_service}
                      >
                        {`${service.name} (${
                          service.type === "public" ? "publique" : "privée"
                        })`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Col>
          </Row>
        </Form>
      </Card>
      <br />
      <Card style={{ width: "60%", margin: "0 auto" }}>
        <Form layout="vertical" style={{ marginTop: "2%" }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Sujet du courriel "
                rules={[
                  {
                    message: "Veuillez inserer un sujet du courriel",
                  },
                ]}
              >
                <Input
                  style={{ height: "40px" }}
                  placeholder="Veuillez inserer un sujet du courriel"
                  onChange={(value) => {
                    handleChange("subjectText", value.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Message d'email"
                rules={[
                  {
                    message: "Veuillez sélectionner un message d'email",
                  },
                ]}
              >
                <TextArea
                  placeholder="Veuillez sélectionner un message d'email"
                  rows={4}
                  onChange={(e) => {
                    handleChange("messageText", e.target.value);
                  }}
                />
              </Form.Item>
              <div style={{ width: "100%", textAlign: "center" }}>
                <Button
                  loading={loadingState.submit}
                  type="text"
                  style={{ backgroundColor: "#bff6fc" }}
                  onClick={() => {
                    createAppointment();
                  }}
                >
                  Envoyer
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
};

export default AddBooking;