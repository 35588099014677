import {
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Input,
  message,
  Space,
  List,
  Select,
} from "antd";
import { FaLock } from "react-icons/fa";
import { GiEarthAmerica } from "react-icons/gi";

import { appointmentService, serviceService } from "../utils/URL";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import jwt from "jsonwebtoken";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useChat } from "../components/layout/ChatContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import rearange from "../assets/images/rearange.png"; // Import the logo image

const getInitials = (name) => {
  const [firstName, lastName] = name.split(" ");
  const initials = firstName.charAt(0) + lastName.charAt(0);
  return initials.toUpperCase();
};

const MyServices = () => {
  const { service, setService, profile, setProfile } = useChat();

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [patientData, setPatientData] = useState({});
  const [services, setServices] = useState([]);
  const [patientOriginalData, setPatientOriginalData] = useState({});
  const token = Cookies.get("token");
  const decoded = jwt.decode(token);
  const [id_user, setIdUser] = useState(decoded?.id_user);
  const [patients, setPatients] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [modalMode, setModalMode] = useState("create"); // Add modal mode state
  const [serviceData, setServiceData] = useState({});
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [loadingState, setLoadingState] = useState({
    delete: false,
    submit: false,
  });
  const history = useHistory();

  const handleChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const fetchServices = async () => {
    try {
      let response;
      let values;
      if (profile === undefined) {
        response = await axios.get(
          `${serviceService}/getServiceByIdDoctor/${id_user}`
        );
        values = response.data;
      } else {
        response = await axios.get(
          `${serviceService}/getServiceByIdDoctor/${profile}`
        );
        values = response.data.filter((service) => service.type !== "private");
      }
      const sortedServices = values.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      const services = sortedServices.map((service) => ({
        key: service.id_service,
        name: `${service.name} `,
        image: getInitials(`${service.name} `),
        time: moment(service.created_at).format("ddd MMM D @ hh:mm A"),
        price_Service: service.price_Service,
        buffer_time: service.buffer_time,
        type: service.type,
        duration: service.duration,
        actions:
          profile === undefined
            ? [
                {
                  action: "Réarranger",
                  color: "#F08B09",
                  icon: rearange,
                  onclick: (key) => {
                    setModalOpen(true);
                    const data = sortedServices.find(
                      (service) => service.id_service === key
                    );
                    setModalMode("update");
                    setServiceData(data);
                    setFormData(data);
                  },
                },
              ]
            : [
                {
                  // action: "Disponibilité",
                  action: "Emplacements",
                  color: "#F08B09",
                  icon: rearange,
                  onclick: (key) => {
                    const data = sortedServices.find(
                      (service) => service.id_service === key
                    );
                    setService(data.id_service);
                    // history.push(`/Availibilities`);
                    history.push(`/Locations`);
                  },
                },
              ],
      }));

      setServices(services);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };

  const createService = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, submit: true }));
      await axios.post(`${serviceService}/createService`, formData, {
        headers: {
          "auth-token": token,
        },
      });
      setServiceData({});
      await fetchServices();
      message.success("Service créé avec succès");
      setVisible(false);
      setModalOpen(false);

    } catch (error) {
      console.error("Error creating service:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    } finally {
      setConfirmLoading(false);
    }
    setLoadingState((prevState) => ({ ...prevState, submit: false }));

  };
  const updateService = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, submit: true }));

      const id = serviceData.id_service;
      await axios.put(`${serviceService}/updateServiceById/${id}`, formData);
      setServiceData({});
      fetchServices();
      message.success("Service mise à jour avec succès");
      setVisible(false);
      setModalOpen(false);
    } catch (error) {
      console.error("Error updating service:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    } finally {
      setConfirmLoading(false);
    }
    setLoadingState((prevState) => ({ ...prevState, submit: false }));

  };
  const DeleteService = async () => {
    try {
      const id = serviceData.id_service;
      await axios.delete(`${serviceService}/deleteService/${id}`);
      setLoadingState((prevState) => ({ ...prevState, delete: true }));
      message.success("Service supprimé avec succès");
      setServiceData({});
      await fetchServices();
      setModalOpen(false);
      setVisibleDelete(false);
    } catch (error) {
      message.error(
        error.response.data.message ||
          "Erreur lors de la suppression du service"
      );
      console.error("Error deleting service:", error);
    }
    setLoadingState((prevState) => ({ ...prevState, delete: false }));

  };

  const handleOk = async () => {
    

    if (modalMode === "create") {
      await createService(formData);
    } else if (modalMode === "update") {
      await updateService();
    }

  };
  const handleCancel = () => {
    setServiceData({});
    setFormData({});
    setModalOpen(false);
  };
  useEffect(() => {
    fetchServices();
  }, [confirmLoading]);
  useEffect(() => {
    handleData(patientData.id_patient);
  }, [patients]);
  const handleData = (patientIds) => {
    const filteredPatients = patients.filter(
      (patient) => patient.id_patient === patientIds
    );

    setPatientOriginalData(selectedPatient);
  };

  const handleDelete = async () => {
    await DeleteService();
  };

  const openModalcreate = () => {
    setModalOpen(true);
    setModalMode("create");
  };

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours > 0 ? `${hours} heure${hours > 1 ? "s" : ""} ` : ""}${
      remainingMinutes > 0
        ? `${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}`
        : ""
    }`;
  };

  return (
    <>
      <div>
        <div className="tabled">
          {profile !== undefined && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {patientData !== undefined && (
                <Button
                  type="text"
                  style={{
                    marginLeft: "100%",
                    marginBottom: "1%",
                    backgroundColor: "#CDFADB",
                  }}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Retour à la page des médecins
                </Button>
              )}
            </div>
          )}
          <Card
            bordered={false}
            className="criclebox tablespace mb-24 "
            extra={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {profile === undefined ? (
                  <Button
                    type="text"
                    style={{ backgroundColor: "#CDFADB" }}
                    onClick={openModalcreate}
                  >
                    Ajouter un Service
                  </Button>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "1000px",
                      padding: "0px",
                      margin: "0px",
                    }}
                  >
                    <h4>Services :</h4>
                  </div>
                )}

                <Modal
                  title={
                    modalMode === "create"
                      ? "Ajouter un service"
                      : "Modifier le service"
                  }
                  centered
                  width={500}
                  visible={modalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  destroyOnClose
                  okText={modalMode === "create" ? "Enregistrer" : "Modifier "}
                  cancelText="Annuler"
                  style={{ marginBottom: "8%" }}
                  afterClose={handleCancel}
                  onValuesChange={handleChange}
                  footer={[
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      key="footer"
                    >
                      {modalMode === "update" ? (
                        <>
                          <Button
                            key="delete"
                            type="danger"
                            // onClick={handleDelete}
                            onClick={() => {
                              setVisibleDelete(true);
                            }}
                          >
                            Supprimer
                          </Button>
                          <Modal
                            title="Confirmer la supprission"
                            visible={visibleDelete}
                            onCancel={() => setVisibleDelete(false)}
                            footer={[
                              <Button
                                key="cancel"
                                onClick={() => setVisibleDelete(false)}
                              >
                                Annuler
                              </Button>,
                              <Button
                              //loader
                              loading={loadingState.delete}
                                key="delete"
                                type="primary"
                                danger
                                onClick={handleDelete}
                              >
                                Supprimer
                              </Button>,
                            ]}
                          >
                            <p>
                              Êtes-vous sûr de vouloir supprimer ce service ?
                            </p>
                          </Modal>
                        </>
                      ) : (
                        <>
                          <p></p>
                        </>
                      )}
                      <div key="right-buttons" style={{ display: "flex" }}>
                        <Button key="cancel" onClick={handleCancel}>
                          Annuler
                        </Button>
                        <Button key="submit" type="primary" onClick={handleOk} 
                        //loader
                        loading={loadingState.submit}
                        >
                          {modalMode === "create" ? "Sauvegarder" : "Modifier"}
                        </Button>
                      </div>
                    </div>,
                  ]}
                >
                  <Row>
                    <Col span={24}>
                      <Form layout="vertical">
                        <Row gutter={[16, 16]}>
                          <Col span={24}>
                            <Form.Item
                              label="Nom du service"
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez saisir le nom du service!",
                                },
                              ]}
                            >
                              <Input
                                defaultValue={formData.name}
                                onChange={(e) => {
                                  handleChange("name", e.target.value);
                                }}
                                style={{
                                  width: "100%",
                                  height: "40px",
                                  borderRadius: "5px",
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Type du service"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Veuillez sélectionner le type de ce service!",
                                },
                              ]}
                            >
                              <Select
                                defaultValue={formData.type || ""}
                                onChange={(e) => {
                                  handleChange("type", e);
                                }}
                                placeholder="Sélectionner un type"
                                style={{ fontWeight: "lighter" }}
                                options={[
                                  {
                                    value: "public",
                                    label: "Publique",
                                  },
                                  {
                                    value: "private",
                                    label: "Privé",
                                  },
                                ]}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Tanpon"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Veuillez sélectionner la durée du tampon!",
                                },
                              ]}
                            >
                              <Input
                                suffix="| Par minute"
                                defaultValue={formData.buffer_time || ""}
                                onSelect={(e) => {
                                  handleChange("buffer_time", e.target.value);
                                }}
                                style={{
                                  width: "100%",
                                  height: "40px",
                                  borderRadius: "5px",
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                          <Col span={12}>
                            <Form.Item
                              label="Durée"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Veuillez sélectionner la durée de la séance!",
                                },
                              ]}
                            >
                              <Input
                                suffix="| Par minute"
                                defaultValue={formData.duration || ""}
                                onSelect={(e) => {
                                  handleChange("duration", e.target.value);
                                }}
                                style={{
                                  width: "100%",
                                  height: "40px",
                                  borderRadius: "5px",
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Prix"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Veuillez sélectionner le prix de la séance!",
                                },
                              ]}
                            >
                              <Input
                                prefix=" $"
                                suffix="| Par séance"
                                defaultValue={formData.price_Service || ""}
                                onSelect={(e) =>
                                  handleChange("price_Service", e.target.value)
                                }
                                style={{
                                  width: "100%",
                                  height: "40px",
                                  borderRadius: "5px",
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Modal>
              </div>
            }
          >
            <div className="table-responsive">
              <List
                dataSource={services}
                renderItem={(item, index) => (
                  <List.Item
                    key={`${item.key}_${index}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid #f0f0f0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        width: "500px",
                        marginLeft: "3%",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            lineHeight: "33px",
                          }}
                        >
                          {item?.name}
                        </p>{" "}
                        {profile === undefined && (
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "15px",
                              lineHeight: "22px",
                            }}
                          >
                            {item?.time}
                          </p>
                        )}
                        <p
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "grey",
                          }}
                        >
                          {formatDuration(item?.duration)} @ ${" "}
                          {item?.price_Service?.toFixed(2)}
                        </p>
                      </div>
                    </div>
                    {profile === undefined && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "start",
                          width: "500px",
                          marginLeft: "3%",
                        }}
                      >
                        <div>
                          <h5>Tampon</h5>
                          <p
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              color: "grey",
                            }}
                          >
                            {item.buffer_time ? item.buffer_time : 0}{" "}
                            {" Minutes "}
                          </p>
                        </div>
                      </div>
                    )}
                    <Space direction="vertical">
                      <div
                        style={{
                          fontWeight: "bold",
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          position: "absolute",
                          right: "3%",
                        }}
                      >
                        {item.type === "public" ? (
                          <GiEarthAmerica style={{ marginRight: "8px" }} />
                        ) : (
                          <FaLock style={{ marginRight: "8px" }} />
                        )}
                        {item.type}
                      </div>
                      <div style={{ display: "flex", marginLeft: "10px" }}>
                        {item.actions.map((action, index) => (
                          <div
                            key={index}
                            style={{
                              fontWeight: "bold",
                              background: "none",
                              border: "none",
                              textDecoration: "underline",
                              cursor: "pointer",
                              marginLeft: "15px",
                              marginRight: "30px",
                              marginTop: "40%",
                              position: "relative",
                              right: "3%",
                              top: "5%",
                              color: action.color || "#F08B09",
                            }}
                            onClick={() => action.onclick(item.key)}
                          >
                            <img
                              src={action.icon}
                              alt={`${action.action} icon`}
                              style={{ marginRight: "8px", height: "16px" }}
                            />
                            {action.action}
                          </div>
                        ))}
                      </div>
                    </Space>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default MyServices;
