import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import logo from "../assets/images/whiteLogo.png"; // Import the logo image
import { Layout, Button, message, Card } from "antd";
import { userServices } from "../utils/URL";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Footer, Header } from "antd/lib/layout/layout";
const { Content } = Layout;

const Verification = () => {
  const [loadings, setLoadings] = useState(false);
  const enterLoading = () => {
    setLoadings(true);
  };

  const { verifivcationToken } = useParams();

  const verify = async () => {
    try {
      enterLoading(1);
      const response = await axios.get(
        `${userServices}/verifyAccount/${verifivcationToken}`
      );
      const token = response.data.token;
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 1); // 24 hours from now
      Cookies.set("token", token, { expires: expirationDate });
      setLoadings(false);

      setTimeout(() => {}, 6000);
      window.location.href = "/dashboard";
    } catch (error) {
      setLoadings(false);
      console.error("Verification failed:", error);
      message.error(error.response.data.message || "Erreur interne du serveur");
    }
  };

  return (
    <>
      <div className="layout-default ant-layout layout-sign-up">
        <Header>
          <div className="header-col header-brand">
            <img
              src={logo}
              alt="Logo"
              style={{ width: "60%", marginTop: "3%" }}
            />
          </div>
        </Header>

        <Content className="p-0">
          <div className="verification-header">
            <div className="content">
              <p class="VerificationTitle">Verification du compte</p>
              <p className="verificationT">
                Nous somme ravis de vous avoir parmi nous. Explorez nos
                services, découvrez de nouvelles opportunités
              </p>
            </div>
          </div>

          <Card
            className="card-signup header-solid h-full ant-card pt-0"
            style={{ with: "500px", height: "345px", borderRadius: "12px" }}
            bordered="false"
          >
            <p className="verificationText">
              Veuillez cliquer sur ce lien ci-dessous pour finaliser la
              vérification de votre compte. Merci!
            </p>
            <Button
              loading={loadings}
              type="primary"
              htmlType="submit"
              style={{ width: "100%", height: "40px", marginBottom: "10%" }}
              onClick={() => {
                verify();
              }}
              class="verification-button"
            >
              Vérifier mon compte
            </Button>
          </Card>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2024 Practicia by <a href="#pablo">Block-T</a>.{" "}
          </p>
        </Footer>
      </div>
    </>
  );
};

export default Verification;
