import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Button,
  Avatar,
  Modal,
  Form,
  Input,
  message,
  Space,
  DatePicker,
  Descriptions,
  Dropdown,
  Menu,
  List,
  Checkbox,
  ConfigProvider,
} from "antd";
import folder from "../assets/images/folder.png"; // Import the logo image

import { userServices, noteService, activityService } from "../utils/URL";
import { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import frFR from "antd/lib/locale/fr_FR";
import {
  CloseOutlined,
  ContainerOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  MoreOutlined,
  PlusOutlined,
  ProfileOutlined,
  ReloadOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";

import Cookies from "js-cookie";
import PhoneInput from "react-phone-number-input";
import moment from "moment";
import iconRecherche from "../assets/images/iconRecherche.jpg";
const { RangePicker } = DatePicker;

const MyClients = () => {
  const [visible, setVisible] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [infoDisplay, setInfoDisplay] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    legal_first_name: "",
    date_of_birth: "",
    mobile_phone: "",
    home_phone: "",
  });
  const [patientData, setPatientData] = useState({});
  const [patientOriginalData, setPatientOriginalData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const token = Cookies.get("token");
  const [modalHubVisible, setModalHubVisible] = useState(false);

  // const secretKey = "your_session_secret";
  const history = useHistory();

  const [patients, setPatients] = useState([]);
  const [filter, setFilter] = useState("all");
  const [invitedPatientsLength, setInvitedPatientsLength] = useState(0);

  const [isActivityListVisible, setActivityListVisible] = useState(false);
  const [isFormListVisible, setFormListVisible] = useState(false);
  const [isProtocoleListVisible, setProtocoleListVisible] = useState(false);
  const [isNoteListVisible, setNoteListVisible] = useState(false);

  const [data, setData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(7);
  const [reloadStatus, setReloadStatus] = useState(false);

  const [filteredPatientsData, setFilteredPatientsData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    validation_status: "",
    blocked: "",
    legal_first_name: "",
    startDate: "",
    endDate: "",
    startInscriptionDate: "",
    endInscriptionDate: "",
    home_phone: "",
    mobile_phone: "",
    title: "",
    professional_title: "",
    designation_letters: "",
    bioagraphy: "",
    accreditations: "",
    social_networks: "",
    Naissance: "",
    Inscription: "",
  });
  const [searchPhrase, setSearchPhrase] = useState("");
  const plainOptions = [
    { label: "Compte vérifié", value: "true" },
    { label: "Compte non vérifié", value: "false" },
  ];
  const plainOptions2 = [
    { label: "Compte bloqué", value: "true" },
    { label: "Compte non bloqué", value: "false" },
  ];
  const gradientStyle = {
    background: "linear-gradient(to bottom, black 0%, black 100%)",
    color: "black",
    width: "11px",
    height: "100%",
    display: "inline-block",
    margin: "0 5px",
    borderRadius: "20px",
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Rechercheform] = Form.useForm();
  const showSearchModal = () => {
    setIsModalOpen(true);
  };
  const handleSearchOk = () => {
    setIsModalOpen(false);
  };
  const generateData = async () => {
    try {
      const response = await axios.get(
        `${activityService}/getActivityByUserId/${patientData.id_user}`
      );
      // Sort the response data by date in descending order
      const sortedData = response.data.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      setCurrentIndex(7);
      setData(sortedData);
      setDisplayedData(sortedData.slice(0, 7));
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const generateNoteData = async () => {
    try {
      const response = await axios.get(
        `${noteService}/getAllPatientNotes/${patientData.id_patient}`
      );
      // Sort the response data by date in descending order
      const sortedData = response.data.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      setCurrentIndex(7);
      setData(sortedData);
      setDisplayedData(sortedData.slice(0, 7));
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const loadMore = () => {
    const newData = data.slice(currentIndex, currentIndex + 7);

    setDisplayedData([...displayedData, ...newData]);

    setCurrentIndex(currentIndex + 7);
  };

  const handleRecentActivityClick = () => {
    generateData();
    setActivityListVisible(true);
  };
  const handleFromClick = () => {
    generateData();
    setFormListVisible(true);
  };
  const handleProtocoleClick = () => {
    generateData();
    setProtocoleListVisible(true);
  };
  const handleNoteClick = () => {
    generateNoteData();
    setNoteListVisible(true);
  };
  const handleShowInformations = () => {
    setModalHubVisible(false);
    handleRowClick(patientData.id_user);
  };

  const handleActivityListClose = () => {
    setData([]);
    setDisplayedData([]);
    setCurrentIndex(7);
    setActivityListVisible(false);
  };
  const handleFormListClose = () => {
    setData([]);
    setDisplayedData([]);
    setCurrentIndex(7);
    setFormListVisible(false);
  };
  const handleProtocoleListClose = () => {
    setData([]);
    setDisplayedData([]);
    setCurrentIndex(7);
    setProtocoleListVisible(false);
  };

  const handleNoteListClose = () => {
    setData([]);
    setDisplayedData([]);
    setCurrentIndex(7);
    setNoteListVisible(false);
  };

  const handleChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };
  const handleRowClick = (record) => {
    handleData(record.key);
    setInfoDisplay(true);
    handleDatToDelete(record);
  };

  const fetchPatients = async () => {
    try {
      const response = await axios.get(
        `${userServices}/searchUsers/patient?legal_first_name=${filteredPatientsData.legal_first_name}&blocked=${filteredPatientsData.blocked}&validation_status=${filteredPatientsData.validation_status}&last_name=${filteredPatientsData.last_name}&first_name=${filteredPatientsData.first_name}&email=${filteredPatientsData.email}&mobile_phone=${filteredPatientsData.mobile_phone}&home_phone=${filteredPatientsData.home_phone}&startDate=${filteredPatientsData.startDate}&endDate=${filteredPatientsData.endDate}&startInscriptionDate=${filteredPatientsData.startInscriptionDate}&endInscriptionDate=${filteredPatientsData.endInscriptionDate}&title=${filteredPatientsData.title}&professional_title=${filteredPatientsData.professional_title}&designation_letters=${filteredPatientsData.designation_letters}&bioagraphy=${filteredPatientsData.bioagraphy}`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      if (filter === "invited") {
        const sortedPatients = response.data.asignedpatients.sort((a, b) =>
          `${a.user.first_name} ${a.user.last_name}`.localeCompare(
            `${b.user.first_name} ${b.user.last_name}`
          )
        );
        setPatients(sortedPatients);
        setInvitedPatientsLength(sortedPatients.length);
      } else if (filter === "not-invited") {
        const sortedPatients = response.data.unassignedPatients.sort((a, b) =>
          `${a.user.first_name} ${a.user.last_name}`.localeCompare(
            `${b.user.first_name} ${b.user.last_name}`
          )
        );
        setPatients(sortedPatients);
        setInvitedPatientsLength(sortedPatients.length);
      } else {
        const sortedPatients = response.data.patients.sort((a, b) =>
          `${a.user.first_name} ${a.user.last_name}`.localeCompare(
            `${b.user.first_name} ${b.user.last_name}`
          )
        );
        setPatients(sortedPatients);
        setInvitedPatientsLength(sortedPatients.length);
      }
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };
  const showModal = () => {
    setFormData({
      email: "",
      first_name: "",
      last_name: "",
      legal_first_name: "",
      date_of_birth: "",
      mobile_phone: "",
      home_phone: "",
    });
    setVisible(true);
  };
  const handleInfo = (patientIds) => {
    const filteredPatients = patients.filter(
      (patient) => patient.id_patient === patientIds
    );

    if (filteredPatients.length > 0) {
      const selectedPatient = filteredPatients[0];
      setPatientData(selectedPatient);
    }
  };
  const assignPatientToDoctors = async () => {
    try {
      const patientIds = patientData?.id_patient;
      await axios.post(
        `${userServices}/assignPatientToDoctors`,
        { patientIds },
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      await fetchPatients();
      handleInfo(patientIds);
      message.success("Patient invité avec suces");
    } catch (error) {
      console.error("Error creating patient:", error);
      message.error(error.response.data.message || "Erreur interne du serveur");
    }
  };
  const unassignPatientFromDoctor = async () => {
    try {
      const patientIds = patientData?.id_patient;
      await axios.post(
        `${userServices}/unassignPatientFromDoctor`,
        { patientIds },
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      await fetchPatients();
      handleInfo(patientIds);
      message.success("Patient exclue avec suces");
    } catch (error) {
      console.error("Error creating patient:", error);
      message.error(error.response.data.message || "Erreur interne du serveur");
    }
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    try {
      // Make API call to create the patient
      await axios.post(`${userServices}/createPatient`, formData);
      setFormData({
        email: "",
        first_name: "",
        last_name: "",
        legal_first_name: "",
        date_of_birth: "",
        mobile_phone: "",
        home_phone: "",
      });
      message.success("patient created successfully");
      setVisible(false);
    } catch (error) {
      console.error("Error creating patient:", error);
      message.error(error.response.data.message || "Erreur interne du serveur");
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleSubmit = (values) => {
    setFormData(values);
  };

  const handleCancel = () => {
    setFormData({
      email: "",
      first_name: "",
      last_name: "",
    });
    setVisible(false);
    // handleInfo(patientData?.id_patient);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };
  const handleData = (patientIds) => {
    const filteredPatients = patients.filter(
      (patient) => patient.id_patient === patientIds
    );

    if (filteredPatients.length > 0) {
      const selectedPatient = filteredPatients[0];
      setPatientOriginalData(selectedPatient);
    }
  };
  const handleDatToDelete = (record) => {
    // Filtering patients based on a condition
    const filteredPatients = patients.filter(
      (patient) => patient.id_patient === record.key
    );

    if (filteredPatients.length > 0) {
      const selectedPatient = filteredPatients[0];
      setPatientData(selectedPatient);
      // setPatientOriginalData(selectedPatient);
    }
  };
  const getInitials = (name) => {
    const [firstName, lastName] = name.split(" ");
    const initials = firstName.charAt(0) + lastName.charAt(0);
    return initials.toUpperCase();
  };

  useEffect(() => {
    fetchPatients();
  }, [confirmLoading, filter]);

  useEffect(() => {
    handleData(patientData.id_patient);
  }, [patients]);
  useEffect(() => {
    let params = filteredPatientsData;
    if (filteredPatientsData.validation_status.length === 1) {
      if (filteredPatientsData.validation_status[0] === "true") {
        params = {
          ...params,
          validation_status: "vérifié",
        };
      } else if (filteredPatientsData.validation_status[0] === "false") {
        params = {
          ...params,
          validation_status: "non vérifié",
        };
      }
    } else if (filteredPatientsData.validation_status.length === 0) {
      params = {
        ...params,
        validation_status: "",
      };
    } else {
      params = {
        ...params,
        validation_status: "vérifié et non vérifié",
      };
    }

    if (filteredPatientsData.blocked.length === 1) {
      if (filteredPatientsData.blocked[0] === "true") {
        params = {
          ...params,
          blocked: "bloqué",
        };
      } else if (filteredPatientsData.blocked[0] === "false") {
        params = {
          ...params,
          blocked: "non bloqué",
        };
      }
    } else if (filteredPatientsData.blocked.length === 0) {
      params = {
        ...params,
        blocked: "",
      };
    } else {
      params = {
        ...params,
        blocked: "bloqué et non bloqué",
      };
    }

    if (filteredPatientsData.startDate && filteredPatientsData.endDate) {
      params = {
        ...params,
        Naissance: `${filteredPatientsData.startDate} : ${filteredPatientsData.endDate}`,
        startDate: "",
        endDate: "",
      };
    }
    if (
      filteredPatientsData.startInscriptionDate &&
      filteredPatientsData.endInscriptionDate
    ) {
      params = {
        ...params,
        Inscription: `${filteredPatientsData.startInscriptionDate} : ${filteredPatientsData.endInscriptionDate}`,
        startInscriptionDate: "",
        endInscriptionDate: "",
      };
    }

    const fieldTranslations = {
      email: "Email",
      first_name: "Prénom",
      last_name: "Nom",
      validation_status: "Statut de validation",
      blocked: "Statut de blocage",
      legal_first_name: "Prénom légal",
      home_phone: "Téléphone fixe",
      Naissance: "Date de naissance",
      Inscription: "Date d'inscription",
      title: "Titre",
      professional_title: "Titre professionnel",
      designation_letters: "Lettres de désignation",
      bioagraphy: "Biographie",
    };

    const searchParams = Object.entries(params)
      .filter(
        ([key, value]) => value !== "" && value !== null && value !== undefined
      )
      .map(([key, value]) => ({ key, value }));

    setSearchPhrase(
      searchParams.length > 0 && (
        <div style={{ marginBottom: "10px" }}>
          <strong
            style={{ color: "blue", marginRight: "5px", marginLeft: "10px" }}
          >
            Critére de recherche:
          </strong>
          <div
            style={{ display: "flex", flexWrap: "wrap", marginLeft: "15px" }}
          >
            {searchParams.map(({ key, value }, index) => (
              <div key={index} style={{ marginRight: "20px" }}>
                <strong>{fieldTranslations[key]}:</strong> {value}
              </div>
            ))}
          </div>
        </div>
      )
    );
  }, [filteredPatientsData]);
  useEffect(() => {
    setInfoDisplay(false);
    Rechercheform.resetFields();
    setFilteredPatientsData({
      ...filteredPatientsData,
      email: "",
      first_name: "",
      last_name: "",
      validation_status: "",
      blocked: "",
      legal_first_name: "",
      startDate: "",
      endDate: "",
      startInscriptionDate: "",
      endInscriptionDate: "",
      home_phone: "",
      mobile_phone: "",
      title: "",
      professional_title: "",
      designation_letters: "",
      bioagraphy: "",
      accreditations: "",
      social_networks: "",
      Naissance: "",
      Inscription: "",
    });
    setSearchPhrase("");

    fetchPatients();
  }, [confirmLoading, filter, reloadStatus]);
  const handleEdit = (record) => {
    // Filtering patients based on a condition
    const filteredPatients = patients.filter(
      (patient) => patient.id_patient === record.key
    );

    if (filteredPatients.length > 0) {
      const selectedPatient = filteredPatients[0];
      setPatientData(selectedPatient);
      // setPatientOriginalData(selectedPatient);
    }
    setModalVisible(true);
  };

  const onSave = async () => {
    try {
      setLoading(true);
      const id_user = patientData.id_user;
      const updatedData = {
        first_name: patientData.user.first_name,
        last_name: patientData.user.last_name,
        legal_first_name: patientData.legal_first_name,
        home_phone: patientData.home_phone,
        mobile_phone: patientData.mobile_phone,
        date_of_birth: patientData.date_of_birth,
      };
      await axios.put(
        `${userServices}/updatePatient/${id_user}`, // Assuming this is the endpoint to update patient data
        updatedData
      );
      await fetchPatients();
      handleData(patientData.id_patient);
      message.success(
        "Les informations sur le patient ont été mises à jour avec succès"
      );
      setModalVisible(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error updating patient:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    }
  };

  const handleDeleteUser = async () => {
    try {
      if (patientData && patientData.user && patientData.user.id_user) {
        const id = patientData.user.id_user;
        await deleteUserById(id); // Call the function to delete the user by ID
        setVisibleDelete(false); // Close the modal after deletion
      } else {
        console.error("User ID not available");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      message.error(
        error.response?.data?.message || "Erreur internet du serveur"
      );
    }
  };

  const deleteUserById = async (id) => {
    try {
      await axios.delete(`${userServices}/deleteUser/${id}`);
      await fetchPatients();
      setInfoDisplay(false);
      message.success("Patient supprimé avec succès");
    } catch (error) {
      console.error("Error deleting user:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    }
  };
  const onCancel = () => {
    setLoading(false);
    setModalVisible(false);
  };

  const assignToMe = async () => {
    await assignPatientToDoctors();
  };
  const unAssignToMe = async () => {
    await unassignPatientFromDoctor();
  };

  const handleModalHubOpen = (record) => {
    handleData(record.key);
    setInfoDisplay(false);
    setModalHubVisible(true);
    handleDatToDelete(record);
  };
  const handleModalHubClose = (record) => {
    handleData();
    setModalHubVisible(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => assignToMe()}>
        inviter
      </Menu.Item>
      <Menu.Item key="2" onClick={() => unAssignToMe()}>
        exclure
      </Menu.Item>
    </Menu>
  );
  const columns = [
    {
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            className="clickable"
            onClick={(e) => {
              e.stopPropagation(); // Prevent event bubbling to parent div
              const filteredPatients = patients.filter(
                (patient) => patient.id_patient === record.key
              );
              history.push(`/PatientFolder/${filteredPatients[0].id_user}`);
            }}
            style={{ marginRight: "4px" }} // Reduce margin to remove space between image and text
          >
            <img
              src={folder}
              alt="Folder Icon"
              style={{ width: "30px", height: "30px", marginRight: "5px" }}
            />
          </div>
          <div
            className="clickable"
            onClick={(e) => {
              e.stopPropagation(); // Prevent event bubbling to parent div
              handleRowClick(record);
            }}
          >
            {text}
          </div>
        </div>
      ),
    },

    {
      dataIndex: "email",
      key: "email",
    },
    {
      key: "action",
      align: "end",
      render: (text, record) => (
        <>
          <Space>
            <Button type="link" onClick={() => handleEdit(record)}>
              Modifier
            </Button>
            <Avatar
              className="clickable"
              icon={<UserOutlined />}
              onClick={() => {
                handleModalHubOpen(record);
              }}
            />
            <Modal
              title={
                <Space>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        backgroundColor: "lightblue",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "8px",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {getInitials(
                        `${patientData.user?.first_name || ""} ${
                          patientData.user?.last_name || ""
                        }`
                      )}{" "}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <strong>{`${patientData?.user?.first_name || ""} ${
                      patientData?.user?.last_name || ""
                    }`}</strong>
                    <span
                      style={{
                        color: "#B4B4B8",
                        fontSize: "12px",
                        width: "100%",
                      }}
                    >
                      {patientData?.user?.email}
                    </span>
                  </div>
                </Space>
              }
              visible={modalHubVisible}
              onCancel={handleModalHubClose}
              footer={null}
              width={350}
              style={{ top: "30%", left: "34%", borderRadius: "20%" }}
              maskStyle={{ backgroundColor: "transparent" }}
            >
              <Space direction="vertical">
                <Space
                  className="clickable"
                  onClick={handleRecentActivityClick}
                >
                  <div
                    style={{
                      backgroundColor: "#FF6347",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "8px",
                      color: "white",
                      marginBottom: "8px",
                    }}
                  >
                    <ProfileOutlined />
                  </div>{" "}
                  <h2 style={{ color: "black", fontSize: "16px" }}>
                    {" "}
                    Activité récente
                  </h2>
                </Space>
                <Modal
                  title={
                    <p style={{ fontSize: "16px" }}>
                      Activité récente du patient{" "}
                      <strong>
                        {patientData?.user?.first_name}{" "}
                        {patientData?.user?.last_name}
                      </strong>
                    </p>
                  }
                  visible={isActivityListVisible}
                  onCancel={handleActivityListClose}
                  footer={null}
                  maskStyle={{ backgroundColor: "transparent" }}
                  style={{ top: "20%", left: "28%", borderRadius: "20%" }}
                  width={600}
                >
                  <div
                    id="scrollableDiv"
                    style={{
                      height: 570,
                      overflow: "auto",
                      padding: "0 16px",
                      border: "none",
                    }}
                    className="scrollable-list"
                  >
                    <List
                      bordered={false}
                      style={{ border: "none" }}
                      dataSource={displayedData}
                      renderItem={(item, index) => (
                        <List.Item
                          key={item.id_activity}
                          style={{
                            backgroundColor: getColorByType(item.type),
                            marginBottom:
                              index !== displayedData.length - 1 ? "10px" : 0,
                            padding: "10px", // Add padding inside the rectangular items
                            borderRadius: "5px", // Add border radius for rounded corners
                          }}
                        >
                          <List.Item.Meta
                            title={
                              <span
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.name}
                              </span>
                            }
                            description={
                              // <div style={{ marginRight: "20px" }}>
                              //   {item.description}
                              // </div>
                              <div>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.description,
                                  }}
                                ></p>
                              </div>
                            }
                          />
                          <div>
                            {new Date(item.created_at).toLocaleString()}
                          </div>
                        </List.Item>
                      )}
                    />

                    {data.length > currentIndex && (
                      <div style={{ textAlign: "center", marginTop: "3%" }}>
                        <p
                          className="clickable"
                          onClick={loadMore}
                          style={{ color: "#738085" }}
                        >
                          Charger plus
                        </p>
                      </div>
                    )}
                  </div>
                </Modal>
                <Modal
                  title={
                    <p style={{ fontSize: "16px" }}>
                      Formulaires du patient{" "}
                      <strong>
                        {patientData?.user?.first_name}{" "}
                        {patientData?.user?.last_name}
                      </strong>
                    </p>
                  }
                  visible={isFormListVisible}
                  onCancel={handleFormListClose}
                  footer={null}
                  maskStyle={{ backgroundColor: "transparent" }}
                  style={{ top: "20%", left: "28%", borderRadius: "20%" }}
                  width={600}
                >
                  <div
                    id="scrollableDiv"
                    style={{
                      height: 570,
                      overflow: "auto",
                      padding: "0 16px",
                      border: "none",
                    }}
                    className="scrollable-list"
                  >
                    <List
                      bordered={false}
                      style={{ border: "none" }}
                      dataSource={displayedData.filter(
                        (item) => item.type === "form"
                      )}
                      renderItem={(item, index) => (
                        <List.Item
                          key={item.id_activity}
                          style={{
                            backgroundColor:
                              item.type === "note"
                                ? "#f5ebf2"
                                : item.type === "appointment"
                                ? "#f2f5eb"
                                : "#ebf5f2",
                            marginBottom:
                              index !==
                              displayedData.filter(
                                (item) => item.type === "form"
                              ).length -
                                1
                                ? "10px"
                                : 0,
                            padding: "10px", // Add padding inside the rectangular items
                            borderRadius: "5px", // Add border radius for rounded corners
                          }}
                        >
                          <List.Item.Meta
                            title={
                              <span
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.name}
                              </span>
                            }
                            description={
                              <div>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.description,
                                  }}
                                ></p>
                              </div>
                            }
                          />
                          <div>
                            {new Date(item.created_at).toLocaleString()}
                          </div>
                        </List.Item>
                      )}
                    />

                    {data.length > currentIndex && (
                      <div style={{ textAlign: "center", marginTop: "3%" }}>
                        <p
                          className="clickable"
                          onClick={loadMore}
                          style={{ color: "#738085" }}
                        >
                          Charger plus
                        </p>
                      </div>
                    )}
                  </div>
                </Modal>
                <Modal
                  title={
                    <p style={{ fontSize: "16px" }}>
                      Protocoles du patient{" "}
                      <strong>
                        {patientData?.user?.first_name}{" "}
                        {patientData?.user?.last_name}
                      </strong>
                    </p>
                  }
                  visible={isProtocoleListVisible}
                  onCancel={handleProtocoleListClose}
                  footer={null}
                  maskStyle={{ backgroundColor: "transparent" }}
                  style={{ top: "20%", left: "28%", borderRadius: "20%" }}
                  width={600}
                >
                  <div
                    id="scrollableDiv"
                    style={{
                      height: 570,
                      overflow: "auto",
                      padding: "0 16px",
                      border: "none",
                    }}
                    className="scrollable-list"
                  >
                    <List
                      bordered={false}
                      style={{ border: "none" }}
                      dataSource={displayedData.filter(
                        (item) => item.type === "protocole"
                      )}
                      renderItem={(item, index) => (
                        <List.Item
                          key={item.id_activity}
                          style={{
                            backgroundColor:
                              item.type === "note"
                                ? "#f5ebf2"
                                : item.type === "appointment"
                                ? "#f2f5eb"
                                : item.type === "protocole"
                                ? "#DFF5FF"
                                : "#ebf5f2",
                            marginBottom:
                              index !==
                              displayedData.filter(
                                (item) => item.type === "form"
                              ).length -
                                1
                                ? "10px"
                                : 0,
                            padding: "10px", // Add padding inside the rectangular items
                            borderRadius: "5px", // Add border radius for rounded corners
                          }}
                        >
                          <List.Item.Meta
                            title={
                              <span
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.name}
                              </span>
                            }
                            description={
                              <div>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.description,
                                  }}
                                ></p>
                              </div>
                            }
                          />
                          <div>
                            {new Date(item.created_at).toLocaleString()}
                          </div>
                        </List.Item>
                      )}
                    />

                    {data.length > currentIndex && (
                      <div style={{ textAlign: "center", marginTop: "3%" }}>
                        <p
                          className="clickable"
                          onClick={loadMore}
                          style={{ color: "#738085" }}
                        >
                          Charger plus
                        </p>
                      </div>
                    )}
                  </div>
                </Modal>

                <Modal
                  title={
                    <p style={{ fontSize: "16px" }}>
                      Notes du patient{" "}
                      <strong>
                        {patientData?.user?.first_name}{" "}
                        {patientData?.user?.last_name}
                      </strong>
                    </p>
                  }
                  visible={isNoteListVisible}
                  onCancel={handleNoteListClose}
                  footer={null}
                  maskStyle={{ backgroundColor: "transparent" }}
                  style={{ top: "20%", left: "28%", borderRadius: "20%" }}
                  width={600}
                >
                  <div
                    id="scrollableDiv"
                    style={{
                      height: 570,
                      overflow: "auto",
                      padding: "0 16px",
                      border: "none",
                    }}
                    className="scrollable-list"
                  >
                    <List
                      bordered={false}
                      style={{ padding: 0 }}
                      dataSource={displayedData}
                      renderItem={(item, index) => (
                        <List.Item
                          key={item.id_note}
                          style={{
                            backgroundColor: "#f2f5eb",
                            marginBottom:
                              index !== displayedData.length - 1 ? "10px" : 0,
                            padding: "10px", // Add padding inside the rectangular items
                            borderRadius: "5px", // Add border radius for rounded corners
                          }}
                        >
                          <List.Item.Meta
                            title={
                              <span
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                  marginBottom: "3%",
                                }}
                              >
                                {item.title}
                              </span>
                            }
                            description={
                              <div style={{ marginRight: "20px" }}>
                                {item.content}
                              </div>
                            }
                          />
                          <div>
                            {new Date(item.created_at).toLocaleString()}
                          </div>
                        </List.Item>
                      )}
                    />

                    {data.length > currentIndex && (
                      <div style={{ textAlign: "center", marginTop: "3%" }}>
                        <p
                          className="clickable"
                          onClick={loadMore}
                          style={{ color: "#738085" }}
                        >
                          Charger plus
                        </p>
                      </div>
                    )}
                  </div>
                </Modal>

                <Space className="clickable" onClick={handleShowInformations}>
                  <div
                    style={{
                      backgroundColor: "#4682B4",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "8px",
                      color: "white",
                      marginBottom: "8px",
                    }}
                  >
                    <UserOutlined />
                  </div>{" "}
                  <h2 style={{ color: "black", fontSize: "16px" }}>
                    {" "}
                    Informations de base{" "}
                  </h2>
                </Space>
                <Space className="clickable" onClick={handleNoteClick}>
                  <div
                    style={{
                      backgroundColor: "#32CD32",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "8px",
                      color: "white",
                      marginBottom: "8px",
                    }}
                  >
                    <ContainerOutlined />
                  </div>{" "}
                  <h2 style={{ color: "black", fontSize: "16px" }}> Notes </h2>
                </Space>
                <Space className="clickable" onClick={handleProtocoleClick}>
                  <div
                    style={{
                      backgroundColor: "#FFA500",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "8px",
                      color: "white",
                      marginBottom: "8px",
                    }}
                  >
                    <FileProtectOutlined />
                  </div>{" "}
                  <h2 style={{ color: "black", fontSize: "16px" }}>
                    {" "}
                    Protocoles{" "}
                  </h2>
                </Space>
                <Space className="clickable" onClick={handleFromClick}>
                  <div
                    style={{
                      backgroundColor: "#9932CC",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "8px",
                      color: "white",
                      marginBottom: "8px",
                    }}
                  >
                    <FileTextOutlined />
                  </div>{" "}
                  <h2 style={{ color: "black", fontSize: "16px" }}>
                    {" "}
                    Formulaires
                  </h2>
                </Space>
              </Space>
            </Modal>{" "}
            <Dropdown overlay={menu}>
              <a
                onClick={(e) => {
                  handleInfo(record.key);
                  e.preventDefault();
                }}
                onMouseEnter={() => handleInfo(record.key)}
              >
                <MoreOutlined style={{ marginLeft: "12px" }} />
              </a>
            </Dropdown>
          </Space>
        </>
      ),
    },
  ];

  const filterPatients = (patients) => {
    if (filter === "invited") {
      return patients;
    } else if (filter === "not-invited") {
      return patients;
    } else {
      return patients;
    }
  };

  const getColorByType = (type) => {
    switch (type) {
      case "user":
        return "#f5ebf2"; // Light purple
      case "appointment":
        return "#f2f5eb"; // Light green
      case "note":
        return "#ebf5f2"; // Light blue
      case "invoice":
        return "#f2ebf5"; // Light pink
      case "form":
        return "#f5f2eb"; // Light yellow
      case "protocole":
        return "#DFF5FF"; // Light yellow
      default:
        return "#ffffff"; // Default to white
    }
  };
  return (
    <>
      <div
        className="setting-drwer"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#1890ff",
        }}
        onClick={showModal}
      >
        <PlusOutlined />
      </div>
      <Modal
        width={700}
        title="Ajouter un patient"
        visible={visible}
        onOk={handleOk}
        okText="Ajouter" // Change "Ok" to "Ajouter"
        cancelText="Annuler" // Change "Cancel" to "Annuler"
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Form layout="vertical" onFinish={handleSubmit}>
          <Row>
            <Col span={12}>
                   {/* Nom */}
                   <Form.Item
                label="Nom"
                // name="first_name"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir le nom",
                  },
                ]}
              >
                <Input
                  style={{ width: "95%", height: "40px" }}
                  value={formData.first_name}
                  placeholder="Entrez le nom"
                  onChange={(e) => {
                    handleChange("first_name", e.target.value);
                  }}
                />
              </Form.Item>
              {/* Nom légal */}
              <Form.Item
                label="Nom légal"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir le nom légal",
                  },
                ]}
              >
                <Input
                  style={{ width: "95%", height: "40px" }}
                  value={formData.legal_first_name}
                  placeholder="Entrez le nom légal"
                  onChange={(e) => {
                    handleChange("legal_first_name", e.target.value);
                  }}
                />
              </Form.Item>
         
            </Col>
            <Col span={12}>
              {/* Prenom */}
              <Form.Item
                label="Prénom"
                // name="last_name"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir le prénom",
                  },
                ]}
              >
                <Input
                  style={{ height: "40px" }}
                  value={formData.last_name}
                  placeholder="Entrez le prénom"
                  onChange={(e) => {
                    handleChange("last_name", e.target.value);
                  }}
                />
              </Form.Item>

              {/* Date de naissance */}
              <Form.Item label="Date de naissance">
                <DatePicker
                  style={{ width: "100%", height: "40px", borderRadius: "5px" }}
                  value={formData.date_of_birth}
                  onChange={(date) => {
                    handleChange("date_of_birth", date);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* email */}
          <Form.Item
            label="Email"
            // name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Veuillez entrer un email valide",
              },
            ]}
          >
            <Input
              style={{
                height: "40px",
              }}
              value={formData.email}
              placeholder="Entrez un e-mail valide"
              onChange={(e) => {
                handleChange("email", e.target.value);
              }}
            />
        
          </Form.Item>

          <Row>
            <Col span={12}>
              {" "}
              {/* Home Phone */}
              <Form.Item
                label="Téléphone fixe"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir le téléphone fixe",
                  },
                ]}
              >
                <PhoneInput
                  style={{ width: "95%" }}
                  value={formData.home_phone}
                  placeholder="Entrez le téléphone fixe"
                  onChange={(e) => {
                    handleChange("home_phone", e);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {" "}
              {/* Mobile Phone */}
              <Form.Item
                label="Téléphone portable"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir le téléphone portable",
                  },
                ]}
              >
                <PhoneInput
                  style={{ width: "100%" }}
                  value={formData.mobile_phone}
                  placeholder="Entrez le téléphone portable"
                  onChange={(e) => {
                    handleChange("mobile_phone", e);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {infoDisplay ? (
        <>
          <Row>
            <Col style={{ paddingRight: "1%" }} span={18}>
              <div className="tabled">
                <Row gutter={[24, 0]}>
                  <Col xs="24" xl={24}>
                    <div className="formCard">
                      <Card
                        bordered={false}
                        className="criclebox tablespace mb-24"
                        title={`Liste des patients (${invitedPatientsLength})`}
                        extra={
                          <Radio.Group
                            defaultValue={filter}
                            onChange={handleFilterChange}
                          >
                            <Radio.Button value="all">Tout</Radio.Button>
                            <Radio.Button value="invited">Invité</Radio.Button>
                            <Radio.Button value="not-invited">
                              Non invité
                            </Radio.Button>
                          </Radio.Group>
                        }
                      >
                        <div className="table-responsive">
                          <Table
                            columns={columns}
                            dataSource={filterPatients(patients).map(
                              (patient) => ({
                                key: patient.id_patient,
                                name: `${patient.user.first_name} ${patient.user.last_name}`,
                                email: patient.user.email,
                              })
                            )}
                            // onRow={(record) => ({
                            //   onClick: () => handleRowClick(record),
                            // })}
                            pagination={false}
                            className="ant-border-space"
                          />
                        </div>
                      </Card>{" "}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={6}>
              <Card style={{ height: "750px", position: "relative" }}>
                <CloseOutlined
                  style={{
                    position: "absolute",
                    top: 3,
                    right: 5,
                    fontSize: "17px",
                    // color: "red",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setInfoDisplay(false);
                  }}
                />
                <div
                  style={{
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                  }}
                >
                  <h5 style={{ color: "gray" }}>
                    {" "}
                    {patientOriginalData &&
                    patientOriginalData.Doctors &&
                    patientOriginalData.Doctors.length > 0 ? (
                      <p>Invité </p>
                    ) : (
                      <p>Non invité </p>
                    )}
                  </h5>

                  <h3>
                    {patientOriginalData?.user?.first_name}{" "}
                    {patientOriginalData?.user?.last_name}
                  </h3>
                </div>

                <div
                  style={{
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                  }}
                >
                  <h4 className="greyText">Activité récente</h4>
                  <h4
                    className="greyText"
                    onClick={() => setModalVisible(true)}
                  >
                    Modifier les informations de base
                  </h4>
                  <h4 className="greyText">Ouvrir Hub</h4>
                  <Button
                    style={{ color: "red" }}
                    onClick={() => {
                      setVisibleDelete(true);
                      // deleteUserById(patientData?.user?.id_user);
                    }}
                  >
                    Supprimer
                  </Button>
                  <Modal
                    title="Confirm Deletion"
                    visible={visibleDelete}
                    onCancel={() => setVisibleDelete(false)}
                    footer={[
                      <Button
                        key="cancel"
                        onClick={() => setVisibleDelete(false)}
                      >
                        Annuler
                      </Button>,
                      <Button
                        key="delete"
                        type="primary"
                        danger
                        onClick={handleDeleteUser} // Call handleDeleteUser function when the delete button is clicked
                      >
                        Supprimer
                      </Button>,
                    ]}
                  >
                    <p>Êtes-vous sûr de vouloir supprimer ce patient ?</p>
                  </Modal>
                </div>

                <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                  <Descriptions layout="vertical" column={1}>
                    <Descriptions.Item label="Date d'inscription">
                      {patientOriginalData && patientOriginalData?.created_at
                        ? moment(patientOriginalData?.created_at).format(
                            "YYYY-MM-DD"
                          )
                        : null}
                      {}
                    </Descriptions.Item>
                    <Descriptions.Item label="Prénom légal">
                      {patientOriginalData?.legal_first_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Email Adresse">
                      {patientOriginalData?.user?.email}
                    </Descriptions.Item>
                    <Descriptions.Item label="Téléphone fixe">
                      {patientOriginalData?.home_phone}
                    </Descriptions.Item>
                    <Descriptions.Item label="Téléphone mobile">
                      {patientOriginalData?.mobile_phone}
                    </Descriptions.Item>
                    <Descriptions.Item label="Date de naissance">
                      {patientOriginalData && patientOriginalData.date_of_birth
                        ? moment(patientOriginalData.date_of_birth).format(
                            "YYYY-MM-DD"
                          )
                        : null}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <div className="TableCard">
                <Card
                  bordered={false}
                  className="criclebox tablespace mb-24"
                  extra={
                    <div style={{ marginTop: "15px", width: "100%" }}>
                      <Row style={{ width: "100%" }}>
                        <Col xs={24} sm={24} md={14} lg={16} xl={18} xxl={20}>
                          <Col
                            className="hidden-on-small-screen"
                            style={{
                              marginRight: "1%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Input
                              value={filteredPatientsData.first_name}
                              className="inputStyle"
                              placeholder="Entrez le nom"
                              min={1}
                              max={10}
                              style={{ marginRight: "8px" }}
                              onChange={(e) => {
                                const enteredValue = e.target.value.trim();
                                const sanitizedValue = enteredValue || "";
                                setFilteredPatientsData({
                                  ...filteredPatientsData,
                                  first_name: sanitizedValue,
                                });
                                // setSearchData({
                                //   ...searchData,
                                //   Nom_entreprise: sanitizedValue,
                                // });
                              }}
                            />
                            <Input
                              value={filteredPatientsData.last_name}
                              className="inputStyle"
                              placeholder="Entrer le prenom"
                              min={1}
                              max={10}
                              style={{ marginRight: "8px" }}
                              onChange={(e) => {
                                const enteredValue = e.target.value.trim();
                                const sanitizedValue = enteredValue || "";
                                setFilteredPatientsData({
                                  ...filteredPatientsData,
                                  last_name: sanitizedValue,
                                }); // setSearchData({
                                //   ...searchData,
                                //   Ville: sanitizedValue,
                                // });
                              }}
                            />

                            <Input
                              value={filteredPatientsData.email}
                              className="inputStyle"
                              placeholder="Entrez l'e-mail"
                              min={1}
                              max={10}
                              style={{ marginRight: "8px" }}
                              onChange={(e) => {
                                const enteredValue = e.target.value.trim();
                                const sanitizedValue = enteredValue || "";
                                setFilteredPatientsData({
                                  ...filteredPatientsData,
                                  email: sanitizedValue,
                                });
                                // setSearchData({
                                //   ...searchData,
                                //   Email: sanitizedValue,
                                // });
                              }}
                            />

                            <img
                              src={iconRecherche}
                              alt="Sliders"
                              style={{
                                width: "50px", // set the desired width
                                height: "45px", // set the desired height
                                cursor: "pointer", // add pointer cursor to indicate clickability
                              }}
                              onClick={showSearchModal}
                            />
                            <Modal
                              title="Recherche avancé  :"
                              open={isModalOpen}
                              closable={false} // Set this prop to false to hide the close button in the header
                              width={"800px"}
                              onCancel={handleSearchOk}
                              footer={
                                <div style={{ textAlign: "end" }}>
                                  <Button
                                    key="ok1"
                                    type="primary"
                                    onClick={handleSearchOk}
                                  >
                                    OK
                                  </Button>
                                </div>
                              }
                            >
                              <Form
                                form={Rechercheform}
                                style={{ marginTop: "20px" }}
                              >
                                <Row>
                                  <Col span={12}>
                                    <Form.Item
                                      name="validation_status"
                                      label="Compte : "
                                    >
                                      <Checkbox.Group
                                        style={{ marginLeft: "7px" }}
                                        options={plainOptions}
                                        onChange={(e) => {
                                          setFilteredPatientsData({
                                            ...filteredPatientsData,
                                            validation_status: e,
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                    {/* <Form.Item
                                    name="blocked"
                                    label="Compte : "
                                    style={{ marginTop: "10px" }}
                                  >
                                    <Checkbox.Group
                                      style={{ marginLeft: "6px" }}
                                      options={plainOptions2}
                                      // onChange={(e) => {
                                      //   setFilteredPatientsData({
                                      //     ...filteredPatientsData,
                                      //     blocked: e,
                                      //   });
                                      // }}
                                    />
                                  </Form.Item> */}

                                    <Form.Item
                                      name="legal_first_name "
                                      label="Nom légale "
                                    >
                                      <Input
                                        className="inputStyle"
                                        placeholder="Entrer le nom legale"
                                        style={{
                                          width: "76%",
                                          marginLeft: "22px",
                                        }}
                                        onChange={(e) => {
                                          const enteredValue =
                                            e.target.value.trim(); // Trim leading and trailing whitespace
                                          let sanitizedValue = enteredValue;

                                          // Check if the entered value is empty after trimming
                                          if (!enteredValue) {
                                            sanitizedValue = ""; // Set sanitized value to an empty string
                                          }

                                          setFilteredPatientsData({
                                            ...filteredPatientsData,
                                            legal_first_name: sanitizedValue,
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      name="home_phone"
                                      label="Téléphone fixe"
                                    >
                                      <PhoneInput
                                        className="inputStyle"
                                        placeholder="Entrer le téléphone fixe"
                                        style={{ width: "82%" }}
                                        onChange={(value) => {
                                          // 'value' contains the entered phone number

                                          const enteredValue = value
                                            ? value.trim()
                                            : "";
                                          let sanitizedValue = enteredValue;

                                          // Check if the entered value is empty after trimming
                                          if (!enteredValue) {
                                            sanitizedValue = ""; // Set sanitized value to an empty string
                                          } else if (
                                            enteredValue.startsWith("+")
                                          ) {
                                            // Remove the '+' character if it exists at the beginning
                                            sanitizedValue =
                                              enteredValue.substring(1);
                                          }
                                          setFilteredPatientsData({
                                            ...filteredPatientsData,
                                            home_phone: sanitizedValue,
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      name="mobile_phone"
                                      label="Téléphone portable"
                                    >
                                      <PhoneInput
                                        className="inputStyle"
                                        style={{ width: "100%" }}
                                        placeholder="Entrer le numéro du téléphone portable"
                                        onChange={(value) => {
                                          const enteredValue = value
                                            ? value.trim()
                                            : "";
                                          let sanitizedValue = enteredValue;

                                          // Check if the entered value is empty after trimming
                                          if (!enteredValue) {
                                            sanitizedValue = ""; // Set sanitized value to an empty string
                                          } else if (
                                            enteredValue.startsWith("+")
                                          ) {
                                            // Remove the '+' character if it exists at the beginning
                                            sanitizedValue =
                                              enteredValue.substring(1);
                                          }
                                          setFilteredPatientsData({
                                            ...filteredPatientsData,
                                            mobile_phone: sanitizedValue,
                                          });
                                        }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      name="date-of-birth-range-picker"
                                      label="Date de naissance"
                                    >
                                      <ConfigProvider locale={frFR}>
                                        <RangePicker
                                          placeholder={[
                                            "Date de début",
                                            "Date de fin",
                                          ]}
                                          style={{
                                            height: "42px",
                                            borderRadius: "5px",
                                            marginLeft: "10px",
                                          }}
                                          onChange={(dates, dateStrings) => {
                                            setFilteredPatientsData({
                                              ...filteredPatientsData,
                                              startDate: dateStrings[0],
                                              endDate: dateStrings[1],
                                            });
                                          }}
                                        />
                                      </ConfigProvider>
                                    </Form.Item>

                                    <Form.Item
                                      name="inscription-date-range-picker"
                                      label="Date d'incription"
                                    >
                                      <RangePicker
                                        placeholder={[
                                          "Date de début",
                                          "Date de fin",
                                        ]}
                                        style={{
                                          height: "42px",
                                          borderRadius: "5px",
                                          marginLeft: "20px",
                                        }}
                                        onChange={(dates, dateString) => {
                                          setFilteredPatientsData({
                                            ...filteredPatientsData,
                                            startInscriptionDate: dateString[0],
                                            endInscriptionDate: dateString[1],
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Form>
                            </Modal>
                            <div style={gradientStyle} />

                            <Button
                              className="inputStyle"
                              style={{ fontSize: "13px" }}
                              type="primary"
                              onClick={() => {
                                fetchPatients();
                              }}
                            >
                              <SearchOutlined style={{ fontSize: "16px" }} />
                              Recherche
                            </Button>
                            <Button
                              type="primary"
                              style={{
                                width: "5%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginLeft: "5px",
                                fontSize: "13px", // Add this line to maintain font size on the reload button
                              }}
                              className="reloadButton"
                              icon={
                                <ReloadOutlined
                                  style={{
                                    marginLeft: "3px",
                                    fontSize: "20px",
                                  }}
                                />
                              }
                              onClick={async () => {
                                setFilteredPatientsData({
                                  ...filteredPatientsData,
                                  email: "",
                                  first_name: "",
                                  last_name: "",
                                  validation_status: "",
                                  blocked: "",
                                  legal_first_name: "",
                                  startDate: "",
                                  endDate: "",
                                  startInscriptionDate: "",
                                  endInscriptionDate: "",
                                  home_phone: "",
                                  mobile_phone: "",
                                  title: "",
                                  professional_title: "",
                                  designation_letters: "",
                                  bioagraphy: "",
                                  accreditations: "",
                                  social_networks: "",
                                  Naissance: "",
                                  Inscription: "",
                                });
                                reloadStatus
                                  ? setReloadStatus(false)
                                  : setReloadStatus(true);
                              }}
                            />
                          </Col>
                        </Col>

                        <Col xs={24} sm={24} md={10} lg={8} xl={6} xxl={4}>
                          <Radio.Group
                            defaultValue={filter} // Change defaultValue to use the filter state
                            onChange={(e) => {
                              handleFilterChange(e);
                              // handleResetInfoDisplay();
                            }}
                          >
                            <Radio.Button value="all">Tout</Radio.Button>
                            <Radio.Button value="invited">Invité</Radio.Button>
                            <Radio.Button value="not-invited">
                              Non invité
                            </Radio.Button>
                          </Radio.Group>
                        </Col>
                      </Row>
                      <h3>Liste des patients ({invitedPatientsLength})</h3>
                    </div>
                  }
                >
                  <div className="table-responsive">
                    {searchPhrase && (
                      <div className="searchData">{searchPhrase}</div>
                    )}
                    <Table
                      columns={columns}
                      dataSource={filterPatients(patients).map((patient) => ({
                        key: patient.id_patient,
                        name: `${patient.user.first_name} ${patient.user.last_name}`,
                        email: patient.user.email,
                      }))}
                      // onRow={(record) => ({
                      //   onClick: () => handleRowClick(record),
                      // })}
                      pagination={false}
                      className="ant-border-space"
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      )}
      <Modal
        title="Modifier les informations du patient"
        visible={modalVisible}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            Annuler
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={onSave}
          >
            Enregistrer
          </Button>,
        ]}
      >
        {/* Render form fields to edit patient data */}
        <Form layout="vertical">
          <Form.Item label="Prénom légal">
            <Input
              style={{ height: "40px" }}
              value={patientData?.legal_first_name || ""}
              onChange={(e) =>
                setPatientData({
                  ...patientData,
                  legal_first_name: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Prénom">
            <Input
              value={patientData?.user?.first_name || ""}
              onChange={(e) =>
                setPatientData({
                  ...patientData,
                  user: { ...patientData.user, first_name: e.target.value },
                })
              }
            />
          </Form.Item>
          <Form.Item label="Nom">
            <Input
              value={patientData?.user?.last_name || ""}
              onChange={(e) =>
                setPatientData({
                  ...patientData,
                  user: { ...patientData.user, last_name: e.target.value },
                })
              }
            />
          </Form.Item>
          <Form.Item label="Date de naissance">
            <DatePicker
              style={{ width: "100%", height: "40px", borderRadius: "6px" }}
              value={
                patientData && patientData.date_of_birth // Check if patientData is defined
                  ? moment(patientData.date_of_birth, "YYYY-MM-DD")
                  : null
              }
              format="YYYY-MM-DD"
              onChange={(date, dateString) =>
                setPatientData({
                  ...patientData,
                  date_of_birth: dateString,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Email">
            <Input value={patientData?.user?.email || ""} readOnly />
          </Form.Item>
          <Form.Item label="Téléphone fixe">
            <PhoneInput
              style={{ width: "100%" }}
              value={patientData?.home_phone || ""}
              onChange={(value) =>
                setPatientData({
                  ...patientData,
                  home_phone: value, // Update the mobile_phone field
                })
              }
            />
          </Form.Item>
          <Form.Item label="Téléphone mobile">
            <PhoneInput
              style={{ width: "100%" }}
              value={patientData?.mobile_phone || ""}
              onChange={(value) =>
                setPatientData({
                  ...patientData,
                  mobile_phone: value, // Update the mobile_phone field
                })
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default MyClients;