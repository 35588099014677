import moment from "moment";
import React, { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import InputEmoji from "react-input-emoji";
import { messageService, conversationService } from "../../utils/URL";
import axios from "axios";
import { useChat } from "../layout/ChatContext";
import { Button, Card, Col, Dropdown, Menu, Modal, Row, message } from "antd";
import { DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import imageSrc from "../../assets/images/video-icon.png"; // Import the image file

export const ChatBox = ({
  recipientUser,
  messages,
  isMessageLoading,
  getUserListChats,
  setMessages,
  getMessages,
  deleteConv,
  setDeleteConv,
  setCalling,
}) => {
  const { socket, id_user, callUser } = useChat();
  const [textMessage, setTextMessage] = useState("");
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [idMessage, setIdMessage] = useState(false);
  const [idToCall, setIdToCall] = useState("");

  const handleDeleteUser = async () => {
    try {
      await axios.delete(
        `${conversationService}/deleteConversationById/${recipientUser.id_conversation}`
      );
      message.success("la conversation est crée");
      setVisibleDelete(false);
      setDeleteConv(true);
      getUserListChats();
    } catch (error) {
      message.error(
        error.response?.data?.message || "Erreur internet du serveur"
      );
    }
  };

  const deleteMessage = async () => {
    try {
      await axios.delete(`${messageService}/deleteMessage/${idMessage}`);
      message.success("message deleted successfully");
      getMessages(recipientUser.id_conversation);
      setTextMessage("message deleted by other side");
      if (socket) {
        socket.emit("sendMessage", { textMessage, id_user, recipientUser });
      }
      setTextMessage("");
    } catch (error) {
      console.error("Confirmation failed:", error);
      message.error(error?.response?.data?.message);
    }
  };
  const handleDeleteClick = () => {
    setVisibleDelete(true);
  };

  const createMessage = async () => {
    try {
      await axios.post(`${messageService}/createMessage`, {
        text: textMessage,
        senderId: id_user,
        id_conversation: recipientUser.id_conversation,
      });

      setTextMessage("");
      if (socket) {
        socket.emit("sendMessage", { textMessage, id_user, recipientUser });
      }
    } catch (error) {
      console.error("Confirmation failed:", error);
    }
  };

  // useEffect(() => {
  //   if (socket) {
  //     socket.on("callended", () => {
  //       setIdToCall(null);
  //     });
  //   }
  // }, [socket]);
  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          deleteMessage();
        }}
      >
        Supprimer
      </Menu.Item>
    </Menu>
  );
 
  const [activeTab, setActiveTab] = useState("Messages");

  const [notifications] = useState([
    {
      id: 1,
      title: "Notification 1",
      description: "This is the first notification",
      time: "10:00 AM",
    },
    {
      id: 2,
      title: "Notification 2",
      description: "This is the second notification",
      time: "11:30 AM",
    },
    // Add more notifications as needed
  ]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  if (activeTab === "Notifications") {
    return (
      <div className="notification-list">
        <div className="message-notification-tabs">
          <div
            style={{ marginRight: "10%" }}
            className={`tab ${activeTab === "Messages" ? "active" : ""}`}
            onClick={() => handleClick("Messages")}
          >
            <span className="tab-text">Messages</span>
            <div className="line"></div>
          </div>
          <div
            className={`tab ${activeTab === "Notifications" ? "active" : ""}`}
            onClick={() => handleClick("Notifications")}
          >
            <span className="tab-text">Notifications</span>
            <div className="line"></div>
          </div>
        </div>
        <div style={{ marginTop: "5%" }}>
          {notifications.map((notification) => (
            <Card key={notification.id} className="notification-card">
              <div className="notification-content">
                <div className="notification-info">
                  <strong>{notification.title}</strong>
                  <p>{notification.description}</p>
                </div>
                <div className="notification-time">
                  <p>{notification.time}</p>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </div>
    );
  }

  if (recipientUser === null || deleteConv === true)
    return (
      <div className="message-notification-tabs">
        {/* <div
          style={{ marginRight: "10%" }}
          className={`tab ${activeTab === "Messages" ? "active" : ""}`}
          onClick={() => handleClick("Messages")}
        >
          <span className="tab-text">Messages</span>
          <div className="line"></div>
        </div>
        <div
          className={`tab ${activeTab === "Notifications" ? "active" : ""}`}
          onClick={() => handleClick("Notifications")}
        >
          <span className="tab-text">Notifications</span>
          <div className="line"></div>
        </div> */}
      </div>
    );
  if (isMessageLoading)
    return <p style={{ textAlign: "center" }}>Loading Message</p>;

  return (
    <Stack gap={4} className="chat-box">
   
      <div className="chat-header-container">
        <Row>
          <Col span={4}>
            <div
              onClick={handleDeleteClick}
              className="chat-header-icon-button"
            >
              <DeleteOutlined className="chat-header-icon" />
            </div>
            <Modal
              visible={visibleDelete}
              onCancel={() => setVisibleDelete(false)}
              footer={[
                <Button key="cancel" onClick={() => setVisibleDelete(false)}>
                  Annuler
                </Button>,
                <Button
                  key="delete"
                  type="primary"
                  danger
                  onClick={handleDeleteUser} // Call handleDeleteUser function when the delete button is clicked
                >
                  Supprimer
                </Button>,
              ]}
            >
              <p>Êtes-vous sûr de vouloir supprimer cette conversation ?</p>
            </Modal>
          </Col>
          <Col span={16}>
            <strong className="chat-header">{recipientUser?.name}</strong>
          </Col>

          {/* <Col span={4}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                // history.push('/VideoChat');
                callUser();
                setCalling(true);
              }}
              className="chat-header-cam"
            >
              <img
                src={imageSrc}
                alt="imageSrc"
                className="chat-header-icon-cam"
              />
            </div>
          </Col> */}
        </Row>
      </div>

      {messages && messages.length > 0 ? (
        <Stack gap={3} className="messages reverse">
          {messages &&
            messages
              .slice()
              .reverse()
              .map((message, index) => (
                <Stack
                  key={index}
                  gap={2}
                  direction="horizontal"
                  className={` ${
                    message?.senderId &&
                    id_user &&
                    message.senderId.toString() === id_user.toString()
                      ? "self align-self-end"
                      : "other align-self-start"
                  }`}
                >
                  <Stack
                    key={message.id_message}
                    className={` ${
                      message?.senderId &&
                      id_user &&
                      message.senderId.toString() === id_user.toString()
                        ? "message self align-self-end flex-grow-0"
                        : "message other align-self-start flex-grow-0"
                    }`}
                  >
                    <span style={{ fontWeight: "bold" }}>{message.text}</span>
                    <span className="message-footer">
                      {moment(message.created_at).calendar()}
                    </span>
                  </Stack>
                  {message.senderId &&
                  id_user &&
                  message.senderId.toString() === id_user.toString() ? (
                    <button className="chat-header-icon-button">
                      <Dropdown overlay={menu}>
                        <a
                          onMouseEnter={() => setIdMessage(message.id_message)}
                        >
                          <MoreOutlined className="self align-self-end flex-grow-0 message-footer" />
                        </a>
                      </Dropdown>
                    </button>
                  ) : null}
                </Stack>
              ))}
        </Stack>
      ) : (
        <Stack gap={3} className="messages">
          <p style={{ textAlign: "center", fontWeight: "bold" }}>
            Dites bonjour
          </p>
        </Stack>
      )}

      <Stack direction="horizontal" gap={3} className="chat-input flex-grow-0">
        <InputEmoji
          value={textMessage}
          fontFamily="nunito"
          borderColor="rgba(72,122,223,0.2)"
          onChange={(e) => {
            setTextMessage(e);
          }}
          placeholder="Entrer un message"
        />
        <button
          className="send-btn"
          onClick={() => {
            if (textMessage !== "") {
              createMessage();
            }
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-send"
            viewBox="0 0 16 16"
          >
            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
          </svg>
        </button>
      </Stack>
    </Stack>
  );
};
