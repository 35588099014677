import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Input,
  message,
  Select,
  DatePicker,
  ConfigProvider,
} from "antd";
import moment from "moment";

import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { appointmentService, invoiceService } from "../../utils/URL";
import axios from "axios";
import { useHistory } from "react-router-dom";
import frFR from "antd/lib/locale/fr_FR";
import { useChat } from "../layout/ChatContext";
import { RollbackOutlined } from "@ant-design/icons";

const AddBill = () => {
  const history = useHistory();

  const { TextArea } = Input;
  const token = Cookies.get("token");

  const [formData, setFormData] = useState({});
  const [invoiceNumber, setInvoiceNumber] = useState({});
  const [appointments, setAppointments] = useState([]);

  const { selectedPatient, user, BillData, lastInvoiceId } = useChat();

  const handleChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const fetchBookings = async () => {
    try {
      const response = await axios.get(
        `${appointmentService}/getAllAppointmentsPaid`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      const filteredAppointments = response.data.appointments.filter(
        (app) => app.appointment.id_patient === selectedPatient.id_patient
      );

      const formattedAppointments = filteredAppointments.map((app) => ({
        ...app.appointment,
        paid: app.paid,
      }));

      setAppointments(formattedAppointments);
    } catch (error) {
      console.error("Error fetching booking:", error);
    }
  };
  const createInvoice = async () => {
    try {
      await axios.post(`${invoiceService}/createInvoice`, {
        invoice_date: formData.date,
        id_doctor: user.Doctor.id_doctor,
        id_patient: selectedPatient.id_patient,
        appointmentIds: formData.appointmentIds,
        invoice_number: invoiceNumber,
      });
      setLoading(false);

      history.goBack();
      
      message.success("la facture est créé avec succès");
    } catch (error) {
      console.error("Error creating invoice:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
      setLoading(false);
    }
  };
  const modifyInvoice = async () => {
    try {
      await axios.put(
        `${invoiceService}/updateInvoice/${BillData.id_invoice}`,
        {
          invoice_date: formData.date,
          id_doctor: user.Doctor.id_doctor,
          id_patient: selectedPatient.id_patient,
          appointmentIds: formData.appointmentIds,
          invoice_number: invoiceNumber,
        }
      );
      setLoading(false);

      history.goBack();

      message.success("la facture est modifiée avec succès");
    } catch (error) {
      console.error("Error updating invoice:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
      setLoading(false);
    }
  };
  const markInvoicePaied = async () => {
    try {
      await axios.put(
        `${invoiceService}/updateInvoice/${BillData.id_invoice}`,
        {
          invoice_date: formData.date,
          id_doctor: user.Doctor.id_doctor,
          id_patient: selectedPatient.id_patient,
          appointmentIds: formData.appointmentIds,
          invoice_number: invoiceNumber,
          paid: "confirmed",
        }
      );
      setLoading1(false);

      history.goBack();

      message.success("la facture est modifiée avec succès");
    } catch (error) {
      console.error("Error updating invoice:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    }
  };

  const handleCreateInvoice = () => {
    setLoading(true);

    createInvoice();
  };
  const handleModifyInvoice = () => {
    setLoading(true);
    modifyInvoice();
  };
  const PayInvoice = () => {
    setLoading1(true);

    markInvoicePaied();
  };
  const cancel = () => {
    history.goBack();
  };

  useEffect(() => {
    if (BillData !== null) {
      setInvoiceNumber(BillData.invoice_number);
      setFormData({
        date: BillData.invoice_date,
        appointmentIds: BillData?.Appointments?.map((appointment) =>
          Number(appointment.id_appointment)
        ),
      });
    } else {
      setInvoiceNumber(generateInvoiceNumber());
    }
    fetchBookings();
  }, []);

  // Function to generate random 18-character invoice number
  const generateInvoiceNumber = () => {
    const lastIdDigits = (lastInvoiceId + 1).toString().slice(-5);
    const currentYear = new Date().getFullYear();
    const paddedLastIdDigits = lastIdDigits.padStart(5, "0");
    const result = `${paddedLastIdDigits}_${currentYear}`;

    return result;
  };

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f0f2f5", // Grey background color
      }}
    >
      <div className="icon-container">
        <Button
          type="text"
          shape="circle"
          icon={<RollbackOutlined />}
          className="circle-icon"
          onClick={cancel}
        />
      </div>
      <div style={{ width: "60%", textAlign: "center" }}>
        <Card
          style={{ width: "60%", margin: "0 auto", marginBottom: "2rem" }}
          title={<div style={{ textAlign: "start" }}>En-tête de facture</div>}
        >
          <Form layout="vertical" style={{ marginTop: "2%" }}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Ocupation"
                  rules={[
                    {
                      message: "Veuillez sélectionner une journée",
                    },
                  ]}
                >
                  <TextArea
                    style={{ height: "70px" }}
                    value={`${user.first_name} ${user.last_name}\n ${user.Doctor.professional_title}`}
                    readOnly
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Médecin"
                  rules={[
                    {
                      // required: formData.buffer_option,
                      message: "Veuillez insérer votre nom",
                    },
                  ]}
                >
                  <TextArea
                    style={{ height: "70px" }}
                    value={`${user.first_name} ${user.last_name}`}
                    readOnly
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <br />
        <Card
          style={{ width: "60%", margin: "0 auto", marginBottom: "2rem" }}
          title={<div style={{ textAlign: "start" }}>Details de facture</div>}
        >
          <Form layout="vertical" style={{ marginTop: "2%" }}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item label="Numéro de facture ">
                  <Input
                    value={invoiceNumber}
                    style={{ height: "40px" }}
                    placeholder="Veuillez inserer un sujet du courriel"
                    readOnly
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Date de facture "
                  rules={[
                    {
                      message: "Veuillez selectionner la date de facture",
                    },
                  ]}
                >
                  <ConfigProvider locale={frFR}>
                    <DatePicker
                      defaultValue={
                        BillData?.invoice_date
                          ? moment(BillData?.invoice_date)
                          : null
                      }
                      onChange={(value) => {
                        handleChange(
                          "date",
                          value ? value.format("YYYY-MM-DD") : ""
                        );
                      }}
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      allowClear={false}
                    />
                  </ConfigProvider>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Card
          style={{ width: "60%", margin: "0 auto", marginBottom: "2rem" }}
          title={
            <div style={{ textAlign: "start" }}>Réservation de facture</div>
          }
        >
          <Form layout="vertical" style={{ marginTop: "2%" }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item label="Numéro de facture ">
                  <Select
                    onChange={(value) => {
                      handleChange("appointmentIds", value.map(Number));
                    }}
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Sélectionner au moins un rendez-vous"
                    defaultValue={BillData?.Appointments?.map((appointment) =>
                      appointment.id_appointment.toString()
                    )}
                    options={appointments.map((appointment) => ({
                      label: `${moment(appointment.date).format(
                        "ddd MMM D @ hh:mm A"
                      )} ${
                        appointment.Invoice ? "(facturé)" : "(non facturé)"
                      }`, // Include payment status in label
                      value: appointment.id_appointment.toString(),
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Button
          loading={loading}
          style={{
            backgroundColor: "#00800087",
            width: "15%",
            marginRight: "3%",
          }}
          type="text"
          onClick={
            BillData !== null ? handleModifyInvoice : handleCreateInvoice
          }
        >
          {BillData !== null ? "Modifier la facture" : "Créer la facture"}
        </Button>

        {BillData !== null && BillData.paid === "pending" && (
          <Button
            loading={loading1}
            style={{
              backgroundColor: "#00800087",
              width: "15%",
              marginRight: "3%",
            }}
            type="text"
            onClick={PayInvoice}
          >
            Payer
          </Button>
        )}

        <Button
          style={{ backgroundColor: "#ff000054", width: "15%" }}
          type="text"
          onClick={() => {
            cancel();
          }}
        >
          Annuler
        </Button>
      </div>
    </div>
  );
};

export default AddBill;
