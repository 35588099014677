import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import PatientFolder from "./pages/PatientFolder";
import Myavailability from "./pages/Myavailability";
import VideoChat from "./pages/VideoChat";
import MyBookings from "./pages/MyBookings";
import Protocoles from "./pages/Protocoles";
import Documents from "./pages/Documents";
import addProtocole from "./pages/addProtocole";
import ProtocoleDetails from "./pages/ProtocoleDetails";
import MyServices from "./pages/MyServices";
import MyPackages from "./pages/MyPackages";
import MyLocations from "./pages/MyLocations";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import Ok from "./pages/Ok";
import Confirm from "./pages/Confirm";
import UpdateBooking from "./pages/UpdateBooking";
import forgetPasswordRecovery from "./pages/forgetPasswordRecovery";
import MyEvents from "./pages/MyEvents";
import forms from "./pages/forms";
import addBooking from "./pages/addBooking";
import forgetPasswordRecoveryAndVerification from "./pages/forgetPasswordRecoveryAndVerification";
import resetPassword from "./pages/resetPassword";
import Fail from "./pages/Fail";
import SignUpPartOne from "./pages/SignUpPartOne";
import PasswordRecovery from "./pages/PasswordRecovery";
import SignIn from "./pages/SignIn";
import Chat from "./pages/Chat";
import TableDoc from "./pages/TableDoc";
import PrivateRoute from "./PrivateRoute"; // Adjust the path accordingly
import Private from "./Private"; // Adjust the path accordingly
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import axios from "axios";
import { userServices, SocketUrl } from "./utils/URL";
import "bootstrap/dist/css/bootstrap.min.css";
import io from "socket.io-client";
import jwt from "jsonwebtoken";
import { useChat } from "./components/layout/ChatContext";
import AddBill from "./components/layout/AddBill";
import Recommendation from "./components/layout/Recommendation";
import AddForm from "./components/layout/AddForm";
import FormToFill from "./components/layout/FormToFill";
import { ChatProvider } from "./components/layout/ChatContext";
import Locations from "./pages/Locations";

const App = () => {
  const [userRole, setUserRole] = useState(null);
  const [socket, setSocket] = useState(null);
  const [onlineUsers, serOnlineUsers] = useState([]);

  const token = Cookies.get("token");
  let id_user = null;
  if (token) {
    // Decode the token to extract the user ID
    const decoded = jwt.decode(token);
    id_user = decoded.id_user;
  }
  const fetchUserRole = async () => {
    try {
      const response = await axios.get(`${userServices}/getUser`, {
        headers: {
          "auth-token": token,
        },
      });
      setUserRole(response.data.User.role);
    } catch (error) {
      console.error("Error fetching user role:", error);
      setUserRole(null);
    }
  };
  useEffect(() => {
    if (token) {
      fetchUserRole();
    }
  }, []);

  useEffect(() => {
    const newSocket = io(`${SocketUrl}`, {
      reconnectionDelayMax: 10000,
    });
    setSocket(newSocket);
    return () => {
      newSocket.disconnect();
    };
  }, [token]);

  useEffect(() => {
    if (socket) {
      socket.emit("addNewUser", id_user);
      socket.on("getOnlineUsers", (response) => {
        serOnlineUsers(response);
      });
      return () => {
        socket.off("getOnlineUsers");
      };
    }
  }, [socket]);

  return (
    <div className="App">
      <ChatProvider>
        <Switch>
          <Route
            path="/verification/:verifivcationToken"
            exact
            component={Ok}
          />
          <Route
            path="/confirm/:confirmation_token"
            exact
            component={Confirm}
          />
          <Route
            path="/UpdateBooking/:confirmation_token/:verification_token"
            exact
            component={UpdateBooking}
          />
          <Route
            path="/forgetPasswordRecovery/:reset_password_code"
            exact
            component={forgetPasswordRecovery}
          />
          <Route
            path="/reset&verify/:verification_token/:reset_password_code"
            exact
            component={forgetPasswordRecoveryAndVerification}
          />
          <Route exact path="/VideoChat" component={VideoChat} />

          <Route path="/Fail/" exact component={Fail} />
          <Route path="/sign-up" exact component={SignUpPartOne} />
          <Route path="/forgot-password" exact component={PasswordRecovery} />
          <Route path="/" exact component={SignIn} />
          {userRole === "doctor" && (
            <>
              <PrivateRoute exact path="/dashboard" component={Home} />
              <PrivateRoute exact path="/forms" component={forms} />
              <Private exact path="/addBill" component={AddBill} />
              <Private exact path="/AddForm" component={AddForm} />
              <PrivateRoute exact path="/tables" component={Tables} />
              <PrivateRoute
                exact
                path="/PatientFolder/:id_user"
                component={PatientFolder}
              />
              <PrivateRoute
                exact
                path="/addBooking/:id_user"
                component={addBooking}
              />
              <PrivateRoute exact path="/MyEvents" component={MyEvents} />
              {/* <PrivateRoute exact path="/VideoChat" component={VideoChat} /> */}
              <PrivateRoute
                exact
                path="/Calendar/Myavailability"
                component={Myavailability}
              />
              <PrivateRoute
                exact
                path="/Calendar/MyLocations"
                component={MyLocations}
              />
              <PrivateRoute
                exact
                path="/Calendar/MyBookings"
                component={MyBookings}
              />
              <PrivateRoute exact path="/Protocoles" component={Protocoles} />
              <PrivateRoute
                exact
                path="/addProtocole"
                component={addProtocole}
              />
              <PrivateRoute
                exact
                path="/Recommendation/:id_recommendation"
                component={Recommendation}
              />
              <PrivateRoute
                exact
                path="/updateProtocole/:id_protocol"
                component={addProtocole}
              />
              <PrivateRoute
                exact
                path="/Calendar/MyServices"
                component={MyServices}
              />
              <PrivateRoute
                exact
                path="/Calendar/MyPackages"
                component={MyPackages}
              />
              <PrivateRoute
                exact
                path="/resetPassword"
                component={resetPassword}
              />
              <PrivateRoute exact path="/billing" component={Billing} />
              <PrivateRoute exact path="/rtl" component={Rtl} />
              <PrivateRoute exact path="/profile" component={Profile} />
              <PrivateRoute exact path="/Chat" component={Chat} />
              <PrivateRoute
                path="/FormToFill/:id_patient/:id_form"
                component={FormToFill}
              />
            </>
          )}
          <PrivateRoute exact path="/Documents" component={Documents} />

          <PrivateRoute exact path="/forms" component={forms} />
          <PrivateRoute exact path="/FormToFill" component={FormToFill} />
          <PrivateRoute
            exact
            path="/FormToFill/:id_form"
            component={FormToFill}
          />
          <PrivateRoute exact path="/dashboard" component={MyEvents} />
          <PrivateRoute exact path="/MyEvents" component={MyEvents} />
          <PrivateRoute exact path="/Availibilities" component={MyEvents} />
          <PrivateRoute exact path="/Locations" component={Locations} />
          <PrivateRoute exact path="/Protocoles" component={Protocoles} />
          <PrivateRoute
            exact
            path="/ProtocoleDetails/:id_protocol"
            component={ProtocoleDetails}
          />

          <PrivateRoute
            exact
            path="/Recommendation/:id_recommendation"
            component={Recommendation}
          />
          {/* <PrivateRoute exact path="/VideoChat" component={VideoChat} /> */}
          <PrivateRoute exact path="/resetPassword" component={resetPassword} />
          <PrivateRoute exact path="/billing" component={Billing} />
          <PrivateRoute exact path="/rtl" component={Rtl} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/DoctorProfile" component={Profile} />
          <PrivateRoute exact path="/DoctorService" component={MyServices} />
          <PrivateRoute exact path="/Chat" component={Chat} />
          <PrivateRoute exact path="/TableDoc" component={TableDoc} />
        </Switch>
      </ChatProvider>
    </div>
  );
};

export default App;
