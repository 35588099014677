/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography, DatePicker, Space, Button } from "antd";
import eChart from "./configs/eChart";
import moment from "moment";
import { ReloadOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import { invoiceService } from "../../utils/URL";
import { useEffect, useState } from "react";
import axios from "axios";

const EChart = ({invoiceData}) =>{
  const [invoiceChartData, setInvoiceChartData] = useState(null); // Set initial state to null or a sensible default
  const [year, setYear] = useState("");

  const { Title, Paragraph } = Typography;
  const sanitizedData = invoiceChartData
    ? invoiceChartData.map((value) =>
        value !== null && value !== undefined ? value : 0
      )
    : [];

  const series = [
    {
      name: "revenus",
      data: sanitizedData,
      color: "#fff",
    },
  ];
  const getInvoiceChart = async () => {
    try {
      const token = Cookies.get("token");
      const result = await axios.get(
        `${invoiceService}/kpiInvoiceChart?year=${year}`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setInvoiceChartData(result.data); // Ensure you're setting the correct part of the result
    } catch (error) {
      console.error("Confirmation failed:", error);
    }
  };

  useEffect(() => {
      getInvoiceChart();
  }, [year]);
  return (
    <>
      <div id="chart">
        <h4>Revenus / mois :</h4>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ fontSize: "16px", marginRight: "10px" }}>
            Vous pouvez sélectionner l'année :
          </p>
          <Space style={{ marginLeft: "auto", marginBottom: "10px", }}>
            <DatePicker
              picker="year"
              style={{
                height: "40px",
                borderRadius: "5px",
                
              }}
              onChange={(date) => {
                if (date === null || date === undefined) {
                  setYear("");
                } else {
                  setYear(date.year());
                }
              }}
            />
            <Button
              type="primary"
              style={{
                width: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={
                <ReloadOutlined
                  style={{ fontSize: "20px", marginLeft: "2px" }}
                />
              }
              onClick={() => {
                setYear(new Date().getFullYear());
              }}
            />{" "}
          </Space>
        </div>
        <ReactApexChart
          className="bar-chart"
          options={eChart.options}
          series={series}
          type="bar"
          height={400}
        />
      </div>
      {/* <div className="chart-vistior">
        <Paragraph className="lastweek">
          Cette semaine{" "}
          <span className={invoiceData?.className}>
            {invoiceData?.result ?? 0} %
          </span>
        </Paragraph>
      </div> */}
    </>
  );
}

export default EChart;
