import React, { useRef, useState } from "react";
import { Button, Layout, Table } from "antd";
import moment from "moment";
import { useChat } from "../layout/ChatContext";
import imageSrc from "../../assets/images/BlueLogo.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const { Footer } = Layout;

const InvoiceModalContent = () => {
  const { selectedPatient, BillData, user } = useChat();
  const [loader, setLoader] = useState(false);

  const downloadPDF = () => {
    const capture = document.querySelector(".invoice-modal-content");

    // Checking if the container is found
    if (!capture) {
      console.error("Container not found");
      return;
    }

    // Hide the footer
    const footerToHide = capture.querySelector(".ant-layout-footer");
    if (footerToHide) {
      footerToHide.style.display = "none";
    }

    // Setting loader to true
    setLoader(true);

    // Using html2canvas to capture the modified content as an image
    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL("img/png");
      const doc = new jsPDF("p", "mm", "a4");
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
      setLoader(false);

      // Restore the footer visibility
      if (footerToHide) {
        footerToHide.style.display = ""; // Restore original display property
      }

      doc.save("receipt.pdf");
    });
  };

  const calculateDuration = (startTime, endTime) => {
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const [endHours, endMinutes] = endTime.split(":").map(Number);

    if (
      startHours < 0 ||
      startHours > 23 ||
      startMinutes < 0 ||
      startMinutes > 59 ||
      endHours < 0 ||
      endHours > 23 ||
      endMinutes < 0 ||
      endMinutes > 59
    ) {
      return "Invalid time values";
    }

    const startMinutesTotal = startHours * 60 + startMinutes;
    const endMinutesTotal = endHours * 60 + endMinutes;

    const diffInMinutes = endMinutesTotal - startMinutesTotal;

    const adjustedDiff =
      diffInMinutes < 0 ? diffInMinutes + 24 * 60 : diffInMinutes;

    const durationInHours = adjustedDiff / 60;
    return durationInHours;
  };

  const appointmentColumns = [
    {
      title: "Service",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Prix unitaire",
      dataIndex: "price_Service",
      key: "price_Service",
      render: (text) => <span dangerouslySetInnerHTML={{ __html: text }} />,
    },
    {
      title: "Prix consultation",
      dataIndex: "Price_appointment",
      key: "Price_appointment",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => moment(text).format("MMM D, YYYY"),
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
      render: (text) => moment(text, "HH:mm:ss").format("hh:mm A"),
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_time",
      render: (text) => moment(text, "HH:mm:ss").format("hh:mm A"),
    },
    // {
    //   title: "Temps unitaire",
    //   dataIndex: "duration",
    //   key: "duration",
    // },
  ];

  const appointmentData = BillData.Appointments
    ? BillData?.Appointments.map((appointment, index) => ({
        ...appointment,
        key: index,
        name: appointment.Service.name,
        price_Service: `
        $${appointment.Service.price_Service} (par séance) <br />
        $${(
          appointment.Service.price_Service / appointment.Service.duration
        ).toFixed(2)} (par Min)
      `,
        duration: `${appointment.Service.duration} Min`,
        Price_appointment: `$${(
          (appointment.Service.price_Service / appointment.Service.duration) * 60 *
          calculateDuration(appointment.start_time, appointment.end_time)
        ).toFixed(2)}`,
      }))
    : [];
  return (
    <Layout className="p-3 invoice-modal-content">
      <div className="flex justify-between items-end pb-4 mb-8 ">
        <div>
          <h1 className="text-2xl font-bold">
            Facture #{BillData.invoice_number}
          </h1>
          <p className="text-xs">
            {moment(BillData.invoice_date).format("ddd MMM D YYYY")}
          </p>
        </div>
        <img
          src={imageSrc}
          alt="imageSrc"
          style={{ width: "60%", margin: "0 20%" }}
        />
      </div>

      <div className="text-right">
        <p className="p-0 mb-1">
          <b>Doctor</b>
        </p>
        {user.Doctor ? (
          <p className="p-0 mb-1">
            {user.first_name} {user.last_name}
          </p>
        ) : (
          <p className="p-0 mb-1">
            {BillData?.Doctor?.user?.first_name}{" "}
            {BillData?.Doctor?.user?.last_name}
          </p>
        )}

        {/* <p className="p-0 mb-1">Washington,</p>
        <p className="p-0 mb-1">DC 20500,</p>
        <p className="p-0 mb-1">United States of America</p> */}
      </div>

      <div className="h-px bg-gray-300 my-4" />

      <div>
        <p className="p-0 mb-1">
          <b>Bill to:</b>
        </p>
        <p className="p-0 mb-1">
          {selectedPatient?.first_name || selectedPatient?.user?.first_name}{" "}
          {selectedPatient?.last_name || selectedPatient?.user?.last_name}
        </p>
        {/* <p className="p-0 mb-1">72 Faxcol Dr Gotahm City,</p>
        <p className="p-0 mb-1">NJ 12345,</p>
        <p className="p-0 mb-1">United States of America</p> */}
      </div>

      <div className="h-px bg-gray-300 my-4" />

      <div className="p-0 leading-5">
        <p>
          Bien reçu, veuillez trouver ci-joint la facture correspondant à nos
          services. N'hésitez pas à nous contacter pour toute question ou
          clarification.
        </p>
        <p>Voici les deails de votre facture:</p>
      </div>

      <Table
        dataSource={appointmentData}
        pagination={false}
        rowKey="id_appointment"
        columns={appointmentColumns}
      />
      <p style={{ margin: "0 auto", paddingTop: "3%" }}>
        <b className=" mb-1">Prix total :</b>
        <b>{BillData.bill} €</b>
      </p>
      <div className="bg-blue-100 p-3 rounded-md border-blue-300 text-blue-800 text-sm">
        crée le {moment(BillData.created_at).format("ddd MMM D YYYY")}
      </div>
      {user.Doctor ? (
        <Footer style={{ margin: "0 auto", width: "100%" }}>
          <div className="text-gray-400 text-sm">
            <span>Merci</span>
          </div>
        </Footer>
      ) : (
        <Footer style={{ margin: "0 auto", width: "100%" }}>
          <div className="text-gray-400 text-sm">
            <span>Téléchargez cette facture </span>
            <a href="#" onClick={downloadPDF}>
              {loader ? (
                <span>Téléchargement en cours</span>
              ) : (
                <span>Cliquez ici</span>
              )}
            </a>
          </div>
        </Footer>
      )}
    </Layout>
  );
};

export default InvoiceModalContent;
