import { Avatar } from "antd";
import moment from "moment";
import React from "react";
import { Stack } from "react-bootstrap";
import { useChat } from "../layout/ChatContext";
import profileIcon from "../../assets/images/profileIcon.png"; // Import the image file

export const UserChat = ({ chat, User, onSelectUser }) => {
  const {  onlineUsers ,  id_user } = useChat();

  const formattedDate = moment(User.created_at).format("YYYY-MM-DD");
  const isToday = moment(formattedDate).isSame(moment(), "day");
  const isGroupChat = User?.users.length > 2;
  let otherUserImage = null;

  if (!isGroupChat) {
    // Find the first user that is not the current user
    for (const u of User.users) {
      if (u.id_user !== id_user) {
        otherUserImage = u.image || profileIcon;
        break;
      }
    }
  }

  let displayDate;
  if (isToday) {
    // If the date is today, display only the time
    displayDate = moment(User.created_at).format("HH:mm");
  } else {
    // If the date is not today, display only the date
    displayDate = formattedDate;
  }
  const userstatus = User.users.filter((user) => user.id_user !== id_user)[0];
  const isOnline = onlineUsers?.some(
    (user) => user.id_user === userstatus.id_user
  );


  return (
    <Stack
      direction="horizontal"
      gap={3}
      className="user-card align-itmes-center p-2 justify-content-between"
      style={{ cursor: "pointer" }}
    >
      <div className="d-flex">
        {isGroupChat ? (
          <Avatar
            className=" avatar-chat"
            style={{ backgroundColor: "#1890ff" }}
          >
            G
          </Avatar>
        ) : (
          <Avatar
            className=" avatar-chat"
            style={{
              backgroundImage: `url(${otherUserImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></Avatar>
        )}
        <div className="text-content">
          <div className="name">
            {userstatus.first_name}
            {/* {userstatus.last_name} */}
          </div>
          <div className="text">Text Message</div>
        </div>
      </div>
      <div className="d-flex flex-column align-items-end">
        <div className="date">{displayDate}</div>
        <div className="this-user-notifications">2</div>
        <span className={isOnline ? "user-online" : ""}></span>
      </div>
    </Stack>
  );
};
