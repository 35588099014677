import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Input,
  message,
  Image,
  Checkbox,
  Radio,
} from "antd";
import { reponceService } from "../../utils/URL";

import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { useChat } from "./ChatContext";
import SignatureCanvas from "react-signature-canvas";
import { ChromePicker } from "react-color";
const CheckboxGroup = Checkbox.Group;

const FormToFill = () => {
  const { form, setForm } = useChat();
  const { id_patient, id_form } = useParams();
  const fetchResponses = async (patient, form) => {
    try {
      const response = await axios.get(
        `${reponceService}/getReponcesByIdPatientAndIdForm/${id_patient}/${id_form}`
      );
      setForm({
        id_form: 0,
        name: response.data.form.name,
        description: response.data.form.description,
        questions: response.data.reponse.questions,
        created_at: response.data.reponse.created_at,
        updated_at: response.data.reponse.updated_at,
        id_doctor: response.data.form.id_doctor,
        signature: response.data.reponse.signature,
      });
      setQuestions(response.data.reponse.questions);
    } catch (error) {
      message.error(`le patient  n'a pas remplie le formulaire`);
      console.error("Error fetching patients:", error);
    }
  };

  const history = useHistory();
  const token = Cookies.get("token");
  const [signatureRef, setSignatureRef] = useState(null);

  const clearSignature = () => {
    signatureRef.clear();
  };
  const [formData, setFormData] = useState({
    name: form ? form.name : "",
    description: form ? form.description : "",
  });
  const [loading, setLoading] = useState(false);
  const [questionData, setQuestionData] = useState({
    id: null,
    question: "",
    questionType: "",
    questionOptions: [],
    value: "",
    questionRequired: false,
  });
  const [questions, setQuestions] = useState(form?.questions ?? []);

  const updateQuestionData = (field, value) => {
    setQuestionData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleQuestionChange = (questionIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].value = value;
    setQuestions(updatedQuestions);
  };

  const handleSubmit = async () => {
    const unansweredRequiredQuestions = questions.filter(
      (question) => question.questionRequired && !question.value
    );
    if (unansweredRequiredQuestions.length > 0) {
      message.error("Veuillez répondre à toutes les questions requises.");
      return;
    }

    const signatureData = signatureRef.toDataURL();

    setLoading(true);

    try {
      await axios.post(
        `${reponceService}/createResponce`,
        {
          id_form: form.id_form,
          questions: questions,
          signature: signatureData,
        },
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      message.success("Reponce envoyé avec succès !");
      history.goBack();
    } catch (error) {
      message.error(error.response.data.message || "Erreur interne du serveur");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(form).length === 0) {
      fetchResponses();
    }
  }, [form]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <div style={{ width: "60%", textAlign: "center" }}>
        <Card
          style={{
            width: "80%",
            margin: "0 auto",
            marginBottom: "2rem",
            marginTop: "5%",
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24} style={{ marginTop: "2%" }}>
              <p
                style={{
                  fontSize: "15px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "Bold",
                }}
              >
                <samp
                  style={{
                    fontSize: "20px",
                    marginRight: "10px",
                    color: "#002379",
                  }}
                >
                  Nom du formulaire:{" "}
                </samp>
                <span style={{ marginLeft: "21%" }}>{form?.name}</span>
              </p>
            </Col>
            <Col span={24}>
              <p
                style={{
                  fontSize: "15px",
                  fontWeight: "Bold",
                }}
              >
                <samp
                  style={{
                    fontSize: "20px",
                    marginRight: "10px",
                    color: "#002379",
                  }}
                >
                  Description:{" "}
                </samp>
                <span style={{}}>{form?.description}</span>
              </p>
            </Col>
          </Row>
        </Card>
        <br />
        <Card
          style={{ width: "80%", margin: "0 auto", marginBottom: "2rem" }}
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ color: "#002379", fontSize: "20px" }}>
                Questionnaire
              </div>
            </div>
          }
        >
          <Form layout="vertical">
            {questions.map((question, index) => (
              <div key={index} style={{ textAlign: "start" }}>
                <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                  {question.questionRequired && "*"} {question.question}
                </p>

                {question.questionType === "Input" && (
                  <Form.Item
                    name={`question_${index}`}
                    rules={[
                      {
                        required: question.questionRequired,
                        message: `Please answer ${question.question}`,
                      },
                    ]}
                  >
                    <Input
                      style={{ marginBottom: "15px", width: "60%" }}
                      placeholder="répondre à cette question"
                      defaultValue={question.value}
                      readOnly={form.id_form === 0}
                      onChange={(e) =>
                        handleQuestionChange(index, e.target.value)
                      }
                    />
                  </Form.Item>
                )}
                {question.questionType === "Checkbox" && (
                  <>
                    <Form.Item
                      rules={[
                        {
                          required: question.questionRequired,
                          message: `Please select an option for ${question.question}`,
                        },
                      ]}
                    >
                      <CheckboxGroup
                        options={question.questionOptions}
                        value={question.value}
                        onChange={(value) => handleQuestionChange(index, value)}
                        style={{ marginBottom: "15px" }}
                        disabled={form.id_form === 0}
                      />
                    </Form.Item>
                  </>
                )}

                {question.questionType === "Radio" && (
                  <Form.Item
                    rules={[
                      {
                        required: question.questionRequired,
                        message: `Please select an option for ${question.question}`,
                      },
                    ]}
                  >
                    <Radio.Group
                      style={{ marginBottom: "15px" }}
                      onChange={(e) =>
                        handleQuestionChange(index, e.target.value)
                      }
                      value={question.value}
                      disabled={form.id_form === 0}
                    >
                      {question.questionOptions.map((option, idx) => (
                        <Radio key={idx} value={option}>
                          {option}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                )}
              </div>
            ))}
          </Form>
        </Card>

        {form.id_form !== 0 ? (
          <Card
            style={{ width: "80%", margin: "0 auto", marginBottom: "2rem" }}
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ color: "#002379", fontSize: "20px" }}>
                  Signature
                </div>
              </div>
            }
          >
            <Row>
              <Col span={24} style={{ width: "100%", height: "100%" }}>
                {" "}
                <SignatureCanvas
                  ref={(ref) => setSignatureRef(ref)}
                  penColor="#0C0C0C"
                  canvasProps={{
                    style: {
                      cursor: "pointer",
                      marginTop: "4%",
                      width: "100%",
                      height: "40%",
                      border: "1px solid #ddd",
                    },
                  }}
                />
              </Col>
            </Row>

            <div style={{ marginTop: "20px" }}>
              <Button onClick={clearSignature}>Effacer la signature</Button>
            </div>
          </Card>
        ) : (
          <Card
            style={{ width: "80%", margin: "0 auto", marginBottom: "2rem" }}
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ color: "#002379", fontSize: "20px" }}>
                  Signature
                </div>
              </div>
            }
          >
            <Row>
              <Col span={24} style={{ width: "100%", height: "100%" }}>
                <img
                  style={{
                    marginTop: "4%",
                    width: "100%",
                    height: "40%",
                    border: "1px solid #ddd",
                  }}
                  src={form.signature}
                  alt="signature"
                />
              </Col>
            </Row>
          </Card>
        )}

        {form.id_form !== 0 && (
          <Button
            loading={loading}
            style={{
              backgroundColor: "#00800087",
              width: "100%",
              maxWidth: "170px", // Adjust the maxWidth as needed
              marginRight: "3%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            type="text"
            onClick={() => {
              handleSubmit();
            }}
          >
            Envoyer le formumaire
          </Button>
        )}

        <Button
          style={{
            backgroundColor: "#ff000054",
            width: "100%",
            maxWidth: "170px", // Adjust the maxWidth as needed
            marginRight: "3%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          type="text"
          onClick={() => {
            history.goBack();
          }}
        >
          {form.id_form !== 0 ? "Annuler" : "Retour"}
        </Button>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default FormToFill;
