import { useEffect, useRef, useState } from "react";

import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Timeline,
  Tooltip,
  Typography,
  Upload,
  message,
} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import frFR from "antd/lib/locale/fr_FR";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import defaultAvatar from "../assets/images/profileIcon.png";
import { useChat } from "../components/layout/ChatContext";
import InvoiceModalContent from "../components/layout/InvoiceModalContent";
import Wysiwyg from "react-simple-wysiwyg";

import {
  activityService,
  appointmentService,
  documentService,
  formService,
  invoiceService,
  noteService,
  protocoleService,
  reponceService,
} from "../utils/URL";

const PatientFolder = () => {
  const history = useHistory();
  const { form, setSelectedPatient, setForm, setBillData, BillData } =
    useChat();

  const [confirmedBookings, setConfirmedBookings] = useState([]);
  const [pendingBookings, setPendingBookings] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [activities, setActivities] = useState([]);
  const [noteSuivie, setNoteSuivie] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [forms, setForms] = useState([]);
  const [protocoles, setProtocoles] = useState([]);
  const [billModal, setBillModal] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [filteredData, setFilteredData] = useState({
    type: "",
    created_at: "",
  });
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [documentContent, setDocumentContent] = useState(null);
  const [modalDeleteDocument, setModalDeleteDocument] = useState(false);
  const [document, setDocument] = useState(null);
  const [newDocument, setNewDocument] = useState({
    title: "",
    description: "",
    file: null,
  });
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentForm] = Form.useForm();
  const [expandedItems, setExpandedItems] = useState({});

  const toggleExpand = (index) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const handleFileChange = (info) => {
    if (info.fileList.length > 0) {
      documentForm.file = info.fileList[0].originFileObj;
      setNewDocument({ ...newDocument, file: info.fileList[0].originFileObj });
    } else {
      setNewDocument({ ...newDocument, file: null });
    }
  };
  const [description, setDescription] = useState("");
  const token = Cookies.get("token");
  const { id_user } = useParams();

  const handleDeleteDocument = async (id) => {
    try {
      const response = await axios.delete(
        `${documentService}/deleteDocument/${id}`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      setModalDeleteDocument(false);
      await fetchDocuments();
      message.success("Le document est supprimé avec succées");
    } catch (error) {
      message.error("Error deleting document");

      console.error(
        "Error deleting document:",
        error.response ? error.response.data : error.message
      );
      // Optionally handle error messages in the UI
    }
  };
  const showBillModal = () => {
    setBillModal(true);
  };
  const handleShowModal = async (document) => {
    setSelectedDocument(document);
    setIsModalVisible(true);

    try {
      const { file_type, file_url } = document;

      if (file_type.includes("text")) {
        const response = await axios.get(file_url);
        setDocumentContent(response.data); // Set the text content directly
      } else if (file_type.includes("image")) {
        setDocumentContent(file_url); // Directly use the URL for images
      } else if (file_type.includes("pdf")) {
        setDocumentContent(file_url); // Directly use the URL for PDFs
      } else {
        setDocumentContent(null);
        console.error("Unsupported document type");
      }
    } catch (error) {
      console.error("Error fetching the document:", error);
      setDocumentContent("Error loading document content"); // Update modal with error message
    }
  };

  const handleAddDocumentModal = () => {
    setIsAddModalVisible(true);
  };
  const handleAddDocument = async (newDocument) => {
    try {
      setLoading(true);
      const formData = new FormData();

      // Append title, description, and file to the FormData object
      formData.append("title", newDocument.title);
      formData.append("description", newDocument.description);
      formData.append("file", newDocument.file);
      const response = await axios.post(
        `${documentService}/createDocument/${id_user}`,
        newDocument,
        {
          headers: {
            "auth-token": token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      await fetchDocuments();

      // Close the modal after successful submission
      setIsAddModalVisible(false);

      documentForm.resetFields();
      setDefaultFileList([]);
    } catch (error) {
      console.error("Error adding document:", error);
    }

    setLoading(false);
  };

  const handleEditDocument = async (id, updatedDocument) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("title", updatedDocument.title);
      formData.append("description", updatedDocument.description);
      formData.append("file", updatedDocument.file);

      const response = await axios.put(
        `${documentService}/updateDocument/${id}`,
        formData,
        {
          headers: {
            "auth-token": token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      await fetchDocuments();
      setIsAddModalVisible(false);
      documentForm.resetFields();
      setDefaultFileList([]);
    } catch (error) {
      console.error("Error updating document:", error);
      message.error(error.response.data.message);
    }
    setLoading(false);
  };

  const handleAddDocumentSubmit = (values) => {
    handleAddDocument({
      ...newDocument,
      title: values.title,
      description: values.description,
    });
  };
  const handleEditDocumentSubmit = (values) => {
    handleEditDocument(document.id_document, {
      ...newDocument,
      title: values.title,
      description: values.description,
      // file: values.file,
    });
  };
  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedDocument(null);
    setDocumentContent(null);
  };

  const getInitials = (name) => {
    const [firstName, lastName] = name.split(" ");
    const initials = firstName.charAt(0) + lastName.charAt(0);
    return initials.toUpperCase();
  };
  const fetchDInvoices = async (id_doctor) => {
    try {
      const response = await axios.get(
        `${invoiceService}/getAllInvoicesUser/${id_user}`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      const sortedInvoices = response.data.invoices.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      const invoicesList = sortedInvoices.map((invoice) => ({
        img: invoice?.image, // Assuming invoiceImage contains the image URL
        Title: `#${invoice?.invoice_number} `,
        bud: `$${invoice.BillData}`,
        time: moment(`${invoice.invoice_date}`).format("ddd MMM D "),
        paid: invoice.paid ? "payée" : "non payée",
        member: (
          <div className="avatar-group mt-2">
            <Tooltip
              placement="bottom"
              title={`${invoice?.Patient?.user?.first_name} ${invoice?.Patient?.user?.last_name}`}
            >
              <Space>
                <img
                  style={{
                    width: "25px",
                    borderRadius: "50%",
                    border: " 1px black",
                  }}
                  src={invoice?.Patient?.user?.image || defaultAvatar}
                  alt="Avatar"
                />
                <h4>{invoice?.Patient?.user?.first_name} </h4>
              </Space>
            </Tooltip>
          </div>
        ),
        actions: (
          <Space>
            <Button
              type="text"
              style={{ color: "black", border: "1px solid black" }}
              onClick={() => {
                const data = sortedInvoices.find(
                  (inv) => inv.id_invoice === invoice.id_invoice
                );
                setSelectedPatient(data.Patient);
                setBillData(data);
                setInvoiceModal(true);
              }}
            >
              Bilan
            </Button>

            <Button
              type="text"
              style={{ color: "black", border: "1px solid black" }}
              onClick={() => {
                const data = sortedInvoices.find(
                  (inv) => inv.id_invoice === invoice.id_invoice
                );
                setBillData(data);
                showBillModal();
              }}
            >
              Virement
            </Button>
          </Space>
        ),
      }));
      setInvoices(invoicesList);
    } catch (error) {
      console.error("Error fetching invoice:", error);
    }
  };
  const fetchBookings = async () => {
    try {
      const appointmentResponse = await axios.get(
        `${appointmentService}/getAllAppointmentsUser/${id_user}`,
        {
          headers: { "auth-token": token },
        }
      );

      const sortedAppointments = appointmentResponse.data.appointments.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      // Filter appointments by their confirmed status
      // const confirmedAppointments = sortedAppointments.filter(
      //   (appointment) => appointment.confirmed === "confirmed"
      // );
      const confirmedAppointments = sortedAppointments.filter(
        (appointment) => appointment.confirmed !== "pending"
      );

      const pendingAppointments = sortedAppointments.filter(
        (appointment) => appointment.confirmed === "pending"
      );

      // Map the confirmed appointments
      const confirmedBookingList = confirmedAppointments.map((appointment) => ({
        key: appointment.id_appointment,
        name: `${appointment.patient_name} `,
        image: getInitials(`${appointment.patient_name} `),
        time: moment(`${appointment.date} ${appointment.start_time}`).format(
          "ddd MMM D @ hh:mm A"
        ),
        confirmed: appointment.confirmed,
        name_service: appointment?.Service?.name,
        time_service: moment(appointment?.Service?.created_at).format(
          "ddd MMM D @ hh:mm A"
        ),
        duration_service: appointment?.Service?.duration,
        type_service: appointment?.Service?.type,
        video_conferencing: appointment?.video_conferencing,
      }));

      // Map the pending appointments
      const pendingBookingList = pendingAppointments.map((appointment) => ({
        key: appointment.id_appointment,
        name: `${appointment.patient_name} `,
        image: getInitials(`${appointment.patient_name} `),
        time: moment(`${appointment.date} ${appointment.start_time}`).format(
          "ddd MMM D @ hh:mm A"
        ),
        confirmed: appointment.confirmed,
        name_service: appointment?.Service?.name,
        time_service: moment(appointment?.Service?.created_at).format(
          "ddd MMM D @ hh:mm A"
        ),
        duration_service: appointment?.Service?.duration,
        type_service: appointment?.Service?.type,
        video_conferencing: appointment?.video_conferencing,
        location: appointment.buffer_option
          ? ` ${appointment.after_session} minutes after session and ${appointment.before_session} minutes before session `
          : `Buffer options is null`,
      }));

      setConfirmedBookings(confirmedBookingList);
      setPendingBookings(pendingBookingList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchActivities = async () => {
    try {
      const response = await axios.get(
        `${activityService}/getFiltredActivityByUserId/${id_user}?type=${filteredData.type}&created_at=${filteredData.created_at}`
      );

      const sortedData = response.data.query.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      const sortedNoteSuivie = response.data.noteSuivie.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      setActivities(sortedData);
      setNoteSuivie(sortedNoteSuivie);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDocuments = async () => {
    try {
      const response = await axios.get(
        `${documentService}/getDocumentsPatients/${id_user}`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      const sortedData = response.data.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });

      setDocuments(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchFormDoctor = async (id) => {
    try {
      const response = await axios.get(
        `${formService}/getUserForms/${id_user}`,
        {
          headers: { "auth-token": token },
        }
      );
      const sortedForms = response.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      const formsList = sortedForms.map((form) => ({
        name: `${form?.name} `,
        created_at: `${form.created_at}`,

        actions: (
          <Space>
            <Button
              type="text"
              style={{ color: "black", border: "1px solid black" }}
              onClick={() => {
                const data = sortedForms.find(
                  (inv) => inv.id_form === form.id_form
                );
                fetchResponses(id_user, data.id_form);
              }}
            >
              Réponce
            </Button>

            <Button
              type="text"
              style={{ color: "black", border: "1px solid black" }}
              onClick={() => {
                const data = sortedForms.find(
                  (inv) => inv.id_form === form.id_form
                );
                setForm(data);
                history.push("/addForm");
              }}
            >
              Form
            </Button>
          </Space>
        ),
      }));

      setForms(formsList);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };
  const fetchProtocoleDoctor = async () => {
    try {
      const response = await axios.get(
        `${protocoleService}/getAllProtocolsDoctorPatients/${id_user}`,
        {
          headers: { "auth-token": token },
        }
      );
      const sortedProtocoles = response.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      const protocolesList = sortedProtocoles.map((prot) => ({
        name: `${prot?.name} `,
        created_at: `${prot.created_at}`,

        actions: (
          <Button
            type="text"
            style={{ color: "black", border: "1px solid black" }}
            onClick={() => {
              const data = sortedProtocoles.find(
                (pro) => pro.id_protocol === prot.id_protocol
              );
              history.push(`/updateProtocole/${prot.id_protocol}`);
            }}
          >
            Protocole
          </Button>
        ),
      }));

      setProtocoles(protocolesList);
    } catch (error) {
      console.error("Error fetching protocoles:", error);
    }
  };
  const handleOkNote = async () => {
    const updatedNoteFormData = {
      title: "Note du patient",
      content: description,
    };

    try {
      await axios.post(
        `${noteService}/createNotePatient/${id_user}`,
        updatedNoteFormData,
        {
          headers: { "auth-token": token },
        }
      );
      message.success("Votre note est ajoutée avec succès");
      fetchActivities();
      setDescription("");
    } catch (error) {
      console.error("Error creating note:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    }
  };
  const colors = {
    form: "blue",
    appointment: "green",
    invoice: "red",
    note: "purple",
  };
  const { Title, Text } = Typography;

  const fetchResponses = async (id_patient, id_form) => {
    try {
      const response = await axios.get(
        `${reponceService}/getReponcesUser/${id_patient}/${id_form}`
      );
      setForm({
        id_form: 0,
        name: response.data.form.name,
        description: response.data.form.description,
        questions: response.data.reponse.questions,
        created_at: response.data.reponse.created_at,
        updated_at: response.data.reponse.updated_at,
        id_doctor: response.data.form.id_doctor,
        signature: response.data.reponse.signature,
      });
      history.push(`/formToFill/${id_patient}/${id_form}`);
    } catch (error) {
      message.error(`Ce patient  n'a pas remplie le formulaire`);
      console.error("Error fetching patients:", error);
    }
  };

  const [defaultFileList, setDefaultFileList] = useState([]);

  // Populate form values and default file list for update mode
  useEffect(() => {
    setNewDocument({ ...newDocument, file: null });
    if (isUpdateMode && document) {
      documentForm.setFieldsValue({
        title: document.title,
        description: document.description,
        file: document.file,
      });

      setDefaultFileList([
        {
          uid: document.id_document,
          name: document.file_url || "Uploaded File",
          status: "done",
          url: document.file_url,
        },
      ]);
    } else {
      documentForm.resetFields();
      setDefaultFileList([]);
    }
  }, [document, isUpdateMode, documentForm]);
  useEffect(() => {
    fetchActivities();
  }, [filteredData]);

  const [colHeight, setColHeight] = useState(null);
  const firstColRef = useRef(null);
  useEffect(() => {
    fetchDocuments();
    fetchFormDoctor();
    fetchProtocoleDoctor();
    fetchDInvoices();
    fetchBookings();
    if (firstColRef.current) {
      const heightValue = firstColRef.current.clientHeight;
      setColHeight(heightValue);
    }
  }, []);

  const validateFileUpload = (_, value) => {
    if (
      (defaultFileList.length > 0 && isUpdateMode === true) ||
      (newDocument.file !== null && isUpdateMode === false)
    ) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Please upload the document!"));
  };

  // useEffect(() => {
  //   validateFileUpload()
  // }, [newDocument])

  const loadFileFromUrl = async (url, fileName = "document_from_url.txt") => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], fileName, {
        type: blob.type,
        lastModified: Date.now(), // You can also set this to a fixed timestamp if needed
      });
      return {
        ...file,
        uid: `rc-upload-${Date.now()}-${Math.random().toString().slice(2, 8)}`, // Mimics `uid` structure
      };
    } catch (error) {
      console.error("Error fetching file:", error);
      return null;
    }
  };

  return (
    <>
      <Modal
        visible={invoiceModal}
        onCancel={() => {
          setInvoiceModal(false);
        }}
        footer={null}
        width={800}
      >
        <InvoiceModalContent />
      </Modal>
      <Modal
        visible={billModal}
        onCancel={() => {
          setBillModal(false);
        }}
        footer={null}
        width={800}
        height={800}
      >
        {BillData?.image ? (
          <img
            src={BillData.image}
            alt="virment_image"
            style={{
              width: "100%",
              height: "700px",
            }}
          />
        ) : (
          <p>Le virement n'est pas encore envoyé par le patient.</p>
        )}
      </Modal>
      <Card style={{ margin: "10px 0" }}>
        <p
          style={{
            fontSize: "24px", // Adjust the size as needed
            fontWeight: "bold",
            textAlign: "center",
            margin: 0, // To ensure the text is vertically centered within the card
          }}
        >
          {confirmedBookings[0]?.name}{" "}
        </p>
      </Card>

      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col
            ref={firstColRef}
            xs={24}
            sm={24}
            md={12}
            lg={16}
            xl={16}
            className="mb-24"
            style={{ height: "100%" }}
          >
            <Card bordered={false} className="criclebox cardbody ">
              <div className="project-ant">
                <div>
                  <Title level={5}>Note</Title>
                  <Paragraph className="lastweek">
                    Notes suivie patint
                  </Paragraph>
                </div>
              </div>
              <div style={{ paddingLeft: "2%", paddingRight: "1%" }}>
                <div style={{ position: "relative", marginTop: "1%" }}>
                  <Wysiwyg
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{
                      border: "1px solid #d1d1d1",
                      // borderRadius: "8px",
                      backgroundColor: "#f9f9f9",
                      width : "100%",
                      paddingBottom: "6%"
                    }}
                    toolbar={{
                      options: {
                        inline: {
                          options: [
                            "bold",
                            "italic",
                            "underline",
                            "strike-through",
                          ], // Text styles
                        },
                        list: {
                          options: ["unordered", "ordered"], // List options
                        },
                        textAlign: {
                          options: ["left", "center", "right"], // Text alignment
                        },
                        color: {
                          options: [
                            "red",
                            "blue",
                            "green",
                            "orange",
                            "purple",
                            "pink",
                            "#000000",
                            "#FFFFFF", // More color options
                          ], // Text color options
                        },
                        background: {
                          options: [
                            "yellow",
                            "lightblue",
                            "lightgreen",
                            "lightgray",
                            "#F5F5F5",
                            "#FFE4E1", // More background color options
                          ], // Background color options
                        },
                        link: true, // Link option
                        emoji: true, // Emoji option (if needed)
                        image: true, // Image option if you want to allow image uploads
                        remove: true, // Remove formatting option
                        // Additional options can be added here
                      },
                    }}
                  />
                  <Button
                    type="text"
                    style={{
                      position: "absolute",
                      bottom: "10px", // Adjust this value as necessary
                      right: "10px", // Adjust this value as necessary
                      backgroundColor: "#CDFADB",
                      borderRadius: "20%",
                      color: "black",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={handleOkNote}
                  >
                    <PlusOutlined style={{ fontSize: "18px" }} />
                  </Button>
                </div>
              </div>

              <div
                style={{
                  height: `${colHeight - 200}px`,
                  paddingTop: "2%",
                  paddingLeft: "2%",
                  height: "250px",
                  overflowY: "auto",
                }}
              >
                <Timeline className="timelinelistLeft">
                  {noteSuivie.map((activity, index) => {
                    const isExpanded = expandedItems[index];
                    const displayText = isExpanded
                      ? activity.description
                      : activity.description.slice(0, 100) + "..."; // adjust slice length as needed

                    return (
                      <Timeline.Item
                        color={colors[activity.type] || " "}
                        key={index}
                      >
                        <Title level={5}>{activity.name}</Title>
                        <Text>
                          <div>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: displayText,
                              }}
                              style={{
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: isExpanded ? "none" : 2,
                                WebkitBoxOrient: "vertical",
                              }}
                            ></p>
                            <a
                              onClick={() => toggleExpand(index)}
                              style={{
                                color: "#1890ff",
                                cursor: "pointer",
                                textDecoration: "underline",
                                fontSize: "13px",
                              }}
                            >
                              {isExpanded ? "Voir moins" : "Voir plus"}
                            </a>
                          </div>
                        </Text>
                      </Timeline.Item>
                    );
                  })}
                </Timeline>
              </div>
            </Card>
            <br />
            <Card bordered={false} className="criclebox cardbody ">
              <div className="project-ant">
                <div>
                  <Title level={5}>Réservation</Title>
                  <Paragraph className="lastweek">
                    réservation confirmée et non confirmée
                  </Paragraph>
                </div>
              </div>
              <div className="ant-list-box table-responsive">
                {confirmedBookings.length > 0 ? (
                  <div style={{ maxHeight: "350px", overflowY: "auto" }}>
                    <table className="width-100">
                      <thead>
                        <tr>
                          <th>Nom du patient </th>
                          <th>Service</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {confirmedBookings.map((booking, index) => (
                          <tr key={index}>
                            <td>{booking.name}</td>
                            <td>{booking.name_service}</td>
                            <td>{booking.time}</td>
                            <td>
                              {booking.confirmed === "pending"
                                ? "Démandée"
                                : booking.confirmed === "confirmed"
                                ? "Confirmée"
                                : "Non Confirmée"}
                            </td>
                            <td>
                              {booking.video_conferencing === "online"
                                ? "Video Conférence"
                                : booking.video_conferencing === "locale"
                                ? "présentiel"
                                : "Video Conférence ou présentiel"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <Empty description="Pas de réservation" />
                )}
              </div>
            </Card>
            <br />
            <Card bordered={false} className="criclebox cardbody ">
              <div className="project-ant">
                <div>
                  <Title level={5}>Réservation démander</Title>
                  <Paragraph className="lastweek">
                    réservation à confirmer
                  </Paragraph>
                </div>
              </div>
              <div className="ant-list-box table-responsive">
                {pendingBookings.length > 0 ? (
                  <div style={{ maxHeight: "350px", overflowY: "auto" }}>
                    <table className="width-100">
                      <thead>
                        <tr>
                          <th>Patient Name</th>
                          <th>Service</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pendingBookings.map((booking, index) => (
                          <tr key={index}>
                            <td>{booking.name}</td>
                            <td>{booking.name_service}</td>
                            <td>{booking.time}</td>
                            <td>
                              {booking.confirmed === "pending"
                                ? "Démandée"
                                : booking.confirmed === "confirmed"
                                ? "Confirmée"
                                : "Non Confirmée"}
                            </td>
                            <td>
                              {booking.video_conferencing === "online"
                                ? "Video Conférence"
                                : booking.video_conferencing === "locale"
                                ? "présentiel"
                                : "Video Conférence ou présentiel"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <Empty description="Pas de réservation" />
                )}
              </div>
            </Card>
            <br />
            <Card bordered={false} className="circlebox cardbody">
              <div className="flex-container project-ant">
                <div style={{ marginLeft: "2%" }}>
                  <Title level={5}>Documents</Title>
                </div>
                <div style={{ marginTop: "1%", marginRight: "1%" }}>
                  <Button
                    type="text"
                    style={{ backgroundColor: "rgb(205, 250, 219)" }}
                    onClick={(e) => {
                      setIsUpdateMode(false);
                      setDocument(null);
                      handleAddDocumentModal();
                    }}
                  >
                    Ajouter un Document
                  </Button>
                </div>
              </div>
              <div className="ant-list-box table-responsive">
                {documents.length > 0 ? (
                  <div style={{ maxHeight: "350px", overflowY: "auto" }}>
                    <Table
                      dataSource={documents}
                      pagination={false}
                      rowKey="id_document" // Updated to match your document ID key
                    >
                      <Table.Column title="Document Title" dataIndex="title" />
                      <Table.Column
                        title="Description"
                        dataIndex="description"
                      />
                      <Table.Column
                        title="Creation Date"
                        dataIndex="created_at" // Updated to match the API response key
                        render={(date) => (
                          <span>
                            {new Date(date).toLocaleDateString("fr-FR")}
                          </span>
                        )}
                      />
                      <Table.Column
                        title="Actions"
                        render={(text, record) => (
                          <>
                            <Button
                              type="link"
                              onClick={() => {
                                handleShowModal(record);
                                setDocument(record);
                              }}
                            >
                              Affichage
                            </Button>
                            <Button
                              type="link"
                              style={{ color: "orange" }}
                              onClick={async () => {
                                setDocument(record);
                                setIsUpdateMode(true);
                                handleAddDocumentModal();
                                const file = await loadFileFromUrl(
                                  record.file_url,
                                  "document_from_url.txt"
                                );
                                if (file) {
                                  documentForm.setFieldsValue({ file });
                                  setNewDocument({ ...newDocument, file });
                                }
                              }}
                            >
                              Modifier
                            </Button>
                            <Button
                              type="link"
                              danger
                              onClick={() => {
                                setDocument(record);
                                setModalDeleteDocument(true);
                              }}
                            >
                              Supprimer
                            </Button>
                          </>
                        )}
                      />
                    </Table>
                  </div>
                ) : (
                  <Empty description="Pas des documents" />
                )}
              </div>

              {/* Modal for displaying document content */}
              <Modal
                title={selectedDocument?.title}
                visible={isModalVisible}
                onCancel={handleCloseModal}
                footer={null}
                width={800}
              >
                {documentContent ? (
                  <>
                    {/* Image Check */}
                    {document.file_type === "image/jpeg" ||
                    document.file_type === "image/png" ? (
                      <img
                        src={documentContent}
                        alt="Document"
                        style={{ width: "100%" }}
                      />
                    ) : document.file_type === "application/pdf" ? (
                      <iframe
                        src={documentContent}
                        title="PDF Document"
                        style={{ width: "100%", height: "500px" }}
                      />
                    ) : document.file_type === "text/plain" ? (
                      <iframe
                        src={selectedDocument.file_url}
                        title="Text Document"
                        style={{ width: "100%", height: "500px" }}
                      />
                    ) : (
                      <p>Unsupported document type</p>
                    )}
                  </>
                ) : (
                  <p>Loading document content...</p>
                )}
              </Modal>
              <Modal
                title="Confirmation de Suppression"
                visible={modalDeleteDocument}
                onCancel={() => {
                  setModalDeleteDocument(false);
                }}
                footer={null} // Disable default footer
              >
                <p>Voulez-vous vraiment supprimer ?</p>
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setModalDeleteDocument(false);
                    }}
                    style={{ marginRight: "8px" }}
                  >
                    Annuler
                  </Button>
                  <Button
                    type="danger"
                    onClick={() => {
                      handleDeleteDocument(document.id_document);
                    }}
                  >
                    Supprimer
                  </Button>
                </div>
              </Modal>

              <Modal
                title={isUpdateMode ? "Update Document" : "Add New Document"}
                visible={isAddModalVisible}
                onCancel={() => {
                  setIsAddModalVisible(false);
                  documentForm.resetFields();
                }}
                footer={null}
              >
                <Form
                  form={documentForm}
                  onFinish={
                    isUpdateMode
                      ? handleEditDocumentSubmit
                      : handleAddDocumentSubmit
                  }
                >
                  <Form.Item
                    name="title"
                    label="Document Title"
                    rules={[
                      {
                        required: true,
                        message: "Please input the document title!",
                      },
                    ]}
                  >
                    <Input
                      style={{ marginLeft: "5%", width: "93%" }}
                      placeholder="Enter document title"
                    />
                  </Form.Item>
                  <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                      {
                        required: true,
                        message: "Please input the description!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      style={{ marginLeft: "11%", width: "87%" }}
                      placeholder="Enter document description"
                    />
                  </Form.Item>
                  <Form.Item
                    name="file"
                    label="Document"
                    rules={[{ validator: validateFileUpload }]}
                  >
                    <div style={{ marginLeft: "12%" }}>
                      <Upload
                        defaultFileList={defaultFileList}
                        beforeUpload={() => false}
                        onChange={handleFileChange}
                        maxCount={1}
                      >
                        <Button icon={<UploadOutlined />}>
                          Click to Upload
                        </Button>
                      </Upload>
                    </div>
                  </Form.Item>
                  <Form.Item style={{ textAlign: "center", width: "100%" }}>
                    <Button
                      style={{
                        width: "90%",
                        backgroundColor: "rgb(205, 250, 219)",
                      }}
                      loading={loading}
                      type="text"
                      htmlType="submit"
                    >
                      Confirmer
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            </Card>

            <br />
            <Card bordered={false} className="criclebox cardbody">
              <div className="project-ant">
                <div>
                  <Title level={5}>Factures</Title>
                </div>
                <div className="ant-filtertabs"></div>
              </div>
              <div className="ant-list-box table-responsive">
                {invoices.length > 0 ? (
                  <div style={{ maxHeight: "350px", overflowY: "auto" }}>
                    <table className="width-100">
                      <thead>
                        <tr>
                          <th>Nombre du facture</th>
                          <th>Date</th>
                          <th>Budgets</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoices.map((invoice, index) => (
                          <tr key={index}>
                            <td>
                              <h6>{invoice.Title}</h6>
                            </td>
                            <td>{invoice.time}</td>
                            <td>
                              <span className="text-xs font-weight-bold">
                                {invoice.bud}
                              </span>
                            </td>
                            <td>
                              <div className="percent-progress">
                                {invoice.paid}
                              </div>
                            </td>
                            <td>
                              {invoice.actions} {/* Render the actions */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <Empty description="Pas des factures" />
                )}
              </div>
            </Card>
            <br />
            <Card bordered={false} className="criclebox cardbody">
              <div className="project-ant">
                <div>
                  <Title level={5}>Formulaires</Title>
                </div>
              </div>
              <div className="ant-list-box table-responsive">
                <div style={{ maxHeight: "350px", overflowY: "auto" }}>
                  <table className="width-100">
                    <thead>
                      <tr>
                        <th>Nom</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {forms.map((d, index) => (
                        <tr key={index}>
                          <td>
                            <h6>{d.name}</h6>
                          </td>
                          <td>{moment(d.created_at).format("ddd MMM D")}</td>
                          <td>
                            {d.actions} {/* Render the actions */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Card>
            <br />
            <Card bordered={false} className="criclebox cardbody">
              <div className="project-ant">
                <div>
                  <Title level={5}>Protocoles</Title>
                </div>
              </div>
              <div className="ant-list-box table-responsive">
                <div style={{ maxHeight: "350px", overflowY: "auto" }}>
                  <table className="width-100">
                    <thead>
                      <tr>
                        <th>Nom</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {protocoles.map((d, index) => (
                        <tr key={index}>
                          <td>
                            <h6>{d.name}</h6>
                          </td>
                          <td>{moment(d.created_at).format("ddd MMM D")}</td>
                          <td>{d.actions}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Card>
            <br />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            className="mb-24"
            style={{ height: `${colHeight}px` }}
          >
            <Card bordered={false} className="criclebox h-full">
              <div className="timeline-box">
                <br />
                <Title level={5}>Activité recentes</Title>

                <div
                  style={{
                    position: "relative",
                    marginTop: "4%",
                    marginBottom: "3%",
                  }}
                >
                  <Row style={{ margin: "0 auto", width: "100%" }}>
                    {" "}
                    <Col span={12} style={{ paddingRight: "1%" }}>
                      {" "}
                      <Select
                        defaultValue={filteredData.type || ""}
                        style={{
                          width: "100%",
                        }}
                        options={[
                          {
                            value: "",
                            label: "Tous les Categories",
                          },
                          {
                            value: "user",
                            label: "Utilisateur",
                          },
                          {
                            value: "invoice",
                            label: "Facture",
                          },
                          // {
                          //   value: "note_suivie",
                          //   label: "Note du patient",
                          // },
                          {
                            value: "note_reservation",
                            label: "Note de resérvation",
                          },
                          {
                            value: "appointment",
                            label: "Rendz-vous",
                          },
                          {
                            value: "form",
                            label: "Formulaire",
                          },
                          {
                            value: "protocole",
                            label: "Protocole",
                          },
                        ]}
                        onChange={(e) => {
                          setFilteredData({
                            ...filteredData,
                            type: e,
                          });
                        }}
                      />
                    </Col>
                    <Col span={12} style={{ paddingRight: "1%" }}>
                      {" "}
                      <ConfigProvider locale={frFR}>
                        <DatePicker
                          // value={
                          //   filteredData.created_at && filteredData.created_at
                          //     ? moment(filteredData.created_at, "YYYY-MM-DD")
                          //     : null
                          // }
                          format="YYYY-MM-DD"
                          style={{
                            height: "40px",
                            width: "100%",
                            borderRadius: "5px",
                          }}
                          onChange={(date, dateString) => {
                            if (dateString === "") {
                              setFilteredData({
                                ...filteredData,
                                created_at: "",
                              });
                            } else if (date && date.year) {
                              const selectedDate =
                                moment(date).format("YYYY-MM-DD");
                              setFilteredData({
                                ...filteredData,
                                created_at: selectedDate,
                              });
                            }
                          }}
                        />
                      </ConfigProvider>
                    </Col>
                  </Row>
                </div>

                <div
                  className="timeline-container"
                  style={{
                    height: `${colHeight - 200}px`,
                    paddingTop: "20px",
                    maxHeight: "60%",
                    overflowY: "auto",
                  }}
                >
                  <Timeline className="timelinelist">
                    {activities.map((activity, index) => (
                      <Timeline.Item
                        color={colors[activity.type] || " "} // Default color is gray if type is not found in colors object
                        key={index}
                      >
                        <Title level={5}>{activity.name}</Title>
                        <Text>
                          <div>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: activity.description,
                              }}
                            ></p>
                          </div>
                        </Text>
                      </Timeline.Item>
                    ))}
                  </Timeline>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default PatientFolder;
