import React from 'react';
import { Result } from 'antd';
const App = () => (
  <Result
    status="error"
    title=" Failed"
    subTitle="Please return to the signup page"
   
  >

  </Result>
);
export default App;