import {
  Row,
  Col,
  Card,
  Button,
  Tooltip,
  List,
  Space,
  Dropdown,
  Menu,
  Input,
  Modal,
  message,
  Table,
  Upload,
  ConfigProvider,
  DatePicker,
  Checkbox,
  Select,
} from "antd";
import axios from "axios";
import { invoiceService, userServices } from "../utils/URL";
import moment from "moment";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import {
  MoreOutlined,
  PlusOutlined,
  ReloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useChat } from "../components/layout/ChatContext";
import InvoiceModalContent from "../components/layout/InvoiceModalContent";
import frFR from "antd/lib/locale/fr_FR";
import rearange from "../assets/images/rearange.png"; // Import the logo image
import deletingImg from "../assets/images/deletingImg.png"; // Import the logo image

const Billing = () => {
  const history = useHistory();
  const [infoToShow, setInfoToShow] = useState({});
  const [invoices, setInvoices] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [billModal, setBillModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalUploadVisible, setModalUploadVisible] = useState(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
  const [role, setRole] = useState();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [reloadStatus, setReloadStatus] = useState(false);
  const {
    setSelectedPatient,
    setBillData,
    BillData,
    setLastInvoiceId,
    filter,
    setFilter,
  } = useChat();

  const token = Cookies.get("token");
  const [fileList, setFileList] = useState([]);
  const options = [
    {
      label: "Tout",
      value: "",
    },
    {
      label: "Non Confirmé",
      value: "notConfirmed",
    },
    {
      label: "Confirmé",
      value: "confirmed",
    },
    {
      label: "En attente",
      value: "pending",
    },
  ];
  const [searchData, setSearchData] = useState({
    first_name: "",
    legal_first_name: "",
    invoice_date: "",
    bill: "",
    paid: filter.paid ? filter.paid : "",
  });
  const handleChangeDataSearch = (fieldName, value) => {
    setSearchData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };


  const showUploadModal = () => {
    setModalUploadVisible(true);
  };
  const showDeleteModal = () => {
    setModalDeleteVisible(true);
  };
  const handleCancelUploadModal = () => {
    setModalUploadVisible(false);
    handleRemove();
  };
  const handleCancel = () => {
    setModalVisible(false);
  };
  const handleCancelDelete = () => {
    setModalDeleteVisible(false);
  };
  const handleCancelInvoiceModal = () => {
    setInvoiceModal(false);
  };
  const showInvoiceModal = () => {
    setInvoiceModal(true);
  };
  const handleCancelBillModal = () => {
    setBillModal(false);
  };
  const showBillModal = () => {
    setBillModal(true);
  };
  const fetchUserRole = async () => {
    try {
      const response = await axios.get(`${userServices}/getUser`, {
        headers: {
          "auth-token": token,
        },
      });
      setRole(response.data.User.role);
      if (response.data.User.Doctor) {
        fetchDInvoices(response.data.User.Doctor.id_doctor);
        fetchPatients();
      } else {
        fetchPInvoices(response.data.User);
      }
    } catch (error) {
      console.error("Error fetching user role:", error);
    }
  };
  const getInitials = (name) => {
    const [firstName, lastName] = name.split(" ");
    const initials = firstName.charAt(0) + lastName.charAt(0);
    return initials.toUpperCase();
  };
  const fetchDInvoices = async () => {
    try {
      const response = await axios.get(
        `${invoiceService}/searchInvoice?legal_first_name=${searchData.legal_first_name.trim()}&invoice_date=${
          searchData.invoice_date
        }&bill=${searchData.bill.trim()}&paid=${searchData.paid.trim()}`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setLastInvoiceId(response.data.lastInvoiceId);
      const sortedInvoices = response.data.invoices.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      const invoice = sortedInvoices.map((invoice) => ({
        key: invoice?.id_invoice,
        name: `${invoice?.Patient?.user?.first_name} ${invoice?.Patient?.user?.last_name}`,
        image: getInitials(
          `${invoice?.Patient?.user?.first_name} ${invoice?.Patient?.user?.last_name}`
        ),
        time: moment(invoice?.invoice_date).format("ddd MMM D @ hh:mm A"),

        paid: invoice.paid,
        bill: invoice.bill,
        appointment: invoice.Appointments,
        invoiceImage: invoice.image,
        actions: [
          {
            action: "Processus",
            color: "#F08B09",
            icon: rearange,
            onclick: (key) => {
              const data = sortedInvoices.find(
                (invoice) => invoice.id_invoice === key
              );
              if (data.paid === "confirmed") {
                message.error("Cette facture est payé ");
              } else {
                setSelectedPatient(data.Patient);
                setBillData(data);
                history.push("/addBill");
              }
            },
          },
          {
            action: "Supprimer",
            color: "#c1121f",
            icon: deletingImg,
            onclick: (key) => {
              const data = sortedInvoices.find(
                (invoice) => invoice.id_invoice === key
              );
              setBillData(data);
              showDeleteModal();
            },
          },
          // {
          //   action: "info",
          //   onclick: (key) => {
          //     showInfoModal(key);
          //     const data = sortedInvoices.find(
          //       (invoice) => invoice.id_invoice === key
          //     );
          //     console.log(data);
          //     setInfoToShow(data);
          //   },
          // },
          {
            action: "facturation",
            onclick: (key) => {
              const data = sortedInvoices.find(
                (invoice) => invoice.id_invoice === key
              );
              setSelectedPatient(data.Patient);
              setBillData(data);
              showInvoiceModal();
            },
          },
          {
            action: "Virement",
            onclick: (key) => {
              const data = sortedInvoices.find(
                (invoice) => invoice.id_invoice === key
              );
              setSelectedPatient(data.Patient);
              setBillData(data);
              showBillModal();
            },
          },
        ],
      }));

      setInvoices(invoice);
    } catch (error) {
      console.error("Error fetching invoice:", error);
    }
  };
  const fetchPInvoices = async (user) => {
    try {
      const response = await axios.get(
        `${invoiceService}/searchInvoice?first_name=${searchData.first_name.trim()}&invoice_date=${
          searchData.invoice_date
        }&bill=${searchData.bill.trim()}&paid=${searchData.paid.trim()}`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setLastInvoiceId(response.data.lastInvoiceId);
      const sortedInvoices = response.data.invoices.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      const invoice = sortedInvoices.map((invoice) => ({
        key: invoice?.id_invoice,
        name: `${invoice?.Doctor?.user?.first_name} ${invoice?.Doctor?.user?.last_name}`,
        image: getInitials(
          `${invoice?.Doctor?.user?.first_name} ${invoice?.Doctor?.user?.last_name}`
        ),
        time: moment(invoice?.invoice_date).format("ddd MMM D @ hh:mm A"),

        paid: invoice.paid,
        bill: invoice.bill,
        appointment: invoice.Appointments,
        actions: [
          {
            action: "facturation",
            onclick: (key) => {
              const data = sortedInvoices.find(
                (invoice) => invoice.id_invoice === key
              );
              setSelectedPatient(user);
              setBillData(data);
              showInvoiceModal();
            },
          },
          {
            action: "Payer",
            onclick: (key) => {
              const data = sortedInvoices.find(
                (invoice) => invoice.id_invoice === key
              );
              setBillData(data);
              showUploadModal();
            },
          },
          // {
          //   action: "info",
          //   onclick: (key) => {
          //     showInfoModal(key);
          //     const data = sortedInvoices.find(
          //       (invoice) => invoice.id_invoice === key
          //     );
          //     console.log(data);
          //     setInfoToShow(data);
          //   },
          // },
        ],
      }));

      setInvoices(invoice);
    } catch (error) {
      console.error("Error fetching invoice:", error);
    }
  };
  const deleteInvoice = async (id) => {
    try {
      await axios.delete(`${invoiceService}/deleteInvoice/${id}`);

      fetchUserRole();
      message.success("facture est supprimé avec succée");
      handleCancelDelete();
      setLoading1(false);
    } catch (error) {
      console.error("Error deleting patients:", error);
      message.error(error.response.data.message || "Erreur interne du serveur");
      setLoading1(true);
    }
  };
  const fetchPatients = async () => {
    try {
      const response = await axios.get(`${userServices}/getPatients`, {
        headers: {
          "auth-token": token,
        },
      });
      const sortedPatients = response.data.patients.sort((a, b) =>
        `${a.user.first_name} ${a.user.last_name}`.localeCompare(
          `${b.user.first_name} ${b.user.last_name}`
        )
      );
      setPatients(sortedPatients);
      setFilteredPatients(sortedPatients);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };
  const openModal = () => {
    setModalOpen(true);
    // setMode("book");
  };
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = patients.filter(
      (patient) =>
        patient.user.first_name.toLowerCase().includes(value) ||
        patient.user.last_name.toLowerCase().includes(value) ||
        patient.user.email.toLowerCase().includes(value)
    );
    setFilteredPatients(filtered);
  };
  const handleUpload = async (id) => {
    try {
      let image = null;

      if (fileList.length > 0) {
        const file = fileList[0].originFileObj;
        if (file instanceof Blob) {
          const imageData = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = reject;
          });

          image = imageData;
        } else {
          image = null;
        }
      }
      if (image !== null) {
        const response = await axios.put(
          `${invoiceService}/addBillImage/${id}`,
          {
            image,
          }
        );
        if (response.status === 200) {
          message.success("Image uploaded successfully");
        } else {
          message.error("Failed to upload image");
        }
        handleRemove();
        try {
          await axios.put(`${invoiceService}/payInvoice/${id}`, {
            paid: "pending",
          });

          fetchUserRole();
          setLoading(false);

          // message.success("facture est modifiée avec succée");
          handleCancelUploadModal();
        } catch (error) {
          console.error("Error deleting patients:", error);
          message.error(
            error.response.data.message || "Erreur interne du serveur"
          );
          setLoading(false);
        }
      } else {
        message.error("Failed to upload image");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      message.error("Failed to upload image");
      setLoading(false);
    }
  };
  const handleChange = ({ file, fileList: newFileList }) => {
    file.status = "done";
    setFileList(newFileList);
  };
  const handleRemove = () => {
    setFileList(null);
  };


  useEffect(() => {
    setSearchData({
      ...searchData,
      legal_first_name: "",
      first_name: "",
      invoice_date: "",
      bill: "",
      paid: filter.paid ? filter.paid : "",
    });

    fetchUserRole();
  }, [reloadStatus]);

  return (
    <>
      <Modal
        title="Rendez-vous pour cette facture"
        visible={modalVisible}
        onCancel={handleCancel}
        footer={null}
        style={{ top: 10 }}
      >
        <Table
          dataSource={
            infoToShow &&
            infoToShow.Appointments &&
            Array.isArray(infoToShow.Appointments)
              ? infoToShow.Appointments
              : []
          }
          pagination={false}
          rowKey="id_appointment"
          columns={[
            {
              title: "Date",
              dataIndex: "date",
              key: "date",
              render: (text) => moment(text).format("MMM D, YYYY"),
            },
            {
              title: "Start Time",
              dataIndex: "start_time",
              key: "start_time",
              render: (text) => moment(text, "HH:mm:ss").format("hh:mm A"),
            },
            {
              title: "End Time",
              dataIndex: "end_time",
              key: "end_time",
              render: (text) => moment(text, "HH:mm:ss").format("hh:mm A"),
            },
          ]}
        />
      </Modal>
      <Modal
        title={null}
        visible={modalDeleteVisible}
        onCancel={handleCancelDelete}
        footer={null}
        centered
        style={{ textAlign: "center" }}
      >
        <p
          style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "20px" }}
        >
          Voulez-vous confirmer la suppression de cette facture ?
        </p>
        <Button
          loading={loading1}
          style={{ backgroundColor: "red", color: "white" }}
          type="text"
          onClick={() => {
            setLoading1(true);
            deleteInvoice(BillData.id_invoice);
          }}
        >
          Confirmer
        </Button>
      </Modal>
      <Modal
        title="télecharger votre virement"
        visible={modalUploadVisible}
        onCancel={handleCancelUploadModal}
        footer={null}
        width={600}
        centered
      >
        <Row style={{ margin: "0 auto" }}>
          <Col span={20}>
            <Upload
              listType="picture"
              fileList={fileList}
              onChange={handleChange}
            >
              <Button icon={<UploadOutlined />}>Télécharger le virement</Button>
            </Upload>
          </Col>
          <Col span={4}>
            <Button
              loading={loading}
              type="primary"
              onClick={() => {
                setLoading(true);
                handleUpload(BillData.id_invoice);
              }}
            >
              Envoyer
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={invoiceModal}
        onCancel={handleCancelInvoiceModal}
        footer={null}
        width={800}
      >
        <InvoiceModalContent />
      </Modal>
      <Modal
        visible={billModal}
        onCancel={handleCancelBillModal}
        footer={null}
        width={800}
        height={800}
      >
        {BillData?.image ? (
          <img
            src={BillData.image}
            alt="virment_image"
            style={{
              width: "100%",
              height: "700px",
            }}
          />
        ) : (
          <p>Le virement n'est pas encore envoyé par le patient.</p>
        )}
      </Modal>
      {role === "doctor" && (
        <div
          className="setting-drwer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#c1121f",
            color: "white",
            fontSize: "25px",
          }}
          onClick={openModal}
        >
          <PlusOutlined />
        </div>
      )}

      <Modal
        title="Sélectionnez un patient"
        centered
        visible={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={[
          <div
            key="footer"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              key="cancel"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Annuler
            </Button>
          </div>,
        ]}
      >
        <Input
          type="text"
          placeholder="Rechercher par nom ou par email "
          value={searchTerm}
          onChange={handleSearch}
          style={{ marginBottom: "16px" }}
        />

        {filteredPatients.map((patient) => (
          <Space
            key={patient.id_patient}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedPatient(patient);
              setBillData(null);
              history.push("/addBill");
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  backgroundColor: "lightblue",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "8px",
                }}
              >
                {getInitials(
                  `${patient.user.first_name} ${patient.user.last_name}`
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <strong>{`${patient.user.first_name} ${patient.user.last_name}`}</strong>
              <span
                style={{
                  fontWeight: "bold",
                  color: "#C7C8CC",
                  fontSize: "12px",
                  marginLeft: "7%",
                  width: "100%",
                }}
              >
                {patient.Doctors.length > 0 ? "Invité" : "Non invité"}
              </span>
            </div>
          </Space>
        ))}
      </Modal>

      <Row gutter={[24, 0]}>
        <Col span={24} md={24} className="mb-24">
          <Card
            className="criclebox formTable tablespace mb-24 "
            // title={
            //   <div className="title-with-line">
            //     <h6 className="font-semibold m-0">
            //       Informations de facturation
            //     </h6>
            //   </div>
            // }
            extra={
              <Row
                gutter={[16, 0]}
                align="middle"
                style={{ width: "100%", backgroundColor: "#ffffff" }}
              >
                {" "}
                <Col flex="auto" style={{ width: "20%" }}>
                  <Input
                    value={
                      role === "doctor"
                        ? searchData?.legal_first_name
                        : searchData?.first_name
                    }
                    onChange={(e) => {
                      const key =
                        role === "doctor" ? "legal_first_name" : "first_name";
                      handleChangeDataSearch(key, e.target.value);
                    }}
                    style={{ height: "40px" }}
                    placeholder={
                      role === "doctor"
                        ? "Nom Légale du patient"
                        : "Nom du médecin"
                    }
                  />
                </Col>
                <Col flex="auto" style={{ width: "20%" }}>
                  <Input
                    value={searchData?.bill}
                    onChange={(e) => {
                      handleChangeDataSearch("bill", e.target.value);
                    }}
                    style={{ height: "40px" }}
                    placeholder="Prix de la facture"
                  />
                </Col>
                <Col flex="auto" style={{ width: "20%" }}>
                  <Select
                    value={searchData?.paid}
                    onChange={(e) => {
                      handleChangeDataSearch("paid", e);
                      setFilter({})
                    }}
                    style={{ height: "40px", width: "100%" }}
                    placeholder="Status de la facture"
                    options={options}
                  />
                </Col>
                <Col flex="auto" style={{ width: "20%" }}>
                  <ConfigProvider locale={frFR}>
                    <DatePicker
                      value={
                        searchData.invoice_date
                          ? moment(searchData.invoice_date)
                          : null
                      }
                      onChange={(value) => {
                        handleChangeDataSearch(
                          "invoice_date",
                          value ? value.format("YYYY-MM-DD") : ""
                        );
                      }}
                      style={{
                        height: "40px",
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="Date de la facture"
                    />
                  </ConfigProvider>
                </Col>
                <Col flex="none" style={{ width: "20%" }}>
                  <Row gutter={[5, 0]}>
                    <Col span={20}>
                      {" "}
                      <Button
                        type="text"
                        style={{
                          backgroundColor: "#CDFADB",
                          height: "40px",
                          width: "100%",
                        }}
                        onClick={async () => {
                          await fetchUserRole();
                        }}
                      >
                        Chercher
                      </Button>
                    </Col>
                    <Col span={4}>
                      <Button
                        type="text"
                        style={{
                          backgroundColor: "#CDFADB",
                          height: "40px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center", // Center horizontally
                          alignItems: "center", // Center vertically
                        }}
                        onClick={async () => {
                          setFilter({})
                          setSearchData({
                            ...searchData,
                            first_name: "",
                            legal_first_name: "",
                            invoice_date: "",
                            bill: "",
                            paid: "",
                          });
                          reloadStatus
                            ? setReloadStatus(false)
                            : setReloadStatus(true);
                        }}
                      >
                        <ReloadOutlined style={{ fontSize: "20px" }} />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
            bodyStyle={{ paddingTop: "0" }}
          >
            <div className="table-responsive">
              <List
                dataSource={invoices}
                renderItem={(item, index) => (
                  <List.Item
                    key={`${item.key}_${index}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid #f0f0f0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        width: "500px",
                      }}
                    >
                      {" "}
                      <Tooltip
                        title={
                          item.paid === "confirmed"
                            ? "payée"
                            : item.paid === "pending"
                            ? "en attente"
                            : "Non payée"
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "5px",
                          }}
                        >
                          {item.paid === "confirmed" ? (
                            <div
                              style={{
                                marginTop: "3%",
                                marginLeft: "4%",
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                backgroundColor: "#AAD9BB",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "12px",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              {item.image}
                            </div>
                          ) : item.paid === "pending" ? (
                            <div
                              style={{
                                marginTop: "3%",
                                marginLeft: "4%",
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                backgroundColor: "#E59BE9",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "12px",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              {item.image}
                            </div>
                          ) : (
                            <div
                              style={{
                                marginTop: "3%",
                                marginLeft: "4%",
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                backgroundColor: "#FFBB64",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "12px",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              {item.image}
                            </div>
                          )}
                        </div>
                      </Tooltip>
                      <div>
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            lineHeight: "33px",
                          }}
                        >
                          {item.name}
                        </p>
                        <p
                          style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "22px",
                          }}
                        >
                          {item.time}
                        </p>{" "}
                      </div>
                    </div>
                    <div className="bill-container">
                      <p className="bill">{item?.bill?.toFixed(2)} $</p>
                    </div>

                    <Space direction="horizontal">
                      {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          marginRight: "24%",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            background: "none",
                            border: "none",
                            color: "#6e6e6e",
                          }}
                        >
                          {item.confirmed ? (
                            <span>confirmée</span>
                          ) : (
                            <span>non confirmée</span>
                          )}
                        </div>
                      </div> */}

                      <div style={{ display: "flex", marginLeft: "10px" }}>
                        {item.actions.map((action, index) => (
                          <div
                            key={index}
                            style={{
                              fontWeight: "bold",
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                              marginLeft: index === 0 ? "0" : "15px",
                              marginRight: index === 0 ? "0" : "50px",
                              marginTop: "20%",
                              color: action.color || "#0D9276",
                              textDecoration: "underline",
                              alignItems: "center",
                            }}
                            onClick={() => action.onclick(item.key)}
                          >
                            {action?.icon && (
                              <img
                                src={action.icon}
                                alt={`${action.action} icon`}
                                style={{ marginRight: "8px", height: "16px" }}
                              />
                            )}
                            {action.action}{" "}
                          </div>
                        ))}
                      </div>
                      {/* <Dropdown overlay={menu}>
                        <a
                          onClick={(e) => {
                            
                          }}
                          onMouseEnter={(e) => console.log(e.target.value)}
                        >
                          <MoreOutlined
                            style={{ marginLeft: "12px", marginTop: "15px" }}
                          />
                        </a>
                      </Dropdown> */}
                    </Space>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Billing;
