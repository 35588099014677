import React, { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import { UserChat } from "../components/layout/UserChat";
import { Button, Col, Input, List, Modal, Row } from "antd";
import { ChatBox } from "../components/layout/ChatBox";
import { messageService, conversationService, chatUrl } from "../utils/URL";
import Cookies from "js-cookie";
import axios from "axios";
import { useChat } from "../components/layout/ChatContext";
import { useHistory } from "react-router-dom";
import VideoChat from "./VideoChat";

const Chat = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [userListChats, setUserListChats] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [Calling, setCalling] = useState(false);

  const {
    receaivedMessage,
    answerCall,
    call,
    callAccepted,
    socket,
    leaveCall,
    myVideo,
    setStream,
    setCall,
    setMe,
  } = useChat();
  const [deleteConv, setDeleteConv] = useState(false);

  const getUserListChats = async () => {
    try {
      const token = Cookies.get("token");
      const response = await axios.get(
        `${conversationService}/getConversationByUserID`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setUserListChats(response.data);
    } catch (error) {
      console.error("Confirmation failed:", error);
    }
  };


  

  // useEffect(() => {
  //   navigator.mediaDevices
  //     .getUserMedia({ video: true, audio: true })
  //     .then((currentStream) => {
  //       setStream(currentStream);
  //       myVideo.current.srcObject = currentStream;
  //     })
  //     .catch((error) => {
  //       console.error("Error accessing media devices:", error);
  //     });

  //   if (socket) {
  //     socket.on("me", (id) => setMe(id));

  //     socket.on("callUserV", ({ userToCall, from, name, signal }) => {
  //       setCall({ isReceivingCall: true, from, name, signal });
  //     });
  //   }
  // }, [socket]);

  const getMessages = async (conversationId) => {
    try {
      const token = Cookies.get("token");
      const response = await axios.get(
        `${messageService}/getMessagesByConversationId/${conversationId}`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setMessages(response.data);
      setIsMessageLoading(false);
    } catch (error) {
      console.error("Confirmation failed:", error);
    }
  };

  useEffect(() => {
    getUserListChats();
  }, []);
  useEffect(() => {
    setIsModalOpen(call.isReceivingCall);
  }, [call.isReceivingCall]);

  useEffect(() => {
    if (selectedUser?.id_conversation) {
      setMessages((prevMessages) => [...prevMessages, receaivedMessage]);
      getMessages(selectedUser?.id_conversation);
    }
  }, [receaivedMessage]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // useEffect(() => {
  //   let timer;
  //   if ((isModalOpen && !callAccepted) || Calling) {
  //     timer = setTimeout(() => {
  //       // leaveCall();
  //       window.location.reload();
  //     }, 10000); // 20 seconds
  //   }

  //   return () => clearTimeout(timer);
  // }, [isModalOpen, callAccepted, leaveCall, Calling]);

  // useEffect(() => {
  //   if (call.isReceivingCall && !callAccepted) {
  //     // Create an audio element dynamically
  //     const audio = new Audio(require("../assets/Sounds/calsound.mp3").default);

  //     // Play the audio
  //     audio.play();

  //     audio.addEventListener("ended", () => {
  //       // Handle when audio ends
  //       console.log("Audio ended");
  //     });

  //     return () => {
  //       audio.pause();
  //     };
  //   }
  // }, [call, callAccepted]);

  // useEffect(() => {
  //   if (callAccepted) {
  //     setCalling(false);
  //   }
  // }, [callAccepted]);

  return (
    <div className="specialCard">
      {call.isReceivingCall && !callAccepted && (
        <Modal
          title={`${call.name} is calling:`}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          closable={false}
        >
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                answerCall();
                // history.push("/VideoChat");
              }}
            >
              Répondre
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                leaveCall();
              }}
            >
              raccrocher
            </Button>
          </div>
        </Modal>
      )}
      {callAccepted && (
        <VideoChat
          style={{
            width: "75%",
            height: "75%",
          }}
        />
      )}
      {Calling && !callAccepted && (
        <VideoChat
          style={{
            width: "75%",
            height: "75%",
          }}
        />
      )}
      <Row className="containerr">
        <Col
          span={6}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            paddingRight: "5px",
          }}
        >
          <div style={{ flex: 1 }}>
            <div style={{ paddingTop: "3%", height: "720px", width: "100%" }}>
              <div className="chat-color" style={{}}>
                <Input
                  placeholder="Recherche"
                  style={{ height: "40px" }}
                  className="chercheUsers"
                />
                {/* <PotentialChat getUserListChats={getUserListChats} /> */}
                <Stack direction="horizontal" className="align-items-start">
                  <Stack className="messages-box flex-grow-0 " gap={3}>
                    <List
                      style={{ height: "70%", overflowX: "hidden" }}
                      dataSource={userListChats || []}
                      renderItem={(chat, index) => (
                        <List.Item
                          onClick={() => {
                            setSelectedUser(chat);
                            setIsMessageLoading(true);
                            setDeleteConv(false);
                            getMessages(chat.id_conversation);

                            if (socket) {
                              socket.emit("giveMeYourId", {
                                requestId: socket.id,
                              });
                            }
                          }}
                        >
                          <UserChat
                            chat={chat}
                            User={chat}
                            style={{ marginRight: "10px" }}
                          />
                        </List.Item>
                      )}
                    />
                  </Stack>
                </Stack>
              </div>
            </div>
          </div>
        </Col>
        <Col span={18} className="">
          {/* <Card style={{ height: "720px" }}> */}
          <ChatBox
            style={{ paddingTop: "3%" }}
            recipientUser={selectedUser}
            messages={messages}
            setMessages={setMessages}
            getMessages={getMessages}
            isMessageLoading={isMessageLoading}
            getUserListChats={getUserListChats}
            deleteConv={deleteConv}
            setDeleteConv={setDeleteConv}
            setCalling={setCalling}
          />
          {/* </Card> */}
        </Col>
      </Row>
    </div>
  );
};

export default Chat;
