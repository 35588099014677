import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Typography, Row, DatePicker, Space, Button } from "antd";
import axios from "axios";
import { userServices, appointmentService } from "../../utils/URL";
import { ReloadOutlined } from "@ant-design/icons";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import moment from "moment";

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;
const { Title } = Typography;

const LineChart = () => {
  const [patientData, setPatientData] = useState();
  const [appointmentData, setAppointmentData] = useState();
  const [requestData, setRequestData] = useState(); // State for request data

  const [dates, setDates] = useState();
  const token = Cookies.get("token");

  const today = new Date();
  const endDateFormat = today.toLocaleDateString("en-CA");
  // setEndDate(endDateFormat);
  const [endDate, setEndDate] = useState(endDateFormat);

  // Set the startDate to the first day of the current month
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const startDateFormat = firstDayOfMonth.toLocaleDateString("en-CA");
  const [startDate, setStartDate] = useState(startDateFormat);
  if (startDate === endDate) {
    const month = firstDayOfMonth.getMonth() + 1; // Months are zero-based, so add 1
    if (month === 1) {
      setStartDate(
        new Date(today.getFullYear() - 1, 11, 15).toLocaleDateString("en-CA")
      );
    } else {
      setStartDate(
        new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          15
        ).toLocaleDateString("en-CA")
      );
    }
  }
  // Define a function to format dates based on the type of array
  const formatDate = (date, arrayType) => {
    switch (arrayType) {
      case "days":
        const day = date.getDate().toString().padStart(2, "0"); // Get day part
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month part
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
      case "months":
        return `${date.getMonth() + 1}/${date.getFullYear()}`; // Get month/year
      case "years":
        return date.getFullYear().toString(); // Get only the year
      default:
        return "";
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.post(
        `${userServices}/KpiChartUser`,
        {
          startDate: startDate,
          endDate: endDate,
        },
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      let dateVar;

      if (response.data.years.length >= 1) {
        dateVar = "years";
      } else if (response.data.days.length > 31) {
        dateVar = "months";
      } else if (response.data.days.length <= 31) {
        dateVar = "days";
      }

      const Array = response.data[dateVar];
      const patientList = Array.map((day) => ({
        count: day.patients,
      })).map((entry) => entry.count);

      const dateList = Array.map((day) => {
        const date = new Date(day.date);
        return formatDate(date, dateVar); // Pass the array type for formatting
      });

      setPatientData(patientList);
      setDates(dateList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAppointmentData = async () => {
    try {
      const response = await axios.post(
        `${appointmentService}/KpiChartAppointments`,
        {
          startDate: startDate,
          endDate: endDate,
        },
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      let Array;

      if (response.data.years.length >= 1) {
        Array = response.data.years;
      } else if (response.data.days.length > 31) {
        Array = response.data.months;
      } else if (response.data.days.length <= 31) {
        Array = response.data.days;
      }

      // Extracting lists of candidates, clients, and dates from the "days" array

      const appointmentList = Array.map((day) => ({
        count: day?.appointments,
      })).map((entry) => entry.count);

      // const dateList = Array.map(day => new Date(day.date).toLocaleDateString()).sort();
      setAppointmentData(appointmentList);
      // setDates(dateList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
    fetchAppointmentData();
  }, [startDate, endDate]);

  const initialSeries = [
    {
      name: "patients",
      data: patientData || [], // Ensure it's not undefined,
      offsetY: 0,
      color: "#B37FEB",
    },

    {
      name: "Rendez-vous ",
      data: appointmentData || [], // Ensure it's not undefined
      offsetY: 0,
      color: "#1890FF",
    },
  ];

  const lineChart = {
    series: initialSeries,
    options: {
      chart: {
        id: "line-chart",
        width: "100%",
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },

      legend: {
        onItemClick: {
          toggleDataSeries: true, // Set to false to disable toggling on item click
        },
        onItemHover: {
          highlightDataSeries: true, // Set to false to disable highlighting on item hover
        },
        position: "top", // Display legend above the chart
        horizontalAlign: "right", // Align legend items to the left
        floating: true, // Make the legend float above the chart
        fontSize: "14px", // Set font size for legend items
        fontFamily: "Roboto, sans-serif", // Specify the font family for legend
        offsetY: -5, // Move the legend 20 pixels higher (adjust the value as needed)

        labels: {
          colors: "#8c8c8c", // Set the color for legend text
        },
        markers: {
          size: 16,
          radius: 4,
          strokeWidth: 0,
        },
      },

      markers: {
        size: 1, // Set the size of legend markers
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 4,
      },

      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: 600,
            colors: ["#8c8c8c"],
          },
        },
      },

      // Update x-axis categories
      xaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: 600,
          },
          ticks: {
            fontColor: "#8c8c8c", // this here
          },
        },
        categories: dates,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            show: false,
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };

  const { series, options } = lineChart;

  const [seriesVisibility, setSeriesVisibility] = useState(
    Array(series.length).fill(true)
  );

  const handleLegendClick = (seriesIndex) => {
    const updatedVisibility = [...seriesVisibility];
    updatedVisibility[seriesIndex] = !updatedVisibility[seriesIndex];
    setSeriesVisibility(updatedVisibility);
  };

  const getFilteredSeries = () => {
    return series.filter((_, index) => seriesVisibility[index]);
  };

  // Group the series into pairs
  const groupedSeries = [];
  for (let i = 0; i < series.length; i += 2) {
    groupedSeries.push(series.slice(i, i + 2));
  }

  return (
    <>
      <Row className="linechart" gutter={[16, 0]}>
        <Space
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "1%",
          }}
        >
          <h4 style={{ marginLeft: "2%" , width: "100%" }}>
            Votre activité : 
          </h4>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "6%",
            }}
          >
            <RangePicker
              style={{ height: "40px", borderRadius: "5px" }}
              placeholder={["Date de début", "Date de fin"]}
              className="rangePicker"
              allowClear={false} // Hide the clear button
              value={[moment(startDate), moment(endDate)]}
              onChange={(dates) => {
                setStartDate(dates[0]);
                setEndDate(dates[1]);
              }}
            />
            <Button
              type="primary"
              style={{
                width: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "5px",
              }}
              icon={
                <ReloadOutlined
                  style={{ fontSize: "20px", marginLeft: "2px" }}
                />
              }
              onClick={() => {
                setStartDate(startDateFormat);
                setEndDate(endDateFormat);
              }}
            />{" "}
          </div>
        </Space>
      </Row>

      <ReactApexChart
        className="full-width"
        options={options}
        series={getFilteredSeries()}
        type="line"
        height={473}
        width={"100%"}
        events={{
          legendClick: function (event, chartContext, seriesIndex) {
            handleLegendClick(seriesIndex);
          },
        }}
      />
    </>
  );
};

export default LineChart;
