import React, { useState } from "react";
import { Layout, Button, Typography, Card, Form, Input, message } from "antd";
import logo from "../assets/images/whiteLogo.png"; // Import the logo image

import { Link } from "react-router-dom";

import axios from "axios";
import Cookies from "js-cookie";
import { userServices } from "../utils/URL";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

const SignIn = () => {
  const onFinish = async (values) => {
    try {
      enterLoading(1);
      const response = await axios.post(`${userServices}/login`, values);
      if (response.data.user.role === "admin") {
        message.error("Vous n'êtes pas autorisé à accéder à cette page");
      } else {
        const token = response.data.token;
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 1); // 24 hours from now

        Cookies.set("token", token, { expires: expirationDate });

        

        setLoadings(false);

        if (response.data.user.role === "patient"){
          window.location.href = "/MyEvents";
        }else{
          window.location.href = "/dashboard";
        }
        
      }
    } catch (error) {
      setLoadings(false);
      console.error("Login failed:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Erreur interne du serveur");
      }
    }
  };
  const onFinishFailed = (errorInfo) => {
    setLoadings(false);
  };

  const [loadings, setLoadings] = useState(false);
  const enterLoading = () => {
    setLoadings(true);
  };

  return (
    <>
      <div className="layout-default ant-layout layout-sign-up">
        <Header>
          <div className="header-col header-brand">
            <img
              src={logo}
              alt="Logo"
              style={{ width: "60%", marginTop: "3%" }}
            />
          </div>
        </Header>

        <Content className="p-0">
          <div className="verification-header">
            <div className="content">
              <Title>Se connecter</Title>
              <p className="text-lg">
                Utilisez ces superbes formulaires pour vous connecter ou vous
                connecter avec votre compte.
              </p>
            </div>
          </div>

          <Card
            className="card-signup header-solid h-full ant-card pt-0"
            title={<h5>Se connecter </h5>}
            bordered="false"
          >
            <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              className="row-col"
            >
              <Form.Item
                className="username"
                style={{ with: "100%" }}
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir votre email !",
                  },
                  {
                    type: "email",
                    message:
                      "S'il vous plaît, mettez une adresse email valide!",
                  },
                ]}
              >
                <Input placeholder="Email" style={{ height: "40px" }} />
              </Form.Item>

              <Form.Item
                className="username"
                style={{ with: "100%" }}
                label="Mot de passe"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir votre mot de passe !",
                  },
                ]}
              >
                <Input.Password placeholder="password" type="password" />
              </Form.Item>

              <Form.Item>
                <Button
                  loading={loadings}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%", marginTop: "10%" }}
                >
                  Se connecter
                </Button>
              </Form.Item>
              <div style={{ textAlign: "center", marginBottom: "10px" }}>
                <p className="font-semibold text-muted">
                  ou{" "}
                  <Link to="/forgot-password" className="text-dark font-bold">
                    Mot de passe oublié
                  </Link>
                </p>
                <div style={{ width: "50%", margin: "auto" }}>
                  <hr
                    style={{
                      borderTop: "1px  #adb5bd",
                      borderColor: "#adb5bd",
                    }}
                  />
                </div>
              </div>
              <p className="font-semibold text-muted">
                Vous n'avez pas de compte ?{" "}
                <Link to="/sign-up" className="text-dark font-bold">
                  S'inscrire
                </Link>
              </p>
            </Form>
          </Card>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2024 PB par <a href="#pablo">créatif</a>.{" "}
          </p>
        </Footer>
      </div>
    </>
  );
};
export default SignIn;
