import React, { useEffect, useState } from "react";
import axios from "axios";
import logo from "../assets/images/whiteLogo.png"; // Import the logo image
import {
  Layout,
  Button,
  message,
  Card,
  Select,
  Row,
  Col,
  Form,
  Input,
} from "antd";
import {
  appointmentService,
  userServices,
  availibilityService,
  LocationService,
} from "../utils/URL";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Footer, Header } from "antd/lib/layout/layout";
import Cookies from "js-cookie";
const { Content } = Layout;
const { Option } = Select;

const UpdateBooking = () => {
  const token = Cookies.get("token");

  const [services, setServices] = useState([]);

  const [appointment, setAppointment] = useState();
  const [availabilityData, setAvailabilityData] = useState([]);
  const [loadings, setLoadings] = useState(false);
  const [doctor, setDoctor] = useState();
  const [dates, setDates] = useState([]);
  const [filteredAvailabilities, setFilteredAvailabilities] = useState([]);
  const [localData, setLocalData] = useState();

  const fetchData = async (id) => {
    try {
      const response = await axios.get(
        `${LocationService}/getLocationById/${id}`
      );
      setLocalData(response.data.location);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [formData, setFormData] = useState({
    id_patient: "",
    date: "",
    start_time: "",
    end_time: "",
    id_locale: "",
    id_service:""
  });
  const enterLoading = () => {
    setLoadings(true);
  };
  const [selectedDate, setSelectedDate] = useState("");

  const handleChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };
  const { confirmation_token, verification_token } = useParams();
  const getAppointment = async () => {
    try {
      const response = await axios.get(
        `${appointmentService}/getAppointment/${confirmation_token}`
      );
      await fetchAvailibilities(response.data);
      setAppointment(response.data);
      const response2 = await axios.get(
        `${userServices}/getDoctor/${response.data.id_doctor}`
      );
      setDoctor(response2.data);
      handleChange("id_patient", response.data?.id_patient);
    } catch (error) {
      console.error("Confirmation failed:", error);
    }
  };

  const fetchAvailibilities = async (app) => {
    try {
      const response = await axios.post(
        `${availibilityService}/getAllAvailabilityByToken/${verification_token}`,{availibilityExeption : app?.Availability}
      );
      
      setAvailabilityData(response.data);
      // Get today's date
      const today = new Date();
      // Filter availability data for dates in the future and remove duplicates
      const uniqueFutureDates = Array.from(
        new Set(
          response.data
            .map((item) => new Date(item.date))
            .filter((date) => date >= today)
            .map((date) => date.toISOString().slice(0, 10)) // Convert dates to strings (YYYY-MM-DD)
        )
      );

      // Sort the list of dates chronologically
      uniqueFutureDates.sort();
      setDates(uniqueFutureDates);
    } catch (error) {
      message.error(error.response.data.message || "Erreur interne du serveur");
      console.error("Error creating availability:", error);
    }
  };

  const confirmer = async () => {
    try {
      enterLoading(1);
      await axios.put(
        `${appointmentService}/confirmAndUpdateAppointment/${confirmation_token}/${verification_token}`,
        formData
      );
      setLoadings(false);

      setTimeout(() => {}, 6000);
      window.location.href = "/dashboard";
    } catch (error) {
      setLoadings(false);
      console.error("Confirmation failed:", error);
      message.error(error.response.data.message || "Erreur interne du serveur");
    }
  };
  useEffect(() => {
    getAppointment();
    
  }, []);

  return (
    <>
      <div className="layout-default ant-layout layout-sign-up">
        <Header>
          <div className="header-col header-brand">
            <img
              src={logo}
              alt="Logo"
              style={{ width: "60%", marginTop: "3%" }}
            />
          </div>
        </Header>

        <Content className="p-0">
          <div className="verification-header">
            <div className="content">
              <p class="VerificationTitle" style={{ width: "500px" }}>
                Réarrangement du rendez-vous
              </p>
              <p className="verificationT">
                Nous somme ravis de vous avoir parmi nous. Explorez nos
                services, découvrez de nouvelles opportunités
              </p>
            </div>
          </div>

          <Card className="card-reservation" bordered="false">
            <p className="verificationText">
              vous pouver réaranger votre Rendez-vous avec le medecin{" "}
              {doctor?.user?.first_name} {doctor?.user?.last_name}.
              {/* le {appointment.date} de {appointment.start_time} à {appointment.end_time} avec le médecin {doctor?.user?.first_name} {doctor?.user?.last_name} , cliquer sur ce bouton
              pour confirmer votre render-vous s'il vous plait ! */}
            </p>

            <Form layout="vertical" style={{ marginTop: "2%" }}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label="suggérer une journée"
                    rules={[
                      {
                        message: "Veuillez sélectionner une journée",
                      },
                    ]}
                  >
                    <Select
                      style={{ height: "40px" }}
                      onChange={(value) => {
                        handleChange("date", value);

                        setSelectedDate(value);

                        const filteredAvail = availabilityData.filter(
                          (item) => item.date === value // Use the selected date (value) directly, not selectedDate
                        );
                        handleChange("id_locale", filteredAvail[0]?.id_locale);
                        setServices(filteredAvail[0].Services);

                        if (filteredAvail[0]?.id_locale) {
                          fetchData(filteredAvail[0]?.id_locale?.split("_")[0]);
                        }
                        // .filter((item) => new Date(item.end_time) > new Date()); // Filter availabilities with end times in the future
                        setFilteredAvailabilities(filteredAvail);
                      }}
                      //   disabled={!formData.buffer_option}
                    >
                      {dates.map((date, index) => (
                        <Select.Option key={index} value={date}>
                          {date}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="date de réservation la plus rapprochée"
                    rules={[
                      {
                        // required: formData.buffer_option,
                        message: "Veuillez sélectionner une heure",
                      },
                    ]}
                  >
                    <Select
                      // defaultValue={bookData.after_session}
                      style={{ height: "40px" }}
                      onChange={(value) => {
                        const [startTime, endTime] = value.split(" - ");
                        handleChange("start_time", startTime);
                        handleChange("end_time", endTime);
                      }}
                      // disayybled={!formData.buffer_option}
                    >
                      {filteredAvailabilities.map((item, index) => (
                        <Select.Option
                          key={index}
                          value={`${item.start_time} - ${item.end_time}`}
                        >
                          {`${item.start_time} - ${item.end_time}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Services">
                    <Select
                      defaultValue={
                        `${
                          services?.find(
                            (service) =>
                              service?.id_service === formData?.id_service
                          )?.name || "Pas de service "
                        }   -  ${
                          services?.find(
                            (service) =>
                              service?.id_service === formData?.id_service
                          )?.type || ""
                        }` || ""
                      }
                      onChange={(value) => handleChange("id_service", value)}
                      className="custom-timezone-select"
                      style={{ width: "100%" }}
                    >
                      {services?.map((service) => (
                        <Option
                          key={service.id_service}
                          value={service.id_service}
                        >
                          {`${service.name} (${
                            service.type === "public" ? "publique" : "privée"
                          })`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Emplacement">
                    <Input
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      value={
                        `${
                          localData?.find(
                            (local) => local?.id === formData?.id_locale
                          )?.title || "Pas d'emplacement "
                        }   -  ${
                          localData?.find(
                            (local) => local?.id === formData?.id_locale
                          )?.bigtitle || ""
                        }` || ""
                      }
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <Button
              loading={loadings}
              type="text"
              htmlType="submit"
              style={{
                width: "100%",
                height: "40px",
                marginBottom: "10%",
                backgroundColor: "#005b8a",
              }}
              onClick={() => {
                confirmer();
              }}
              class="verification-button"
            >
              Confirmer
            </Button>
          </Card>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2024 Practicia by <a href="#pablo">Block-T</a>.{" "}
          </p>
        </Footer>
      </div>
    </>
  );
};

export default UpdateBooking;
