import React, { useEffect } from "react";
import VideoPlayer from "../components/layout/VideoPlayer";
import { useChat } from "../components/layout/ChatContext";

const VideoChat = () => {
  const { myVideo, setStream } = useChat();

  // useEffect(() => {
  //   navigator.mediaDevices
  //     .getUserMedia({ video: true, audio: true })
  //     .then((currentStream) => {
  //       setStream(currentStream);
  //       myVideo.current.srcObject = currentStream;
  //     })
  //     .catch((error) => {
  //       console.error("Error accessing media devices:", error);
  //     });
  // }, []);

  return (
    <>
      <VideoPlayer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      />
    </>
  );
};

export default VideoChat;
