import { Button, Card, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import Editor from "react-simple-wysiwyg";





const FicheComponent = ({
  handleFile,
  fileData,
  id_protocol,
  handleState,
  show,
}) => {
  const [data, setData] = useState(
    fileData
      ? {
          name: fileData?.name,
          description: fileData?.description,
        }
      : { name: "", description: "" }
  );

  const handleChange = (fieldName, value) => {
    setData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  return (
    <Card style={{ marginTop: "3%", marginBottom: "1%", width: "100%" }}>
      <div
        style={{
          marginTop: "3%",
          marginRight: "2%",
          padding: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Change "end" to "center"
        }}
      >
        <div>
          <h2 style={{ margin: 0 }}>Fiche</h2>
          <div
            style={{
              borderBottom: "5px solid #023047",
              width: "100%",
              marginTop: "5px",
            }}
          ></div>
        </div>
      </div>
      <div style={{ marginTop: "2%" }}>
        <p style={{ fontSize: "18px" }}> Titre</p>
        <Input
          disabled={show === true}
          value={data?.name}
          onChange={(e) => {
            handleChange("name", e.target.value);
            handleState(false);
          }}
          style={{ width: "50%", border: "2px solid #023047" }}
          placeholder="Titre"
        />{" "}
      </div>
      <div style={{ marginTop: "2%" }}>
        <p style={{ fontSize: "18px" }}> Contenu</p>

        <Editor
          disabled={show === true}
          value={data?.description}
          onChange={(e) => {
            handleChange("description", e.target.value);
            handleState(false);
          }}
          rows={4}
          style={{ width: "90%", border: "2px solid #023047" }}
          placeholder="Editeur de texte"
        />

        {/* <TextArea
            disabled={show === true}
          value={data?.description}
          onChange={(e) => {
            handleChange("description", e.target.value);
            handleState(false);
          }}
          rows={4}
          style={{ width: "90%", border: "2px solid #023047" }}
          placeholder="Editeur de texte"
        /> */}
      </div>
      {show === false && (
        <div style={{ textAlign: "center", marginTop: "3%" }}>
          <Button
            type="text"
            style={{ backgroundColor: "#DEF9C4" }}
            onClick={() => {
              handleFile(data);
              handleState(true);
            }}
          >
            {id_protocol ? `Modifier le fiche` : `Enregistrer le fiche`}
          </Button>{" "}
        </div>
      )}
    </Card>
  );
};

export default FicheComponent;
