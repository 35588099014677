import { PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Input,
  List,
  Modal,
  Row,
  Space,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ResetPassword from "../../pages/resetPassword";
import { useChat } from "./ChatContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const DoctorInformations = ({
  doctorData,
  editable,
  onEditClick,
  onEditCancel,
  onEditSave,
  updatedData,
  onUpdate,
  loading,
}) => {
  const history = useHistory();

  const isValidURL = (url) => {
    // Regular expression for URL validation
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };
  const { profile } = useChat();

  const [accreditations, setAccreditations] = useState(
    Array.isArray(doctorData?.Doctor?.accreditations)
      ? doctorData.Doctor.accreditations
      : []
  );

  const [socialNetworks, setSocialNetworks] = useState(
    doctorData.Doctor.social_networks || {}
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newAccreditationTitle, setNewAccreditationTitle] = useState("");
  const [newAccreditationBigTitle, setNewAccreditationBigTitle] = useState("");
  const showModal = () => {
    setNewAccreditationTitle("");
    setNewAccreditationBigTitle("");
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setAccreditationUpdatebale(false);
    setIsModalOpen(false);
    // setAccreditations(doctorData.Doctor.accreditations);
  };

  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ];
  const [editingIndex, setEditingIndex] = useState(null);
  const [accreditationUpdatebale, setAccreditationUpdatebale] = useState(false);

  const handleAccreditationUpdate = (index, fieldName, newData) => {
    const updatedAccreditations = [...accreditations];
    if (index >= 0 && index < updatedAccreditations.length) {
      updatedAccreditations[index][fieldName] = newData;
      onUpdate("accreditations", updatedAccreditations);
    } else {
      console.error("Index fourni non valide.");
    }
  };

  const handleDeleteAccreditation = (index) => {
    const updatedAccreditations = [...accreditations];
    if (index >= 0 && index < updatedAccreditations.length) {
      updatedAccreditations.splice(index, 1);
      setAccreditations(updatedAccreditations);
      onUpdate("accreditations", updatedAccreditations);
    } else {
      console.error("Invalid index provided.");
    }
  };

  const cancelLine = () => {
    setAccreditationUpdatebale(false);
    setAccreditations(doctorData.Doctor.accreditations);
  };

  const handleAddAccreditation = () => {
    if (!(newAccreditationTitle === "" || newAccreditationBigTitle === "")) {
      const updatedAccreditations = Array.isArray(accreditations)
        ? [...accreditations]
        : [];
      const newAccreditation = {
        title: newAccreditationTitle,
        bigtitle: newAccreditationBigTitle,
      };
      setAccreditations([...updatedAccreditations, newAccreditation]);

      const addedAccreditations = [...updatedAccreditations, newAccreditation];
      onUpdate("accreditations", addedAccreditations);
      setIsModalOpen(false);
    } else {
      message.error("Remplacer les champs s'il vous plais");
    }
  };

  const socialNetworkPlatforms = [
    "LinkedIn",
    "Twitter",
    "Pinterest",
    "Facebook",
    "Instagram",
    "Skype",
    "Blog",
    // Add more platforms here if needed
  ];
  const handleSocialNetworksUpdate = (platform, newValue) => {
    const updatedSocialNetworks = { ...socialNetworks, [platform]: newValue };
    setSocialNetworks(updatedSocialNetworks);
    onUpdate("social_networks", updatedSocialNetworks);
  };

  useEffect(() => {
    setSocialNetworks(doctorData.Doctor.social_networks || {});
    setAccreditations(doctorData.Doctor.accreditations);
  }, [editable]);

  return (
    <Row justify="start" align="middle" gutter={[24, 0]}>
      <Col span={24} md={18} className="mb-24">
        <Card
          bordered={false}
          title={<h6 className="font-semibold m-0"></h6>}
          style={{ width: "134%", backgroundColor: "#ededed" }}
          className="header-solid h-full card-profile-information"
          extra={
            <>
              {profile === undefined ? (
                editable ? (
                  <Space>
                    {/* <Button
                    style={{
                      backgroundColor: "green",
                      marginRight: "20px",
                      border: "1px solid gray",
                    }}
                    type="primary"
                    onClick={() => {
                      window.location.href = "/resetPassword";
                    }}
                  >
                    Changer mot de passe
                  </Button> */}
                    <Button
                      loading={loading}
                      type="primary"
                      style={{ backgroundColor: "#01013d" }}
                      onClick={onEditSave}
                    >
                      Sauvegarder
                    </Button>
                    <Button
                      type="text"
                      style={{ backgroundColor: "orange" }}
                      onClick={onEditCancel}
                    >
                      Annuler
                    </Button>
                  </Space>
                ) : (
                  <Button type="link" onClick={onEditClick}>
                    {pencil}
                  </Button>
                )
              ) : (
                <Button
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Retour à la page des médecins
                </Button>
              )}
            </>
          }
          bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
        >
          <Card
            title={
              <h6 className="font-semibold m-0">Informations sur le profil</h6>
            }
          >
            <div layout="vertical" className="descritipntesxarea" column={1}>
              <Descriptions.Item label="Description" span={1} id={"hh"}>
                {editable ? (
                  <Input.TextArea
                    defaultValue={doctorData.Doctor.bioagraphy}
                    onChange={(e) => {
                      onUpdate("bioagraphy", e.target.value);
                    }}
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    style={{ width: "96.5%" }}
                  />
                ) : (
                  <p className="AccreditationsV">
                    {doctorData.Doctor.bioagraphy}
                  </p>
                )}
              </Descriptions.Item>
            </div>

            <Descriptions layout="vertical" column={3}>
              <Descriptions.Item label="Titre" span={1}>
                {editable ? (
                  <Input
                    style={{ width: "90%" }}
                    defaultValue={doctorData.Doctor.title}
                    onChange={(e) => {
                      onUpdate("title", e.target.value);
                    }}
                  />
                ) : (
                  <p className="AccreditationsV">{doctorData.Doctor.title}</p>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Prénom" span={1}>
                {editable ? (
                  <Input
                    style={{ width: "90%" }}
                    defaultValue={doctorData.first_name}
                    onChange={(e) => {
                      onUpdate("first_name", e.target.value);
                    }}
                  />
                ) : (
                  <p className="AccreditationsV">{doctorData.first_name}</p>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Nom" span={1}>
                {editable ? (
                  <Input
                    style={{ width: "90%" }}
                    defaultValue={doctorData.last_name}
                    onChange={(e) => {
                      onUpdate("last_name", e.target.value);
                    }}
                  />
                ) : (
                  <p className="AccreditationsV">{doctorData.last_name}</p>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Titre professionnel" span={1}>
                {editable ? (
                  <Input
                    style={{ width: "90%" }}
                    defaultValue={doctorData.Doctor.professional_title}
                    onChange={(e) => {
                      onUpdate("professional_title", e.target.value);
                    }}
                  />
                ) : (
                  <p className="AccreditationsV">
                    {doctorData.Doctor.professional_title}
                  </p>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Lettres de désignation" span={1}>
                {editable ? (
                  <Input
                    style={{ width: "90%" }}
                    defaultValue={doctorData.Doctor.designation_letters}
                    onChange={(e) => {
                      onUpdate("designation_letters", e.target.value);
                    }}
                  />
                ) : (
                  <p className="AccreditationsV">
                    {doctorData.Doctor.designation_letters}
                  </p>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Adresse email" span={1}>
                {editable ? (
                  <Input
                    style={{ width: "90%" }}
                    value={doctorData.email}
                    readOnly={true}
                  />
                ) : (
                  <p className="AccreditationsV">{doctorData.email}</p>
                )}
              </Descriptions.Item>
              {/* <Descriptions.Item
                label="Prix d'une consultation (Heure)"
                span={1}
              >
                {editable ? (
                  <Input
                    prefix=" € "
                    suffix="Eur"
                    type="number"
                    style={{ width: "90%" }}
                    defaultValue={doctorData.Doctor.hour_price}
                    onChange={(e) => {
                      const realValue = parseFloat(e.target.value);
                      onUpdate("hour_price", realValue);
                    }}
                  />
                ) : (
                  <p className="AccreditationsV">
                    {doctorData.Doctor.hour_price} €
                  </p>
                )}
              </Descriptions.Item> */}
              {/* <Descriptions.Item
                label="Prix d'une consultation en serie (Heure)"
                span={1}
              >
                {editable ? (
                  <Input
                    prefix=" € "
                    suffix="Eur"
                    style={{ width: "90%" }}
                    type="number"
                    // step="0.01"
                    defaultValue={doctorData.Doctor.hour_serie_price}
                    onChange={(e) => {
                      const realValue = parseFloat(e.target.value);
                      onUpdate("hour_serie_price", realValue);
                    }}
                  />
                ) : (
                  <p className="AccreditationsV">
                    {doctorData.Doctor.hour_serie_price} €
                  </p>
                )}
              </Descriptions.Item> */}
            </Descriptions>
            {/* <hr className="my-25" /> */}
          </Card>

          <Card
            style={{ margin: "2% 0" }}
            title={<h6 className="font-semibold m-0">Réseaux sociaux</h6>}
          >
            <div style={{}}>
              <Row gutter={[24, 24]}>
                {[0, 1].map((index) => (
                  <Col span={8} key={index}>
                    <div>
                      <p className="AccreditationsP">
                        {socialNetworkPlatforms[index]} nom d'utilisateur:
                      </p>
                      {editable ? (
                        <Input
                          style={{ width: "90%" }}
                          defaultValue={
                            socialNetworks[
                              socialNetworkPlatforms[index].toLowerCase()
                            ]
                          }
                          onChange={(e) =>
                            handleSocialNetworksUpdate(
                              socialNetworkPlatforms[index].toLowerCase(),
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        <span className="AccreditationsV">
                          {socialNetworks[
                            socialNetworkPlatforms[index].toLowerCase()
                          ] || ""}
                        </span>
                      )}
                    </div>
                  </Col>
                ))}
                {[2, 3].map((index) => (
                  <Col span={8} key={index}>
                    <div>
                      <p className="AccreditationsP">
                        {socialNetworkPlatforms[index]} nom d'utilisateur:
                      </p>
                      {editable ? (
                        <Input
                          style={{ width: "90%" }}
                          defaultValue={
                            socialNetworks[
                              socialNetworkPlatforms[index].toLowerCase()
                            ]
                          }
                          onChange={(e) =>
                            handleSocialNetworksUpdate(
                              socialNetworkPlatforms[index].toLowerCase(),
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        <span className="AccreditationsV">
                          {socialNetworks[
                            socialNetworkPlatforms[index].toLowerCase()
                          ] || ""}
                        </span>
                      )}
                    </div>
                  </Col>
                ))}
                {[4, 5].map((index) => (
                  <Col span={8} key={index}>
                    <div>
                      <p className="AccreditationsP">
                        {socialNetworkPlatforms[index]} nom d'utilisateur:
                      </p>
                      {editable ? (
                        <Input
                          style={{ width: "90%" }}
                          defaultValue={
                            socialNetworks[
                              socialNetworkPlatforms[index].toLowerCase()
                            ]
                          }
                          onChange={(e) =>
                            handleSocialNetworksUpdate(
                              socialNetworkPlatforms[index].toLowerCase(),
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        <span className="AccreditationsV">
                          {socialNetworks[
                            socialNetworkPlatforms[index].toLowerCase()
                          ] || ""}
                        </span>
                      )}
                    </div>
                  </Col>
                ))}
              </Row>
              <Row>
                <Col span={24}>
                  <div style={{ marginTop: "1%" }}>
                    <p className="AccreditationsP">
                      {socialNetworkPlatforms[6]} URL:
                    </p>
                    {editable ? (
                      <Input
                        style={{
                          width: "97%",
                          marginBottom: "4px",
                        }}
                        defaultValue={
                          socialNetworks[
                            socialNetworkPlatforms[6].toLowerCase()
                          ]
                        }
                        onChange={(e) => {
                          handleSocialNetworksUpdate(
                            socialNetworkPlatforms[6].toLowerCase(),
                            e.target.value
                          );
                        }}
                      />
                    ) : (
                      <span className="AccreditationsV">
                        {socialNetworks[
                          socialNetworkPlatforms[6].toLowerCase()
                        ] || ""}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Card>

          <Card
            title={<h6 className="font-semibold m-0">Accréditations</h6>}
            extra={
              <>
                {editable && (
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#01013d" }}
                    className="AccreditationIcon"
                    onClick={showModal}
                  >
                    Ajouter une accréation
                  </Button>
                )}
              </>
            }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0",
              }}
            >
              <h2></h2>

              <Modal
                title="Ajouter une accréditation"
                visible={isModalOpen}
                onOk={handleAddAccreditation}
                onCancel={handleCancel}
              >
                <Input
                  value={newAccreditationTitle}
                  style={{ width: "61%", marginBottom: "3%" }}
                  placeholder="Entrez un titre"
                  onChange={(e) => {
                    setNewAccreditationTitle(e.target.value);
                  }}
                />
                <Input
                  value={newAccreditationBigTitle}
                  style={{ width: "61%" }}
                  placeholder="Entrez un grand titre"
                  onChange={(e) => {
                    setNewAccreditationBigTitle(e.target.value);
                  }}
                />
              </Modal>
            </div>

            <div
              style={{
                height: 120,
                overflow: "auto",
                padding: "0 16px",
                border: "1px solid rgba(140, 140, 140, 0.35)",
              }}
            >
              <InfiniteScroll
                dataLength={
                  editable
                    ? accreditations?.length || 0 // Use optional chaining to check if accreditations is null or undefined
                    : doctorData?.Doctor?.accreditations?.length || 0 // Use optional chaining to check if doctorData.Doctor.accreditations is null or undefined
                }
                hasMore={false}
                loader={<h4>Chargement...</h4>}
                scrollableTarget="scrollableDiv"
              >
                <List
                  dataSource={
                    editable
                      ? accreditations || []
                      : doctorData?.Doctor?.accreditations || []
                  }
                  renderItem={(item, index) => (
                    <List.Item key={item.id}>
                      <List.Item.Meta
                        title={
                          accreditationUpdatebale && editingIndex === index ? (
                            <Input
                              style={{ width: "35%", height: "80%" }}
                              defaultValue={item.title}
                              onChange={(e) =>
                                handleAccreditationUpdate(
                                  index,
                                  "title",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            item.title
                          )
                        }
                        description={
                          accreditationUpdatebale && editingIndex === index ? (
                            <Input
                              style={{ width: "35%", height: "80%" }}
                              defaultValue={item.bigtitle}
                              onChange={(e) =>
                                handleAccreditationUpdate(
                                  index,
                                  "bigtitle",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            item.bigtitle
                          )
                        }
                      />
                      {editable && (
                        <Space>
                          {editable &&
                          accreditationUpdatebale &&
                          editingIndex === index ? (
                            <Button
                              type="primary"
                              danger
                              onClick={() => cancelLine()}
                            >
                              Annuler
                            </Button>
                          ) : (
                            <Button
                              type="text"
                              style={{ backgroundColor: "orange" }}
                              onClick={() => {
                                setAccreditationUpdatebale(true);
                                setEditingIndex(index);
                              }}
                            >
                              Mise à jour
                            </Button>
                          )}
                          {editable &&
                          accreditationUpdatebale &&
                          editingIndex === index ? (
                            <Button
                              type="primary"
                              onClick={() => {
                                setAccreditationUpdatebale(false);
                                setEditingIndex(null);
                              }}
                            >
                              Sauvegarder
                            </Button>
                          ) : (
                            <Button
                              type="primary"
                              danger
                              onClick={() => handleDeleteAccreditation(index)}
                            >
                              Supprimer
                            </Button>
                          )}
                        </Space>
                      )}
                    </List.Item>
                  )}
                />
              </InfiniteScroll>
            </div>
          </Card>
        </Card>
      </Col>

      {editable && (
        <div style={{ width: "100%", margin: "0 auto" }}>
          <ResetPassword />
        </div>
      )}
    </Row>
  );
};
