import React, { useState } from "react";
import { Layout, Button, Typography, Card, Form, Input, message } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { userServices } from "../utils/URL";
import logo from "../assets/images/whiteLogo.png"; // Import the logo image

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

const ForgotPassword = () => {
  const onFinish = async (values) => {
    try {
      setLoadings(true);
      const response = await axios.post(
        `${userServices}/sendResetPasswordEmail`,
        values
      );
      message.success(response.data.message);
      setLoadings(false);
      window.location.href = "/";
    } catch (error) {
      setLoadings(false);
      console.error("Failed to send reset password email:", error);
      message.error(error.response.data.message || "Erreur interne du serveur");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [loadings, setLoadings] = useState(false);

  return (
    <>
      <Layout className="layout-default ant-layout layout-sign-up">
        <Header>
          <div className="header-col header-brand">
          <img src={logo} alt="Logo" style={{width: "60%", marginTop: "3%"}} />
          </div>
        </Header>

        <Content className="p-0">
          <div className="verification-header">
            <div
              className="content"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Title style={{ width: "900px", textAlign: "center" }}>
                Vous avez oublié votre mot de passe.
              </Title>
              <p className="text-lg" style={{ textAlign: "center" }}>
                Utilisez ce superbe formulaire pour envoyer un e-mail où vous
                pouvez changer votre mot de passe.
              </p>
            </div>
          </div>

          <Card
            className="card-signup header-solid h-full ant-card pt-0"
            title={<h5>Mot de passe oublié </h5>}
            bordered={false}
          >
            <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              className="row-col"
            >
              <Form.Item
                className="username"
                style={{ width: "100%" }}
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir votre email!",
                  },
                  {
                    type: "email",
                    message: "Veuillez saisir une adresse email valide!",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item>
                <Button
                  loading={loadings}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%", marginTop: "7%" }}
                >
                  Réinitialiser le mot de passe
                </Button>
              </Form.Item>

              <p className="font-semibold text-muted">
                ou{" "}
                <Link to="/" className="text-dark font-bold">
                  Se connecter
                </Link>
              </p>
            </Form>
          </Card>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2024 PB par <a href="#pablo">créatif</a>.{" "}
          </p>
        </Footer>
      </Layout>
    </>
  );
};

export default ForgotPassword;
